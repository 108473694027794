import { createAction } from 'redux-actions';
import searchTypes from '../../types/search';

const {
  GET_CARS,
  GET_MORE_CARS,
  GET_CARS_SUCCESS,
  GET_MORE_CARS_SUCCESS,
  GET_CARS_ERROR,
} = searchTypes;

export const getCars = createAction(GET_CARS, queries => ({ queries }));
export const getMoreCars = createAction(GET_MORE_CARS, (queries, page) => ({ queries, page }));
export const getCarsSuccess = createAction(GET_CARS_SUCCESS, cars => ({ cars }));
export const getMoreCarsSuccess = createAction(GET_MORE_CARS_SUCCESS, cars => ({ cars }));
export const getCarsError = createAction(GET_CARS_ERROR);
