export const desktopNavigationSchema = [
  {
    title: 'Зарегистрировать машину',
    key: 'register',
    path: '/car/register',
  },
  {
    title: 'Мои поездки',
    key: 'trips',
    path: '#',
  },
  {
    title: 'Центр помощи',
    key: 'help',
    path: '#',
  },
  {
    title: 'Аккаунт',
    key: 'account',
    path: '#',
  },
];
