import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const Container = styled.div`
  & {
    width: 100%;
  }

  .currency {
    font-weight: 400;
  }

  @media ${breakpoints.laptop} {
    position: relative;
    width: calc((100% - 264px) / 12 * 7 + 168px);
    margin-left: calc((100% - 264px) / 12 + 24px);
  }
`;

export const Title = styled.h2`
  && {
    font-family: 'Open Sans', 'sans-serif';
    font-weight: 600;
    color: var(--primary-text);
    font-size: 24px;
    line-height: 24px;
    margin: 16px 0 8px 0;
  }

  @media ${breakpoints.tablet} {
    && {
      font-size: 24px;
      line-height: 37px;
      color: var(--dark-text);
      margin: 38px 0 7px 0;
    }
  }

  @media ${breakpoints.laptop} {
    && {
      font-size: 32px;
      margin: 39px 0 11px 0;
    }
  }
`;

export const Subtitle = styled.h3`
  font-family: 'Open Sans', 'sans-serif';
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: var(--primary-text);
  margin-bottom: 18px;

  @media ${breakpoints.tablet} {
    margin: 0 0 24px 0;
    font-size: 20px;
    line-height: 20px;
  }
`;

export const Box = styled.div`
  margin-bottom: ${props => (props.margin ? '20px' : '37px')};
  .limit {
    margin: 0;
    padding: 0;
  }

  .limit .item {
    list-style-type: none;
    color: #8f9bb3;
    margin-bottom: 8px;
    &:last-child {
      position: relative;
      margin-bottom: 0px;
    }

    &::before {
      content: '';
      display: inline-block;
      transform: translate(0, -50%);
      width: 4px;
      height: 4px;
      margin-right: 12px;
      background-color: #8f9bb3;
    }
  }

  .footnote {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5d5d5d;
    margin-left: 16px;
  }

  .limit .text {
    color: var(--primary-text);
    font-size: 16px;
    line-height: 24px;
  }

  .limit .-bold {
    font-weight: 600;
  }

  .-help {
    position: absolute;
    margin-left: 5px;
  }

  .periods {
    margin: 0 0 6px 16px;
  }

  .period {
    color: var(--primary-text);

    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.05px;
    line-height: 24px;
    border-right: 1px solid #c4c4c4;
    padding: 0 8px;
    &:first-child {
      padding: 0 8px 0 0;
    }
    &:last-child {
      border-right: none;
    }

    &:after {
      margin: 0 16px;
    }
  }

  .features {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${breakpoints.tablet} {
    & {
      margin-bottom: ${props => (props.margin ? '28px' : '44px')};
    }

    .periods {
      margin: 7px 0 7px 16px;
    }

    .period {
      font-size: 16px;
    }

    .host {
      margin-top: 44px;
    }

    .limit .item {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export const Host = styled.div`
  & {
    display: flex;
  }

  .reliability {
    display: inline-block;
    color: var(--primary-text);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .checkmark {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  .skills {
    margin-left: 13px;
  }

  .skills > .name {
    display: inline-block;
    line-height: 40px;
    color: var(--dark-text);
    font-size: 16px;
    font-weight: 600;
    margin-right: 12px;
  }

  .skills > .skill {
    color: var(--primary-text);
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 16px;
    &:not(:last-child)::after {
      content: '';
      display: inline-block;
      transform: translate(0, -50%);
      width: 4px;
      height: 4px;
      margin: 0 14px 0 12px;
      background-color: #c4c4c4;
    }
  }

  @media ${breakpoints.tablet} {
    .avatar {
      width: 60px;
      height: 60px;
    }

    .skills {
      margin-left: 18px;
    }
  }
`;

export const Rate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #f1a153;
  border-radius: 16px;
  width: 65px;
  margin-right: 20px;

  .rate {
    height: 13px;
    width: 14px;
    margin-left: 4px;
    fill: #f1a153;
  }
`;

export const ReviewsCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 32px;

  &:before {
    content: '';
    background-color: #c4c4c4;
    width: 4px;
    height: 4px;
    margin: 0 12px 0 12px;
  }

  .reviews {
    height: 23px;
    width: 23px;
    margin-right: 6px;
  }

  .reviews use {
    fill: #222b45;
  }
`;

export const SocialParametrs = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 8px 0 20px 0;
    color: var(--primary-text);

    font-size: 16px;
  }

  @media ${breakpoints.tablet} {
    & {
      margin: 0px 0 15px 0;
    }
  }
`;

export const Car = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .icon {
    width: 24px;
    height: 24px;
  }

  .icon use {
    fill: #303133;
  }

  .icon path {
    fill: #303133;
  }

  .icon.-body {
    width: 32px;
    height: 15px;
    margin-right: 8px;
  }

  .icon.-transmission {
    width: 20px;
    height: 24px;
    margin-right: 8px;
  }

  .icon.-seat {
    width: 15px;
    height: 24px;
    margin-right: 8px;
  }

  .icon.-engine {
    width: 22px;
    height: 24px;
    margin-right: 8px;
  }

  .element {
    display: flex;
    align-items: center;
    color: var(--primary-text);
    font-size: 16px;
    height: 30px;
    width: 50%;
  }

  @media ${breakpoints.tablet} {
    .element {
      width: auto;
      height: auto;
      margin-left: 32px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media ${breakpoints.laptop} {
    .element {
      margin-left: 22px;
    }
  }
`;

export const Text = styled.p`
  margin: 0;
  color: var(--primary-text);
  font-size: 16px;
  line-height: 24px;

  @media ${breakpoints.tablet} {
    width: 558px;
  }
`;

export const Feature = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  width: 344px;

  .icon {
    width: 26px;
    height: 26px;
  }

  .icon use {
    fill: #303133;
  }

  .text {
    color: var(--primary-text);
    font-family: 'Open Sans';
    font-size: 16px;
    letter-spacing: -0.07px;
    line-height: 24px;
    margin-left: 15px;
  }

  @media ${breakpoints.tablet} {
    width: 45%;

    .br {
      display: none;
    }
  }
`;

export const Map = styled.div`
  .map-title {
    margin-bottom: 7px;
  }

  .address,
  .delivery {
    color: var(--primary-text);

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .img {
    width: calc(100% + 32px);
    margin-left: -16px;
    overflow: hidden;
  }

  @media ${breakpoints.tablet} {
    .map-title {
      margin-bottom: 15px;
    }

    .br {
      display: none;
    }

    .img {
      width: calc(100% + 50px);
      margin-left: -25px;
    }

    .address {
      margin-bottom: 32px;
    }
  }

  @media ${breakpoints.laptop} {
    .img {
      margin: 0 -24px;
    }

    .map-title,
    .address {
      margin-left: calc((100% - 264px) / 12 + 24px);
    }

    .address {
      margin-bottom: 22px;
    }

    .img {
      width: calc(100% + 50px);
      margin-left: -25px;
    }
  }

  @media ${breakpoints.desktop} {
    .img {
      width: 100vw;
      transform: translate(770px);
      margin: 0 -50vw 0 0;
      margin-left: calc(2px - 50vw - 52px);
    }
  }
`;

export const ListItem = styled.div`
  & {
    margin-bottom: 16px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .name,
  .price {
    display: block;
  }

  .name.-device,
  .price.-device {
    display: inline-block;
    margin-left: 0;
  }

  .name {
    color: var(--primary-text);

    font-size: 16px;
    line-height: 16px;
    margin-bottom: 4px;
    &::before {
      content: '';
      display: inline-block;
      transform: translate(0, -50%);
      width: 4px;
      height: 4px;
      margin-right: 12px;
      background-color: #8f9bb3;
    }
  }

  .price {
    margin-left: 16px;
    color: var(--grey-text);
    font-family: 'Open Sans', san-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }

  .name > b,
  .price > b {
    color: var(--primary-text);
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
  }

  .currency {
    color: var(--primary-text);
    font-size: 16px;
    font-weight: 400;
  }

  @media ${breakpoints.tablet} {
    .br {
      display: none;
    }
  }
`;
