import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const PhotoWrap = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
    }

    .photo-container {
      position: relative;
      margin-bottom: 32px;
    }

    .photos {
      margin-top: 25px;
    }

    .photo {
      width: 100%;
      height: 198px;
      object-fit: cover;
      border: 1px solid rgba(6, 107, 214, 0.3);
      border-radius: 10px;
      background-color: rgba(6, 107, 214, 0.03);
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .filepond--wrapper {
      align-items: center;
      width: 100%;
      height: 100%;
      padding-bottom: 10px;
    }

    .filepond--wrapper .uploader {
      height: auto !important;
      margin: 0;
    }

    .filepond--root.uploader.filepond--hopper {
      display: flex;
      flex-wrap: wrap;
    }

    .filepond--wrapper .filepond--list.filepond--list {
      min-height: 0;
    }

    .filepond--item {
      margin: 32px 0 0 0;
      border: 1px solid rgba(6, 107, 214, 0.3);
      border-radius: 10px;
      background-color: rgba(6, 107, 214, 0.03);
      overflow: hidden;
    }

    .filepond--wrapper .filepond--drop-label {
      opacity: 1 !important;
      visibility: visible !important;
      width: 100%;
      position: static;
    }

    .filepond--wrapper .filepond--list-scroller {
      position: static;
      width: 100%;
      height: auto !important;
      margin: 0;
    }

    .filepond--wrapper .filepond--item {
      width: 100%;
    }

    .filepond--wrapper .filepond--image-clip {
      width: 100% !important;
    }

    .filepond--wrapper .filepond--file-action-button.filepond--action-revert-item-processing {
      width: 40px;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .filepond--wrapper .filepond--action-revert-item-processing[disabled='disabled'] {
      display: none;
    }

    .filepond--wrapper .filepond--file-action-button.filepond--file-action-button svg {
      width: 25px;
      height: 25px;
    }
  }

  @media ${breakpoints.tablet} {
    .photo-container {
      width: 300px;
    }

    .photo {
      width: 298px;
    }

    .filepond--wrapper .uploader {
      height: auto !important;
    }

    .filepond--wrapper .filepond--drop-label {
      width: 300px;
    }

    .filepond--wrapper .filepond--item {
      width: 298px !important;
      height: 198px !important;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 40px;
    }

    .photos {
      width: 100%;
      margin-bottom: 32px;
    }

    .photo-container {
      margin-right: 24px;
    }

    .filepond--wrapper {
      min-height: 200px;
      min-width: 300px;
    }

    .filepond--wrapper .uploader {
      margin: 0;
    }

    .filepond--root.uploader.filepond--hopper {
      position: relative;
    }

    .filepond--wrapper .filepond--drop-label {
      width: 300px;
      position: absolute;
    }

    .filepond--wrapper .filepond--item {
      margin: 0 32px 32px 0;

      &:first-child {
        margin: 0 32px 32px 332px;
      }
    }
  }
`;
