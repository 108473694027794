import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { includes } from 'ramda';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { carActions } from '../../redux/actions';
import { colors, names, statuses } from '../../constants/statuses';

import { CarMiniature, Photo, Marker } from './styles';

const { publishCar, unpublishCar } = carActions;

const { PUBLISHED, RENT, STASHED } = statuses;

export const CarListItem = ({
  car: {
    uuid,
    photoUrl,
    status,
    brand,
    model,
    productionYear,
    rentPricePerDay,
    registrationNumber,
  },
}) => {
  const [state, setState] = React.useState({
    active: includes(status, [PUBLISHED, RENT]),
  });

  const dispatch = useDispatch();

  const handleChange = ({ target: { checked } }) => {
    checked ? dispatch(publishCar(uuid)) : dispatch(unpublishCar(uuid));
    setState({ active: checked });
  };
  return (
    <Grid item sm={12} md={6} lg={4} xl={3} key={uuid}>
      <CarMiniature key={uuid}>
        <div className="photo-box">
          <Photo photo={photoUrl} />
          <div className="status-box">
            <Marker color={colors[status]} />
            <span className="status">{names[status]}</span>
          </div>
        </div>
        <div className="description">
          <div className="title">
            <h4 className="name">{`${brand} ${model}, ${productionYear}`}</h4>
            <h4 className="price">{rentPricePerDay}</h4>
          </div>
          <span className="number">{`Номер: ${registrationNumber}`}</span>
        </div>
        <div className="actions">
          <FormControlLabel
            className="switch"
            disabled={!includes(status, [STASHED, PUBLISHED])}
            control={
              <Switch
                checked={state.active}
                onChange={handleChange}
                name="active"
                color="primary"
              />
            }
            label={state.active ? 'На поиске' : 'Скрыта'}
          />
          <Tooltip title="Редактировать объявление">
            <Link to={`/car/edit/${uuid}`} className="icon-box">
              <EditIcon className="icon" />
            </Link>
          </Tooltip>
        </div>
      </CarMiniature>
    </Grid>
  );
};
