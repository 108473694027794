import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const ConfirmWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      max-width: 400px;
      padding: 24px;
    }

    .actions {
      display: flex;
      justify-content: end;
      margin-top: 24px;
    }

    .confirm {
      margin-left: 24px;
    }

    .close {
      position: absolute;
      top: 12px;
      right: 14px;
      cursor: pointer;
    }
  }
`;

// TODO style it
export const Title = styled.h2``;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    & {
      color: #303133;
      font-size: 16px;
      line-height: 24px;
      margin-right: 30px;
    }
  }
`;
