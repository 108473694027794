import React from 'react';
import { Field } from 'formik';
import Input from '@material-ui/core/Input';

import { Error } from '../../../styledComponents';
import { TabLabel } from '../featureStyles';
import { DeviceWrapper } from './styles';

export const AdditionalDeviceConditions = ({ setFieldValue, name, values, errors }) => {
  const { dailyPrice, wholeRentPrice } = values[name];
  return (
    <DeviceWrapper>
      <div className="input-wrapper">
        <TabLabel fontWeight={400} htmlFor="under">
          Цена за день (при аренде до 7 дней), &#8381;
        </TabLabel>
        <Field
          id={`${name}-under`}
          className="input"
          placeholder="Например, 250"
          defaultValue={dailyPrice}
          onBlur={({ target: { value } }) =>
            setFieldValue(name, { ...values[name], dailyPrice: value })
          }
          component={Input}
          type="number"
        />
        {errors[name] && errors[name].dailyPrice ? <Error>{errors[name].dailyPrice}</Error> : null}
      </div>
      <div className="input-wrapper">
        <TabLabel fontWeight={400} htmlFor="over">
          Цена за весь период (при аренде от 7 дней), &#8381;
        </TabLabel>
        <Field
          id={`${name}-over`}
          className="input"
          placeholder="Например, 2300"
          defaultValue={wholeRentPrice}
          onBlur={({ target: { value } }) =>
            setFieldValue(name, { ...values[name], wholeRentPrice: value })
          }
          component={Input}
          type="number"
        />
        {errors[name] && errors[name].wholeRentPrice ? (
          <Error>{errors[name].wholeRentPrice}</Error>
        ) : null}
      </div>
    </DeviceWrapper>
  );
};
