import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import { ReviewWrapper } from './styles';

export const Review = ({ author, date, rating, text }) => (
  <ReviewWrapper>
    <div className="params">
      <Avatar
        alt="avatar"
        // TODO add image location parametr
        src="http://creu.ru/wp-content/uploads/2017/06/55b3f623ddede1b610979edc8455d267.jpg"
      />
      <div className="container">
        <div className="line">
          <span className="author">{author}</span>
          <Rating name="read-only" value={rating} readOnly />
        </div>
        <span className="date">{date}</span>
      </div>
    </div>
    <p className="text">{text}</p>
  </ReviewWrapper>
);
