import React from 'react';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { useQuery, checkQuery, setQuery } from '../../../../services/query-service';

import { StyledPriceSort } from './styles';

// TODO disable active price arrow
export const PriceSort = () => {
  const query = useQuery();
  const history = useHistory();
  const order = query.get('order') ? checkQuery(query.get('order')) : 'price:asc';
  return (
    <StyledPriceSort>
      <h6 className="title">Цена:</h6>
      <Link
        component="button"
        variant="body2"
        className="btn -up"
        onClick={() => setQuery('order', 'price:asc', query, history)}
      >
        <ArrowUpwardOutlinedIcon className={`icon ${order === 'price:asc' ? '-active' : ''}`} />
      </Link>
      <Link
        component="button"
        variant="body2"
        className="btn -down"
        onClick={() => setQuery('order', 'price:desc', query, history)}
      >
        <ArrowDownwardOutlinedIcon className={`icon ${order === 'price:desc' ? '-active' : ''}`} />
      </Link>
    </StyledPriceSort>
  );
};
