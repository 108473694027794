import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Wrapper } from './styles';

export const Loader = () => (
  <Wrapper>
    <CircularProgress size={50} />
  </Wrapper>
);
