import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';

export const UserPanel = styled.div`
  @media ${breakpoints.tablet} {
    & {
      position: relative;
      display: flex;
      width: 35px;
      height: 35px;
    }

    .menu-paper {
      margin-top: 5px;
    }

    .icon-wrap {
      position: absolute;

      &:hover {
        background: none;
      }
    }
  }
`;

export const NavigationMenu = styled.div`
  @media ${breakpoints.tablet} {
    & {
      display: flex;
      align-items: center;
    }

    .menu-paper {
      margin-top: 15px;
    }
  }
`;

export const AuthorizationWrap = styled.div`
  & {
    display: flex;
    align-items: center;
  }

  .link {
    font-family: 'Open Sans', sans-serif;
  }

  .dot-divider {
    width: 3px;
    height: 3px;
    border: 1px solid #c4c4c4;
    background: #c4c4c4;
    margin: 0 15px;
  }
`;

export const DesktopNavWrapper = styled.div`
  @media ${breakpoints.tablet} {
    & {
      display: flex;
    }

    .link {
      color: #303133;
      margin-left: 30px;
      font-size: 14px;
      cursor: pointer;

      &.-register {
        font-weight: 600;
        color: #066bd6;
      }

      &.-item {
        margin-left: 0;
      }
    }

    ${AuthorizationWrap} {
      & {
        padding-left: 30px;
        margin-left: 25px;
        border-left: 1px solid #c4c4c4;
      }

      .link {
        margin: 0;
      }
    }

    ${UserPanel} {
      margin-left: 30px;
    }
  }
`;
