import { styled } from 'linaria/react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { breakpoints } from '../../constants/breakpoints';

export const TabTitle = styled.h3`
  @media ${breakpoints.mobile} {
    & {
      font-weight: 600;
      line-height: 32px;
      font-size: 20px;
      color: #303133;
    }
  }
`;

export const TabRecomendation = styled.p`
  @media ${breakpoints.mobile} {
    & {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #5d5d5d;
      width: 100%;
      margin: 4px 0 32px 0;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      margin: 4px 0 24px 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      max-width: 650px;
    }
  }
`;

export const TabTextArea = styled(TextField)`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      max-width: 650px;
    }
  }
`;

export const TabFormGroup = styled(FormGroup)`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const TabFormControlLabel = styled(FormControlLabel)`
  @media ${breakpoints.mobile} {
    .MuiFormControlLabel-label {
      color: #303133;
      font-size: 16px;
      letter-spacing: -0.07px;
      line-height: 24px;
    }
  }
`;

export const TabLabel = styled.label`
  @media ${breakpoints.mobile} {
    & {
      display: block;
      font-weight: ${props => props.fontWeight || 600};
      font-size: 16px;
      line-height: 24px;
      color: #303133;
      margin-bottom: 8px;
    }
  }
`;

export const TabSubtitle = styled.h4`
  @media ${breakpoints.mobile} {
    & {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #303133;
    }
  }
`;

export const CarEditSidebar = styled.div`
  @media ${breakpoints.tablet} {
    & {
      width: 255px;
    }

    .list {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #d8d8d8;
      border-radius: 10px;
      background-color: #ffffff;
      padding: 12px 0;
      margin-top: 38px;
    }

    .subtab {
      width: 100%;
      padding: 0 20px;
    }

    .advice {
      width: 100%;
      margin-top: 20px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 30%;
    }
  }
`;
