// import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createRootReducer } from './reducers';
import rootSaga from './sagas';

const getComposeEnhancers = () => {
  if (process.env.NODE_ENV !== 'production') {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  return compose;
};

const composeEnhancers = getComposeEnhancers();

// export const history = createBrowserHistory();

export default () => {
  const sagaMiddleware = createSagaMiddleware({});

  const middlewares = [
    // TODO: add routerMiddleware(history),
    sagaMiddleware,
  ];

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const rootReducer = createRootReducer();
  const store = createStore(rootReducer, enhancer);
  sagaMiddleware.run(rootSaga);
  return store;
};
