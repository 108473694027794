import { compose, lifecycle } from 'recompose';
import { format, parseISO } from 'date-fns';
import { withFormik } from 'formik';
import { joinDayAndHours } from '../../utils/date';
import { EditCalendarPeriod } from '../../components/CarEdit/EditCalendarPeriod';

const withForm = withFormik({
  validateOnChange: false,
  mapPropsToValues: props => ({
    start:
      typeof props.index === 'number'
        ? parseISO(props.unavailabilityDates.blocked[props.index].startDate)
        : undefined,
    end:
      typeof props.index === 'number'
        ? parseISO(props.unavailabilityDates.blocked[props.index].endDate)
        : undefined,
  }),

  handleSubmit: (values, { props: { addPeriod, updatePeriod, index, showDatePicker } }) => {
    const dayStart = format(values.start, 'yyyy-MM-dd', {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true,
    });
    const dayEnd = format(values.end, 'yyyy-MM-dd', {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true,
    });
    const defaultTime = '00:00';
    const changePeriod = typeof index === 'number' ? updatePeriod : addPeriod;
    changePeriod(
      {
        startDate: joinDayAndHours(dayStart, defaultTime),
        endDate: joinDayAndHours(dayEnd, defaultTime),
      },
      index,
    );
    showDatePicker();
  },
  displayName: 'calendar-form',
});

const withLifecycle = lifecycle({
  componentWillUnmount() {
    const { setPeriodForUpdate } = this.props;
    setPeriodForUpdate('');
  },
});

export default compose(
  withForm,
  withLifecycle,
)(EditCalendarPeriod);
