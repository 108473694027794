import React from 'react';
import Button from '@material-ui/core/Button';

import { Container, Wrapper } from './styles';

export const ShowBox = ({ ViewComponent, isHide, handleIsHide, height, button, isButton }) => (
  <Container>
    <Wrapper height={height} isHide={isHide}>
      <ViewComponent />
    </Wrapper>
    {isButton ? (
      <Button className="btn" variant="text" size="small" onClick={handleIsHide}>
        {isHide ? button : 'Скрыть'}
      </Button>
    ) : null}
  </Container>
);
