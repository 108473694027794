import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { signupValidationSchema } from '../../../../utils/validate';
import { signUpUser } from '../../../../redux/actions/auth';
import { RegistrationForm } from '../Form';

export const RegistrationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const nextStepOrNull = search && new URLSearchParams(search).get('nextStep');
  // TODO: create url map ('/' - HOMEPAGE_URL)
  const onFormSubmit = useCallback(
    ({ email, password }) => {
      dispatch(signUpUser({ authData: { email, password }, history, nextStep: nextStepOrNull }));
    },
    [dispatch, history, nextStepOrNull],
  );
  const onLoginClick = useCallback(() => history.push(`/login${search}`), [history, search]);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        isAgree: true,
      }}
      validationSchema={signupValidationSchema}
      onSubmit={onFormSubmit}
    >
      <RegistrationForm onLoginClick={onLoginClick} />
    </Formik>
  );
};
