import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { useFormikContext } from 'formik';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Autocomplete } from '../../Autocomplete';
import {
  carBodyType,
  carEngineType,
  carTransmissionType,
  carTransmissionLayout,
  carSeats,
} from '../../../assets/data';
import { carActions } from '../../../redux/actions';

import { TabTitle, TabLabel } from '../featureStyles';
import { YourCarWrapper } from './styles';

const { editCarData } = carActions;

export const TabYourCar = () => {
  const {
    values: { bodyType, engineType, seatsQuantity, transmissionType, transmissionLayout },
    setFieldValue,
  } = useFormikContext();

  const car = useSelector(
    ({
      carEditData: {
        carData: { car },
      },
    }) => car,
  );

  const { brand, model, productionYear, location } = car;
  const previosLocation = location || {};

  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    lat: 55.76413648027942,
    lng: 37.606820757672104,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (previosLocation.latitude && previosLocation.longitude && previosLocation.address) {
      setCoordinates({
        lat: previosLocation.latitude,
        lng: previosLocation.longitude,
      });
      setAddress(previosLocation.address);
    }
  }, [previosLocation.address, previosLocation.latitude, previosLocation.longitude]);

  useEffect(() => {
    if (window.google) {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: coordinates,
        zoom: 13,
        mapTypeId: 'roadmap',
        mapTypeControl: false,
        streetViewControl: false,
      });

      const marker = new window.google.maps.Marker({
        map,
        draggable: true,
        position: coordinates,
      });
      const geocoder = new window.google.maps.Geocoder();

      window.google.maps.event.addListener(marker, 'dragend', () => {
        geocodePosition(marker.getPosition());
      });
      const geocodePosition = pos => {
        geocoder.geocode(
          {
            latLng: pos,
          },
          responses => {
            if (responses && responses.length > 0) {
              marker.formatted_address = responses[0].formatted_address;
            } else {
              marker.formatted_address = 'адреса нет.';
            }
            setAddress(marker.formatted_address);
            dispatch(
              editCarData({
                car: {
                  ...car,
                  location: {
                    address: marker.formatted_address,
                    latitude: pos.lat(),
                    longitude: pos.lng(),
                  },
                },
              }),
            );
          },
        );
      };

      window.google.maps.event.addListener(marker, 'dragend', () => {
        geocodePosition(marker.getPosition());
      });
      window.google.maps.event.addListener(marker, 'dragend', () => {
        geocodePosition(marker.getPosition());
      });
      window.google.maps.event.trigger(marker, 'click');
    }
  }, [coordinates, dispatch, window.google]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = address => {
    setAddress(address);
  };

  const setMarker = latLng => {
    setCoordinates({ lat: latLng.lat, lng: latLng.lng });
  };
  const handleSelect = address => {
    setAddress(address);

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setMarker(latLng);
        dispatch(
          editCarData({
            car: {
              ...car,
              location: { address, latitude: latLng.lat, longitude: latLng.lng },
            },
          }),
        );
      })
      .catch(error => console.error('Error', error));
  };

  return (
    <YourCarWrapper>
      <TabTitle>Ваша машина</TabTitle>
      <div className="block">
        <div className="box">
          <span className="name">Марка:</span>
          <span className="value">{brand}</span>
        </div>
        <div className="box">
          <span className="name">Модель:</span>
          <span className="value">{model}</span>
        </div>
        <div className="box">
          <span className="name">Год:</span>
          <span className="value">{productionYear}</span>
        </div>
      </div>
      <TabLabel htmlFor="bodyType">Укажите тип кузова</TabLabel>
      <Autocomplete
        id="bodyType"
        className="select"
        options={Object.values(carBodyType)}
        getOptionLabel={({ label }) => label}
        value={carBodyType[bodyType] || { label: '' }}
        onChange={(_, newValue) => setFieldValue('bodyType', newValue ? newValue.value : null)}
        renderInput={params => <TextField {...params} placeholder="- Выберите тип -" />}
      />
      <TabLabel htmlFor="engineType">Выберите тип двигателя</TabLabel>
      <Autocomplete
        id="engineType"
        className="select"
        options={Object.values(carEngineType)}
        getOptionLabel={({ label }) => label}
        value={carEngineType[engineType] || { label: '' }}
        onChange={(_, newValue) => setFieldValue('engineType', newValue ? newValue.value : '')}
        renderInput={params => <TextField {...params} placeholder="- Выберите тип -" />}
      />
      <TabLabel htmlFor="transmissionLayout">Какой привод?</TabLabel>
      <Autocomplete
        id="transmissionLayout"
        className="select"
        options={Object.values(carTransmissionLayout)}
        getOptionLabel={({ label }) => label}
        value={carTransmissionLayout[transmissionLayout] || { label: '' }}
        onChange={(_, newValue) =>
          setFieldValue('transmissionLayout', newValue ? newValue.value : null)
        }
        renderInput={params => <TextField {...params} placeholder="- Выберите привод -" />}
      />
      <TabLabel htmlFor="transmissionType">Какая коробка передач?</TabLabel>
      <Autocomplete
        id="transmissionType"
        className="select"
        options={Object.values(carTransmissionType)}
        getOptionLabel={({ label }) => label}
        value={carTransmissionType[transmissionType] || { label: '' }}
        onChange={(_, newValue) =>
          setFieldValue('transmissionType', newValue ? newValue.value : null)
        }
        renderInput={params => <TextField {...params} placeholder="- Выберите коробку -" />}
      />
      <TabLabel htmlFor="seatsQuantity">Количество мест</TabLabel>
      <Autocomplete
        id="seatsQuantity"
        className="select"
        options={Object.values(carSeats)}
        getOptionLabel={({ label }) => label}
        value={carSeats[seatsQuantity] || { label: '' }}
        onChange={(_, newValue) => setFieldValue('seatsQuantity', newValue ? newValue.value : null)}
        renderInput={params => <TextField {...params} placeholder="- Выберите количество -" />}
      />
      <TabLabel htmlFor="weekDiscount">Где находится машина?</TabLabel>
      <div className="select-wrap">
        {window.google && (
          <PlacesAutocomplete
            id="autocomplete"
            className="autocomplete"
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="input-address">
                <TextField
                  {...getInputProps({
                    placeholder: 'Поиск адреса...',
                    className: 'select --address',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div className="loading">Поиск...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    return (
                      <div
                        key={suggestion.index}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span className="suggest">{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
        <div id="map" className="map" />
      </div>
    </YourCarWrapper>
  );
};
