import { handleActions } from 'redux-actions';
import { carActions } from '../../actions';

const {
  deleteCar,
  deleteCarSuccess,
  deleteCarFailure,
  startVerification,
  startVerificationSuccess,
  startVerificationFailure,
} = carActions;

// TODO fields naming
const initialState = {
  deleteError: null,
  publishError: null,
  loading: false,
};

export default handleActions(
  {
    [deleteCar](state) {
      return { ...state, deleteError: null, loading: true };
    },
    [deleteCarSuccess](state) {
      return {
        ...state,
        loading: false,
        deleteError: null,
      };
    },
    [deleteCarFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, deleteError: error, loading: false };
    },
    [startVerification](state) {
      return { ...state, publishError: null, loading: true };
    },
    [startVerificationSuccess](state) {
      return {
        ...state,
        loading: false,
        publishError: null,
      };
    },
    [startVerificationFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, publishError: error, loading: false };
    },
  },
  initialState,
);
