import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { signinValidationSchema } from '../../../../utils/validate';
import { signInUser } from '../../../../redux/actions/auth';
import { LoginForm } from '../Form';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const nextStepOrNull = search && new URLSearchParams(search).get('nextStep');
  // TODO: create url map ('/' - HOMEPAGE_URL)
  const onFormSubmit = useCallback(
    ({ email, password }) => {
      dispatch(signInUser({ authData: { email, password }, history, nextStep: nextStepOrNull }));
    },
    [dispatch, history, nextStepOrNull],
  );
  const onRegistrationClick = useCallback(() => history.push(`/registration${search}`), [
    history,
    search,
  ]);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validateOnChange={false}
      validationSchema={signinValidationSchema}
      onSubmit={onFormSubmit}
    >
      <LoginForm onRegistrationClick={onRegistrationClick} />
    </Formik>
  );
};
