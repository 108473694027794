import axios from 'axios';
import { REFRESH_TOKENS_URL } from '../constants/endpoints';
import { httpVerbs } from '../constants';
import { updateUserInfo, deleteUserFromStorage } from './auth-service';

const { POST } = httpVerbs;
const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const apiFetch = (method, url, data, headers = {}) =>
  axios.request({
    url,
    data,
    method,
    headers: { ...defaultHeaders, ...headers },
    withCredentials: true,
    crossDomain: true,
  });

export const makeRefreshCall = async () => {
  try {
    const { data } = await apiFetch(POST, REFRESH_TOKENS_URL);
    return data;
  } catch (error) {
    // TODO: add error handling
    deleteUserFromStorage();
    window.location = '/';
    throw error;
  }
};

export const makeCall = async (...options) => {
  let response;
  try {
    response = await apiFetch(...options);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await makeRefreshCall();
      response = await apiFetch(...options);
      return response;
    }
    throw error;
  }
};

export const makeAuthCall = async (method, url, data) => {
  const {
    data: { user },
  } = await apiFetch(method, url, data);
  updateUserInfo(user);
  return user;
};

export const makeSocialAuthCall = async (method, url) => {
  const {
    data: { user },
  } = await apiFetch(method, url);
  updateUserInfo(user);
  return { ...user };
};
