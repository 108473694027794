import React from 'react';
import MaskComponent from 'react-text-mask';
import Input from '@material-ui/core/Input';

const TextMaskCustom = props => {
  const { inputRef, placeholder, mask, ...other } = props;
  return (
    <MaskComponent
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholder={placeholder}
      guide={false}
      showMask
    />
  );
};

export const MaskedInput = ({ mask, ...other }) => (
  <Input
    {...other}
    inputProps={{
      mask,
    }}
    inputComponent={TextMaskCustom}
  />
);
