import React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as EyeOnIcon } from '../../assets/icons/eyeOn.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/icons/eyeOff.svg';

export const PasswordInput = ({ field, handleClickShowPassword, showPassword, ...props }) => (
  <Input
    {...field}
    {...props}
    endAdornment={
      <InputAdornment position="end" className="eye">
        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
          {showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
        </IconButton>
      </InputAdornment>
    }
  />
);
