import { handleActions, combineActions } from 'redux-actions';
import { authActions, userActions } from '../../actions';

const {
  signInUser,
  signUpUser,
  signOutUser,
  signInUserSuccess,
  signInUserError,
  signUpUserSuccess,
  signUpUserError,
  signOutUserSuccess,
  setInitialUser,
  resetPassword,
  resetPasswordError,
  resetAuth,
  resetAuthError,
} = authActions;

const { updateUserDataSuccess, confirmRecoveryEmailSuccess } = userActions;

const initialState = {
  loading: false,
  user: null,
  error: null,
  isLoggedIn: false,
};

export const auth = handleActions(
  {
    [combineActions(signInUser, signUpUser, signOutUser, resetPassword)](state) {
      return { ...state, loading: true };
    },
    [combineActions(signInUserSuccess, signUpUserSuccess, setInitialUser)](
      state,
      {
        payload: { user },
      },
    ) {
      return { ...state, user, isLoggedIn: true, error: null, loading: false };
    },
    [combineActions(signUpUserError, signInUserError, resetPasswordError)](
      state,
      {
        payload: { error },
      },
    ) {
      return {
        ...state,
        error,
        loading: false,
      };
    },
    [updateUserDataSuccess](
      state,
      {
        payload: { user },
      },
    ) {
      return { ...state, user, error: null, loading: false };
    },
    [signOutUserSuccess]() {
      return initialState;
    },
    [confirmRecoveryEmailSuccess](
      state,
      {
        payload: { id },
      },
    ) {
      return { ...state, user: state.user ? state.user : { id }, loading: false };
    },
    [resetAuth]() {
      return initialState;
    },
    [resetAuthError](state) {
      return { ...state, error: null };
    },
  },
  initialState,
);
