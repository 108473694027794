import React, { useState } from 'react';
import { MapContext } from './MapContext';

import MapMarker from './MapMarker';
import { Map } from './Map';

export default function PageMap({ car }) {
  const [mapInstance, setMapInstance] = useState();

  return (
    <MapContext.Provider value={[mapInstance, setMapInstance]}>
      <Map
        disableZoomOnScroll={true}
        center={[car.homeLocation.lon, car.homeLocation.lat]}
        zoom={14}
      >
        <MapMarker car={car} isLabel={false} isTooltip={false} />
      </Map>
    </MapContext.Provider>
  );
}
