import React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckboxButton } from '../../../Button/CheckboxButton';
import { useQuery, checkQuery, setQuery } from '../../../../services/query-service';

export const ControlDelivery = () => {
  const query = useQuery();
  const history = useHistory();
  const delivery = query.get('delivery') ? checkQuery(query.get('delivery')) : false;
  return (
    <CheckboxButton
      name="instant"
      label="Доставка"
      checked={delivery}
      onChange={({ target: { checked } }) => setQuery('delivery', checked, query, history)}
      className={`tab-btn ${delivery ? '-active' : ''}`}
    />
  );
};
