import { styled } from 'linaria/react';
import Divider from '@material-ui/core/Divider';
import { breakpoints } from '../../../constants/breakpoints';

export const Wrapper = styled.form`
  @media ${breakpoints.mobile} {
    & {
      border-top: 1px solid #d8d8d8;
      padding-top: 12px;
      margin-bottom: 37px;
    }

    > .address {
      display: block;
      color: var(--primary-text);
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }

    .dropdown {
      box-shadow: none;
    }

    .dropdown-summary {
      padding: 0;
      display: inline;
    }

    .dropdown-summary .MuiAccordionSummary-content {
      display: inline;
      margin: 0;
      font-size: 12px;
      line-height: 20px;
      color: #066bd6;
    }

    .dropdown-details {
      padding: 10px 0 15px;
    }

    .dropdown-actions {
      justify-content: flex-start;
      padding: 0;
    }

    .delivery-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #303030;
      margin-top: 15px;
    }

    .delivery-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #5d5d5d;
      margin-bottom: 10px;
    }

    .input {
      margin-top: 5px;
    }

    .text-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #066bd6;
      border: none;
      height: auto;
      cursor: pointer;
      padding: 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      position: absolute;
      top: 0;
      left: calc(100% - 48px);
      width: calc(100% * 4 / 7 - 24px);
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 4px 16px 0 rgba(72, 107, 125, 0.15);
      border: none;
      padding: 20px 16px 24px 16px;
    }
  }
`;

export const Title = styled.span`
  @media ${breakpoints.mobile} {
    & {
      color: var(--primary-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  @media ${breakpoints.mobile} {
    && {
      height: 1px;
      background-color: #c4c4c4;
      margin: 16px 0;
    }
  }

  @media ${breakpoints.laptop} {
    && {
      margin: 25px 0;
      &:last-child {
        display: none;
      }
    }
  }
`;

export const InlineReservation = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      padding: 10px 16px 0;
      box-sizing: border-box;
      z-index: 3;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .number {
      color: var(--primary-text);

      font-weight: 600;
      font-size: 20px;
    }

    .duration {
      color: var(--primary-text);

      font-weight: 400;
      font-size: 16px;
    }

    .sale > .number,
    .sale > .duration {
      color: var(--grey-text);
      font-size: 12px;
    }

    .currency {
      color: var(--primary-text);
      font-size: 20px;
    }
  }

  @media ${breakpoints.tablet} {
    .number {
      font-size: 32px;
      line-height: 24px;
    }

    .currency {
      font-size: 32px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      position: static;
      padding: 0;
    }

    .btn {
      display: none;
    }
  }
`;

export const Reservation = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 72px;
      background-color: #fff;
      z-index: 9;
    }

    .price {
      position: absolute;
      left: 10px;
      top: 17px;
    }

    .number {
      color: var(--primary-text);

      font-weight: 600;
      font-size: 20px;
    }

    .currency {
      color: var(--primary-text);
      font-size: 20px;
    }

    .duration {
      color: var(--primary-text);

      font-weight: 400;
      font-size: 16px;
    }

    .wrapper {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }

    .notice {
      color: var(--primary-text);
      font-size: 12px;
    }

    .btn {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }

  @media ${breakpoints.tablet} {
    .number {
      font-size: 24px;
    }

    .price {
      left: 25px;
      top: 11px;
    }

    .wrapper {
      bottom: 10px;
      left: 25px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      position: relative;
      width: 100%;
      height: auto;
    }

    .price {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 11;
    }

    .btn {
      position: initial;
      width: 100%;
      margin: 24px 0 8px 0;
    }

    .wrapper {
      position: initial;
      text-align: center;
    }
  }
`;

export const SelectDate = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      height: 24px;
      align-items: center;
      justify-content: space-between;
      border: ${props => (props.error ? '1px solid red' : '1px solid #c4c4c4')};
      border-radius: 4px;
      background-color: #fff;
      margin-top: 6px;
      padding: 12px 12px 12px 16px;
    }

    .picker-input {
      border: none;
      background-color: #fff;
      padding: 0;
      color: ${props => (props.error ? 'red' : 'inherit')};
    }

    .picker-wrap {
      position: relative;
      width: 200px;
    }

    .picker-wrap .DayPicker-Months {
      flex-wrap: nowrap;
    }

    .picker-wrap
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: rgb(220, 230, 242) !important;
      color: var(--primary-text);
      border-radius: 0;
    }

    .picker-wrap > div {
      position: absolute;
      left: 130px;
      top: 35px;
      transform: translate(-40%, 0);
      border: 1px solid #d5d5d5;
      border-radius: 10px;
      background: #fff;
      z-index: 10;
    }

    .picker-input {
      width: 100%;
      height: 48px;
      font-size: 16px;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }

      &.-from {
        border: none;
      }

      &.-to {
        border: none;
      }
    }

    .hours:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 36px;
      z-index: 10;
      background: #c4c4c4;
      top: 50%;
      left: 0;
      transform: translate(1px, -50%);
      opacity: 0.5;
    }

    .hours
      .MuiAutocomplete-inputRoot[class*='MuiInput-root']
      .MuiAutocomplete-input.MuiAutocomplete-input:first-child {
      border: none;
      width: 75px;
      color: ${props => (props.error ? 'red' : 'inherit')};
    }

    .MuiAutocomplete-endAdornment {
      right: -25px;
    }
  }

  @media ${breakpoints.laptop} {
    .hours div {
      z-index: 99;
    }
  }
`;

export const Rent = styled.div`
  @media ${breakpoints.mobile} {
    > .-end {
      margin-top: 18px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      display: flex;
      justify-content: space-between;
    }

    > .-end {
      margin-top: 0;
    }

    .picker {
      width: calc(50% - 10px);
    }

    .picker-wrap {
      max-width: 210px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      flex-direction: column;
    }

    > .-end {
      margin-top: 24px;
    }

    .picker {
      width: 100%;
    }

    .picker-wrap {
      max-width: 130px;
    }
  }
`;
