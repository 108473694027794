import { all, fork } from 'redux-saga/effects';

import { authSaga } from './user/auth';
import { userUpdateSaga } from './user/update';
import { confirmationSaga } from './user/confirmation';
import card from './card';
import registerCar from './registerCar';
import editCar from './editCar';
import search from './search';
import manageCar from './manageCar';
import listCar from './listCar';
import scoring from './scoring';

export default function* rootSaga() {
  const effects = [
    fork(card),
    fork(registerCar),
    fork(editCar),
    fork(search),
    fork(manageCar),
    fork(listCar),
    fork(authSaga),
    fork(userUpdateSaga),
    fork(confirmationSaga),
    fork(scoring),
  ];
  yield all(effects);
}
