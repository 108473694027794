import { handleActions, combineActions } from 'redux-actions';
import {
  checkUserDataCompleteness,
  checkUserDataCompletenessSuccess,
  checkUserDataCompletenessError,
  submitUserEmail,
  submitUserEmailSuccess,
  submitUserEmailError,
  submitUserPhone,
  submitUserPhoneSuccess,
  submitUserPhoneError,
  submitRecoveryEmail,
  submitRecoveryEmailSuccess,
  submitRecoveryEmailError,
  confirmUserEmail,
  confirmUserEmailSuccess,
  confirmUserEmailError,
  confirmUserPhone,
  confirmUserPhoneSuccess,
  confirmUserPhoneError,
  confirmRecoveryEmail,
  confirmRecoveryEmailSuccess,
  confirmRecoveryEmailError,
  resetConfirmation,
  resetConfirmationError,
} from '../../actions/user';

const initialState = {
  loading: false,
  error: null,
  isSubmit: false,
  userId: null,
  confirmationData: {},
};
export const confirmation = handleActions(
  {
    [combineActions(
      checkUserDataCompleteness,
      submitUserEmail,
      submitUserPhone,
      submitRecoveryEmail,
      confirmUserEmail,
      confirmUserPhone,
      confirmRecoveryEmail,
    )](state) {
      return { ...state, loading: true };
    },

    [combineActions(
      checkUserDataCompletenessError,
      submitRecoveryEmailError,
      submitUserPhoneError,
      submitUserEmailError,
      confirmUserEmailError,
      confirmUserPhoneError,
      confirmRecoveryEmailError,
    )](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, loading: false, error };
    },

    [combineActions(submitUserEmailSuccess, submitUserPhoneSuccess)](state) {
      return { ...state, loading: false, isSubmit: true };
    },
    [combineActions(
      checkUserDataCompletenessSuccess,
      confirmUserEmailSuccess,
      confirmUserPhoneSuccess,
    )](
      state,
      {
        payload: { confirmationData },
      },
    ) {
      return { ...state, confirmationData, loading: false, isSubmit: false };
    },
    [submitRecoveryEmailSuccess](
      state,
      {
        payload: { id },
      },
    ) {
      return { ...state, userId: id, loading: false, isSubmit: true };
    },
    [resetConfirmation]() {
      return initialState;
    },
    [resetConfirmationError](state) {
      return { ...state, error: initialState.error };
    },
  },
  initialState,
);
