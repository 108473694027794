import { styled } from 'linaria/react';
import Slider from '@material-ui/core/Slider';
import InputAdornment from '@material-ui/core/InputAdornment';

export const StyledSlider = styled(Slider)`
  .MuiSlider-thumb {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    margin-top: -7px;
    border: 1px solid #006ade;
    background-color: #ffffff;
  }

  .MuiSlider-valueLabel {
    * {
      background: transparent;
      color: #000;
    }
  }

  .MuiSlider-valueLabel span {
    top: 30px;
    left: -2px;
    width: 0;
    height: 0;
    transform: none;
    align-items: center;
    border-radius: 0;
    color: var(--primary-text);
    letter-spacing: -0.07px;
  }

  .MuiSlider-thumb.Mui-disabled {
    width: 20px;
    height: 20px;
    margin-top: -7px;
    margin-left: -6px;
  }
`;

export const StyledInputLabel = styled(InputAdornment)`
  & {
    position: absolute;
    left: 10px;
    bottom: 22px;
  }
`;
