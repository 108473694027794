import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Loader } from '../../components/Loader/Backdrop';
import { CarListItem } from '../../components/CarListItem';
import { carActions } from '../../redux/actions';
import { statuses } from '../../constants/statuses';

import { MyCarsWrapper } from './styles';

const { getCarList } = carActions;

export default () => {
  const { data, loading } = useSelector(({ carList }) => carList);
  const carList = data.map(car => {
    const {
      uuid,
      brand,
      model,
      productionYear,
      rentPricePerDay,
      registrationNumber,
      status,
      photoUrl,
      activeRent,
    } = car;
    return {
      uuid,
      brand,
      model,
      productionYear,
      rentPricePerDay,
      registrationNumber,
      photoUrl,
      status: activeRent ? statuses.RENT : status,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCarList());
  }, [dispatch]);

  return (
    <MyCarsWrapper>
      <Loader open={loading} />
      <Grid container spacing={3}>
        {carList.map(car => (
          <CarListItem key={car.uuid} car={car} />
        ))}
      </Grid>
    </MyCarsWrapper>
  );
};
