import React, { useEffect, useContext } from 'react';
import { load } from '@2gis/mapgl';
import { MapContext } from './MapContext';
import MapWrapper from './MapWrapper';

export const Map = ({
  center,
  zoom = 10,
  disableZoomOnScroll,
  disableRotationByUserInteraction,
  zoomControl,
  setBounds,
  onMoveStart,

  children,
}) => {
  const [_, setMapInstance] = useContext(MapContext);

  useEffect(() => {
    async function getMap() {
      const mapglAPI = await load();
      const map = new mapglAPI.Map('map-container', {
        center,
        zoom,
        disableZoomOnScroll,
        disableRotationByUserInteraction,
        zoomControl,
        key: process.env.REACT_APP_MAP_KEY,
      });

      setMapInstance({ mapglAPI, map });

      if (setBounds) {
        map.on('move', () => {
          setBounds(map.getBounds());
        });
        if (onMoveStart) map.on('movestart', onMoveStart);
      }

      return map;
    }

    let map;
    getMap().then(x => {
      map = x;
    });

    const resizeHandler = window.addEventListener('resize', () => map?.invalidateSize());

    // Destroy the map on unmounted
    return () => {
      map && map.destroy();

      window.removeEventListener(resizeHandler);
    };
  }, []);

  return <MapWrapper>{children}</MapWrapper>;
};
