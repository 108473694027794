import React from 'react';
import Popover from '@material-ui/core/Popover';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import { StyledPopover } from './styles';

export const CustomPopover = ({ control, children, label, className }) => {
  const Control = control;
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' }); // TODO: move to container
  return (
    <StyledPopover className={className}>
      <Control handler={{ ...bindTrigger(popupState) }} label={label} isOpen={popupState.isOpen} />
      <Popover
        disablePortal
        transitionDuration={0}
        keepMounted
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {typeof children === 'function' ? children(popupState.close) : children}
      </Popover>
    </StyledPopover>
  );
};
