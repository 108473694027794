import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { carActions, errorActions } from '../actions';
import { GET_CAR_LIST, PUBLISH_CAR_URL, UNPUBLISH_CAR_URL } from '../../constants/endpoints';
import {
  listCarErrors,
  publishCarErrors,
  unpublishCarErrors,
  defaultError,
} from '../../constants/errors';
import { httpVerbs } from '../../constants/http';
import { makeCall } from '../../services/api-service';

const { GET, PATCH } = httpVerbs;
const {
  getCarList,
  getCarListSuccess,
  getCarListFailure,
  publishCar,
  publishCarSuccess,
  unpublishCar,
  unpublishCarSuccess,
} = carActions;

const { setError } = errorActions;

function* getCarListHandler(action) {
  try {
    const { data } = yield makeCall(GET, `${GET_CAR_LIST}`);
    yield put(getCarListSuccess(data));
  } catch (error) {
    const message = error.response ? listCarErrors[error.response.status] : defaultError;
    yield put(setError(message));
    yield put(getCarListFailure(error));
  }
}

function* publishCarHandler(action) {
  const { payload } = action;
  try {
    yield makeCall(PATCH, `${PUBLISH_CAR_URL}/${payload}`);
    yield put(publishCarSuccess(payload));
  } catch (error) {
    const message = error.response ? publishCarErrors[error.response.status] : defaultError;
    yield put(setError(message));
  }
}

function* unpublishCarHandler(action) {
  const { payload } = action;
  try {
    yield makeCall(PATCH, `${UNPUBLISH_CAR_URL}/${payload}`);
    yield put(unpublishCarSuccess(payload));
  } catch (error) {
    const message = error.response ? unpublishCarErrors[error.response.status] : defaultError;
    yield put(setError(message));
  }
}

function* watchListCarEvent() {
  yield takeEvery(getCarList, getCarListHandler);
  yield takeEvery(publishCar, publishCarHandler);
  yield takeEvery(unpublishCar, unpublishCarHandler);
}

export default watchListCarEvent;
