import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const NavButtonWrapper = styled.div`
  @media ${breakpoints.mobile} {
    .button {
      display: none;
    }
  }

  @media ${breakpoints.tablet} {
    .-back {
      margin-right: 15px;
    }

    .button {
      display: initial;
      font-size: 16px;
      width: 87px;
    }
  }
`;
