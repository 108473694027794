import React from 'react';
import Button from '@material-ui/core/Button';

import { NavButtonWrapper } from './styles';

export const NavButton = ({ prev, next, prevHandler, nextHandler, disabled }) => {
  return (
    <NavButtonWrapper>
      {typeof prev === 'number' && (
        <Button
          disabled={disabled}
          variant="outlined"
          className="button -back"
          onClick={() => prevHandler(null, prev)}
        >
          Назад
        </Button>
      )}
      {typeof next === 'number' && (
        <Button
          disabled={disabled}
          variant="outlined"
          color="primary"
          className="button"
          onClick={() => nextHandler(null, next)}
        >
          Далее
        </Button>
      )}
    </NavButtonWrapper>
  );
};
