import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';
import { CustomPopover } from '../../../Popover';

export const StyledLabel = styled.p`
  & {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary-text);
    line-height: 24px;
    padding: 0 0 10px 0;
  }
`;

export const StyledPopover = styled(CustomPopover)`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      height: 100%;
      margin: 0 15px 0 0;
    }

    .divider {
      height: 1px;
      margin: 15px -20px 20px;
      width: calc(100% + 40px);
      background-color: #d8d8d8;
    }

    .MuiPopover-paper {
      max-width: 1060px;
      max-height: calc(100vh - 150px);
      top: 140px !important;
      padding: 20px 0 15px;
      border-radius: 8px;
      box-sizing: border-box;
    }

    .icon {
      cursor: pointer;
      position: absolute;
      bottom: 14px;
      right: -2px;
      color: #006ade;
    }

    .MuiSlider-root {
      padding: 40px 0 20px;
      width: 190px;
    }
  }

  @media ${breakpoints.desktop} {
    .MuiPopover-paper {
      left: 0 !important;
      top: 62px !important;
    }
  }
`;

export const Form = styled.form`
  @media ${breakpoints.tablet} {
    padding: 0 20px;
  }
`;
