import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import { without } from 'ramda';
import { Uploader } from '../../Uploader';
import { ReactComponent as ClosingCross } from '../../../assets/icons/closingCross.svg';
import { uploaderActions, carActions } from '../../../redux/actions';

import { TabTitle, TabRecomendation } from '../featureStyles';
import { PhotoWrap } from './styles';

const carPhotosNumber = 20;

const { deleteFile } = uploaderActions;
const { editCarData } = carActions;

export const TabPhoto = () => {
  const { carPhotos } = useSelector(({ uploader }) => uploader);
  const [sessionPhotos, setSessionPhotos] = useState([]);

  const dispatch = useDispatch();
  const {
    car,
    car: { photos = [], uuid },
  } = useSelector(({ carEditData: { carData } }) => carData);

  useEffect(() => {
    setSessionPhotos(carPhotos);
  }, []);

  const newCarPhotos = sessionPhotos.filter(photo => photo.action === 'save');
  const isPhotosExists = Boolean(photos.length);
  const isNewCarPhotos = Boolean(newCarPhotos.length);
  const handleRemove = (key, photo) => {
    const updatedPhotos = without([photo], photos);
    dispatch(editCarData({ car: { ...car, photos: updatedPhotos } }));
    dispatch(deleteFile('carPhotos', key));
  };

  const handleRemoveNewPhotos = (key, photo) => {
    const updatedSessionPhotos = without([photo], sessionPhotos);
    dispatch(deleteFile('carPhotos', key));
    setSessionPhotos(updatedSessionPhotos);
  };

  return (
    <PhotoWrap>
      <TabTitle>Загрузите фотографии машины</TabTitle>
      <TabRecomendation>
        Ознакомьтесь с рекомендациями{' '}
        <Link
          target="_blank"
          href="https://help.getarent.ru/knowledge-bases/9/articles/95-kak-pravilno-sdelat-fotografii-mashinyi"
          className="text-link"
        >
          как правильно сделать фотографии
        </Link>
      </TabRecomendation>
      <Uploader maxFiles={carPhotosNumber - photos.length} name="carPhotos" className="uploader" />
      {(isPhotosExists || isNewCarPhotos) && (
        <div className="photos">
          {newCarPhotos.map(({ key, url, action }) => (
            <div key={key} className="photo-container">
              <img className="photo" src={`${url}/cars/${uuid}/${key}`} alt="car" />
              <div
                className="close"
                onClick={() => handleRemoveNewPhotos(key, { key, url, action })}
              >
                <ClosingCross className="closing-icon" />
              </div>
            </div>
          ))}
          {photos.map(({ key, url }) => (
            <div key={key} className="photo-container">
              <img className="photo" src={url} alt="car" />
              <div className="close" onClick={() => handleRemove(key, { key, url })}>
                <ClosingCross className="closing-icon" />
              </div>
            </div>
          ))}
        </div>
      )}
    </PhotoWrap>
  );
};
