import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const InformationWrapper = styled.div`
  @media ${breakpoints.tablet} {
    & {
      width: 100%;
      display: flex;
    }

    .content {
      padding: 38px 0 0 24px;
      max-width: calc(100% - 255px);
      box-sizing: border-box;
      width: 100%;
    }
  }

  @media ${breakpoints.laptop} {
    .content {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 70%;
      padding: 40px 0 0 35px;
    }

    .filepond--item > .filepond--panel .filepond--panel-bottom {
      box-shadow: none;
    }

    .filepond--image-bitmap,
    .filepond--image-vector {
      position: relative;
    }

    .filepond--image-clip {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
