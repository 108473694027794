import React from 'react';
import { TabInsurance } from '../../components/CarEdit/TabInsurance';
import { NavButton } from '../../components/Button/NavButton';
import getNavigation from '../../utils/navigation';

import { DocsWrapper } from './styles';

export default ({ tabValue, tabsHandleChange, parentNavigation }) => {
  const navigation = getNavigation({}, parentNavigation);
  return (
    <DocsWrapper>
      <TabInsurance />
      <div className="navigation">
        <NavButton {...navigation} />
      </div>
    </DocsWrapper>
  );
};
