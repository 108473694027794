import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const MyCarsWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      max-width: 1440px;
      padding: 106px 16px 145px 16px;
      box-sizing: border-box;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 36px 16px 145px 16px;
    }
  }
`;
