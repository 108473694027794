import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const ServicesWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
    }

    .text {
      margin: 8px 0 24px 0;
    }

    .text-link {
      cursor: pointer;
      text-decoration: none;
      border-bottom: dotted;
      border-width: thin;
      color: #066bd6;
    }

    .checkbox > span:last-of-type {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      color: #303133;
    }

    .checked {
      padding: 0 0 11px;
    }

    .unchecked {
      padding: 0;
    }

    .text-link {
      cursor: pointer;
      text-decoration: none;
      border-bottom: dotted;
      border-width: thin;
      color: #066bd6;
    }
  }

  @media ${breakpoints.tablet} {
    .checkbox-holder {
      margin-bottom: 31px;
    }
  }

  @media ${breakpoints.laptop} {
    .text {
      margin: 8px 0 17px 0;
    }
  }
`;
