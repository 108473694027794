import { createAction } from 'redux-actions';
import carTypes from '../../types/car';

const {
  register: { REGISTER_CAR, REGISTER_CAR_SUCCESS, REGISTER_CAR_FAILURE },
  edit: {
    EDIT_CAR_DATA,
    POST_EDIT_DATA,
    POST_EDIT_DATA_SUCCESS,
    POST_EDIT_DATA_FAILURE,
    // UPLOAD_CAR_FILES,
    // UPLOAD_CAR_FILES_SUCCESS,
    // UPLOAD_CAR_FILES_FAILURE,
    GET_CAR_DATA,
    GET_CAR_DATA_SUCCESS,
    GET_CAR_DATA_FAILURE,
  },
  manage: {
    DELETE_CAR,
    DELETE_CAR_SUCCESS,
    DELETE_CAR_FAILURE,
    START_VERIFICATION,
    START_VERIFICATION_SUCCESS,
    START_VERIFICATION_FAILURE,
    PUBLISH_CAR,
    PUBLISH_CAR_SUCCESS,
    // PUBLISH_CAR_FAILURE,
    UNPUBLISH_CAR,
    UNPUBLISH_CAR_SUCCESS,
    // UNPUBLISH_CAR_FAILURE,
  },
  card: { GET_CARD_DATA, GET_CARD_DATA_SUCCESS, GET_CARD_DATA_FAILURE },
  list: { GET_CAR_LIST, GET_CAR_LIST_SUCCESS, GET_CAR_LIST_FAILURE },
} = carTypes;

// register car
export const registerCar = createAction(REGISTER_CAR);
export const registerCarSuccess = createAction(REGISTER_CAR_SUCCESS, data => ({
  data,
}));
export const registerCarFailure = createAction(REGISTER_CAR_FAILURE, error => ({
  error,
}));

// edit car
export const getCarData = createAction(GET_CAR_DATA);
export const getCarDataSuccess = createAction(GET_CAR_DATA_SUCCESS, data => ({
  data,
}));
export const getCarDataFailure = createAction(GET_CAR_DATA_FAILURE, error => ({
  error,
}));

export const editCarData = createAction(EDIT_CAR_DATA, data => ({ data }));
export const postEditData = createAction(POST_EDIT_DATA, (id, data, history) => ({
  id,
  data,
  history,
}));
export const postEditDataSuccess = createAction(POST_EDIT_DATA_SUCCESS);
export const postEditDataFailure = createAction(POST_EDIT_DATA_FAILURE, error => ({
  error,
}));

// delete car
export const deleteCar = createAction(DELETE_CAR, (id, history) => ({
  id,
  history,
}));
export const deleteCarSuccess = createAction(DELETE_CAR_SUCCESS);
export const deleteCarFailure = createAction(DELETE_CAR_FAILURE, error => ({
  error,
}));

// verification car
export const startVerification = createAction(START_VERIFICATION, (id, data, history) => ({
  id,
  data,
  history,
}));
export const startVerificationSuccess = createAction(START_VERIFICATION_SUCCESS);
export const startVerificationFailure = createAction(START_VERIFICATION_FAILURE, error => ({
  error,
}));

// publish car
export const publishCar = createAction(PUBLISH_CAR);
export const publishCarSuccess = createAction(PUBLISH_CAR_SUCCESS, uuid => ({ uuid }));

export const unpublishCar = createAction(UNPUBLISH_CAR);
export const unpublishCarSuccess = createAction(UNPUBLISH_CAR_SUCCESS, uuid => ({ uuid }));

// car card
export const getCardData = createAction(GET_CARD_DATA);
export const getCardDataSuccess = createAction(GET_CARD_DATA_SUCCESS, data => ({
  data,
}));
export const getCardDataFailure = createAction(GET_CARD_DATA_FAILURE, error => ({
  error,
}));

// car list
export const getCarList = createAction(GET_CAR_LIST);
export const getCarListSuccess = createAction(GET_CAR_LIST_SUCCESS, data => ({
  data,
}));
export const getCarListFailure = createAction(GET_CAR_LIST_FAILURE, error => ({
  error,
}));
