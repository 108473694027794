import { combineReducers } from 'redux';
import carRegistrationData from './car/registration';
import carEditData from './car/edit';
import cardData from './car/card';
import carList from './car/list';
import carManage from './car/manage';
import error from './error';
import uploader from './uploader';
import search from './search';
import { auth } from './auth/main';
import { authPopup } from './auth/popup';
import scoring from './user/scoring';
import { userUpdate } from './user/update';
import { confirmation } from './user/confirmation';

export const createRootReducer = () =>
  combineReducers({
    carRegistrationData,
    carEditData,
    cardData,
    carManage,
    carList,
    uploader,
    search,
    auth,
    authPopup,
    userUpdate,
    confirmation,
    scoring,
    error,
  });
