import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const DocsWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .navigation {
      margin-left: 0;
      margin-top: 40px;
    }
  }
`;
