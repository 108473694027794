import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, useFormikContext } from 'formik';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { Loader } from '../../../Loader/Backdrop';
import { PasswordInput } from '../../../PasswordInput';
import { SocialAuth } from '../../SocialAuth';
import { closeAuthPopup, resetAuthError } from '../../../../redux/actions/auth';

import { Error } from '../../../../styledComponents';
import { CustomInputLabel, Separator, Title } from '../../featureStyles';
import { Form } from './styles';

export const LoginForm = ({ onRegistrationClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const nextStepOrNull = search && new URLSearchParams(search).get('nextStep');
  const isShowRegistrationLink = Boolean(nextStepOrNull && nextStepOrNull === '/car/register');
  const [showPassword, setShowPassword] = useState(false);
  const {
    values: { email, password },
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldError,
  } = useFormikContext();
  const { error, loading, isLoggedIn } = useSelector(({ auth }) => auth);
  const { popupOption } = useSelector(({ authPopup }) => authPopup);

  useEffect(() => {
    if (isLoggedIn) {
      resetForm();
    }
  }, [dispatch, resetForm, isLoggedIn, popupOption]);

  useEffect(() => {
    if (!popupOption) {
      resetForm();
      dispatch(resetAuthError());
    }
  }, [dispatch, resetForm, popupOption]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Loader open={loading} />
      {/* <Title>Войти через:</Title>
      <SocialAuth />
      <Separator>или</Separator> */}
      <Title>Войти по электронной почте:</Title>
      <CustomInputLabel htmlFor="email">Ваш email</CustomInputLabel>
      <Field
        id="email-login"
        className={(errors.email && touched.email) || error ? 'input error' : 'input'}
        placeholder="example@mail.ru"
        value={email}
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetAuthError());
          }
          if (errors.email) {
            setFieldError('email');
          }
          setFieldValue('email', value);
        }}
        onBlur={handleBlur}
        component={Input}
        type="text"
      />
      {errors.email && touched.email ? <Error>{errors.email}</Error> : null}
      <div className="flex-wrapper">
        <CustomInputLabel htmlFor="password">Ваш пароль</CustomInputLabel>
        <span
          className="link"
          onClick={e => {
            dispatch(closeAuthPopup());
            history.push('/forgot-password');
          }}
        >
          {/* eslint-disable-line*/}
          Забыли пароль?
        </span>
      </div>
      <Field
        id="password-login"
        className={(errors.password && touched.password) || error ? 'input error' : 'input'}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetAuthError());
          }
          if (errors.password) {
            setFieldError('password');
          }
          setFieldValue('password', value);
        }}
        onBlur={handleBlur}
        handleClickShowPassword={handleClickShowPassword}
        showPassword={showPassword}
        component={PasswordInput}
      />
      {errors.password && touched.password ? <Error>{errors.password}</Error> : null}
      {error ? <Error>{error}</Error> : null}
      <Button className="btn" variant="contained" size="large" type="submit">
        Войти
      </Button>
      {isShowRegistrationLink && (
        <div className="login">
          <span className="small-text">Нет аккаунта?</span>
          <span className="link" onClick={e => onRegistrationClick()}>
            {/* eslint-disable-line*/}
            Зарегистрируйтесь
          </span>
        </div>
      )}
    </Form>
  );
};
