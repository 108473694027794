import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext, Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { resetUserUpdateErrors } from '../../../redux/actions/user';
import { MaskedInput } from '../../MaskedInput';
import { dateMask } from '../../../constants/masks';

import { Error } from '../../../styledComponents';
import { CustomFormGroup } from '../styles';

export const PersonalDataForm = () => {
  const dispatch = useDispatch();
  const { error } = useSelector(({ userUpdate }) => userUpdate);
  // TODO: create util for checking errors
  const {
    setFieldValue,
    setFieldError,
    handleBlur,
    values,
    errors: { userData: fieldsError },
    touched: { userData: fieldsTouched },
  } = useFormikContext();
  return (
    <CustomFormGroup>
      <InputLabel className="label" htmlFor="lastName">
        Фамилия
      </InputLabel>
      <Field
        id="lastName"
        className={
          fieldsError && fieldsError.lastName && fieldsTouched.lastName ? 'input error' : 'input'
        }
        placeholder="Например, Иванов"
        component={Input}
        type="text"
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetUserUpdateErrors());
          }
          if (fieldsError && fieldsError.lastName) {
            setFieldError('userData.lastName');
          }
          setFieldValue('userData', { ...values.userData, lastName: value });
        }}
        onBlur={handleBlur}
      />
      {fieldsError
        ? fieldsError.lastName && fieldsTouched.lastName && <Error>{fieldsError.lastName}</Error>
        : null}
      <InputLabel className="label" htmlFor="firstName">
        Имя
      </InputLabel>
      <Field
        id="firstName"
        className={
          fieldsError && fieldsError.firstName && fieldsTouched.firstName ? 'input error' : 'input'
        }
        placeholder="Например, Иван"
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetUserUpdateErrors());
          }
          if (fieldsError && fieldsError.firstName) {
            setFieldError('userData.firstName');
          }
          setFieldValue('userData', { ...values.userData, firstName: value });
        }}
        onBlur={handleBlur}
        component={Input}
        type="text"
      />
      {fieldsError
        ? fieldsError.firstName && fieldsTouched.firstName && <Error>{fieldsError.firstName}</Error>
        : null}
      <InputLabel className="label" htmlFor="midName">
        Отчество
      </InputLabel>
      <Field
        id="midName"
        className={
          fieldsError && fieldsError.midName && fieldsTouched.midName ? 'input error' : 'input'
        }
        placeholder="Например, Иванович"
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetUserUpdateErrors());
          }
          if (fieldsError && fieldsError.midName) {
            setFieldError('userData.midName');
          }
          setFieldValue('userData', { ...values.userData, midName: value });
        }}
        onBlur={handleBlur}
        component={Input}
        type="text"
      />
      {fieldsError
        ? fieldsError.midName && fieldsTouched.midName && <Error>{fieldsError.midName}</Error>
        : null}
      <InputLabel className="label" htmlFor="birthDate">
        Дата рождения
      </InputLabel>
      <p className="text -label">
        Пользование услугами сервиса доступно только лицам старше 18 лет
      </p>
      <Field
        id="birthDate"
        className={
          fieldsError && fieldsError.birthDate && fieldsTouched.birthDate ? 'input error' : 'input'
        }
        placeholder="дд.мм.гггг"
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetUserUpdateErrors());
          }
          if (fieldsError && fieldsError.birthDate) {
            setFieldError('userData.birthDate');
          }
          setFieldValue('userData', { ...values.userData, birthDate: value });
        }}
        onBlur={handleBlur}
        component={MaskedInput}
        mask={dateMask}
        type="text"
      />
      {fieldsError
        ? fieldsError.birthDate && fieldsTouched.birthDate && <Error>{fieldsError.birthDate}</Error>
        : null}
    </CustomFormGroup>
  );
};
