import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Toolbar from '@material-ui/core/Toolbar';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav/MobileNav';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import { Header, Content } from './styles';

const CommonHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Header className="header" position={isMobile ? 'fixed' : 'static'}>
      <Content>
        <a href="/" className="link">
          <LogoIcon className="logo" />
        </a>
        <Toolbar className="toolbar">{isMobile ? <MobileNav /> : <DesktopNav />}</Toolbar>
      </Content>
    </Header>
  );
};

export default CommonHeader;
