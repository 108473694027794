import CarEditInformation from '../../views/CarEditInformation';
import CarRentConditions from '../../views/CarEditConditions';
import CarEditDocs from '../../views/CarEditDocs';
import CarEditCalendar from '../../views/CarEditCalendar';

export default {
  '0': CarEditInformation,
  '1': CarEditDocs,
  '2': CarRentConditions,
  '3': CarEditCalendar,
};
