import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import { resetPassword, resetAuth, resetAuthError } from '../../../../redux/actions/auth';
import { Loader } from '../../../Loader/Backdrop';
import { PasswordInput } from '../../../PasswordInput';
import { restorePasswordValidationSchema } from '../../../../utils/validate';

import { Error } from '../../../../styledComponents';
import { CustomInputLabel, Title } from '../../featureStyles';
import { Form } from './styles';

export const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error } = useSelector(({ auth }) => auth);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmedPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };
  useEffect(() => () => dispatch(resetAuth()), [dispatch]);

  return (
    <Formik
      initialValues={{
        password: '',
        confirmedPassword: '',
      }}
      validationSchema={restorePasswordValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={({ password, confirmedPassword }, { setFieldError }) => {
        if (password !== confirmedPassword) {
          setFieldError('confirmedPassword', 'Пароли не совпадают');
          return;
        }
        dispatch(resetPassword({ password, history }));
      }}
    >
      {({
        values: { password, confirmedPassword },
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        handleBlur,
        setFieldError,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Loader open={loading} />
          <Title>Восстановление пароля</Title>
          <p className="text">Придумайте и подтвердите новый пароль для входа в ваш аккаунт</p>
          <CustomInputLabel htmlFor="password">Введите новый пароль</CustomInputLabel>
          <Field
            id="password"
            className={errors.password && touched.password ? 'input error' : 'input'}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={({ target: { value } }) => {
              if (error) {
                dispatch(resetAuthError());
              }
              if (errors.password) {
                setFieldError('password');
              }
              setFieldValue('password', value);
            }}
            onBlur={handleBlur}
            handleClickShowPassword={handleClickShowPassword}
            showPassword={showPassword}
            component={PasswordInput}
          />
          {errors.password && touched.password ? <Error>{errors.password}</Error> : null}
          <CustomInputLabel htmlFor="confirmedPassword">Подтвердите пароль</CustomInputLabel>
          <Field
            id="confirmedPassword"
            className={
              errors.confirmedPassword && touched.confirmedPassword ? 'input error' : 'input'
            }
            type={showConfirmedPassword ? 'text' : 'password'}
            value={confirmedPassword}
            onChange={({ target: { value } }) => {
              if (error) {
                dispatch(resetAuthError());
              }
              if (errors.confirmedPassword) {
                setFieldError('confirmedPassword');
              }
              setFieldValue('confirmedPassword', value);
            }}
            onBlur={handleBlur}
            handleClickShowPassword={handleClickShowConfirmedPassword}
            showPassword={showConfirmedPassword}
            component={PasswordInput}
          />
          {errors.confirmedPassword && touched.confirmedPassword ? (
            <Error>{errors.confirmedPassword}</Error>
          ) : null}
          {error ? <Error>{error}</Error> : null}
          <Button className="btn" variant="contained" size="large" type="submit">
            Подтвердить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
