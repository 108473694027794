import { styled } from 'linaria/react';

export const Wrapper = styled.div`
  & {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    height: 32px;
    line-height: 24px;
    font-weight: 600;

    &.-submit {
      width: 130px;
      font-size: 16px;
      margin: 0 0 0 15px;
    }

    &.-cancel {
      border: none;
      padding: 0;
    }

    &.-cancel:hover {
      background: transparent;
    }
  }

  .closing-icon {
    position: relative;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    padding: 0 5px 0 0;
    color: #8f9bb3;
  }
`;
