import { styled } from 'linaria/react';

export const StyledPopover = styled.div`
  & {
    position: relative;
    height: 100%;
    margin: 0 15px 0 0;
  }

  .divider {
    height: 1px;
    margin: 15px 0 20px;
    background-color: #d8d8d8;
  }

  .MuiPopover-paper {
    max-width: 1060px;
    max-height: calc(100vh - 150px);
    top: 125px !important;
    padding: 20px 0 15px;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .icon {
    cursor: pointer;
    position: absolute;
    bottom: 14px;
    right: -2px;
    color: #006ade;
  }

  .MuiSlider-root {
    padding: 40px 0 20px;
    width: 190px;
  }
`;
