import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { addDays, isAfter } from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import { DayPickerInput } from '../../DayPickerInput';
import { Autocomplete } from '../../Autocomplete';
import { PopupWindow } from '../../PopupWindow';
import { ReactComponent as FreeCancelingIcon } from '../../../assets/icons/freeCanceling.svg';
import { ReactComponent as ShieldIcon } from '../../../assets/icons/shield.svg';
import {
  getCancelDate,
  getRentInitialValues,
  defaultRentPeriod,
  joinDayAndHours,
  formatDateISO,
  mapDisabledDates,
} from '../../../utils/date';
import localizationUtils from '../../../assets/locale';
import { validateForm } from './formValidation';
import { getUserInfo } from '../../../services/auth-service';
import { useQuery, setQuery } from '../../../services/query-service';
import timeSlots from '../../../constants/timeSlots';
import { popupData } from '../../../assets/data';
import { showLoginPopup } from '../../../redux/actions/auth';

import { Insurance, Notice, Error } from '../../../styledComponents';
import {
  Wrapper,
  Title,
  StyledDivider,
  Reservation,
  InlineReservation,
  Rent,
  SelectDate,
} from './styles';

const { insurance } = popupData;

export const CheckoutCard = ({
  price,
  maxRentDays,
  minRentDays,
  unavailabilityDates: { booked, blocked, rent },
  weekDiscount,
  monthDiscount,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const query = useQuery();
  const history = useHistory();
  const { uuid } = useSelector(({ cardData: { data } }) => data);
  const [expanded, setExpanded] = useState(false);
  const dateStart = query.get('dateStart');
  const dateEnd = query.get('dateEnd');
  const { dayStart, dayEnd, dayISOStart, timeStart, dayISOEnd, timeEnd } = getRentInitialValues({
    dateStart,
    dateEnd,
  });
  const blockedPeriods = [...booked, ...blocked, ...rent];
  const disabledDates = mapDisabledDates(blockedPeriods);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    handleSubmit,
    values: { dayStart: formDayStart, timeStart: formTimeStart, timeEnd: formTimeEnd },
    errors,
  } = useFormik({
    initialValues: {
      dayStart: dayISOStart,
      timeStart,
      dayEnd: dayISOEnd,
      timeEnd,
    },
    validateOnChange: false,
    validate: () => validateForm({ dateStart, dateEnd, minRentDays, maxRentDays, blockedPeriods }),
    onSubmit(values) {
      const userInfo = getUserInfo();
      if (userInfo) {
        return history.push(`/checkout/${uuid}?dateStart=${dateStart}&dateEnd=${dateEnd}`);
      }
      history.location.state = {
        nextStep: `/checkout/${uuid}?dateStart=${dateStart}&dateEnd=${dateEnd}`,
      };
      return dispatch(showLoginPopup());
    },
  });
  const cancelDay = getCancelDate(formDayStart);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Wrapper onSubmit={handleSubmit}>
      <InlineReservation>
        <div className="wrapper">
          <div className="price">
            <span className="number">{price}</span> <span className="currency">{'\u20BD'}</span>
            <span className="duration">/день</span>
          </div>
          <div className="sale">
            {(weekDiscount || monthDiscount) && (
              <>
                {(isDesktop && weekDiscount) || (isDesktop && monthDiscount) ? (
                  <span className="duration">Владелец предлагает скидки:</span>
                ) : (
                  <span className="duration">Скидки:</span>
                )}
              </>
            )}
            {weekDiscount && (
              <>
                <span className="number">{` ${weekDiscount}% `}</span>
                {isDesktop ? (
                  <span className="duration">за неделю</span>
                ) : (
                  <span className="duration">/неделя</span>
                )}{' '}
              </>
            )}
            {weekDiscount && monthDiscount && <span className="duration">,</span>}
            {monthDiscount && (
              <>
                <span className="number">{` ${monthDiscount}% `}</span>
                {isDesktop ? (
                  <span className="duration">за месяц</span>
                ) : (
                  <span className="duration">/месяц</span>
                )}{' '}
              </>
            )}
          </div>
        </div>
        <Button type="submit" variant="contained" size="large" className="btn">
          Продолжить
        </Button>
      </InlineReservation>
      {isDesktop && <StyledDivider />}
      <Title>Место получения</Title>
      {/* TODO refactor it after BE integration */}
      <span className="address">
        Сочи, Краснодарский край, Россия
        <Accordion
          expanded={expanded === 'delivery'}
          onChange={handleChange('delivery')}
          className="dropdown"
        >
          <AccordionSummary
            aria-controls="delivery-content"
            id="delivery"
            className="dropdown-summary"
          >
            {expanded !== 'delivery' ? <span className="text-btn">Изменить</span> : null}
          </AccordionSummary>
          <AccordionDetails className="dropdown-details">
            <RadioGroup aria-label="delivery-radio" name="delivery" defaultValue="host">
              <FormControlLabel
                className="filters-label"
                value="host"
                control={<Radio />}
                label="Забрать по адресу владельца"
              />
              <span className="delivery-title">Доставка</span>
              <p className="delivery-text">
                В стоимость доставки входит получение и возврат автомобиля по выбранному адресу
              </p>
              <FormControlLabel
                className="filters-label"
                value="sher"
                control={<Radio />}
                label="аэр. Шереметьево"
              />
              <FormControlLabel
                className="filters-label"
                value="vnuk"
                control={<Radio />}
                label="аэр. Внуково"
              />
              <FormControlLabel
                className="filters-label"
                value="custom"
                control={<Radio />}
                label="Доставка по адресу"
              />
              <Input className="input" placeholder="Введите адрес, мы его проверим" type="text" />
            </RadioGroup>
          </AccordionDetails>
          <AccordionActions className="dropdown-actions">
            <span className="text-btn" onClick={handleChange(false)}>
              Сохранить
            </span>
          </AccordionActions>
        </Accordion>
      </span>
      <Rent>
        <div className="picker -start">
          <Title>Получение</Title>
          <SelectDate error={errors.period || errors.duration}>
            <DayPickerInput
              id="dayStart"
              key="dayStart"
              value={dayStart}
              format="dd.MM.yyyy"
              inputProps={{
                className: 'picker-input -from',
              }}
              dayPickerProps={{
                month: new Date(),
                fromMonth: new Date(),
                disabledDays: [...disabledDates, { before: new Date() }],
                numberOfMonths: 1,
                locale: 'ru',
                localeUtils: localizationUtils,
              }}
              onDayChange={day => {
                const newDay = formatDateISO(day);
                // TODO add correct days( > 365 days)
                const nextDay = formatDateISO(addDays(day, defaultRentPeriod));
                if (isAfter(day, dayEnd)) {
                  setQuery('dateEnd', joinDayAndHours(nextDay, timeEnd), query, history);
                }
                setQuery('dateStart', joinDayAndHours(newDay, timeStart), query, history);
              }}
              classNames={{ container: 'picker-wrap -from' }}
            />
            <Autocomplete
              id="hourStart"
              className="hours -start"
              value={formTimeStart}
              options={timeSlots}
              getOptionDisabled={option => option}
              renderInput={params => <TextField className="autocomplete" {...params} />}
              onChange={(_, newValue) =>
                setQuery('dateStart', joinDayAndHours(dayISOStart, newValue), query, history)
              }
            />
          </SelectDate>
        </div>
        <div className="picker -end">
          <Title>Возврат</Title>
          <SelectDate error={errors.period || errors.duration}>
            <DayPickerInput
              id="dayEnd"
              key="hourEnd"
              value={dayEnd}
              format="dd.MM.yyyy"
              inputProps={{
                className: 'picker-input -to',
              }}
              dayPickerProps={{
                month: new Date(),
                fromMonth: dayStart,
                disabledDays: [...disabledDates, { before: dayStart }],
                numberOfMonths: 1,
                locale: 'ru',
                localeUtils: localizationUtils,
              }}
              onDayChange={day => {
                const newDay = formatDateISO(day);
                setQuery('dateEnd', joinDayAndHours(newDay, timeEnd), query, history);
              }}
              classNames={{ container: 'picker-wrap -to' }}
            />
            <Autocomplete
              id="hourEnd"
              className="hours -end"
              value={formTimeEnd}
              options={timeSlots}
              getOptionDisabled={option => option}
              renderInput={params => <TextField className="autocomplete" {...params} />}
              onChange={(_, newValue) =>
                setQuery('dateEnd', joinDayAndHours(dayISOEnd, newValue), query, history)
              }
            />
          </SelectDate>
        </div>
      </Rent>
      {errors.duration ? <Error>{errors.duration}</Error> : null}
      {errors.period ? <Error>{errors.period}</Error> : null}
      {isDesktop && (
        <Reservation>
          <div className="price">
            <span className="number">{price}</span> <span className="currency">{'\u20BD'}</span>
            <span className="duration">/день</span>
          </div>
          <Button type="submit" variant="contained" size="large" className="btn">
            Продолжить
          </Button>
          <div className="wrapper">
            <span className="notice">Пока вы ни за что не платите</span>
          </div>
        </Reservation>
      )}

      <Notice>
        <FreeCancelingIcon className="icon -hand" />
        <div className="notice">
          <span className="subtitle">Бесплатная отмена</span>
          <span className="text">
            Мы полностью вернем вам деньги в случае отмены бронирования до
          </span>{' '}
          <span className="date">{`${cancelDay} ${formTimeEnd}`}</span>
        </div>
      </Notice>
      <StyledDivider />
      <Insurance>
        <ShieldIcon className="icon -insurance" />
        <div className="notice">
          <span className="subtitle">Страхование</span>
          <span className="text">Страхование предоставляется компанией</span>{' '}
          <PopupWindow
            className="popup"
            popupData={insurance}
            popupTitle="Ваша поездка застрахована"
            popupLinkTitle="Ренессанс. Страхование"
          />
        </div>
      </Insurance>
      <StyledDivider />
    </Wrapper>
  );
};
