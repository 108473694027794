import React from 'react';
import StarRateIcon from '@material-ui/icons/StarRate';
import { Review } from '../../Review';

import { Wrapper, Title } from './styles';

export const Reviews = ({ reviews, quantity, rating }) => (
  <Wrapper id="reviews">
    <Title>Отзывы</Title>
    <div className="params">
      <span>{rating}</span>
      <StarRateIcon className="rate" />
      <span className="quantity">{`отзывы: ${quantity}`}</span>
    </div>
    {reviews.map(review => {
      const { author, date, rating, text } = review;
      return <Review author={author} date={date} rating={rating} text={text} />;
    })}
  </Wrapper>
);
