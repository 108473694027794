import { styled } from 'linaria/react';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Slider from '@material-ui/core/Slider';
import { SearchControlButton } from '../Button/SearchControlButton';
import { SliderInput } from '../SliderInput';
import mapImg from '../../assets/pictures/mapExample.JPG';
import { breakpoints } from '../../constants/breakpoints';

export const FiltersBar = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      position: fixed;
      bottom: 15px;
      width: 230px;
      height: 30px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 4px 16px 0 rgba(72, 107, 125, 0.15);
    }

    .filters {
      display: flex;
      align-items: center;
    }

    .filters > .filters-badge > .MuiBadge-anchorOriginTopRightRectangle {
      width: 25px;
      height: 23px;
      top: 7px;
      left: 67px;
      justify-content: center;
      font-weight: 600;
    }

    .filters > .filters-badge > .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }

    .filter-container {
      margin: 4px 0 0 10px;
      padding: 0 0 0 10px;
      border-left: 1px solid #8f9bb3;
      height: 24px;
    }

    .filters > .MuiButton-outlinedSecondary {
      color: #066bd6;
      border: none;
      font-size: 14px;
      height: 28px;
      padding: 0 0 3px 10px;
    }

    .map-container {
      margin: 0 5px 0 10px;
    }

    .MuiFormControlLabel-root {
      margin-left: 10px;
    }

    .label {
      margin-left: 20px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      width: 90px;
    }

    .filter-container {
      display: none;
    }

    .filters > .MuiButton-outlinedSecondary {
      padding: 0 0 0px 3px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: none;
    }
  }
`;

// todo: remove after merging branches
export const DialogContainer = styled(Dialog)`
  .MuiDialog-container .MuiDialog-paper {
    bottom: 0;
    margin: 0;
    padding: ${props => (props.openMap ? '0' : '0 15px')};
    border-radius: 0;
  }

  .PrivateSwitchBase-root-1 {
    padding: 0 9px;
  }

  .price-label {
    margin-left: 0;
  }

  .filters-label {
    width: 100%;
    margin-bottom: 12px;
  }

  .MuiDivider-root {
    margin: 20px 0 20px;
  }

  .closing-cross {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
  }

  .title {
    color: var(--grey-text);
    font-family: 'Open Sans', 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    margin: 25px 0 15px 0;

    &.-first {
      margin-top: 15px;
    }
  }

  .map {
    width: 100%;
    height: 100%;
    background-image: url(${mapImg});
  }

  @media ${breakpoints.tablet} {
    .MuiDialog-container .MuiDialog-paper {
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  & {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .box {
    width: 100%;
    margin: 30px 0 0;

    &.-using {
      width: 100%;
      margin-right: 0;
    }

    &.-selects {
      width: 100%;
      max-width: 320px;
      height: 120px;
      margin: 0;
    }

    & .title.-first.-distance {
      margin-bottom: 8px;
    }

    &.-seats {
      padding: 0 0 60px;
      border-bottom: 1px solid #d8d8d8;
    }

    &.-engine {
      margin: 0;
      width: 345px;
    }
  }

  .box.-selects > .title.-first {
    margin-top: 70px;
  }

  .select {
    width: 100%;
    margin-bottom: 20px;
  }

  .select > div {
    height: 40px;
  }

  .title {
    color: var(--grey-text);
    font-family: 'Open Sans', 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    margin: 25px 0 15px 0;

    &.-first {
      margin-top: 0;
    }
  }
`;

export const Collection = styled.div`
  & {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${props => (props.last ? '0 0 235px 0' : '0')};
  }

  .title {
    color: var(--grey-text);
    font-family: 'Open Sans', 'sans-serif';
    font-size: 14px;
    line-height: 20px;

    &.-device {
      margin: 25px 0 20px;
    }
  }

  .filters-label {
    width: 100%;
  }
`;

export const StyledSliderInput = styled(SliderInput)`
  & {
    margin: 50px 0 0;
  }

  .MuiSlider-root {
    padding: 20px 0 50px 0;
  }
`;

export const StyledSlider = styled(Slider)`
  .MuiSlider-track {
    height: 4px;
    border-radius: 6px;
  }

  .MuiSlider-rail {
    height: 4px;
    border-radius: 6px;
    background-color: #c4c4c4;
  }

  .MuiSlider-thumb {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    margin-top: -7px;
    border: 2px solid #006ade;
    background-color: #ffffff;
  }

  .MuiSlider-thumb.Mui-disabled {
    margin-top: -7px;
    margin-left: -6px;
    width: 20px;
    height: 20px;
  }

  .MuiSlider-valueLabel {
    left: -30%;
    top: 35px;
    font-size: 16px;
    line-height: 24px;
    color: #303133;

    & * {
      background: transparent;
      color: #000;
    }
  }

  .MuiSlider-valueLabel span {
    top: 30px;
    left: -2px;
    width: 0;
    height: 0;
    transform: none;
    align-items: center;
    border-radius: 0;
    color: var(--primary-text);
    letter-spacing: -0.07px;
  }
`;

export const Form = styled.form`
  @media ${breakpoints.mobile} {
    padding-top: 45px;
  }
`;

export const StyledSearchButtons = styled(SearchControlButton)`
  & {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px 15px;
    background-color: #fff;
    border-top: 1px solid #d8d8d8;
    z-index: 1;

    &.-submit {
      margin: 0 0 0 24px;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  & {
    height: 1px;
    margin: 20px 0 0;
    background-color: #d8d8d8;
  }
`;
