import { handleActions } from 'redux-actions';
import { without } from 'ramda';

import { uploaderActions } from '../../actions';

const { uploadFile, deleteFile, clearStorage } = uploaderActions;

const initialState = { STSFrontSide: [], STSReverseSide: [], carPhotos: [] };
export default handleActions(
  {
    [uploadFile](
      state,
      {
        payload: { name, key, url },
      },
    ) {
      return { ...state, [name]: [...state[name], { key, url, action: 'save' }] };
    },
    [deleteFile](
      state,
      {
        payload: { name, key },
      },
    ) {
      const sessionImage = state[name].filter(image => image.key === key);
      if (sessionImage.length > 0) {
        const updatedImages = without(sessionImage, state[name]);
        return { ...state, [name]: updatedImages };
      }
      return { ...state, [name]: [...state[name], { key, action: 'delete' }] };
    },
    [clearStorage]() {
      return initialState;
    },
  },
  initialState,
);
