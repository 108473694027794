import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Link from '@material-ui/core/Link';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CustomCheckbox } from '../../Checkbox';
import { AdditionalServiceConditions } from '../AdditionalServiceConditions';
import { carServices, carServicesLabels } from '../../../assets/data';

import { TabTitle, TabRecomendation } from '../featureStyles';
import { ServicesWrapper } from './styles';

export const TabServices = () => {
  const { values, errors, setFieldValue } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ServicesWrapper>
      <FormGroup className="checkbox-holder">
        <TabTitle>Предложите дополнительные услуги</TabTitle>
        <TabRecomendation className="text">
          Прочитайте инструкцию{' '}
          <Link
            target="_blank"
            href="https://help.getarent.ru/knowledge-bases/9/articles/97-kakuyu-tsenu-ustanovit-za-uslugi"
            className="text-link"
          >
            как установить цены за услуги
          </Link>
        </TabRecomendation>
        {Object.keys(carServices).map(service => {
          const { checked, price } = values[service];
          return (
            // TODO check can we replace it with psevdocalass
            <div key={`${service}-wrapper`} className={checked ? 'checked' : 'unchecked'}>
              <FormControlLabel
                className="checkbox"
                control={
                  <CustomCheckbox
                    id={service}
                    name={service}
                    checked={checked}
                    onChange={({ target: { checked } }) =>
                      setFieldValue(service, { ...values[service], checked })
                    }
                  />
                }
                label={carServices[service]}
                key={service}
              />
              {checked && (
                <AdditionalServiceConditions
                  setFieldValue={setFieldValue}
                  name={service}
                  price={price}
                  values={values}
                  errors={errors}
                  priceLabel={carServicesLabels[service]}
                />
              )}
            </div>
          );
        })}
      </FormGroup>
    </ServicesWrapper>
  );
};
