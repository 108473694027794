import { parseISO, differenceInDays } from 'date-fns';
import { getOverlappingPeriods, getRentDuration } from '../../../utils/date';

export const validateForm = ({
  dateStart,
  dateEnd,
  minRentDays,
  maxRentDays,
  blockedPeriods = [],
}) => {
  const errors = {};
  const formattedDateStart = parseISO(dateStart, new Date());
  const formattedDateEnd = parseISO(dateEnd, new Date());
  const rentDuration = getRentDuration(formattedDateEnd, formattedDateStart);
  if (rentDuration > maxRentDays) {
    errors.duration = `Максимально количество дней аренды ${maxRentDays}`;
  }
  if (rentDuration < minRentDays) {
    errors.duration = `Минимальное количество дней аренды ${minRentDays}`;
  }
  const overlappingDays = getOverlappingPeriods(
    blockedPeriods,
    formattedDateStart,
    formattedDateEnd,
  );
  // TODO add correct error, we have array with overlappingDays
  if (overlappingDays.length > 0) {
    errors.period = 'В выбранный период машина недоступна';
  }
  return errors;
};
