import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';
import { CustomInputLabel } from '../../featureStyles';

export const ConfirmationCodeWrapper = styled.form`
  @media ${breakpoints.mobile} {
    & {
      max-width: 320px;
    }

    ${CustomInputLabel} {
      margin: 0 0 10px;
    }

    .code-input {
      width: 191px;
      margin-bottom: 0;
    }

    .btn {
      width: 191px;
      display: block;
      margin-top: 25px;
    }
  }
`;
