import React from 'react';
import Car from '../../views/Card';

import { CardContainer } from './styles';

const Card = () => {
  return (
    <CardContainer>
      <Car />
    </CardContainer>
  );
};

export default Card;
