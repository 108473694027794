import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const SearchResultContainer = styled.div`
  @media ${breakpoints.mobile} {
    .location {
      font-size: 12px;
      color: var(--primary-text);
    }

    .date-time {
      color: var(--grey-text);

      font-size: 12px;
      letter-spacing: -0.05px;
    }

    .periods-wrapper {
      display: none;

      &-.mobile {
        display: flex;
      }
    }

    .menu-opened,
    .menu-closed {
      position: absolute;
      top: -5px;
      right: 10px;
    }

    .menu-opened {
      transform: rotate(180deg);
    }

    .date-pick {
      position: absolute;
      top: 40px;
      background: #fff;
      right: 0;
      width: 100vw;
      z-index: 4;
      display: none;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px 0 50px 20px;
    }

    .actions {
      display: flex;
      width: 100%;
      height: auto;
      justify-content: flex-end;
      margin-top: 20px;
    }

    .btn,
    .btn:hover,
    .btn:active {
      margin: 0;
      background-color: #fff;
      height: 30px;
      box-shadow: none;
      cursor: pointer;
    }

    .btn > .MuiButton-label {
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      padding: 3px 10px;
    }

    .btn.-cancel > .MuiButton-label {
      color: var(--grey-text);
      border: 1px solid #5d5d5d;
    }

    .btn.-search > .MuiButton-label {
      color: #066bd6;
      border: 1px solid #066bd6;
    }

    .MuiAutocomplete-popupIndicator {
      color: #8f9bb3;
    }

    .opened {
      display: flex;
      z-index: 5;
    }

    .change-parameters {
      display: none;
    }

    .arrow-right {
      position: relative;
      top: 2px;
      margin: 0 5px;
    }
  }

  @media ${breakpoints.tablet} {
    .location {
      font-size: 16px;
      color: var(--primary-text);
    }

    .actions {
      width: 520px;
      margin: 20px auto 0 80px;
    }

    .date-time {
      color: var(--grey-text);
      font-size: 14px;
    }

    .periods-wrapper {
      display: flex;

      &.-mobile {
        display: none;
      }
    }

    .menu-opened,
    .menu-closed {
      display: none;
    }

    .date-pick {
      left: -115px;
    }

    .date-pick .cancel-button {
      position: relative;
      top: 25px;
      right: 0;
      background-color: #fff;
      height: 30px;
      box-shadow: none;
      cursor: pointer;
    }

    .cancel-button > .MuiButton-label {
      font-size: 16px;
      position: relative;
      bottom: 5px;
      font-weight: 400;
      color: var(--grey-text);
    }

    .opened {
      display: flex;
      z-index: 1;
    }

    .change-parameters {
      display: inline;
      font-size: 14px;
      color: #0069dd;
      margin-left: 10px;
      cursor: pointer;
    }

    .arrow-right {
      position: relative;
      top: 1px;
      margin: 0 5px;
    }
  }

  @media ${breakpoints.laptop} {
    .location {
      display: none;
    }

    .date-time {
      display: none;
    }

    .date-pick {
      display: flex;
      position: relative;
      flex-direction: row;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0;
      box-shadow: none;
    }

    .cancel-button {
      display: none;
    }

    .date-pick
      .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
      .MuiAutocomplete-inputRoot {
      padding-right: 10px;
    }

    .change-parameters {
      display: none;
    }
  }
`;

export const ChangeParametersLink = styled.span`
  @media ${breakpoints.tablet} {
    & {
      font-size: 14px;
      color: #0069dd;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: none;
    }
  }
`;
