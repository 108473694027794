import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { confirmationCodeValidationSchema } from '../../../../utils/validate';
import {
  submitUserPhone,
  confirmUserPhone,
  resetConfirmationError,
} from '../../../../redux/actions/user';
import { PhoneForm } from '../PhoneForm';
import { ConfirmationCodeForm } from '../ConfirmationCode';
import { PopupWrap } from '../../featureStyles';
import { PhoneWrapper } from './styles';

// TODO: move to constants
const phoneRegExp = /^(?=.{10,11}$)[0-9]{1}[0-9]{9,10}$/;
const prefix = '+';

export const ConfirmPhonePopup = () => {
  const dispatch = useDispatch();
  const { isSubmit } = useSelector(({ confirmation }) => confirmation);
  const onFormSubmit = useCallback(
    ({ phone }, { setFieldError }) => {
      if (!phone.match(phoneRegExp)) {
        setFieldError('phone', 'Введите правильный номер телефона');
        return;
      }
      dispatch(submitUserPhone({ phone: `${prefix}${phone}` }));
    },
    [dispatch],
  );
  const onConfirmationSubmit = useCallback(
    values => {
      dispatch(confirmUserPhone(values));
    },
    [dispatch],
  );
  useEffect(() => () => dispatch(resetConfirmationError()), [dispatch]);

  return (
    <PopupWrap>
      <PhoneWrapper>
        <Formik
          initialValues={{ phone: '' }}
          onSubmit={onFormSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <PhoneForm />
        </Formik>
        {isSubmit && (
          <Formik
            initialValues={{ confirmationCode: '' }}
            onSubmit={onConfirmationSubmit}
            validationSchema={confirmationCodeValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            <ConfirmationCodeForm />
          </Formik>
        )}
      </PhoneWrapper>
    </PopupWrap>
  );
};
