import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const SwitchButton = ({ showMap, checked }) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={showMap} value="checked" />}
      label="Показать карту"
      className="switch-button"
    />
  );
};
