import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NavButton } from '../../components/Button/NavButton';
import tabs from './tabsMapping';
import getNavigation from '../../utils/navigation';
import MobileView from './mobileView';

import { CarEditSidebar } from '../../components/CarEdit/featureStyles';
import { InformationWrapper } from './styles';

export default ({ parentNavigation }) => {
  const [tabValue, updateTabValue] = useState(0);
  const tabsHandleChange = (event, value) => updateTabValue(value);
  const theme = useTheme();

  const { content, advice } = tabs[tabValue];
  const childNavigation = {
    prev: tabs[tabValue - 1] ? tabValue - 1 : null,
    next: tabs[tabValue + 1] ? tabValue + 1 : null,
    handler: tabsHandleChange,
  };
  const navigation = getNavigation(childNavigation, parentNavigation);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isMobile ? (
        <MobileView />
      ) : (
        <InformationWrapper>
          <CarEditSidebar>
            <Tabs
              className="list"
              orientation="vertical"
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={tabsHandleChange}
            >
              <Tab className="subtab" label="Основное" />
              <Tab className="subtab" label="Описание машины" />
              <Tab className="subtab" label="Фотографии" />
            </Tabs>
            <div className="advice">
              <div>{advice}</div>
            </div>
          </CarEditSidebar>
          <div className="content">
            {content}
            <NavButton {...navigation} />
          </div>
        </InformationWrapper>
      )}
    </>
  );
};
