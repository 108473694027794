import { styled } from 'linaria/react';
import Dialog from '@material-ui/core/Dialog';
import { breakpoints } from '../../constants/breakpoints';
import { PopupWindow } from '../../components/PopupWindow';

export const StyledDialod = styled(Dialog)`
  background: #142c4c;
`;

export const StyledPopupWindow = styled(PopupWindow)`
  @media ${breakpoints.mobile} {
    & {
      display: inline;
      position: initial;
      line-height: 24px;
      font-size: 16px;
      padding: 0;
    }

    .MuiButtonBase-root {
      line-height: 24px;
      height: 22px;
      margin-bottom: 3px;
      border-radius: 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      position: relative;
      left: 0px;
      bottom: 6px;
      z-index: 0;
      padding: 0;
    }
    .popup-open-button {
      background: none;
    }
  }
`;

export const CarRegisterWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 320px;
      padding: 110px 25px 54px;
    }

    .title {
      font-weight: 600;
      color: var(--primary-text);
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      color: var(--grey-text);
    }
  }

  @media ${breakpoints.tablet} {
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 445px;
      margin: 70px auto 70px;
      padding: 0;
    }

    .title {
      font-weight: 600;
      color: var(--primary-text);
      font-size: 24px;
      margin: 0 0 8px 0;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--grey-text);
      margin: 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 500px;
    }

    .title {
      font-weight: 600;
      color: var(--primary-text);
      font-size: 24px;
      margin: 0 0 8px;
    }

    .text {
      width: 500px;
      font-weight: 400;
      font-size: 16px;
      color: var(--grey-text);
      margin: 0;
    }
  }
`;
