import {
  addDays,
  addHours,
  differenceInMinutes,
  getMinutes,
  setMinutes,
  format as formatDateFns,
  formatISO,
  parse,
  parseISO,
  areIntervalsOverlapping,
} from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

export const defaultRentPeriod = 5;

export const hoursForPrepearing = 3;

export const getDate = (dateISO, format = 'dd.MM.yyyy', locale = 'en') => {
  const date = parseISO(dateISO);
  return formatDateFns(date, format, locale);
};

export const getTime = dateISO => {
  const date = parseISO(dateISO);
  return formatDateFns(date, 'HH:mm');
};

export const getOverlappingPeriods = (blockedPeriods, start, end) =>
  blockedPeriods.filter(current => {
    const crossDays = areIntervalsOverlapping(
      {
        start: parseISO(current.startDate),
        end: parseISO(current.endDate),
      },
      { start, end },
      { inclusive: true },
    );
    return crossDays;
  });

export const getCancelDate = day => {
  const date = parseISO(day, new Date());
  const cancelDate = formatDateFns(addDays(date, -1), 'd MMM yyyy', { locale: ruLocale });

  return cancelDate;
};

export const getRentInitialValues = ({ dateStart, dateEnd }) => {
  const dayStart = parseISO(dateStart);
  const dayEnd = parseISO(dateEnd);
  const dayISOStart = getDate(dateStart, 'yyyy-MM-dd');
  const timeStart = getTime(dateStart);
  const dayISOEnd = getDate(dateEnd, 'yyyy-MM-dd');
  const timeEnd = getTime(dateEnd);
  const initialValues = { dayStart, dayEnd, dayISOStart, timeStart, dayISOEnd, timeEnd };
  return initialValues;
};

export const getRentDuration = (end, start) => {
  const duration = Math.ceil(differenceInMinutes(end, start) / 1440) || 0;
  return duration;
};

export const joinDayAndHours = (day, hours) => `${day}T${hours}`;

export const formatDateISO = day => formatISO(day, { representation: 'date' });

export const defaultStartRent = () => {
  const startMinutes = getMinutes(new Date()) < 30 ? 30 : 0;
  const startDate = addHours(setMinutes(new Date(), startMinutes), hoursForPrepearing);
  return formatDateFns(startDate, "yyyy-MM-dd'T'HH:mm");
};

export const defaultEndRent = start => {
  const startDate = parse(start, "yyyy-MM-dd'T'HH:mm", new Date());
  const endDate = addDays(startDate, defaultRentPeriod);
  return formatDateFns(endDate, "yyyy-MM-dd'T'HH:mm");
};

export const mapDisabledDates = periods => {
  const disabledDates = periods.map(period => ({
    after: addDays(parseISO(period.startDate), -1),
    before: addDays(parseISO(period.endDate), 1),
  }));
  return disabledDates;
};
