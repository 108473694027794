// TODO: combine logic for social and common email confirmation
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
// import { closeAuthPopup } from '../../../../redux/actions/auth';
import {
  // submitEmailValidationSchema,
  confirmationCodeValidationSchema,
} from '../../../../utils/validate';
import {
  submitUserEmail,
  confirmUserEmail,
  resetConfirmationError,
} from '../../../../redux/actions/user';
import { Loader } from '../../../Loader/Backdrop';
import { ConfirmationCodeForm } from '../ConfirmationCode';
import { PopupWrap, Title } from '../../featureStyles';
import { EmailWrapper } from './styles';

export const ConfirmEmailPopup = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ confirmation }) => confirmation);
  // const onFormSubmit = useCallback(
  //   values => {
  //     dispatch(submitUserEmail(values));
  //   },
  //   [dispatch],
  // );
  const onRepeatedCall = useCallback(() => dispatch(submitUserEmail()), [dispatch]);
  const onConfirmationSubmit = useCallback(
    values => {
      dispatch(confirmUserEmail(values));
    },
    [dispatch],
  );
  useEffect(() => {
    dispatch(submitUserEmail());
    return () => dispatch(resetConfirmationError());
  }, [dispatch]);

  return (
    <PopupWrap>
      <EmailWrapper>
        <Loader open={loading} />
        <div className="wrap">
          <Title>Подтвердите почту</Title>
          <p className="text">Мы отправили на вашу почту код</p>
          <Button className="text-btn" variant="contained" size="large" onClick={onRepeatedCall}>
            Отправить код повторно
          </Button>
        </div>
        {/* <Formik
          initialValues={{ email: '' }}
          onSubmit={onFormSubmit}
          validationSchema={submitEmailValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <EmailForm />
        </Formik>
        {isSubmit && ( */}
        <Formik
          initialValues={{ confirmationCode: '' }}
          onSubmit={onConfirmationSubmit}
          validationSchema={confirmationCodeValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <ConfirmationCodeForm />
        </Formik>
        {/* )} */}
      </EmailWrapper>
    </PopupWrap>
  );
};
