import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const DeviceWrapper = styled.div`
  @media ${breakpoints.mobile} {
    .box {
      margin-top: 18px;
    }
  }

  @media ${breakpoints.tablet} {
    .text {
      margin: 8px 0 23px;
    }

    .box {
      margin: 0 0 31px;
    }
  }

  @media ${breakpoints.laptop} {
    .text {
      margin: 8px 0 17px 0;
    }
  }
`;
