import { useLocation } from 'react-router-dom';

export const useQuery = () => new URLSearchParams(useLocation().search);

export const checkQuery = query => {
  switch (query) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'null':
      return null;
    default:
      return query;
  }
};

const requiredFiters = ['dateEnd', 'dateStart', 'location', 'order', 'page', 'bounds'];

const setParams = (name, value, query) => {
  query.set(name, value);
  return query.toString();
};

export const setQuery = (name, value, query, history) => {
  const url = setParams(name, value, query);
  history.push(`?${url}`);
};

export const submitQueries = (values, query, history) => {
  Object.keys(values).forEach(key => query.set(key, values[key]));
  const url = query.toString();
  history.push(`?${url}`);
};

export const resetAllQueries = (query, history) => {
  const queries = [];
  query.forEach((value, key) => queries.push(key));
  queries.forEach(key => {
    if (requiredFiters.indexOf(key) === -1) {
      query.delete(key);
    }
  });
  const url = query.toString();
  history.push(`?${url}`);
};

export const resetQueries = (filters, query, history) => {
  filters.forEach(key => {
    // for Safari
    if (query.has(key)) {
      query.delete(key);
    }
  });
  const url = query.toString();
  history.push(`?${url}`);
};

export const filtersMapper = (filtersQueries, defaultValues) => {
  const formValues = Object.keys(defaultValues).reduce((acc, filter) => {
    return { ...acc, [filter]: checkQuery(filtersQueries.get(filter)) || defaultValues[filter] };
  }, {});
  return formValues;
};
