import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { signinValidationSchema } from '../../../../utils/validate';
import { signInUser, showSignupPopup, closeAuthPopup } from '../../../../redux/actions/auth';
import { LoginForm } from '../Form';

import { PopupWrap } from '../../featureStyles';

export const LoginPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onFormSubmit = useCallback(
    ({ email, password }) => {
      dispatch(signInUser({ authData: { email, password }, history }));
    },
    [dispatch, history],
  );
  const onRegistrationClick = useCallback(() => dispatch(showSignupPopup()), [dispatch]);

  return (
    <PopupWrap>
      <CloseIcon className="close" onClick={() => dispatch(closeAuthPopup())} />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validateOnChange={false}
        validationSchema={signinValidationSchema}
        onSubmit={onFormSubmit}
      >
        <LoginForm onRegistrationClick={onRegistrationClick} />
      </Formik>
    </PopupWrap>
  );
};
