import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { omit } from 'ramda';
import { Formik, Field } from 'formik';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '../../../components/Autocomplete';
import { MaskedInput } from '../../../components/MaskedInput';
import { Uploader } from '../../../components/Uploader';
import { CustomCheckbox } from '../../../components/Checkbox';
import { Loader } from '../../../components/Loader/Backdrop';
import { registrationNumberMask } from '../../../constants/masks';
import { carRegistrationValidationSchema } from '../../../utils/validate';
import { carActions } from '../../../redux/actions';

import { Error as StyledError } from '../../../styledComponents';
import { RegisterForm } from './styles';

const { registerCar } = carActions;

const documentPhotosNumber = 1;

export const CarRegistrationForm = ({ carRegisterData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector(({ carRegistrationData }) => carRegistrationData);
  const { STSFrontSide, STSReverseSide } = useSelector(({ uploader }) => uploader);

  return (
    <Formik
      initialValues={{
        brand: '',
        model: '',
        productionYear: null,
        registrationNumber: '',
      }}
      validationSchema={carRegistrationValidationSchema}
      onSubmit={values => {
        const updatedValues = omit(['licence'], values);
        const registrationData = {
          ...updatedValues,
          STSReverseSide: {
            key: STSReverseSide[0].key,
          },
          STSFrontSide: {
            key: STSFrontSide[0].key,
          },
        };
        dispatch(registerCar({ car: registrationData, history }));
      }}
    >
      {({
        values: { brand, model, registrationNumber },
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        handleBlur,
        isValid,
        dirty,
      }) => {
        const cars = Object.keys(carRegisterData.cars);
        const models = brand ? carRegisterData.cars[brand].models : [];
        const { years } = carRegisterData;
        return (
          <RegisterForm onSubmit={handleSubmit}>
            <Loader open={loading} />
            <label className="label" htmlFor="brand">
              Марка
            </label>
            <Autocomplete
              id="brand"
              className="select"
              options={cars}
              getOptionSelected={option => option}
              onChange={(_, newValue) => {
                setFieldValue('brand', newValue);
                setFieldValue('model', '');
              }}
              renderInput={params => <TextField {...params} placeholder="- Например, Hyundai -" />}
            />
            <label className="label" htmlFor="model">
              Модель
            </label>
            <Autocomplete
              id="model"
              className="select"
              options={models}
              value={model}
              disabled={!brand}
              getOptionSelected={option => option}
              onChange={(_, newValue) => setFieldValue('model', newValue)}
              renderInput={params => <TextField {...params} placeholder="- Выберите модель -" />}
            />
            <label className="label" htmlFor="productionYear">
              Год
            </label>
            <Autocomplete
              id="productionYear"
              className="select"
              options={years}
              getOptionLabel={option => `${option}`}
              getOptionSelected={option => `${option}`}
              onChange={(_, newValue) => setFieldValue('productionYear', newValue)}
              renderInput={params => <TextField {...params} placeholder="- Выберите год -" />}
            />
            <InputLabel className="label" htmlFor="registrationNumber">
              Гос. Номер
            </InputLabel>
            <Field
              id="registrationNumber"
              className="select"
              placeholder="Например, А123АА12"
              mask={registrationNumberMask}
              component={MaskedInput}
              value={registrationNumber}
              type="text"
              onChange={({ target: { value } }) =>
                setFieldValue('registrationNumber', value.toUpperCase())
              }
              onBlur={handleBlur}
            />
            {errors.registrationNumber && touched.registrationNumber ? (
              <StyledError>{errors.registrationNumber}</StyledError>
            ) : null}
            <label className="label" htmlFor="brand">
              Загрузите фотографию лицевой стороны СТС
            </label>
            <Uploader maxFiles={documentPhotosNumber} name="STSFrontSide" private />
            <label className="label" htmlFor="brand">
              Загрузите фотографию обратной стороны СТС
            </label>
            <Uploader maxFiles={documentPhotosNumber} name="STSReverseSide" private />
            <div className="licence-wrapper">
              <FormControlLabel
                className="licence"
                control={
                  <CustomCheckbox
                    onChange={({ target: { checked } }) => setFieldValue('licence', checked)}
                    defaultChecked
                    name="licence"
                    className="checkbox"
                  />
                }
                label={
                  <div>
                    <span>Я принимаю </span>
                    <Link target="_blank" href="http://www.google.com">
                      Правила и принципы Сервиса
                    </Link>{' '}
                    <span>и согласен с </span>
                    <Link
                      target="_blank"
                      href="https://help.getarent.ru/knowledge-bases/8/articles/77-politika-obrabotki-personalnyih-dannyih"
                    >
                      Политикой обработки персональных данных
                    </Link>
                  </div>
                }
              />
              {/* TODO replace with FAQ url */}
            </div>
            <Button
              color="primary"
              className="btn"
              variant="contained"
              disabled={!(isValid && dirty && STSFrontSide.length > 0 && STSReverseSide.length > 0)}
              type="submit"
            >
              Зарегистрировать
            </Button>
          </RegisterForm>
        );
      }}
    </Formik>
  );
};
