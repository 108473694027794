import React from 'react';
import { ControlPrice } from './Price';
import { ControlDelivery } from './Delivery';
import { ControlInstantBook } from './InstantBook';
import { Filters } from './Filters';
import { SwitchButton } from '../../SwitchButton';

export const Controls = ({ minPrice, maxPrice, showMap, checked, mapExists }) => {
  return (
    <div className="search-nav-container">
      <div className="search-nav">
        <ControlPrice minPrice={minPrice} maxPrice={maxPrice} />
        <ControlInstantBook />
        <ControlDelivery />
        <Filters />
        {{ mapExists } && <SwitchButton showMap={showMap} checked={checked} />}
      </div>
    </div>
  );
};
