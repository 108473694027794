import React from 'react';
import { TabPrices } from '../../components/CarEdit/TabPrices';
import { TabDevices } from '../../components/CarEdit/TabDevices';
import { TabServices } from '../../components/CarEdit/TabServices';
import { TabRules } from '../../components/CarEdit/TabRules';
import { TabInformation } from '../../components/CarEdit/TabInformation';
import { Advice } from '../../components/Advice';
import { advice } from '../../assets/data';

export default {
  '0': {
    content: <TabPrices />,
    advice: <Advice advice={advice.prices} styleModificator="-main" />,
  },
  '1': {
    content: <TabRules />,
    advice: <Advice advice={advice.rules} styleModificator="-main" />,
  },
  '2': {
    content: <TabDevices />,
    advice: <Advice advice={advice.devices} styleModificator="-main" />,
  },
  '3': {
    content: <TabServices />,
    advice: <Advice advice={advice.services} styleModificator="-main" />,
  },
  '4': {
    content: <TabInformation />,
    advice: <Advice advice={advice.information} styleModificator="-main" />,
  },
};
