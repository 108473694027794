import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash/debounce';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { useQuery, setQuery } from '../../services/query-service';
import { Map } from './Map';
import { MapContext } from './MapContext';

import MapMarker from './MapMarker';
import { MapTooltip } from './MapTooltip';

export default function MainMap({ zoomControl = true }) {
  const [mapInstance, setMapInstance] = useState();
  const [tooltip, setTooltip] = useState();
  const [active, setActive] = useState();

  const query = useQuery();
  const history = useHistory();
  const queryRef = useRef(query);

  const { data, meta } = useSelector(({ search: { cars } }) => cars);

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  const setBounds = debounce(e => {
    setQuery('bounds', [...e.northEast, ...e.southWest], queryRef.current, history);
  }, 500);

  const handleClick = i => e => {
    setActive(i);
  };

  if (data) {
    let center = [];

    if (meta.bounds) {
      const { sw, ne } = meta.bounds;

      center = [(sw.lon + ne.lon) / 2, (sw.lat + ne.lat) / 2];
    }
    return (
      <MapContext.Provider value={[mapInstance, setMapInstance]}>
        <Map zoomControl={zoomControl} center={center} setBounds={setBounds}>
          {data.map((car, i) => (
            <MapMarker
              key={car.uuid}
              car={car}
              onClick={handleClick(i)}
              onBlur={setActive}
              active={active == i}
              setTooltip={setTooltip}
              isLabel={true}
              index={i + 1}
            />
          ))}
        </Map>
        {tooltip && tooltip.visible && (
          <MapTooltip position={tooltip.position} visible={tooltip.visible} car={tooltip.car} />
        )}
      </MapContext.Provider>
    );
  }

  return <Skeleton animation="wave" variant="rect" width="100%" height="100%" />;
}
