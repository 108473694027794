import { styled } from 'linaria/react';
import Dialog from '@material-ui/core/Dialog';
import { breakpoints } from '../../constants/breakpoints';

export const StyledDialog = styled(Dialog)`
  @media ${breakpoints.mobile} {
    .MuiDialog-container .MuiDialog-paper {
      margin: 16px;
      border-radius: 10px;
    }

    .MuiPaper-root .MuiDialogTitle-root {
      padding: 20px 30px 0;
    }

    .MuiPaper-root .MuiDialogContent-root {
      padding: 10px;
    }

    .popup-items-list {
      list-style-type: square;
      line-height: 24px;
      color: #8f9bb3;
    }

    .list-item {
      color: #8f9bb3;
      margin-bottom: 10px;
    }

    .item-text {
      color: var(--primary-text);
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.07px;
    }

    .MuiTypography-h6 {
      font-size: 20px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .MuiButton-label {
      font-weight: 400;
      text-transform: initial;
    }

    .MuiPaper-root .MuiDialogActions-root {
      padding: 0 30px 40px;
    }

    .MuiDialog-root .MuiDialogActions-root .MuiButton-root {
      height: 35px;
      width: 125px;
      padding: 0 8px;
      font-size: 16px;
      background-color: #066bd6;
      color: #fff;
      border-radius: 4px;
    }

    .MuiDialog-root .MuiDialogActions-root .MuiButton-root:last-child {
      width: 90px;
      color: var(--grey-text);
      background: none;
      box-shadow: none;
    }

    .closing-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  @media ${breakpoints.tablet} {
    .popup-open-button {
      background-color: #fff;
      border-bottom: 1px dotted;
    }

    .MuiPaper-root .MuiDialogActions-root {
      padding: 0 20px 20px;
    }

    .MuiDialog-container .MuiDialog-paper {
      width: 650px;
    }

    .MuiPaper-root .MuiDialogContent-root {
      padding: 0;
    }

    .MuiPaper-root .MuiDialogTitle-root {
      padding: 20px 20px 0;
    }

    .MuiDialog-root .MuiTypography-h6 {
      font-size: 24px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .MuiDialog-root .MuiDialogActions-root .MuiButton-root {
      height: 35px;
      width: 125px;
      padding: 0 8px;
      font-size: 16px;
      background-color: #066bd6;
      color: #fff;
      border-radius: 4px;
      margin-right: 30px;
    }
  }

  @media ${breakpoints.laptop} {
    .MuiDialog-container .MuiDialog-paperWidthSm {
      max-width: 650px;
    }
  }
`;

export const Popup = styled.div`
  @media ${breakpoints.mobile} {
    & {
      cursor: pointer;
      display: inline;
    }

    .popup-open-button {
      font-size: 16px;
      border-bottom: 1px dotted;
      height: 25px;
      line-height: 24px;
      border-radius: 0;
      padding: 0;
      background: none;
      box-shadow: none;

      &:hover {
        background-color: #fff;
      }
    }

    .MuiDialog-container .MuiDialog-paper {
      bottom: 150px;
      margin: 16px;
      border-radius: 10px;
    }
    .MuiPaper-root .MuiDialogTitle-root {
      padding: 20px 30px 0;
    }

    .MuiPaper-root .MuiDialogContent-root {
      padding: 10px;
    }

    .popup-items-list {
      list-style-type: square;
      line-height: 24px;
      color: #8f9bb3;
    }

    .list-item {
      color: #8f9bb3;
      margin-bottom: 10px;
    }

    .item-text {
      color: var(--primary-text);
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.07px;
    }

    .MuiDialog-root .MuiTypography-h6 {
      font-size: 20px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .MuiButton-label {
      font-weight: 400;
      text-transform: initial;
    }

    .MuiPaper-root .MuiDialogActions-root {
      justify-content: space-around;
      padding: 0 10px 40px;
    }

    .MuiDialog-root .MuiDialogActions-root .MuiButton-root {
      height: 35px;
      width: 125px;
      padding: 0 8px;
      font-size: 16px;
      background-color: #066bd6;
      color: #fff;
      border-radius: 4px;
    }

    .MuiDialog-root .MuiDialogActions-root .MuiButton-root:last-child {
      width: 90px;
      color: var(--grey-text);
      background: none;
      box-shadow: none;
    }

    .closing-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  @media ${breakpoints.tablet} {
    .popup-open-button {
      background-color: #fff;
      border-bottom: 1px dotted;
    }

    .MuiPaper-root .MuiDialogActions-root {
      padding: 0 20px 20px;
    }

    .MuiDialog-container .MuiDialog-paper {
      width: 650px;
    }

    .MuiPaper-root .MuiDialogContent-root {
      padding: 10px 40px;
    }

    .MuiPaper-root .MuiDialogTitle-root {
      padding: 20px 60px 0;
    }

    .MuiDialog-root .MuiTypography-h6 {
      font-size: 24px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .MuiDialog-root .MuiDialogActions-root .MuiButton-root {
      height: 35px;
      width: 125px;
      padding: 0 8px;
      font-size: 16px;
      background-color: #066bd6;
      color: #fff;
      border-radius: 4px;
      margin-right: 30px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      left: 125px;
    }

    .MuiDialog-container .MuiDialog-paperWidthSm {
      max-width: 650px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      display: inline;
      left: 260px;
      bottom: 31px;
    }
  }
`;
