import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const CalendarContainer = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
    }

    .content {
      width: 100%;
      box-sizing: border-box;
    }
  }

  @media ${breakpoints.tablet} {
    .navigation {
      margin-left: 400px;
    }
  }

  @media ${breakpoints.laptop} {
    .navigation {
      margin-left: 465px;
    }
  }

  @media ${breakpoints.desktop} {
    .navigation {
      margin-left: 570px;
    }
  }
`;
