import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#066BD6',
    },
    secondary: {
      main: '#066BD6',
      dark: '#07A186',
      contrastText: '#FFFFFF',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },

  overrides: {
    MuiButton: {
      root: {
        height: '52px',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        fontSize: '20px',
        textTransform: 'capitalize',
        borderRadius: '4px',
        color: '#FFFFFF',
        boxShadow: '0 2px 8px 0 rgba(51,36,3,0.08)',
        backgroundColor: '#06BB9B',
        '&:hover': {
          backgroundColor: '#07A186',
        },
        '&:active': {
          backgroundColor: '#049077',
        },
        '&:disabled': {
          opacity: '0.5',
          backgroundColor: '#06BB9B',
          color: '#FFFFFF',
        },
      },
      containedPrimary: {
        height: '48px',
        padding: '0 28px',
        color: '#FFFFFF',
        boxShadow: '0 2px 8px 0 rgba(51,36,3,0.08)',
        backgroundColor: '#06BB9B',
        '&:hover': {
          backgroundColor: '#07A186',
        },
        '&:active': {
          backgroundColor: '#049077',
        },
        '&:disabled': {
          opacity: '0.5',
          color: '#FFFFFF',
        },
      },
      containedSecondary: {
        height: '48px',
        padding: '0 28px',
        color: '#FFFFFF',
        backgroundColor: '#066BD6',
        boxShadow: '0 2px 8px 0 rgba(51,36,3,0.08)',
        '&:hover': {
          backgroundColor: '#0258B3',
        },
        '&:active': {
          backgroundColor: '#004694',
        },
        '&:disabled': {
          opacity: '0.5',
          color: '#FFFFFF',
        },
      },
      outlinedPrimary: {
        height: '48px',
        padding: '0 20px',
        color: '#066BD6',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 8px 0 rgba(0,199,148,0.1)',
        border: '1px solid #066BD6',
        '&:hover': {
          backgroundColor: '#E9F3FF',
          color: '#066BD6',
          border: '1px solid #066BD6',
        },
        '&:active': {
          backgroundColor: '#D3F2EC',
          color: '#06BB9B',
          border: '1px solid #00C397',
        },
        '&:disabled': {
          backgroundColor: '#FFFFFF',
          color: '#D6D6D6',
          border: '1px solid #E4E4E4',
          opacity: '0.5',
        },
      },
      outlined: {
        height: '48px',
        padding: '0 20px',
        fontWeight: 'normal',
        color: '#5D5D5D',
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
        border: '1px solid #8F9BB3',
        '&:hover': {
          backgroundColor: '#F7F7F7',
          color: '#5D5D5D',
          border: '1px solid #8F9BB3',
        },
        '&:active': {
          backgroundColor: '#F0F0F0',
          color: '#5D5D5D',
          border: '1px solid #8F9BB3',
        },
        '&:disabled': {
          backgroundColor: '#FFFFFF',
          color: '#D6D6D6',
          border: '1px solid #E4E4E4',
          opacity: '0.5',
        },
      },
      containedSizeSmall: {
        height: '32px',
        padding: '0 12px',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#FFFFFF',
        backgroundColor: '#066BD6',
        boxShadow: '0 2px 8px 0 rgba(51,36,3,0.08)',
        '&:hover': {
          backgroundColor: '#0258B3',
        },
        '&:active': {
          backgroundColor: '#066BD6',
        },
        '&:disabled': {
          backgroundColor: '#066BD6',
          color: '#FFFFFF',
          opacity: '0.5',
        },
      },
      textSizeSmall: {
        height: '32px',
        color: '#303133',
        fontWeight: 'normal',
        fontSize: '16px',
        boxShadow: 'none',
        border: '1px solid #8F9BB3',
        backgroundColor: '#FFFFFF',
        padding: '0 12px',
        '&:hover': {
          backgroundColor: '#F7F7F7',
          color: '#5D5D5D',
          border: '1px solid #8F9BB3',
        },
        '&:active': {
          backgroundColor: '#F0F0F0',
          color: '#5D5D5D',
          border: '1px solid #8F9BB3',
        },
        '&:disabled': {
          backgroundColor: '#FFFFFF',
          color: '#5D5D5D',
          border: '1px solid #8F9BB3',
          opacity: '0.5',
        },
      },
      containedSizeLarge: {
        height: '52px',
        color: '#FFFFFF',
        fontSize: '20px',
        padding: '0 20px',
        boxShadow: '0 2px 8px 0 rgba(51,36,3,0.08)',
        backgroundColor: '#066BD6',
        '&:hover': {
          backgroundColor: '#0258B3',
        },
        '&:active': {
          backgroundColor: '#004694',
        },
        '&:disabled': {
          opacity: '0.5',
          color: '#FFFFFF',
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: '4px',
      },
      flexContainer: {
        justifyContent: 'center',
      },
      flexContainerVertical: {
        justifyContent: 'flex-start',
      },
    },
    MuiTab: {
      root: {
        justifyContent: 'inherit',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#5D5D5D',
        '&$selected': {
          color: '#303133',
        },
        textAlign: 'left',
        padding: 0,
        maxWidth: 'inherit',
      },
      labelIcon: {
        paddingTop: 0,
      },
      wrapper: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'inherit',
        textTransform: 'none',
        color: '#5D5D5D',
        fontSize: '16px',
        lineHeight: '24px',
      },
      textColorPrimary: {
        '&$selected': {
          color: 'inherit',
        },
      },
    },
    PrivateTabIndicator: {
      vertical: {
        width: '4px',
        left: '0',
        transform: 'translate(0, 50%)',
        height: '24px !important',
      },
    },
    MuiInput: {
      multiline: {
        padding: 0,
      },
      input: {
        fontFamily: 'Open Sans',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#5D5D5D',
        borderRadius: '4px',
        border: '1px solid #8F9BB3',
        padding: '12px 16px',
        '&:hover': {
          border: '1px solid #066BD6',
        },
        '&$disabled:hover': {
          border: '1px solid #8F9BB3',
        },
        '&:focus': {
          border: '1px solid #066BD6',
        },
      },
      underline: {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        width: '100%',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: '58px',
        padding: 0,
        borderBottom: '1px solid #D8D8D8',
        '&$expanded': {
          height: '58px',
          minHeight: '58px',
          borderBottom: 'none',
        },
      },
      content: {
        margin: '18px 0 16px 0 !important',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
      },
    },
    MuiAutocomplete: {
      root: {
        position: 'relative',
      },
      input: {
        boxSizing: 'border-box',
        height: '48px',
        border: '1px solid #C4C4C4',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          border: '1px solid #1973E8',
        },
        '&:focus': {
          border: '1px solid #1973E8',
        },
      },
      hasPopupIcon: {
        '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot.MuiAutocomplete-inputRoot': {
          padding: 0,
        },
        '& .MuiAutocomplete-inputRoot.MuiAutocomplete-inputRoot': {
          padding: 0,
        },
      },
      inputRoot: {
        paddingRight: 0,
        marginTop: 0,
        '&[class*="MuiInput-root"] .MuiAutocomplete-input.MuiAutocomplete-input:first-child': {
          padding: '12px 16px',
        },
        '&[class*="MuiInput-root"] .MuiAutocomplete-input.MuiAutocomplete-input': {
          padding: '12px 16px',
          color: '#303133',
        },
      },
      popupIndicator: {
        width: '22px',
        marginRight: '16px',
      },
      popper: {
        borderRadius: '0 !important',
      },
      listbox: {
        padding: 0,
      },
      option: {
        height: '50px',
        paddingLeft: '15px',
        '&:hover': {
          backgroundColor: 'rgba(6,107,214,0.1)',
          cursor: 'pointer',
        },
        '&[aria-selected="true"]': {
          backgroundColor: '#fff',
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: '0 !important',
        borderRadius: '0 !important',
        '@media (min-width: 768px)': {
          borderRadius: '10px !important',
          boxShadow: '0 0 6px 0 rgba(81,93,204,0.2)',
        },
      },
      paperScrollBody: {
        top: 0,
        // width: '550px !important',
      },
      paperWidthSm: {
        maxWidth: 'initial',
      },
    },
  },
});

export default theme;
