import { handleActions } from 'redux-actions';
import { omit, pick } from 'ramda';
import { searchActions } from '../../actions';

const { getCarsSuccess, getMoreCarsSuccess, resetFilters } = searchActions;
const initialState = { cars: [], query: { order: 'price:asc', page: 1 }, loading: true };

export default handleActions(
  {
    [getCarsSuccess](
      state,
      {
        payload: { cars },
      },
    ) {
      return { ...state, loading: false, cars };
    },
    [getMoreCarsSuccess](
      state,
      {
        payload: {
          cars: { data },
        },
      },
    ) {
      return { ...state, cars: { ...state.cars, data: [...state.cars.data, ...data] } };
    },
    [getMoreCarsSuccess](
      state,
      {
        payload: {
          cars: { data },
        },
      },
    ) {
      return { ...state, cars: { ...state.cars, data: [...state.cars.data, ...data] } };
    },
  },
  initialState,
);
