import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { checkHost } from '../../services/auth-service';
import { userActions } from '../../redux/actions';
import { Loader } from '../../components/Loader/Backdrop';
import { ConfirmEmailPopup } from '../../components/UserRegistration/Confirmation/ConfirmEmail';
import { ConfirmPhonePopup } from '../../components/UserRegistration/Confirmation/ConfirmPhone/index';
import { Transition } from '../../components/UserRegistration/AuthPopup';
import { PopupWindow } from '../../components/PopupWindow';
import { CarRegistrationForm } from './CarRegistrationForm';
import { UserDataForm } from './UserDataForm';
import { carRegisterData, popupData } from '../../assets/data';
import { CarRegisterWrapper, StyledDialod } from './styles';

const { checkUserDataCompleteness } = userActions;

export const CarRegistrationPage = () => {
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery('(max-width:768px)');
  const isHost = checkHost();

  const {
    confirmationData: { isEmailConfirmed, isPhoneConfirmed },
    loading,
  } = useSelector(({ confirmation }) => confirmation);
  useEffect(() => {
    dispatch(checkUserDataCompleteness());
  }, [dispatch]);
  const isRenderEmailDialog = !isEmailConfirmed && isEmailConfirmed !== undefined;
  const isRenderPhoneDialog =
    isEmailConfirmed && !isPhoneConfirmed && isPhoneConfirmed !== undefined;

  return (
    <CarRegisterWrapper>
      <Loader open={loading} />
      {isRenderEmailDialog && (
        <StyledDialod
          open={isRenderEmailDialog}
          scroll="body"
          TransitionComponent={Transition}
          keepMounted
          fullScreen={fullScreen}
        >
          <ConfirmEmailPopup />
        </StyledDialod>
      )}
      {isRenderPhoneDialog && (
        <StyledDialod
          open={isRenderPhoneDialog}
          scroll="body"
          TransitionComponent={Transition}
          keepMounted
          fullScreen={fullScreen}
        >
          <ConfirmPhonePopup />
        </StyledDialod>
      )}
      {isHost ? (
        <>
          <h2 className="title">Какая у вас машина?</h2>
          <div className="text">
            <span> Проверьте, подходит ли ваш автомобиль для сдачи в аренду.&nbsp;</span>
            <PopupWindow
              popupData={popupData.registration}
              popupTitle="Ваша машина подходит, если она:"
              popupLinkTitle="Требования к машинам"
            />
          </div>
          <CarRegistrationForm carRegisterData={carRegisterData} />
        </>
      ) : (
        <UserDataForm />
      )}
    </CarRegisterWrapper>
  );
};
