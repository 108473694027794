export default {
  register: {
    REGISTER_CAR: 'REGISTER_CAR',
    REGISTER_CAR_SUCCESS: 'REGISTER_CAR_SUCCESS',
    REGISTER_CAR_FAILURE: 'REGISTER_CAR_FAILURE',
  },
  edit: {
    GET_CAR_DATA: 'GET_CAR_DATA',
    GET_CAR_DATA_SUCCESS: 'GET_CAR_DATA_SUCCESS',
    GET_CAR_DATA_FAILURE: 'GET_CAR_DATA_FAILURE',
    EDIT_CAR_DATA: 'EDIT_CAR_DATA',
    POST_EDIT_DATA: 'POST_EDIT_DATA',
    POST_EDIT_DATA_SUCCESS: 'POST_EDIT_DATA_SUCCESS',
    POST_EDIT_DATA_FAILURE: 'POST_EDIT_DATA_FAILURE',
    UPLOAD_CAR_FILES: 'UPLOAD_CAR_FILES',
    UPLOAD_CAR_FILES_SUCCESS: 'UPLOAD_CAR_FILES_SUCCESS',
    UPLOAD_CAR_FILES_FAILURE: 'UPLOAD_CAR_FILES_FAILURE',
  },
  manage: {
    DELETE_CAR: 'DELETE_CAR',
    DELETE_CAR_SUCCESS: 'DELETE_CAR_SUCCESS',
    DELETE_CAR_FAILURE: 'DELETE_CAR_FAILURE',
    START_VERIFICATION: 'START_VERIFICATION',
    START_VERIFICATION_SUCCESS: 'START_VERIFICATION_SUCCESS',
    START_VERIFICATION_FAILURE: 'START_VERIFICATION_FAILURE',
    PUBLISH_CAR: 'PUBLISH_CAR',
    PUBLISH_CAR_SUCCESS: 'PUBLISH_CAR_SUCCESS',
    UNPUBLISH_CAR: 'UNPUBLISH_CAR',
    UNPUBLISH_CAR_SUCCESS: 'UNPUBLISH_CAR_SUCCESS',
  },
  card: {
    GET_CARD_DATA: 'GET_CARD_DATA',
    GET_CARD_DATA_SUCCESS: 'GET_CARD_DATA_SUCCESS',
    GET_CARD_DATA_FAILURE: 'GET_CARD_DATA_FAILURE,',
  },
  list: {
    GET_CAR_LIST: 'GET_CAR_LIST',
    GET_CAR_LIST_SUCCESS: 'GET_CAR_LIST_SUCCESS',
    GET_CAR_LIST_FAILURE: 'GET_CAR_LIST_FAILURE,',
  },
};
