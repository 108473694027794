import { styled } from 'linaria/react';
import AppBar from '@material-ui/core/AppBar';
import { breakpoints } from '../../../constants/breakpoints';

export const Header = styled(AppBar)`
  @media ${breakpoints.mobile} {
    && {
      display: flex;
      align-items: center;
      background: #fff;
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  @media ${breakpoints.mobile} {
    && {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 70px;
      width: 100%;
      max-width: 1440px;
      box-sizing: border-box;
      background: #fff;
      padding: 0 3%;
    }

    .logo {
      width: 40px;
      height: 24px;
      margin: 0;
    }

    .toolbar {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      min-height: 100%;
      padding: 0;
    }

    .icon-wrap {
      width: 35px;
      height: 35px;
      z-index: 1201;
      color: #000;
      padding: 0;
    }

    .menu-icon {
      width: 35px;
      height: 35px;
      color: #5d5d5d;
    }

    .link {
      display: flex;
    }
  }
`;
