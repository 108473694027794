import { styled } from 'linaria/react';
import Divider from '@material-ui/core/Divider';
import Slider from '@material-ui/core/Slider';
import { CustomPopover } from '../../../Popover';
import { SearchControlButton } from '../../../Button/SearchControlButton';
import { breakpoints } from '../../../../constants/breakpoints';

export const FilterForm = styled.form`
  @media ${breakpoints.tablet} {
    & {
      overflow: auto;
      overflow-x: hidden;
    }

    .form-group.-body {
      height: 300px;
    }

    .form-group.-devices {
      display: flex;
      flex-direction: row;
    }

    .device {
      width: calc((100% - 15px) / 3);

      &:nth-child(2n) {
        margin: 0 5px 0 1px;
      }

      &:nth-child(3n) {
        margin: 0 -11px 0 11px;
      }

      &:nth-child(4n) {
        margin: 0 16px 0 -11px;
      }
    }
  }

  @media ${breakpoints.desktop} {
    .device {
      width: 25%;
      min-width: auto;
      margin-right: 11px;
    }
  }
`;

export const Container = styled.div`
  @media ${breakpoints.tablet} {
    & {
      width: 100%;
      display: flex;
      flex-wrap: ${props => (props.nowrap ? 'nowrap' : 'wrap')};
    }

    .box {
      width: 100%;
      margin-right: 30px;
      margin-bottom: 15px;

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      > .-distance {
        margin-bottom: 8px;
      }
    }

    .box .filters-label {
      width: calc((100% - 15px) / 3);

      &:nth-child(2) {
        margin: 0 5px 0 1px;
      }

      &:nth-child(3) {
        margin: 0 -11px 0 11px;
      }
    }

    .box.-using {
      margin-right: 0;
    }

    .box.-seats {
      width: calc((100% - 70px) / 3);
      margin-bottom: 15px;
    }

    .box.-selects {
      width: calc((100% - 70px) / 3);
      height: 120px;
      margin-right: 35px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .box.-engine {
      margin-right: 0;
      width: 100%;

      &:first-child {
        margin-top: 25px;
      }
    }

    .box.-seats {
      width: 100%;
    }

    .radio {
      width: calc((100% - 15px) / 3);
    }

    .form-group {
      flex-direction: row;
    }

    .select {
      margin-top: 15px;
      width: 100%;
      max-width: 320px;
    }

    .title {
      color: var(--grey-text);
      font-size: 14px;
      line-height: 20px;
      margin: ${props => (props.inline ? '0 0 15px 0' : '25px 0 15px 0')};
      &.-first {
        margin-top: 0;
      }
    }
  }

  @media ${breakpoints.desktop} {
    & {
      flex-wrap: nowrap;
    }

    .box {
      margin-bottom: 0;
    }

    .box.-engine {
      width: calc((100% - 70px) / 3);
      margin-right: 35px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .box.-engine:first-child {
      margin-top: 0;
    }

    .title {
      margin: 25px 0 5px 0;
    }

    .radio {
      width: 100%;
    }

    .box.-selects {
      width: inherit;
    }

    .box .filters-label {
      width: 100%;

      &:nth-child(2) {
        margin: 0 16px 0 -11px;
      }

      &:nth-child(3) {
        margin: 0 16px 0 -11px;
      }
    }

    .select {
      width: 100%;
      max-width: none;
    }

    .form-group {
      flex-direction: column;
    }
  }
`;

export const Collection = styled.div`
  @media ${breakpoints.tablet} {
    & {
      width: 100%;
      display: flex;
      flex-direction: column;

      &:last-child {
        padding-bottom: 30px;
      }
    }

    .title {
      color: var(--grey-text);
      font-family: 'Open Sans', 'sans-serif';
      font-size: 14px;
      line-height: 20px;
    }

    .filters-label {
      width: calc((100% - 15px) / 3);

      &:nth-child(3n - 1) {
        margin: 0 5px 0 1px;
      }

      &:nth-child(3n) {
        margin: 0 -11px 0 11px;
      }
    }

    .title.-device {
      margin: 25px 0 10px;
    }
  }

  @media ${breakpoints.desktop} {
  }
`;

export const StyledSlider = styled(Slider)`
  @media ${breakpoints.tablet} {
    .MuiSlider-track {
      height: 4px;
      border-radius: 6px;
    }

    .MuiSlider-rail {
      height: 4px;
      border-radius: 6px;
      background-color: #c4c4c4;
    }

    .MuiSlider-thumb {
      box-sizing: border-box;
      height: 20px;
      width: 20px;
      margin-top: -7px;
      border: 2px solid #006ade;
      background-color: #ffffff;
    }

    .MuiSlider-thumb.Mui-disabled {
      margin-top: -7px;
      margin-left: -6px;
      width: 20px;
      height: 20px;
    }

    .MuiSlider-valueLabel {
      left: -30%;
      top: 28px;
      font-size: 16px;
      line-height: 24px;
      color: #303133;

      & * {
        background: transparent;
        color: #000;
      }
    }

    .MuiSlider-valueLabel span {
      top: 30px;
      left: -2px;
      width: 0;
      height: 0;
      transform: none;
      align-items: center;
      border-radius: 0;
      color: var(--primary-text);
      letter-spacing: -0.07px;
    }
  }

  @media ${breakpoints.desktop} {
    .MuiFormControlLabel-root {
      margin-right: 5px;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  @media ${breakpoints.tablet} {
    && {
      height: 1px;
      margin: 20px 0 0;
      background-color: #d8d8d8;
    }
  }
`;

export const Buttons = styled.div`
  @media ${breakpoints.tablet} {
    & {
      position: sticky;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      bottom: -15px;
      z-index: 3;
      height: 35px;
      padding: 15px 0;
      background-color: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: calc(100% + 30px);
      margin: 0 30px 0 -30px;
      padding-right: 60px;
    }
  }
`;

export const SearchControlButtons = styled(SearchControlButton)`
  @media ${breakpoints.tablet} {
    & {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    .btn {
      height: 32px;
      line-height: 24px;
      font-weight: 600;

      &.-submit {
        width: 130px;
        font-size: 16px;
        margin: 0 0 0 30px;
      }

      &.-cancel {
        border: none;
        padding: 0;
        color: var(--grey-text);
      }

      &.-cancel:hover {
        background: transparent;
      }
    }

    .closing-icon {
      position: relative;
      left: 0;
      top: 1px;
      width: 15px;
      height: 15px;
      padding: 0 5px 0 0;
      color: #8f9bb3;
    }
  }
`;

export const StyledPopover = styled(CustomPopover)`
  @media ${breakpoints.tablet} {
    & {
      position: relative;
      height: 100%;
      margin: 0 15px 0 0;
    }

    .divider {
      height: 1px;
      margin: 15px 0 20px;
      background-color: #d8d8d8;
    }

    .MuiPopover-paper {
      width: 100%;
      box-sizing: border-box;
      max-width: 1090px;
      max-height: calc(100vh - 150px);
      top: 140px !important;
      left: 0 !important;
      padding: 25px 30px 15px 30px;
      border-radius: 8px;
      box-sizing: border-box;
      box-shadow: 0 4px 16px 0 rgba(72, 107, 125, 0.15);
    }

    .icon {
      cursor: pointer;
      position: absolute;
      bottom: 14px;
      right: -2px;
      color: #006ade;
    }

    .MuiSlider-root {
      padding: 20px 0 20px;
      margin: 0 0 15px 10px;
      width: 190px;
    }
  }

  @media ${breakpoints.desktop} {
    .MuiSlider-root {
      padding: 17px 0 20px;
      margin: 0 0 15px 0;
    }

    .MuiPopover-paper {
      left: 0 !important;
      top: 62px !important;
    }
  }
`;
