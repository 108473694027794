import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { carActions } from '../actions';
import { GET_CARD_URL } from '../../constants/endpoints';

const { getCardData, getCardDataSuccess, getCardDataFailure } = carActions;

function* getCardHandler(action) {
  const { payload } = action;
  try {
    const { data } = yield call(axios.get, `${GET_CARD_URL}/${payload}`);
    yield put(getCardDataSuccess(data));
  } catch (error) {
    yield put(getCardDataFailure(error));
  }
}

function* watchCardEvent() {
  yield takeEvery(getCardData, getCardHandler);
}

export default watchCardEvent;
