import React from 'react';
import Input from '@material-ui/core/Input';
import parseNumberValue from '../../utils/parseNumberValue';
import { StyledSlider, StyledInputLabel } from './styles';
import styles from './SliderInput.module.css';

export const SliderInput = ({
  min,
  max,
  minFieldName,
  maxFieldName,
  collectData,
  labels: { minLabel, maxLabel },
  slider: { minValue, maxValue, defaultValue },
}) => {
  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputContainer}>
        <div className={styles.tooltip}>
          <Input
            id="min"
            type="number"
            className={styles.inputWithTooltip}
            value={min === null ? '' : min}
            onChange={({ target: { value } }) => collectData(minFieldName, parseNumberValue(value))}
            startAdornment={<StyledInputLabel position="start">{`\u20bd`}</StyledInputLabel>}
          />
          <span className={styles.text}>{minLabel}</span>
        </div>
        <div className={styles.separator} />
        <div className={styles.tooltip}>
          <Input
            id="max"
            type="number"
            className={styles.inputWithTooltip}
            value={max === null ? '' : max}
            onChange={({ target: { value } }) => collectData(maxFieldName, parseNumberValue(value))}
            startAdornment={<StyledInputLabel position="start">{`\u20bd`}</StyledInputLabel>}
          />
          <span className={styles.text}>{maxLabel}</span>
        </div>
      </div>
      {/* <StyledSlider
        onChangeCommitted={(event, newValue) => {
          collectData(minFieldName, newValue[0]);
          collectData(maxFieldName, newValue[1]);
        }}
        className={styles.range}
        aria-labelledby="range-slider"
        min={minValue}
        max={maxValue}
        defaultValue={defaultValue}
        valueLabelDisplay="on"
      /> */}
    </div>
  );
};
