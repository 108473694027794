import { put, takeEvery } from 'redux-saga/effects';
import { userActions, errorActions } from '../../actions';
import { USER_DATA_UPDATE_URL } from '../../../constants/endpoints';
import { defaultError, userUpdateErrors } from '../../../constants/errors';
import { httpVerbs } from '../../../constants';
import { getUserInfo, updateUserInfo } from '../../../services/auth-service';
import { makeCall } from '../../../services/api-service';

const { PATCH } = httpVerbs;
const { updateUserData, updateUserDataSuccess, updateUserDataError } = userActions;
const { setError } = errorActions;

function* userDataUpdateSaga(action) {
  const {
    payload: { history, data: userData },
  } = action;

  const { id } = getUserInfo();
  try {
    const { data } = yield makeCall(PATCH, `${USER_DATA_UPDATE_URL}/${id}`, userData);
    yield put(updateUserDataSuccess(data));
    updateUserInfo(data);
    // TODO: replace with constant URL
    history.push('/car/register');
  } catch (error) {
    const message = error.response ? userUpdateErrors[error.response.status] : defaultError;
    yield put(setError(message));
    yield put(updateUserDataError({ error: message }));
  }
}

function* userUpdateSaga() {
  yield takeEvery(updateUserData, userDataUpdateSaga);
}

export { userUpdateSaga };
