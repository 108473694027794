export const parseCookie = cookies => {
  if (!cookies) {
    return {};
  }
  return Object.fromEntries(
    cookies.split(/; */).map(c => {
      const [key, ...v] = c.split('=');
      return [key, decodeURIComponent(v.join('='))];
    }),
  );
};
