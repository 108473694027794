import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'ramda';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Loader } from '../../../components/Loader/Backdrop';
import { PassportForm } from '../../../components/Scoring/PassportForm';
import { PersonalDataForm } from '../../../components/Scoring/PersonalDataForm';
import { CustomCheckbox } from '../../../components/Checkbox';
import { userDataValidationSchema } from '../../../utils/validate';
import { resetUserUpdateErrors, updateUserData } from '../../../redux/actions/user';
import { UserDataFormWrapper } from './styles';
import { Error as CustomError } from '../../../styledComponents';

const initialValues = {
  passport: {
    serialNumber: '',
    registrationAddress: '',
    dateOfIssue: '',
  },
  userData: {
    firstName: '',
    lastName: '',
    midName: '',
    birthDate: '',
  },
};

export const UserDataForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, loading } = useSelector(({ userUpdate }) => userUpdate);

  return (
    <UserDataFormWrapper>
      <Loader open={loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={userDataValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={({ passport, userData }) =>
          dispatch(
            updateUserData({
              history,
              data: {
                userData,
                passport,
              },
            }),
          )
        }
      >
        {({ errors, setFieldValue, setFieldError, dirty }) => (
          <Form>
            <h2 className="title">Ваши данные</h2>
            <p className="text -data">
              Заполните форму вашими данными. Все данные будут сохранены в ваш личный аккаунт.
            </p>
            {/* TODO replace with FAQ url */}
            <p className="text -note">
              Если собственником машины является юридическое лицо, введите данные человека, который
              будет следить за сдачей автомобиля в аренду, эти данные также будут использоваться в
              договоре аренды{' '}
              <Link
                target="_blank"
                href="https://help.getarent.ru/knowledge-bases/2/articles/27-registratsiya-mashinyi"
              >
                узнать подробнее
              </Link>
            </p>
            <PersonalDataForm />
            <PassportForm />
            <div className="licence-wrapper">
              <FormControlLabel
                className="licence"
                control={
                  <CustomCheckbox
                    defaultChecked
                    name="licence"
                    onChange={({ target: { checked } }) => {
                      if (error) {
                        dispatch(resetUserUpdateErrors());
                      }
                      if (errors.licence) {
                        setFieldError('licence');
                      }
                      setFieldValue('licence', checked);
                    }}
                    className="checkbox"
                  />
                }
                label={
                  <div>
                    <span>Я прочитал и согласен с </span>
                    <Link target="_blank" href="http://www.google.com">
                      лицензионным соглашением
                    </Link>
                  </div>
                }
              />
              {/* TODO replace with FAQ url */}
            </div>
            {errors.licence ? <CustomError>{errors.licence}</CustomError> : null}
            {error ? <CustomError>{error}</CustomError> : null}
            <Button
              type="submit"
              variant="contained"
              size="large"
              className="btn"
              disabled={
                !(
                  dirty &&
                  Object.keys(filter(item => Object.keys(item).length !== 0, errors)).length === 0
                ) || Boolean(error)
              }
            >
              Перейти к регистрации
            </Button>
          </Form>
        )}
      </Formik>
    </UserDataFormWrapper>
  );
};
