const SERVICE_BASE_URL = process.env.REACT_APP_SERVICE_BASE_URL;
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
const UPLOADER_BASE_URL = process.env.REACT_APP_UPLOADER_BASE_URL;

const errorMessages = [];
if (!SERVICE_BASE_URL) {
  errorMessages.push('Env var REACT_APP_SERVICE_BASE_URL is not defined');
}
if (!AUTH_BASE_URL) {
  errorMessages.push('Env var REACT_APP_AUTH_BASE_URL is not defined');
}
if (!UPLOADER_BASE_URL) {
  errorMessages.push('Env var REACT_APP_UPLOADER_BASE_URL is not defined');
}

if (errorMessages.length > 0) {
  throw new Error(`Improperly configured: ${errorMessages.join(', ')}`);
}

module.exports = {
  CAR_RENT_SERVICE_URL: SERVICE_BASE_URL,
  AUTH_SERVICE_URL: AUTH_BASE_URL,
  UPLOADER_URL: UPLOADER_BASE_URL,
  // user urls
  USER_DATA_UPDATE_URL: `${SERVICE_BASE_URL}/user`,
  USER_DATA_COMPLETENESS_URL: `${SERVICE_BASE_URL}/user/completeness`,
  SUBMIT_USER_EMAIL_URL: `${SERVICE_BASE_URL}/user/email`,
  SUBMIT_USER_PHONE_URL: `${SERVICE_BASE_URL}/user/phone`,
  CONFIRM_USER_EMAIL_URL: `${SERVICE_BASE_URL}/user/confirm/email`,
  CONFIRM_USER_PHONE_URL: `${SERVICE_BASE_URL}/user/confirm/phone`,
  SUBMIT_RECOVERY_EMAIL_URL: `${SERVICE_BASE_URL}/user/recover-password`,
  CONFIRM_RECOVERY_EMAIL_URL: `${SERVICE_BASE_URL}/user/recovery-email`,
  // auth urls
  SIGNIN_URL: `${AUTH_BASE_URL}/login`,
  SIGNOUT_URL: `${AUTH_BASE_URL}/logout`,
  SIGNUP_URL: `${AUTH_BASE_URL}/signup`,
  REFRESH_TOKENS_URL: `${AUTH_BASE_URL}/refresh`,
  RESET_PASSWORD_URL: `${AUTH_BASE_URL}/reset-password`,
  SIGNIN_GOOGLE_URL: `${AUTH_BASE_URL}/google`,
  SIGNIN_FACEBOOK_URL: `${AUTH_BASE_URL}/facebook`,
  SIGNIN_YANDEX_URL: `${AUTH_BASE_URL}/yandex`,
  SIGNIN_VK_URL: `${AUTH_BASE_URL}/vk`,
  // car urls
  CAR_URL: `${SERVICE_BASE_URL}/car`,
  GET_CARD_URL: `${SERVICE_BASE_URL}/car/card`,
  GET_CAR_LIST: `${SERVICE_BASE_URL}/car/list`,
  CAR_VERIFICATION_URL: `${SERVICE_BASE_URL}/car/verify`,
  PUBLISH_CAR_URL: `${SERVICE_BASE_URL}/car/publish`,
  UNPUBLISH_CAR_URL: `${SERVICE_BASE_URL}/car/unpublish`,
  SEARCH_CAR_URL: `${SERVICE_BASE_URL}/car-search`,

  // uploader urls
  UPLOADER_LINK_URL: `${UPLOADER_BASE_URL}/get-upload-url`,
  // depricated urls
  POST_SCORING_DATA_URL: `${SERVICE_BASE_URL}/user`,
  GET_SCORING_RESULTS_URLS: `${AUTH_BASE_URL}/user/scoring-results`,
  USER_SCORING_RESULTS: `${AUTH_BASE_URL}/user/scoring-results`,
  USER_SCORING_REFRESH: `${AUTH_BASE_URL}/user/scoring-refresh`,
};
