import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Loader } from '../../../Loader/Backdrop';
import { PasswordInput } from '../../../PasswordInput';
import { SocialAuth } from '../../SocialAuth';
import { resetAuthError } from '../../../../redux/actions/auth';

import { Error } from '../../../../styledComponents';
import { CustomInputLabel, Separator, Title } from '../../featureStyles';
import { Form } from './styles';

export const RegistrationForm = ({ onLoginClick }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const {
    values: { email, password, isAgree },
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldError,
  } = useFormikContext();
  const { error, loading, isLoggedIn } = useSelector(({ auth }) => auth);
  const { popupOption } = useSelector(({ authPopup }) => authPopup);
  useEffect(() => {
    if (isLoggedIn) {
      resetForm();
    }
  }, [dispatch, resetForm, isLoggedIn, popupOption]);

  useEffect(() => {
    if (!popupOption) {
      resetForm();
      dispatch(resetAuthError());
    }
  }, [dispatch, resetForm, popupOption]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Loader open={loading} />
      {/* <Title>Быстрая регистрация через:</Title>
      <SocialAuth />
      <Separator>или</Separator> */}
      <Title>Регистрация:</Title>
      <CustomInputLabel htmlFor="email">Ваш email</CustomInputLabel>
      <Field
        id="email-register"
        className={(errors.email && touched.email) || error ? 'input error' : 'input'}
        placeholder="example@mail.ru"
        value={email}
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetAuthError());
          }
          if (errors.email) {
            setFieldError('email');
          }
          setFieldValue('email', value);
        }}
        onBlur={handleBlur}
        component={Input}
        type="text"
      />
      {errors.email && touched.email ? <Error>{errors.email}</Error> : null}
      <CustomInputLabel htmlFor="password">Задайте пароль</CustomInputLabel>
      <Field
        id="password-register"
        className={(errors.password && touched.password) || error ? 'input error' : 'input'}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetAuthError());
          }
          if (errors.password) {
            setFieldError('password');
          }
          setFieldValue('password', value);
        }}
        onBlur={handleBlur}
        handleClickShowPassword={handleClickShowPassword}
        showPassword={showPassword}
        component={PasswordInput}
      />
      {errors.password && touched.password ? <Error>{errors.password}</Error> : null}
      {error ? <Error>{error}</Error> : null}
      <FormControlLabel
        control={
          <Checkbox
            padding="0 8px"
            id="licence"
            name="licence"
            checked={isAgree}
            className="checkbox"
            onChange={({ target: { checked } }) => {
              if (error) {
                dispatch(resetAuthError());
              }
              if (errors.isAgree) {
                setFieldError('isAgree');
              }
              setFieldValue('isAgree', checked);
            }}
          />
        }
        className="checkbox-wrap"
        label={
          <span className="licence-label">
            <span>Я принимаю</span>{' '}
            <a href="#" className="inline-link">
              Условия пользовательского соглашения
            </a>
          </span>
        }
        key="licence"
      />
      {errors.isAgree && touched.isAgree ? <Error>{errors.isAgree}</Error> : null}
      <Button className="btn" variant="contained" size="large" type="submit">
        Зарегистрироваться
      </Button>
      <div className="login">
        <span className="small-text">Уже есть аккаунт?</span>
        <span className="link" onClick={e => onLoginClick()}>
          {/* eslint-disable-line*/}
          Войти
        </span>
      </div>
    </Form>
  );
};
