import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext, Field } from 'formik';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { resetConfirmationError } from '../../../redux/actions/user';
import { Loader } from '../../Loader/Backdrop';

import { Error } from '../../../styledComponents';
import { CustomInputLabel, Title } from '../featureStyles';
import { EmailFormWrapper } from './ConfirmEmail/styles';

export const EmailForm = () => {
  const dispatch = useDispatch();
  const { error, loading, isSubmit } = useSelector(({ confirmation }) => confirmation);
  const {
    values: { email },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
  } = useFormikContext();

  return (
    <EmailFormWrapper onSubmit={handleSubmit} className="form">
      <Loader open={loading} />
      <Title>Укажите ваш email</Title>
      <p className="text">На указанный вами email мы вышлем 4-значный код подтверждения</p>
      <CustomInputLabel htmlFor="email">Введите email</CustomInputLabel>
      <Field
        id="email"
        className={(errors.email && touched.email) || error ? 'input error' : 'input'}
        placeholder="example@mail.ru"
        value={email}
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetConfirmationError());
          }
          if (errors.email) {
            setFieldError('email');
          }
          setFieldValue('email', value);
        }}
        onBlur={handleBlur}
        component={Input}
        type="text"
      />
      {errors.email && touched.email ? <Error>{errors.email}</Error> : null}
      {error && !isSubmit ? <Error>{error}</Error> : null}
      {email && !isSubmit && (
        <Button className="btn" variant="contained" size="large" type="submit">
          Выслать код
        </Button>
      )}
      {isSubmit && (
        <Button className="text-btn" variant="contained" size="large" type="submit">
          Отправить код повторно
        </Button>
      )}
    </EmailFormWrapper>
  );
};
