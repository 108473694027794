import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { Popup, StyledDialog } from './styles';

export const PopupWindow = ({ popupData, popupTitle, popupLinkTitle, className }) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const theme = useTheme();

  const handleClickOpen = scrollType => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Popup className={className}>
      <Button onClick={handleClickOpen('paper')} className="popup-open-button" color="primary">
        {popupLinkTitle}
      </Button>
      <StyledDialog
        fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <CloseIcon className="closing-icon" onClick={handleClose} />
        <DialogTitle id="scroll-dialog-title" className="popup-title">
          {popupTitle}
        </DialogTitle>
        <DialogContent>
          {
            <ul className="popup-items-list">
              {popupData.map(el => (
                <li key={el} className="list-item">
                  <span className="item-text">{el}</span>
                </li>
              ))}
            </ul>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            size="small"
            variant="contained"
            className="next-button"
          >
            Продолжить
          </Button>
        </DialogActions>
      </StyledDialog>
    </Popup>
  );
};
