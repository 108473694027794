import { put, takeEvery } from 'redux-saga/effects';
import { carActions, errorActions } from '../actions';
import { CAR_URL } from '../../constants/endpoints';
import { httpVerbs } from '../../constants';
import { registerCarErrors } from '../../constants/errors';
import { makeCall } from '../../services/api-service';

const { POST } = httpVerbs;
const { registerCar, registerCarSuccess, registerCarFailure } = carActions;

const { setError } = errorActions;

function* registerCarSaga(action) {
  const {
    payload: { car, history },
  } = action;
  try {
    const { data } = yield makeCall(POST, CAR_URL, car);
    yield put(registerCarSuccess(data));
    history.push(`/car/edit/${data.uuid}`);
  } catch (error) {
    const message = registerCarErrors[error.response.status];
    yield put(setError(message));
    yield put(registerCarFailure(error));
  }
}

function* watchCarRegisterData() {
  yield takeEvery(registerCar, registerCarSaga);
}

export default watchCarRegisterData;
