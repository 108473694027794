import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { parse, isAfter } from 'date-fns';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import { CheckoutCard } from '../../components/Card/Check';
import { Carousel } from '../../components/Carousel';
import { Loader } from '../../components/Loader';
import { Reviews } from '../../components/Card/Reviews';
import ShowBox from '../../containers/ShowBox';
import { ReactComponent as SeatIcon } from '../../assets/icons/seat.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as MessageCircleIcon } from '../../assets/icons/messageCircle.svg';
import complectationSprite from '../../assets/icons/complectationSprite.svg';
import transmissionSprite from '../../assets/icons/transmissionSprite.svg';
import bodyTypeSprite from '../../assets/icons/bodyTypeSprite.svg';
import engineTypeSprite from '../../assets/icons/engineTypeSprite.svg';
import mapImg from '../../assets/pictures/mapKrasnodar.png';
import { defaultStartRent, defaultEndRent, getRentDuration } from '../../utils/date';
import { useQuery, setQuery } from '../../services/query-service';
import { carActions } from '../../redux/actions';
import calculateMileageLimit from './mileageCalculate';
import PageMap from '../../components/Map/PageMap';

import {
  carRentLocation,
  carBodyType,
  carDurations,
  carTransmissionType,
  carEngineType,
  carIncludedFeatures as carFeatures,
  carPredefinedAdditionalFeatures,
} from '../../assets/data';

import {
  Car,
  Container,
  Title,
  Subtitle,
  Box,
  Rate,
  SocialParametrs,
  ReviewsCount,
  Text,
  Feature,
  Map,
  Host,
  ListItem,
} from './styles';

const FORMAT = "yyyy-MM-dd'T'HH:mm";

const UNLIMITED = 'unlimited';

const carCardData = {
  car: {
    homeLocation: {
      lon: 37.62,
      lat: 55.75,
    },
    uuid: 'aa5b81c9-8110-487b-b398-0d4c28219d6a',
    brand: 'Cadillac',
    model: 'Catera',
    productionYear: 2018,
    status: 'PUBLISHED',
    scoringStatus: 'FAILED',
    moderationStatus: 'SUCCESS',
    guestRequirements: {
      minAge: 18,
      yearsOfExperience: 0,
    },
    predefinedAdditionalFeatures: [
      {
        dailyPrice: 200,
        wholeRentPrice: 1000,
        name: 'navigator',
      },
      {
        dailyPrice: 300,
        wholeRentPrice: 1000,
        name: 'child_seat',
      },
    ],
    photos: [
      'https://getarent-car-bucket-develop.s3-eu-west-1.amazonaws.com/cars/aa5b81c9-8110-487b-b398-0d4c28219d6a/150dd902-ba04-4e56-a348-97e8f2a17265.jpeg',
      'https://getarent-car-bucket-develop.s3-eu-west-1.amazonaws.com/cars/aa5b81c9-8110-487b-b398-0d4c28219d6a/92fef5f4-1d3b-48a8-9b4c-b81e9c3043a9.jpg',
      'https://getarent-car-bucket-develop.s3-eu-west-1.amazonaws.com/cars/aa5b81c9-8110-487b-b398-0d4c28219d6a/a41d4ca3-fd59-4f23-af4a-dd8450ee4673.jpg',
      'https://getarent-car-bucket-develop.s3-eu-west-1.amazonaws.com/cars/aa5b81c9-8110-487b-b398-0d4c28219d6a/b0558b14-8bcc-4d65-830a-8eddfe605a18.jpg',
    ],
    description: 'some description',
    includedFeatures: ['heated_seats', 'usb', 'heated_steering_wheel', 'heated_windshield'],
    engineDisplacement: '2.4',
    rentPricePerDay: 1500,
    weekDiscount: 10,
    monthDiscount: 15,
    unavailabilityDates: {
      blocked: [
        {
          startDate: '2020-10-08T00:00:00.000Z',
          endDate: '2020-10-24T00:00:00.000Z',
        },
      ],
      booked: [
        {
          startDate: '2020-11-08T00:00:00.000Z',
          endDate: '2020-11-24T00:00:00.000Z',
        },
      ],
      rent: [
        {
          startDate: '2020-10-02T00:00:00.000Z',
          endDate: '2020-10-04T00:00:00.000Z',
        },
      ],
    },
    rentRecomendations: 'some recomendation',
    guestInstructions: 'some instractions',
    minRentDays: '1 day',
    maxRentDays: 'infinity',
    bodyType: 'hatchback',
    transmissionLayout: 'four_wheel',
    transmissionType: 'automatic',
    seatsQuantity: 5,
    engineType: 'diesel',
    immediateBooking: false,
    dailyMilageLimit: 300,
    weeklyMilageLimit: 1000,
    monthlyMilageLimit: 5000,
    additionalKmPrice: 300,
    afterRentWashingPrice: 500,
    fuelLiterCompensationPrice: 100,
    unlimitedMilagePrice: null,
    greetingMessage: 'Hello this is a best car in the world',
    rentLocations: 'city',
    location: {
      address: 'Куйбышева 57',
    },
    reviewsQuantity: 50,
    rating: 4.3,
    reviews: [
      {
        author: 'Антон',
        date: '21.09.2018',
        rating: 4,
        text:
          'Каждая поездка обеспечит полный бак топлива в начале и, пожалуйста, вернитесь с тем же количеством. Если у вас нет времени, чтобы заправиться.',
      },
      {
        author: 'Игорь',
        date: '22.09.2019',
        rating: 5,
        text:
          'Каждая поездка обеспечит полный бак топлива в начале и, пожалуйста, вернитесь с тем же количеством. Если у вас нет времени, чтобы заправиться.',
      },
    ],
    rentsQuantity: 15,
  },
  user: {
    uuid: 'aa5b81c9-8110-487b-b398-0d4c28219d6',
    firstName: 'Иванов',
    lastName: 'Иван',
    reviewsQuantity: 70,
    rentsQuantity: 150,
  },
};

const Card = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentCar = useSelector(({ cardData: { data } }) => data);
  const data = { ...carCardData, car: { ...carCardData.car, ...currentCar } };
  const loading = useSelector(({ cardData: { loading } }) => loading);
  const { id } = useParams();
  const dateStart = query.get('dateStart');
  const dateEnd = query.get('dateEnd');
  const formatedDateStart = parse(dateStart, FORMAT, new Date());
  const formatedDateEnd = parse(dateEnd, FORMAT, new Date());
  const rentDuration = getRentDuration(formatedDateEnd, formatedDateStart);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(carActions.getCardData(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!(dateStart && dateEnd && isAfter(formatedDateStart, new Date()))) {
      const defaultStartDate = defaultStartRent();
      const defaultEndDate = defaultEndRent(defaultStartDate);
      setQuery('dateStart', defaultStartDate, query, history);
      setQuery('dateEnd', defaultEndDate, query, history);
    }
  }, [dateStart, dateEnd]);

  if (loading) {
    return <Loader />;
  }

  const {
    car: {
      homeLocation,
      additionalKmPrice,
      brand,
      model,
      productionYear,
      bodyType,
      seatsQuantity,
      transmissionType,
      engineType,
      rentLocations,
      description,
      dailyMilageLimit,
      weeklyMilageLimit,
      monthlyMilageLimit,
      guestRequirements,
      includedFeatures,
      afterRentWashingPrice,
      fuelLiterCompensationPrice,
      unlimitedMilagePrice,
      rentRecomendations,
      predefinedAdditionalFeatures,
      rentPricePerDay,
      photos,
      maxRentDays,
      minRentDays,
      unavailabilityDates,
      weekDiscount,
      monthDiscount,
      location: { address },
      reviews,
      reviewsQuantity: carReviewsQuantity,
      rating: carRating,
      rentsQuantity: carRentsQuantity,
    },
    user: {
      uuid: userUuid,
      firstName,
      lastName,
      reviewsQuantity: userReviewsQuantity,
      rentsQuantity: userRentsQuantity,
    },
  } = data;

  const location = [homeLocation.lon, homeLocation.lat];

  const mileageLimit =
    dailyMilageLimit === null && weeklyMilageLimit === null && monthlyMilageLimit === null
      ? UNLIMITED
      : calculateMileageLimit(
          rentDuration,
          dailyMilageLimit,
          weeklyMilageLimit,
          monthlyMilageLimit,
        );

  const renderDescriptionComponent = () => <Text>{description}</Text>;
  const renderComplectationComponent = () => (
    <div className="features">
      {includedFeatures.map(feature => (
        <Feature key={`${feature}-wrapper`}>
          <svg className="icon">
            <use href={`${complectationSprite}#${feature}`} />
          </svg>
          <span className="text">{carFeatures[feature]}</span>
        </Feature>
      ))}
    </div>
  );
  const renderInstructionComponent = () => <Text>{rentRecomendations}</Text>;

  const renderServiceComponent = () => (
    <div className="features">
      {fuelLiterCompensationPrice && (
        <ListItem>
          <span className="name -device">
            Возврат с пустым баком{' '}
            {fuelLiterCompensationPrice !== '0' ? (
              <>
                <b>
                  {fuelLiterCompensationPrice}
                  <span className="currency">{' \u20BD'}</span>
                  /литр
                </b>
              </>
            ) : (
              <b>Бесплатно</b>
            )}
          </span>
        </ListItem>
      )}
      {afterRentWashingPrice && (
        <ListItem>
          <span className="name -service">
            Мойка после аренды{' '}
            {afterRentWashingPrice !== '0' ? (
              <b>
                {afterRentWashingPrice}
                <span className="currency">{' \u20BD'}</span>
              </b>
            ) : (
              <b>Бесплатно</b>
            )}
          </span>
        </ListItem>
      )}
      {unlimitedMilagePrice && (
        <ListItem>
          <span className="name -service">
            Неограниченный пробег{' '}
            {unlimitedMilagePrice !== '0' ? (
              <>
                <b>
                  {unlimitedMilagePrice}
                  <span className="currency">{' \u20BD'}</span>
                  /день
                </b>
              </>
            ) : (
              <b>Бесплатно</b>
            )}
          </span>
        </ListItem>
      )}
    </div>
  );

  const renderAdditionalFeaturesComponent = () => (
    <div className="features">
      {predefinedAdditionalFeatures.map(({ name, dailyPrice, wholeRentPrice }) => (
        <ListItem key={`${name}-wrapper`}>
          <span className="name -device">
            {carPredefinedAdditionalFeatures[name]}{' '}
            {rentDuration < 7 ? (
              <b>
                {dailyPrice}
                <span className="currency">{' \u20BD'}</span>
                /день
              </b>
            ) : (
              <b>
                {wholeRentPrice}
                <span className="currency">{'\u20BD'}</span>
              </b>
            )}
          </span>
        </ListItem>
      ))}
    </div>
  );

  const servicesQuantity =
    +!!fuelLiterCompensationPrice + !!afterRentWashingPrice + !!unlimitedMilagePrice;

  return (
    <div id="about-car">
      <Carousel photos={photos} />
      <Container>
        <Title>{`${brand} ${model}, ${productionYear}`}</Title>
        <SocialParametrs>
          <Rate>
            <span>{carRating}</span>
            <StarIcon className="rate" />
          </Rate>
          <div>{`Поездки: ${carRentsQuantity}`}</div>
          <ReviewsCount>
            <MessageCircleIcon className="reviews" />
            <span>{`Отзывы: ${carReviewsQuantity}`}</span>
          </ReviewsCount>
        </SocialParametrs>
        <Car>
          {bodyType && (
            <div className="element">
              <svg className="icon -body">
                <use href={`${bodyTypeSprite}#${bodyType}`} />
              </svg>
              {carBodyType[bodyType].label}
            </div>
          )}
          {transmissionType && (
            <div className="element">
              <svg className="icon -transmission">
                <use href={`${transmissionSprite}#${transmissionType}`} />
              </svg>
              {carTransmissionType[transmissionType].label}
            </div>
          )}
          {seatsQuantity && (
            <div className="element">
              <SeatIcon className="icon -seat" />
              {`${seatsQuantity} места`}
            </div>
          )}
          {engineType && (
            <div className="element">
              <svg className="icon -engine">
                <use href={`${engineTypeSprite}#${engineType}`} />
              </svg>
              {carEngineType[engineType].label}
            </div>
          )}
        </Car>
        <CheckoutCard
          price={rentPricePerDay}
          maxRentDays={carDurations.duration[maxRentDays]}
          minRentDays={carDurations.duration[minRentDays]}
          unavailabilityDates={unavailabilityDates}
          weekDiscount={weekDiscount}
          monthDiscount={monthDiscount}
        />
        <Box>
          <Subtitle className="host">Владелец</Subtitle>
          <Host>
            <Avatar
              className="avatar"
              alt="avatar"
              src="http://creu.ru/wp-content/uploads/2017/06/55b3f623ddede1b610979edc8455d267.jpg"
            />
            <div className="skills">
              <span className="name">{`${firstName} ${lastName}`}</span>
              <span className="reliability">
                <CheckmarkIcon className="checkmark" />
                Проверенный пользователь
              </span>
              <br />
              <span className="skill">{`отзывы: ${userReviewsQuantity}`}</span>
              <span className="skill">{`поездки: ${userRentsQuantity}`}</span>
            </div>
          </Host>
        </Box>
        <Box>
          <Subtitle>Условия аренды</Subtitle>
          <ul className="limit">
            <li className="item">
              <span className="text">{carRentLocation[rentLocations]}</span>
            </li>
            <li className="item">
              {mileageLimit === UNLIMITED ? (
                <span className="text">Без ограничения пробега</span>
              ) : (
                <>
                  <span className="text">Ограничение пробега — </span>
                  <span className="text -bold">{mileageLimit} км </span>
                  <span className="text">на всю поездку</span>
                  <br />
                  <span className="footnote">
                    {`Стоимость дополнительного километра ${additionalKmPrice} `}
                    <span className="currency">{'\u20BD'}</span>
                  </span>
                </>
              )}
            </li>
            {/* TODO only for luxury cars */}
            <li className="item">
              <span className="text">Возраст водителя — </span>
              <span className="text -bold">
                {guestRequirements.minAge
                  ? `от ${guestRequirements.minAge} лет`
                  : 'Без ограничений'}
              </span>
            </li>
            <li className="item">
              <span className="text">Залог — </span>
              <span className="text -bold">
                50 000 <span className="currency">{'\u20BD'}</span>
              </span>
            </li>
          </ul>
        </Box>
        {description && (
          <Box>
            <Subtitle>Описание машины</Subtitle>
            <ShowBox
              isButton={true}
              ViewComponent={renderDescriptionComponent}
              height="72px"
              button="Подробнее"
            />
          </Box>
        )}
        {includedFeatures.length > 0 && (
          <Box>
            <Subtitle>Комплектация машины</Subtitle>
            <ShowBox
              isButton={(isMobile && includedFeatures.length > 2) || includedFeatures.length > 4}
              ViewComponent={renderComplectationComponent}
              height="85px"
              button="Показать все"
            />
          </Box>
        )}
        {servicesQuantity > 0 && (
          <Box id="services-devices">
            <Subtitle>Дополнительные услуги</Subtitle>
            <ShowBox
              isButton={servicesQuantity > 2}
              ViewComponent={renderServiceComponent}
              height="60px"
              button="Показать все"
            />
          </Box>
        )}
        {predefinedAdditionalFeatures.length > 0 && (
          <Box>
            <Subtitle>Аксессуары для комфорта</Subtitle>
            <ShowBox
              ViewComponent={renderAdditionalFeaturesComponent}
              isButton={predefinedAdditionalFeatures.length > 2}
              height="60px"
              button="Показать все"
            />
          </Box>
        )}
        {rentRecomendations && (
          <Box>
            <Subtitle>Пожелания владельца</Subtitle>
            <ShowBox
              isButton={true}
              ViewComponent={renderInstructionComponent}
              height="72px"
              button="Подробнее"
            />
          </Box>
        )}
        <Reviews reviews={reviews} quantity={carReviewsQuantity} rating={carRating} />
      </Container>
      <Map id="placement">
        <Subtitle className="map-title">Местоположение</Subtitle>
        <p className="address">
          Машина находится по адресу: <br className="br" />
          {address}
        </p>
        <div style={{ height: '30rem' }} className="img">
          <PageMap
            car={{ rentPricePerDay, homeLocation: { lon: location[0], lat: location[1] } }}
          />
        </div>
      </Map>
    </div>
  );
};

export default Card;
