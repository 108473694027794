import { styled } from 'linaria/react';
import IconButton from '@material-ui/core/IconButton';
import { breakpoints } from '../constants/breakpoints';

export const SubtabContainer = styled.div`
  @media ${breakpoints.desktop} {
    display: flex;
    margin-top: 40px;
    width: 100%;
  }
`;

export const SubtabsWrapper = styled.div`
  box-sizing: border-box;
  width: 300px;
  border: 1px solid rgba(0, 203, 144, 0.24);
  border-radius: 4px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 35px;
`;

export const Title = styled.h3`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  color: var(--primary-text);
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
`;

export const Subtitle = styled.h4`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  color: var(--grey-text);
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
`;

export const Text = styled.p`
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  color: var(--grey-text);
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
`;

export const Notice = styled.div`
  & {
    display: flex;
    margin-top: 20px;
  }

  .icon.-hand {
    width: 32px;
    height: 28px;
    margin-right: 12px;
  }

  > .notice {
    font-size: 12px;
    line-height: 20px;
    width: calc(100% - 44px);
  }

  > .notice > .subtitle {
    display: block;
    color: var(--primary-text);
    font-weight: 600;
  }

  > .notice > .text {
    font-weight: 400;
    color: var(--grey-text);
  }

  > .notice > .date {
    display: inline-block;
    font-weight: 600;
    color: var(--grey-text);
  }

  @media ${breakpoints.tablet} {
    margin-top: 24px;
  }

  @media ${breakpoints.laptop} {
    & {
      border-top: 1px solid #c4c4c4;
      padding-top: 25px;
    }
  }
`;

export const Insurance = styled.div`
  & {
    display: flex;
  }

  .popup .MuiButton-label {
    font-size: 12px;
    line-height: 20px;
  }

  .icon.-insurance {
    width: 35px;
    height: 20px;
    margin-right: 9px;
    fill: #066bd6;
    padding-left: 7px;
    box-sizing: border-box;
  }

  > .notice {
    font-size: 12px;
    line-height: 20px;
    width: calc(100% - 44px);
  }

  > .notice > .subtitle {
    display: block;
    color: var(--primary-text);
    font-weight: 600;
  }

  > .notice > .text {
    font-weight: 400;
    color: var(--grey-text);
  }

  > .notice > .company {
    display: block;
    color: #066bd6;
    font-weight: 600;
  }

  @media ${breakpoints.tablet} {
    > .notice > .company {
      display: inline-block;
    }
  }
`;

// TODO change naming
export const Error = styled.div`
  margin-top: 5px;
  color: red;
  width: 100%;
`;

export const PanelTitle = styled.span`
  color: #5d5d5d;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const AccordionWrapper = styled.div`
  margin-top: 13px;
  width: 100%;
`;

export const OutlineIconButton = styled(IconButton)`
  && {
    box-sizing: border-box;
    height: 46px;
    width: 48px;
    border: 1px solid #8d9bb5;
    border-radius: 4px;
    background-color: #ffffff;
  }
`;
