import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import MapIcon from '@material-ui/icons/Map';
import TuneIcon from '@material-ui/icons/Tune';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '../Autocomplete';
import { CustomCheckbox } from '../Checkbox';
import { ReactComponent as ClosingCross } from '../../assets/icons/closingCross.svg';
import MainMap from '../Map/MainMap';

import {
  carTransmissionLayout,
  carTransmissionType,
  carDevices,
  carEngineType,
  searchFiltersSeats,
  searchFiltersYears,
  searchFiltersBody,
  searchFiltersCars,
} from '../../assets/data';
import {
  useQuery,
  submitQueries,
  resetAllQueries,
  filtersMapper,
} from '../../services/query-service';
import { mapSearchFitersToKeys, mapSearchFiltersBarDefaultValues } from '../../utils/mapper';
import { sliderDefaultProps } from '../SearchResults/Controls/Price/helper';
import {
  FiltersBar,
  DialogContainer,
  Container,
  Collection,
  StyledSearchButtons,
  StyledSliderInput,
  StyledDivider,
  StyledSlider,
  Form,
} from './styles';

const { additions, seats, years, carBody, cars } = mapSearchFitersToKeys();

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

// TODO remove it in search feature
export const MobileFilters = ({ minPrice, maxPrice }) => {
  const query = useQuery();
  const history = useHistory();
  const defaultValues = mapSearchFiltersBarDefaultValues();
  const initialValues = filtersMapper(query, defaultValues);

  const [openMap, setOpenMap] = useState(false);
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const sliderProps = {
    ...sliderDefaultProps,
    minFieldName: 'priceMin',
    maxFieldName: 'priceMax',
  };

  const handleOpenMap = () => {
    setOpenMap(true);
  };
  const handleCloseMap = () => {
    setOpenMap(false);
  };

  const [openFilters, setOpenFilters] = useState(false);
  const handleOpenFilters = () => {
    setOpenFilters(true);
  };

  const handleCloseFilters = () => {
    setOpenFilters(false);
  };

  const devices = Object.keys(carDevices).map(key => carDevices[key]);

  // todo: refactor filters bar, split this code into several components
  return (
    <FiltersBar>
      <div className="filters map-container">
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleOpenMap}
          startIcon={<MapIcon color="primary" />}
        >
          Карта
        </Button>
        <DialogContainer
          fullScreen
          open={openMap}
          onClose={handleOpenMap}
          TransitionComponent={Transition}
          openMap={openMap}
        >
          <ClosingCross onClick={handleCloseMap} className="closing-cross" />
          <MainMap zoomControl={false} />
        </DialogContainer>
      </div>
      <div className="filters filter-container">
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleOpenFilters}
          startIcon={<TuneIcon color="primary" />}
        >
          Фильтры
        </Button>
        <DialogContainer
          fullScreen
          open={openFilters}
          onClose={handleCloseFilters}
          TransitionComponent={Transition}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              submitQueries(values, query, history);
            }}
          >
            {({ setFieldValue, handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <ClosingCross onClick={handleCloseFilters} className="closing-cross" />
                <h5 className="title -first">Цена</h5>
                <FormControlLabel
                  className="price-label"
                  control={
                    <>
                      <StyledSliderInput
                        {...sliderProps}
                        collectData={setFieldValue}
                        min={values.priceMin}
                        max={values.priceMax}
                      />
                    </>
                  }
                />
                <FormControlLabel
                  className="filters-label"
                  control={
                    <CustomCheckbox
                      name="instant"
                      label="Доставка"
                      checked={values.delivery}
                      onChange={({ target: { checked } }) => setFieldValue({ delivery: checked })}
                    />
                  }
                  label="Доставка"
                />
                <FormControlLabel
                  className="filters-label"
                  control={
                    <CustomCheckbox
                      name="instant"
                      label="Мгновенное бронирование"
                      checked={values.immediateBooking}
                      onChange={({ target: { checked } }) =>
                        setFieldValue({ immediateBooking: checked })
                      }
                    />
                  }
                  label="Мгновенное бронирование"
                />
                <Container>
                  <div className="box">
                    <h5 className="title -first">Показать</h5>
                    <FormGroup>
                      <FormControlLabel
                        className="filters-label"
                        control={
                          <CustomCheckbox
                            checked={values.noMilLim}
                            name="noMilLim"
                            onChange={({ target: { checked } }) => {
                              if (checked) {
                                setFieldValue('dailyMil', null);
                              }
                              setFieldValue('noMilLim', checked);
                            }}
                            className="filter-checkbox"
                          />
                        }
                        label="Без ограничений пробега"
                      />
                    </FormGroup>
                  </div>
                  <div className="box -using">
                    <h5 className="title -first">Использование</h5>
                    <RadioGroup aria-label="limitations" name="limitations" defaultValue="world">
                      <FormControlLabel
                        className="filters-label"
                        value="city"
                        control={<Radio onChange={() => setFieldValue('area', null)} />}
                        label="Только в городе и ближайших окрестностях"
                      />
                      <FormControlLabel
                        className="filters-label"
                        value="country"
                        control={<Radio onChange={() => setFieldValue('area', 'country')} />}
                        label="По всей стране"
                      />
                      <FormControlLabel
                        className="filters-label"
                        value="world"
                        control={<Radio onChange={() => setFieldValue('area', 'world')} />}
                        label="По всей стране и заграницей"
                      />
                    </RadioGroup>
                  </div>
                  <div className="box">
                    <h5 className="title -first -distance">Дистанция, км/день</h5>
                    <StyledSlider
                      onChangeCommitted={(event, newValue) => {
                        setFieldValue('dailyMil', newValue);
                      }}
                      disabled={values.noMilLim}
                      value={values.dailyMil ? values.dailyMil : 0}
                      aria-labelledby="discrete-slider-restrict"
                      color="secondary"
                      valueLabelDisplay="on"
                      min={0}
                      max={1000}
                      step={1}
                    />
                  </div>
                  <div className="box -selects -seats">
                    <h5 className="title -first" htmlFor="seatsMax">
                      Количество мест
                    </h5>
                    <Autocomplete
                      id="seats"
                      className="select"
                      options={searchFiltersSeats.options}
                      getOptionSelected={option => option}
                      value={values.seats || null}
                      renderInput={params => (
                        <TextField {...params} placeholder="- Выберите число -" />
                      )}
                      onChange={(_, newValue) => {
                        setFieldValue('seats', newValue);
                      }}
                    />
                  </div>
                </Container>
                <Container>
                  <div className="box -selects">
                    <h5 className="title" htmlFor="manufacturer">
                      Марка
                    </h5>
                    <Autocomplete
                      id="brand"
                      className="select"
                      options={searchFiltersCars.options}
                      getOptionLabel={option => option.label}
                      value={cars[values.brand] || null}
                      renderInput={params => (
                        <TextField {...params} placeholder="- Выберите марку -" />
                      )}
                      onChange={(_, { value }) => {
                        setFieldValue('brand', value);
                      }}
                    />
                  </div>
                  <div className="box -selects">
                    <h5 className="title" htmlFor="prodYear">
                      Год
                    </h5>
                    <Autocomplete
                      id="prodYear"
                      className="select"
                      options={searchFiltersYears.options}
                      getOptionLabel={option => option.label}
                      value={years[values.prodYear] || null}
                      renderInput={params => (
                        <TextField {...params} placeholder="- Выберите год -" />
                      )}
                      onChange={(_, { value }) => {
                        setFieldValue('prodYear', value);
                      }}
                    />
                  </div>
                  <div className="box -selects">
                    <h5 className="title">Тип кузова</h5>
                    <FormGroup className="form-group  ">
                      <Autocomplete
                        id="bodyType"
                        className="select"
                        options={searchFiltersBody.options}
                        getOptionLabel={option => option.label}
                        value={carBody[values.bodyType] || null}
                        renderInput={params => (
                          <TextField {...params} placeholder="- Выберите тип кузова -" />
                        )}
                        onChange={(_, { value }) => {
                          setFieldValue('bodyType', value);
                        }}
                      />
                    </FormGroup>
                  </div>
                </Container>
                <StyledDivider />
                <Container>
                  <div className="box -engine">
                    <h5 className="title">Коробка передач</h5>
                    <FormGroup>
                      {Object.values(carTransmissionType).map(({ value, label }) => (
                        <FormControlLabel
                          className="filters-label"
                          control={
                            <CustomCheckbox
                              checked={values[value]}
                              name={value}
                              onChange={({ target: { checked } }) => setFieldValue(value, checked)}
                              className="filter-checkbox"
                            />
                          }
                          label={label}
                          key={value}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <div className="box -engine">
                    <h5 className="title">Привод</h5>
                    <FormGroup>
                      {Object.values(carTransmissionLayout).map(({ value, label }) => (
                        <FormControlLabel
                          className="filters-label"
                          control={
                            <CustomCheckbox
                              checked={values[value]}
                              name={value}
                              onChange={({ target: { checked } }) => setFieldValue(value, checked)}
                              className="filter-checkbox"
                            />
                          }
                          label={label}
                          key={value}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <div className="box -engine">
                    <h5 className="title">Тип двигателя</h5>
                    <FormGroup>
                      {Object.values(carEngineType).map(({ value, label }) => (
                        <FormControlLabel
                          className="filters-label"
                          control={
                            <CustomCheckbox
                              checked={values[value]}
                              name={value}
                              onChange={({ target: { checked } }) => setFieldValue(value, checked)}
                              className="filter-checkbox"
                            />
                          }
                          label={label}
                          key={value}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </Container>
                <StyledDivider />
                <Collection>
                  <h5 className="title -device">Дополнительно</h5>
                  <FormGroup className="form-group -devices">
                    {devices.map(({ label, data: { name } }) => (
                      <FormControlLabel
                        className="filters-label"
                        control={
                          <CustomCheckbox
                            checked={values[name]}
                            name={name}
                            onChange={({ target: { checked } }) => setFieldValue(name, checked)}
                            className="filter-checkbox"
                          />
                        }
                        label={label}
                        key={name}
                      />
                    ))}
                  </FormGroup>
                </Collection>
                <StyledDivider />
                <Collection last>
                  <h5 className="title -device">Дополнительные услуги</h5>
                  <FormGroup className="form-group -devices">
                    <FormControlLabel
                      className="filters-label"
                      control={
                        <CustomCheckbox
                          checked={values.afterRentWashingPrice}
                          name="afterRentWashingPrice"
                          onChange={({ target: { checked } }) =>
                            setFieldValue('afterRentWashingPrice', checked)
                          }
                          className="filter-checkbox"
                        />
                      }
                      label="Мойка после аренды"
                    />
                    <FormControlLabel
                      className="filters-label"
                      control={
                        <CustomCheckbox
                          checked={values.fuelLiterCompensationPrice}
                          name="fuelLiterCompensationPrice"
                          onChange={({ target: { checked } }) =>
                            setFieldValue('fuelLiterCompensationPrice', checked)
                          }
                          className="filter-checkbox"
                        />
                      }
                      label="Возврат с пустым баком"
                    />
                  </FormGroup>
                </Collection>
                <StyledSearchButtons
                  submit={values => {
                    handleSubmit(values);
                    handleCloseFilters();
                  }}
                  resetForm={() => {
                    resetAllQueries(query, history);
                    // resetForm();
                    handleCloseFilters();
                  }}
                  closingLabel="Сбросить фильтры"
                />
              </Form>
            )}
          </Formik>
        </DialogContainer>
      </div>
    </FiltersBar>
  );
};
