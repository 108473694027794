import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import { ConfirmModal } from '../../ConfirmModal';

import { PlanWrapper } from './styles';

export const InsurancePlan = ({ id, name, taxPercent, features }) => {
  const [open, setOpen] = useState(false);
  const {
    values: { insurancePlanId },
    setFieldValue,
  } = useFormikContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    setFieldValue('insurancePlanId', id);
    setOpen(false);
  };

  return (
    <PlanWrapper selected={insurancePlanId === id}>
      <div className="main-box">
        <span className="name">{`"${name}"`}</span>
        <span className="price">Вы зарабатываете: </span>
        <span className="price">
          <big className="percent">{`${taxPercent}%`}</big> с каждой аренды
        </span>
      </div>
      {features.map(point => (
        <div className="condition" key={`${point}-key`}>
          <DoneIcon className="icon" />
          <span>{point}</span>
        </div>
      ))}
      <Button
        variant="contained"
        size="large"
        className={insurancePlanId === id ? 'active' : 'common'}
        disabled={insurancePlanId === id}
        onClick={handleClickOpen}
      >
        {insurancePlanId === id ? 'Тариф выбран' : 'Выбрать тариф'}
      </Button>
      <ConfirmModal
        open={open}
        handleClose={handleClose}
        sumbmit={handleSelect}
        text={`Вы хотите сменить тариф на “${name}”. Для смены тарифа, нажмите “Подтвердить”.`}
      />
    </PlanWrapper>
  );
};
