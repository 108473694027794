import React, { useState, useEffect } from 'react';
import { parse, isAfter } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Controls } from '../../components/SearchResults/Controls';
import { MobileFilters } from '../../components/FiltersBar';
import { PriceSort } from '../../components/SearchResults/Controls/PriceSort';
import { SkeletonLoader } from './skeletonLoader';
import { useQuery, setQuery, resetAllQueries } from '../../services/query-service';
import { defaultStartRent, defaultEndRent } from '../../utils/date';
import { searchActions } from '../../redux/actions';
import MainMap from '../../components/Map/MainMap';

import { StyledSearchContainer, Photo } from './styles';

const { getCars, getMoreCars } = searchActions;

const carsPerPage = 5;

const FORMAT = "yyyy-MM-dd'T'HH:mm";

export const SearchResults = () => {
  const query = useQuery();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mapExists = useMediaQuery(theme.breakpoints.up('md'));
  const cars = useSelector(({ search: { cars } }) => cars);
  const { loading } = useSelector(({ search }) => search);
  const dateStart = query.get('dateStart');
  const dateEnd = query.get('dateEnd');

  const [isMap, setIsMap] = useState(true);
  const [page, setPage] = useState(2);
  const showMap = () => setIsMap(!isMap);
  const loadMore = () => {
    dispatch(getMoreCars(query.toString(), page));
    setPage(page + 1);
  };
  const resetFilters = () => {
    resetAllQueries(query, history);
  };
  useEffect(() => {
    const formatedDateStart = parse(dateStart, FORMAT, new Date());
    if (dateStart && dateEnd && isAfter(formatedDateStart, new Date())) {
      dispatch(getCars(query.toString()));
    } else {
      const defaultStartDate = defaultStartRent();
      const defaultEndDate = defaultEndRent(defaultStartDate);
      setQuery('dateStart', defaultStartDate, query, history);
      setQuery('dateEnd', defaultEndDate, query, history);
    }
  }, [query.toString()]);

  return (
    <>
      <StyledSearchContainer className="search-container" mapexists={isMap}>
        <Controls
          minPrice={cars.meta ? cars.meta.minPrice : 0}
          maxPrice={cars.meta ? cars.meta.maxPrice : 0}
          showMap={showMap}
          checked={isMap}
          isMapExists={mapExists}
        />
        <div className="search-offers">
          <h3 className="caption">{`Мы нашли для вас ${
            cars.meta ? cars.meta.resultsQuantity : '0'
          } предложений`}</h3>
          <div className="wrap">
            <Link
              component="button"
              variant="body2"
              className="btn"
              onClick={() => {
                dispatch(resetFilters());
              }}
            >
              <ClearOutlinedIcon className="icon" />
              <span className="text">Сбросить фильтры</span>
            </Link>
            <PriceSort />
          </div>
        </div>
        <div className="search-results-wrapper">
          <div className="row">
            <div className="column">
              {loading ? (
                <SkeletonLoader itemQnt={carsPerPage} />
              ) : (
                <div className="search-list">
                  {cars.meta && cars.meta.resultsQuantity ? (
                    <InfiniteScroll
                      dataLength={cars.data.length}
                      next={loadMore}
                      scrollThreshold="20px"
                      style={{ overflow: 'unset' }}
                      hasMore={cars.meta.resultsQuantity > cars.data.length}
                      loader={<h4>Loading...</h4>}
                    >
                      {/* TODO remove default values */}
                      {cars.data &&
                        cars.data.map(
                          ({
                            uuid,
                            photoUrl,
                            brand,
                            model,
                            productionYear,
                            rentPricePerDay,
                            rentsQuantity,
                            rating,
                            reviewsQuantity,
                            immediateBooking,
                            delivery,
                          }) => (
                            <RouterLink
                              to={encodeURI(
                                `/car/card/${uuid}?dateStart=${dateStart}&dateEnd=${dateEnd}`,
                              )}
                              target="_blank"
                              className="car-card-link"
                              key={uuid}
                            >
                              <div className="car-card" key={uuid}>
                                <div className="wrap -img">
                                  <Photo photo={photoUrl} />
                                  <div className="box">
                                    <span className="price">{rentPricePerDay} &#8381;</span>
                                    <span className="label">/сутки</span>
                                  </div>
                                </div>
                                <div className="wrap -model">
                                  <h4 className="subtitle">{`${brand} ${model}, ${productionYear}`}</h4>
                                  <div className="box">
                                    <span className="label">{rating}</span>
                                    <StarOutlinedIcon className="icon" />
                                  </div>
                                </div>
                                <div className="wrap -stat">
                                  <span className="caption">{`поездки: ${rentsQuantity}`} </span>
                                  <span className="caption">{`отзывы: ${reviewsQuantity}`}</span>
                                </div>
                                {!(immediateBooking || delivery) && (
                                  <div className="tags">
                                    {!immediateBooking && (
                                      <span className="tag">Мгновенное бронирование</span>
                                    )}
                                    {!delivery && <span className="tag">Доставка</span>}
                                  </div>
                                )}
                              </div>
                            </RouterLink>
                          ),
                        )}
                    </InfiniteScroll>
                  ) : (
                    <p className="no-results">
                      Машины не найдены. Попробуйте изменить даты, увеличить масштаб карты или
                      сбросить дополнительные фильтры
                    </p>
                  )}
                </div>
              )}
            </div>
            {mapExists ? (
              <div className="row -map">
                <MainMap style={{ width: '100%', height: '100vh' }} />
              </div>
            ) : null}
          </div>
        </div>
      </StyledSearchContainer>
      <MobileFilters
        minPrice={cars.meta ? cars.meta.minPrice : 0}
        maxPrice={cars.meta ? cars.meta.maxPrice : 0}
      />
    </>
  );
};
