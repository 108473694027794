import React from 'react';
import { Field } from 'formik';
import Input from '@material-ui/core/Input';

import { Error } from '../../../styledComponents';
import { ServiceWrapper } from './styles';

export const AdditionalServiceConditions = ({
  setFieldValue,
  name,
  values,
  errors,
  price,
  priceLabel,
}) => {
  return (
    <ServiceWrapper>
      <Field
        id={`${name}-price`}
        className="input"
        placeholder="Например, 300"
        defaultValue={price}
        onBlur={e => {
          setFieldValue(name, { ...values[name], price: e.target.value });
        }}
        component={Input}
        type="number"
      />
      {priceLabel.currency && <span className="additional-price">&#8381; {priceLabel.text}</span>}
      {errors[name] ? <Error>{errors[name].price}</Error> : null}
    </ServiceWrapper>
  );
};
