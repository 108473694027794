import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SliderInput } from '../../../SliderInput';
import { SearchControlButton } from '../../../Button/SearchControlButton';
import { priceSearchValidationSchema } from '../../../../utils/validate';
import { sliderDefaultProps, isExist } from './helper';

import {
  useQuery,
  submitQueries,
  resetQueries,
  filtersMapper,
} from '../../../../services/query-service';
import { Error } from '../../../../styledComponents';
import { StyledLabel, StyledPopover, Form } from './styles';

const Control = ({ handler, label, isOpen }) => (
  // \u20bd - russian ruble symbol, can be replaced if we need to focus on multicurrency
  <Button
    variant="outlined"
    className={`tab-btn ${isOpen ? '-opened' : ''} ${isExist(label) ? '-active' : ''}`}
    {...handler}
  >
    {isExist(label) ? `Цена: \u20bd ${label.priceMin}-${label.priceMax}` : 'Цена'}
    <ExpandMoreIcon className={`filter-arrow ${isOpen ? '-opened' : ''}`} />
  </Button>
);

export const ControlPrice = ({ minPrice, maxPrice }) => {
  const query = useQuery();
  const history = useHistory();
  const defaultValues = {
    priceMin: minPrice,
    priceMax: maxPrice,
  };
  const initialValues = filtersMapper(query, defaultValues);
  const sliderProps = {
    ...sliderDefaultProps,
    minFieldName: 'priceMin',
    maxFieldName: 'priceMax',
  };
  return (
    // TODO label?
    <StyledPopover control={Control} label={initialValues}>
      {closeHandler => (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={priceSearchValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            submitQueries(values, query, history);
            closeHandler();
          }}
        >
          {({ setFieldValue, handleSubmit, values, errors }) => (
            <Form>
              <StyledLabel>Укажите диапазон цены</StyledLabel>
              <SliderInput
                {...sliderProps}
                collectData={setFieldValue}
                min={values.priceMin}
                max={values.priceMax}
              />
              {errors.priceMax ? <Error>{errors.priceMax}</Error> : null}
              <Divider className="divider" />
              <SearchControlButton
                submit={handleSubmit}
                resetForm={() => {
                  resetQueries(Object.keys(values), query, history);
                  closeHandler();
                }}
                label={['priceMin', 'priceMax']}
              />
            </Form>
          )}
        </Formik>
      )}
    </StyledPopover>
  );
};
