import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';

export const StyledDatePicker = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      padding: 10px 16px 0;
      flex-wrap: wrap;
      box-sizing: border-box;
    }

    .autocomplete div input {
      border: none;
    }

    .date-hours {
      width: 110px;
    }

    .date-hours input {
      text-align: center;
    }

    .MuiAutocomplete-endAdornment {
      right: -18px;
    }

    .picker-wrapper {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
    }

    .picker-wrap {
      position: relative;
      width: 230px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 0;
      box-sizing: border-box;

      &.-from:before {
        content: '';
        position: absolute;
        font-family: 'Open Sans';
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }

      &.-to:before {
        content: '';
        position: absolute;
        font-family: 'Open Sans';
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }
    }

    .picker-wrap .DayPicker-Months {
      flex-wrap: nowrap;
    }

    .picker-wrap
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: rgb(220, 230, 242) !important;
      color: var(--primary-text);
      border-radius: 0;
    }

    .picker-wrap > div {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, 10%);
      border: 1px solid #d5d5d5;
      border-radius: 10px;
      background: #fff;
      z-index: 1;
    }

    .picker-input {
      width: 100%;
      font-size: 16px;
      color: var(--primary-text);

      &:focus {
        outline: none;
      }

      &.-from {
        border: 0;
        padding: 0 0 10px;
      }

      &.-to {
        border: 0;
        padding: 0 0 10px;
      }
    }

    .calendar-icon.-from,
    .calendar-icon.-to {
      position: absolute;
      left: 140px;
      top: 15px;
      z-index: 0;
      fill: #222b45;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 10px 16px 0 25px;
      width: 520px;
      margin: 0 auto 0 80px;
    }

    .picker-wrap {
      position: relative;
      width: 160px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 0;
      box-sizing: border-box;

      &.-from:before {
        content: '';
        position: absolute;
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }

      &.-to:before {
        content: '';
        position: absolute;
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }
    }

    .picker-wrap .DayPicker-Months {
      flex-wrap: nowrap;
    }

    .picker-wrap
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: rgb(220, 230, 242) !important;
      color: var(--primary-text);
      border-radius: 0;
    }

    .picker-wrap > div {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, 10%);
      border: 1px solid #d5d5d5;
      border-radius: 10px;
      background: #fff;
      z-index: 1;
    }

    .picker-input {
      width: 100%;
      font-size: 16px;
      color: var(--primary-text);

      &:focus {
        outline: none;
      }

      &.-from {
        border: 0;
        padding: 0 0 6px;
      }

      &.-to {
        border: 0;
        padding: 0 0 6px;
      }
    }
    /* 
    .calendar-icon.-from {
      position: absolute;
      left: 290px;
      top: 76px;
      z-index: 0;
    } */

    .calendar-icon.-to,
    .calendar-icon.-from {
      position: absolute;
      left: 140px;
      top: 18px;
      z-index: 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: auto;
      padding: 0 16px 5px;
      margin: 0;
    }

    .picker-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      width: auto;
      border: none;
      margin-right: 35px;

      &:after {
        content: '';
        height: 1px;
        position: absolute;
        width: 100%;
        background: #d8d8d8;
        margin-bottom: 5px;
      }
    }

    .picker-wrap {
      position: relative;
      width: 160px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 0 0 8px;
      box-sizing: border-box;

      &.-from:before {
        content: '';
        position: absolute;
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }

      &.-to:before {
        content: '';
        position: absolute;
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }
    }

    .date-hours {
      width: 70px;
      margin-left: 20px;
    }

    .MuiAutocomplete-inputRoot[class*='MuiInput-root']
      .MuiAutocomplete-input.MuiAutocomplete-input:first-child {
      padding: 0 4px 0 0;
    }

    .MuiAutocomplete-endAdornment {
      right: -5px;
    }

    .MuiAutocomplete-popupIndicator {
      margin-right: 0;
    }

    .picker-wrap .DayPicker-Months {
      flex-wrap: nowrap;
    }

    .picker-wrap
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: rgb(220, 230, 242) !important;
      color: var(--primary-text);
      border-radius: 0;
    }

    .picker-wrap > div {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, 10%);
      border: 1px solid #d5d5d5;
      border-radius: 10px;
      background: #fff;
      z-index: 1;
    }

    .picker-input {
      width: 100%;
      font-size: 16px;
      color: var(--primary-text);

      &:focus {
        outline: none;
      }
    }

    .calendar-icon.-from,
    .calendar-icon.-to {
      position: absolute;
      left: 135px;
      top: 10px;
      z-index: 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin: 0 0 0 20px;
      width: 575px;
    }

    .picker-wrap {
      position: relative;
      width: 160px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 0 0 8px;
      box-sizing: border-box;

      &.-from:before {
        content: '';
        position: absolute;
        font-family: 'Open Sans';
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }

      &.-to:before {
        content: '';
        position: absolute;
        font-family: 'Open Sans';
        bottom: 8px;
        left: 0;
        font-size: 14px;
        color: var(--grey-text);
      }
    }

    .picker-wrap .DayPicker-Months {
      flex-wrap: nowrap;
    }

    .picker-wrap
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: rgb(220, 230, 242) !important;
      color: var(--primary-text);
      border-radius: 0;
    }

    .picker-wrap > div {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, 10%);
      border: 1px solid #d5d5d5;
      border-radius: 10px;
      background: #fff;
      z-index: 1;
    }

    .picker-input {
      width: 100%;
      font-size: 16px;
      color: var(--primary-text);
      font-family: 'Open Sans';

      &:focus {
        outline: none;
      }
    }
  }
`;
