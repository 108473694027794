export default (childNav, parentNav) => {
  const prev = typeof childNav.prev === 'number' ? childNav.prev : parentNav.prev;
  const next = typeof childNav.next === 'number' ? childNav.next : parentNav.next;
  const prevHandler =
    typeof childNav.prev !== 'number' && parentNav.prev ? parentNav.handler : childNav.handler;
  const nextHandler =
    typeof childNav.next !== 'number' && parentNav.next ? parentNav.handler : childNav.handler;
  return {
    prev,
    next,
    prevHandler,
    nextHandler,
  };
};
