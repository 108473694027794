import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Layout from './components/Layout';
import CarEdit from './pages/CarEdit';
import { LoginPage } from './components/UserRegistration/Login/Page';
import { RegistrationPage } from './components/UserRegistration/Registration/Page';
import { ForgotPasswordPage } from './components/UserRegistration/Confirmation/ForgotPassword';
import { ResetPasswordPage } from './components/UserRegistration/ResetPassword/Page';
import { SearchResults } from './views/SearchResults';
import { CarRegistrationPage } from './pages';
import MapPage from './views/MapPage';
import MyCars from './views/MyCars';
import { getUserInfo, updateUserInfo } from './services/auth-service';
import { parseCookie } from './utils/parseCookie';
import { userRoles } from './constants';
// import Checkout from './pages/Checkout';
// import ScoringResults from './components/ScoringResuts';
import Card from './pages/Card';

const { host, member, admin } = userRoles;

export default () => {
  return (
    <BrowserRouter>
      <Layout>
        <Route
          path="/login"
          render={props => {
            const userInfo = getUserInfo();
            if (userInfo) {
              window.location = '/';
              return null;
            }
            return <LoginPage {...props} />;
          }}
        />
        <Route
          path="/registration"
          render={props => {
            const userInfo = getUserInfo();
            if (userInfo) {
              window.location = '/';
              return null;
            }
            return <RegistrationPage {...props} />;
          }}
        />
        <Route
          path="/social-success"
          render={() => {
            const { user } = parseCookie(document.cookie);
            if (user) {
              // TODO: reset user cookie
              updateUserInfo(user);
              return <Redirect to="/" />;
            }
            return <Redirect to="/" />;
          }}
        />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <PrivateRoute
          path="/car/register"
          roles={Object.values(userRoles)}
          component={CarRegistrationPage}
        />
        <PrivateRoute path="/car/edit/:id" roles={[host, member, admin]} component={CarEdit} />
        <PrivateRoute path="/my-cars" roles={[host, member, admin]} component={MyCars} />
        <Route path="/car/card/:id" component={Card} />
        {/* TODO remove it after demo */}
        {/* <Route path="/checkout/:id" component={Checkout} /> */}
        {/* <Redirect from="/search" to="/" /> */}
        <Route path="/search" component={SearchResults} />
        <Route path="/map" component={MapPage} />
        {/* <Route path="/google/success" render={() => <Redirect to="/" />} /> */}
        {/* <Route path="/scoring" component={ScoringResults} /> */}
      </Layout>
    </BrowserRouter>
  );
};
