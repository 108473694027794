import React, { useEffect, useContext, useState, memo } from 'react';
import { common } from '@material-ui/core/colors';
import { MapContext } from './MapContext';
import circle from '../../assets/icons/circle.svg';
import circleActive from '../../assets/icons/circle_active.svg';
import rect from '../../assets/icons/rect.svg';

export default memo(
  ({
    car,
    isLabel = true,
    setTooltip = () => {},
    index = 10,
    onClick,
    onBlur = () => {},
    active,
  }) => {
    const [mapInstance] = useContext(MapContext);

    useEffect(() => {
      let marker;

      if (mapInstance) {
        const { map, mapglAPI } = mapInstance;

        const defaultLabel = {
          text: `${car.rentPricePerDay.toLocaleString()} Р`,
          fontSize: 14,
          offset: [0, -25],
          relativeAnchor: [0.5, 0],
          image: {
            url: rect,
            size: [50, 50],
            padding: [10, 15, 10, 15],
          },
          minZoom: 10,
          zIndex: index,
        };

        marker = new mapglAPI.Marker(map, {
          coordinates: [car.homeLocation.lon, car.homeLocation.lat],
          icon: active ? circleActive : circle,
          label: isLabel ? defaultLabel : undefined,
        });

        const visible = position => {
          marker._impl.label.hide(); // eslint-disable-line
          setTooltip({ car, position, visible: true });
        };

        const hide = () => {
          marker._impl.label.show(); // eslint-disable-line
          setTooltip({ car, visible: false });
          onBlur();
        };

        if (onClick) {
          marker.on('click', event => {
            onClick({ event, car });
          });
        }

        marker.on('click', event => {
          const { width, height } = event.originalEvent.target;
          const [left, top] = event.point;

          const pos = [width / 2 > left ? 'right' : 'left', height / 2 > top ? 'bot' : 'top'];

          const isOffset = (offset, size, max = 70) => {
            return size / 2 - Math.abs(size / 2 - offset) < max;
          };

          if (isOffset(left, width) || isOffset(top, height)) {
            const duration = 300;

            const openTooltip = new Promise(resolve => {
              map.setCenter(event.lngLat, {
                animate: true,
                duration,
              });

              setTimeout(() => {
                visible({
                  top: `${height / 2}px`,
                  left: `${width / 2}px`,
                  pos: ['right', 'top'],
                });

                resolve();
              }, duration * 2);
            });

            openTooltip.then(() => {
              map.once('move', () => {
                hide();
              });
            });
          } else {
            visible({
              top: `${top}px`,
              left: `${left}px`,
              pos,
            });

            map.once('move', () => {
              hide();
            });
          }

          map.on('click', () => {
            hide();
          });
        });
      }

      return () => marker && marker.destroy();
    }, [mapInstance, car, active]);

    return null;
  },
);
