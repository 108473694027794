import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDays, parseISO } from 'date-fns';
import { insert, update, remove } from 'ramda';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DateRangeIcon from '@material-ui/icons/DateRange';
import useTheme from '@material-ui/core/styles/useTheme';
import DayPicker from 'react-day-picker';
import { CalendarSidebar } from '../CalendarSidebar';
import EditCalendarPeriod from '../../../containers/EditCalendarPeriod';
import { ReactComponent as BackIcon } from '../../../assets/icons/menuArrowLeft.svg';
import localizationUtils from '../../../assets/locale';
import { carActions } from '../../../redux/actions';

import { OutlineIconButton } from '../../../styledComponents';
import { Container, Calendar, Legend, Box, View } from './styles';

const { editCarData } = carActions;

// TODO replace with styled components
const renderDay = day => {
  const date = day.getDate();

  const cellStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(221,237,232,0.43)',
    position: 'relative',
  };
  return (
    <div style={cellStyle}>
      <div>{date}</div>
    </div>
  );
};

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TabCalendar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();

  const [openCalendar, setOpenCalendar] = useState(false);
  const [openEditPeriod, setOpenEditPeriod] = useState(false);
  const [periodForUpdate, changePeriodForUpdate] = useState('');
  const [isShowDatePicker, changeVisibility] = useState(false);

  const { car } = useSelector(({ carEditData: { carData } }) => carData);
  const { unavailabilityDates } = car;
  const dispatch = useDispatch();

  const addPeriod = period => {
    const { blocked } = unavailabilityDates;
    const updateBlocked = insert(0, period, blocked);
    dispatch(
      editCarData({
        car: { ...car, unavailabilityDates: { ...unavailabilityDates, blocked: updateBlocked } },
      }),
    );
  };
  const updatePeriod = (period, index) => {
    const { blocked } = unavailabilityDates;
    const updateBlocked = update(index, period, blocked);
    dispatch(
      editCarData({
        car: { ...car, unavailabilityDates: { ...unavailabilityDates, blocked: updateBlocked } },
      }),
    );
  };
  const removePeriod = index => {
    const { blocked } = unavailabilityDates;
    const updateBlocked = remove(index, 1, blocked);
    dispatch(
      editCarData({
        car: { ...car, unavailabilityDates: { ...unavailabilityDates, blocked: updateBlocked } },
      }),
    );
  };

  const showDatePicker = () => {
    changeVisibility(!isShowDatePicker);
  };
  const setPeriodForUpdate = index => changePeriodForUpdate(index);
  const handleClickOpen = () => {
    setOpenCalendar(true);
  };
  const handleCloseCalendar = () => {
    setOpenCalendar(false);
  };
  const handleOpenEditPeriod = () => {
    setOpenEditPeriod(true);
  };
  const handleCloseEditPeriod = () => {
    setOpenEditPeriod(false);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const modifiers = {
    booked: unavailabilityDates.booked.map(period => ({
      after: addDays(parseISO(period.startDate), -1),
      before: addDays(parseISO(period.endDate), 1),
    })),
    blocked: unavailabilityDates.blocked.map(period => ({
      after: addDays(parseISO(period.startDate), -1),
      before: addDays(parseISO(period.endDate), 1),
    })),
    rent: unavailabilityDates.rent.map(period => ({
      after: addDays(parseISO(period.startDate), -1),
      before: addDays(parseISO(period.endDate), 1),
    })),
  };
  // TODO replace with styled components
  const modifiersStyles = {
    booked: {
      backgroundColor: 'rgba(0,115,249,0.06)',
      borderRadius: '0',
      border: '1px solid #0072F0',
    },
    blocked: {
      backgroundColor: 'rgba(255,21,59,0.05)',
      borderRadius: '0',
      border: '1px solid #F53B45',
    },
    rent: {
      backgroundColor: 'rgba(253, 203, 7 0.05)',
      borderRadius: '0',
      border: '1px solid #FDCB07',
    },
  };

  return (
    <Container>
      {isMobile ? (
        <>
          <CalendarSidebar
            unavailabilityDates={unavailabilityDates}
            removePeriod={removePeriod}
            showDatePicker={handleOpenEditPeriod}
            setPeriodForUpdate={setPeriodForUpdate}
          />
          <Dialog
            fullScreen
            open={openEditPeriod}
            onClose={handleCloseEditPeriod}
            TransitionComponent={Transition}
          >
            <EditCalendarPeriod
              unavailabilityDates={unavailabilityDates}
              addPeriod={addPeriod}
              updatePeriod={updatePeriod}
              showDatePicker={handleCloseEditPeriod}
              index={periodForUpdate}
              setPeriodForUpdate={setPeriodForUpdate}
            />
          </Dialog>
          <OutlineIconButton className="open-btn" onClick={handleClickOpen}>
            <DateRangeIcon className="icon" />
          </OutlineIconButton>
          <Dialog
            fullScreen
            open={openCalendar}
            onClose={handleCloseCalendar}
            TransitionComponent={Transition}
          >
            <Calendar>
              <DayPicker
                disabledDays={{ before: new Date() }}
                renderDay={renderDay}
                modifiers={modifiers}
                modifiersStyles={modifiersStyles}
                fromMonth={new Date()}
                locale="ru"
                localeUtils={localizationUtils}
              />
              <BackIcon onClick={handleCloseCalendar} className="back" />
              <Legend>
                <Box>
                  <View />
                  <div className="description">Доступно для аренды</div>
                </Box>
                <Box>
                  <View color="#0072f0" backgroundColor="rgba(0, 115, 249, 0.06)" />
                  <div className="description">Забронировано</div>
                </Box>
                <Box>
                  <View color="#f53b45" backgroundColor="rgba(255, 21, 59, 0.05)" />
                  <div className="description">Недоступно для аренды</div>
                </Box>
                <Box>
                  <View color="#fdcb07" backgroundColor="rgba(253, 203, 7 0.06)" />
                  <div className="description">Находиться в аренде</div>
                </Box>
              </Legend>
            </Calendar>
          </Dialog>
        </>
      ) : (
        <>
          {isShowDatePicker ? (
            <EditCalendarPeriod
              unavailabilityDates={unavailabilityDates}
              addPeriod={addPeriod}
              updatePeriod={updatePeriod}
              showDatePicker={showDatePicker}
              index={periodForUpdate}
              setPeriodForUpdate={setPeriodForUpdate}
            />
          ) : (
            <CalendarSidebar
              unavailabilityDates={unavailabilityDates}
              removePeriod={removePeriod}
              showDatePicker={showDatePicker}
              setPeriodForUpdate={setPeriodForUpdate}
            />
          )}
          <Calendar>
            <DayPicker
              disabledDays={{ before: new Date() }}
              renderDay={renderDay}
              modifiers={modifiers}
              modifiersStyles={modifiersStyles}
              fromMonth={new Date()}
              locale="ru"
              localeUtils={localizationUtils}
            />
            <Legend>
              <Box>
                <View />
                <div className="description">Доступно для аренды</div>
              </Box>
              <Box>
                <View color="#0072f0" backgroundColor="rgba(0, 115, 249, 0.06)" />
                <div className="description">Забронировано</div>
              </Box>
              <Box>
                <View color="#f53b45" backgroundColor="rgba(255, 21, 59, 0.05)" />
                <div className="description">Недоступно для аренды</div>
              </Box>
              <Box>
                <View color="#fdcb07" backgroundColor="rgba(253, 203, 7 0.06)" />
                <div className="description">Находиться в аренде</div>
              </Box>
            </Legend>
          </Calendar>
        </>
      )}
    </Container>
  );
};
