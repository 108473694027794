import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const DeviceWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: calc(100% - 30px);
      margin: 3px 0 0 30px;
    }

    .input-wrapper {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 13px;
      }
    }

    .input {
      width: 100%;
    }
  }

  @media ${breakpoints.tablet} {
    .input {
      width: 310px;
    }
  }
`;
