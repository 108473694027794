import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { UPLOADER_URL, UPLOADER_LINK_URL } from '../../constants/endpoints';
import { httpVerbs } from '../../constants/http';
import { uploaderActions } from '../../redux/actions';
import { makeCall } from '../../services/api-service';

import { UploaderWrapper } from './styles';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

const { GET, POST } = httpVerbs;
const { uploadFile, deleteFile } = uploaderActions;

const customCloseIconMarkup = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M13.4141,12 L17.7071,7.707 C18.0981,7.316 18.0981,6.684 17.7071,6.293 C17.3161,5.902 16.6841,5.902 16.2931,6.293 L12.0001,10.586 L7.7071,6.293 C7.3161,5.902 6.6841,5.902 6.2931,6.293 C5.9021,6.684 5.9021,7.316 6.2931,7.707 L10.5861,12 L6.2931,16.293 C5.9021,16.684 5.9021,17.316 6.2931,17.707 C6.4881,17.902 6.7441,18 7.0001,18 C7.2561,18 7.5121,17.902 7.7071,17.707 L12.0001,13.414 L16.2931,17.707 C16.4881,17.902 16.7441,18 17.0001,18 C17.2561,18 17.5121,17.902 17.7071,17.707 C18.0981,17.316 18.0981,16.684 17.7071,16.293 L13.4141,12 Z" id="path-2"></path>
    </defs>
    <g id="Asset-Artboard-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="heart-close">
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-2"></use>
            </mask>
            <use id="🎨-Icon-color" fill="#222B45" xlink:href="#path-2"></use>
        </g>
    </g>
</svg>`;

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
);

const UploaderAction = `<div class="upload-action">
    <svg class="icon"><path d="M10,14 C11.656,14 13,12.656 13,11 C13,9.344 11.656,8 10,8 C8.344,8 7,9.344 7,11 C7,12.656 8.344,14 10,14 M30,32 L7.122,32 L21.132,20.31 C21.624,19.892 22.516,19.894 22.998,20.308 L32,27.988 L32,30 C32,31.104 31.104,32 30,32 M6,4 L30,4 C31.104,4 32,4.896 32,6 L32,22.728 L25.594,17.264 C23.614,15.58 20.516,15.58 18.554,17.252 L4,29.396 L4,6 C4,4.896 4.896,4 6,4 M30,0 L6,0 C2.692,0 0,2.692 0,6 L0,30 C0,33.308 2.692,36 6,36 L30,36 C33.308,36 36,33.308 36,30 L36,6 C36,2.692 33.308,0 30,0" id="path-1"></path></svg>
    <div class="box">
      <span class="title -plus">+</span>
      <span class="title">Загрузите фото</span>
    </div>
  </div>`;

export const Uploader = ({ maxFiles, name, private: privateAccess }) => {
  const dispatch = useDispatch();

  return (
    <UploaderWrapper>
      <FilePond
        className="uploader"
        allowMultiple
        acceptedFileTypes={['image/png', 'image/jpeg']}
        allowFileSizeValidation
        allowImagePreview
        imagePreviewHeight={300}
        maxFileSize="6MB"
        iconRemove={customCloseIconMarkup}
        maxFiles={maxFiles}
        onwarning={() => alert(`Вы превысили лимит(${maxFiles}) загружаемых изображений`)}
        labelIdle={UploaderAction}
        labelTapToUndo=""
        labelTapToCancel=""
        labelFileProcessingComplete=""
        labelFileProcessing=""
        labelFileLoading=""
        server={{
          process: async (fieldName, file, metadata, load, error) => {
            const access = privateAccess ? 'private' : 'public';
            const isRightExt = file.name.match(/.jpg|jpeg|png/);
            try {
              if (!isRightExt) {
                error('Неверное расширение файла');
              }
              const ext = isRightExt[0].split('.').join('');
              const { data: uploadMeta } = await makeCall(
                GET,
                `${UPLOADER_LINK_URL}?objectExtension=${ext}&access=${access}`,
              );
              const formData = new FormData();
              formData.append('acl', 'private');
              Object.keys(uploadMeta.fields).forEach(key => {
                formData.append(key, uploadMeta.fields[key]);
              });
              formData.append('file', file, file.name);

              await makeCall(POST, uploadMeta.url, formData);
              dispatch(uploadFile(name, uploadMeta.fields.Key, uploadMeta.url));
              load(uploadMeta.fields.Key);
            } catch (err) {
              error(err);
            }
          },

          revert: (uniqueFileId, load) => {
            dispatch(deleteFile(name, uniqueFileId));
            load();
          },
        }}
      />
    </UploaderWrapper>
  );
};
