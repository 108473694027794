import React from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import { InsurancePlan } from '../InsurancePlan';

import { TabTitle, TabRecomendation } from '../featureStyles';
import { InsuranceWrapper } from './styles';

// TODO remove it after BE integration
const plans = [
  {
    id: 1,
    name: 'Минимальный',
    taxPercent: 80,
    features: [
      'Страхование ответственности ДСАГО на 500 тыс. руб',
      'Защита от физических повреждений и угона без франшизы',
    ],
  },
  {
    id: 2,
    name: 'Стандартный',
    taxPercent: 70,
    features: [
      'Страхование ответственности ДСАГО на 1 млн. руб',
      'Защита от физических повреждений и угона без франшизы',
      'Компенсация потерянных доходов при ремонте',
    ],
  },
  {
    id: 3,
    name: 'Максимальный',
    taxPercent: 65,
    features: [
      'Страхование ответственности ДСАГО на 1 млн. руб',
      'Защита от физических повреждений и угона без франшизы',
      'Компенсация потерянных доходов при ремонте',
      'Оплата штрафов ГИБДД',
    ],
  },
];

export const TabInsurance = () => {
  const { insurancePlans } = useSelector(({ carEditData: { carData } }) => carData);
  return (
    <InsuranceWrapper>
      <TabTitle className="title">Выберите тариф страхования</TabTitle>
      <TabRecomendation className="recomendation">
        Ознакомьтесь с информацией о{' '}
        <span className="subtitle-link"> как правильно составить описание</span>
      </TabRecomendation>
      <FormGroup className="formgroup">
        {plans.map(({ id, name, taxPercent, features }) => (
          <InsurancePlan
            key={`${id}-plan`}
            id={id}
            taxPercent={taxPercent}
            features={features}
            name={name}
          />
        ))}
      </FormGroup>
    </InsuranceWrapper>
  );
};
