import React, { useState, useEffect } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import debounce from 'lodash.debounce';
import { Map } from '../../components/Map/Map';
import { MapContext } from '../../components/Map/MapContext';

import MapMarker from '../../components/Map/MapMarker';

const isReactNative = !!window.ReactNativeWebView;
const center = [37.613345, 55.751143];

const handleEvent = type => e => {
  if (isReactNative) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type,
        data: e,
      }),
    );
  }
};
const initialData = [
  {
    uuid: '944a0b0f-4260-41a5-bf49-09e0315efeac',
    brand: 'Chrysler',
    model: '300 Letter Series',
    productionYear: 2008,
    photoUrl:
      'http://172.31.0.2:9000/getarent-service-bucket/cars/944a0b0f-4260-41a5-bf49-09e0315efeac/c3d81aad-1c31-44a7-91d1-0f4445b47611.jpg',
    rentPricePerDay: 600,
    homeLocation: {
      lon: 30.410237,
      lat: 50.4588035,
    },
    tags: [],
  },
];

export default function MapPage() {
  const [mapInstance, setMapInstance] = useState();
  const [data, setData] = useState(initialData);
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    if (!isReactNative) return;

    window.setData = setData;
    window.setCenter = (...args) => mapInstance?.map?.setCenter(...args);
    window.onerror = handleEvent('error');
    window.setActiveMarker = setActiveMarker;
  }, [mapInstance, setData]);

  if (data) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
        }}
      >
        <MapContext.Provider value={[mapInstance, setMapInstance]}>
          <Map
            disableRotationByUserInteraction
            zoomControl={false}
            center={center}
            setBounds={debounce(handleEvent('bounds'), 100)}
            onMoveStart={handleEvent('movestart')}
          >
            {data.map((car, i) => (
              <MapMarker
                onClick={handleEvent('click')}
                key={car.uuid}
                car={car}
                isLabel={true}
                index={i + 1}
                active={activeMarker === car.uuid}
              />
            ))}
          </Map>
        </MapContext.Provider>
      </div>
    );
  }

  return <Skeleton animation="wave" variant="rect" width="100%" height="100%" />;
}
