import { handleActions, combineActions } from 'redux-actions';
import { authActions } from '../../actions';

const {
  showLoginPopup,
  showSignupPopup,
  showConfirmEmailPopup,
  showConfirmPhonePopup,
  closeAuthPopup,
} = authActions;

const initialState = {
  popupOption: '',
};

export const authPopup = handleActions(
  {
    [combineActions(
      showLoginPopup,
      showSignupPopup,
      showConfirmEmailPopup,
      showConfirmPhonePopup,
      closeAuthPopup,
    )](state, { type }) {
      return { popupOption: type === 'CLOSE_AUTH_POPUP' ? '' : type };
    },
  },
  initialState,
);
