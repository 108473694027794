import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinkButton from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import { desktopNavigationSchema } from '../navigationSchema';
import { checkHost } from '../../../../services/auth-service';
import { signOutUser, showLoginPopup, showSignupPopup } from '../../../../redux/actions/auth';
import { checkAuthPath } from '../../../../utils/checkAuthPath';
import { DesktopNavWrapper, NavigationMenu, UserPanel, AuthorizationWrap } from './styles';

// TODO refactor it
export const DesktopNav = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorNavigation, setAnchorNavigation] = useState(null);
  const [anchorUserMenu, setAnchorUserMenu] = useState(null);
  const isLoggedIn = useSelector(({ auth: { isLoggedIn } }) => isLoggedIn);
  const isAuthPath = checkAuthPath(history);

  const openNavigation = useCallback(event => {
    setAnchorNavigation(event.currentTarget);
  }, []);
  const closeNavigation = useCallback(() => {
    setAnchorNavigation(null);
  }, []);
  const openUserMenu = useCallback(event => {
    setAnchorUserMenu(event.currentTarget);
  }, []);
  const closeUserMenu = useCallback(() => {
    setAnchorUserMenu(null);
  }, []);
  const signOutClick = useCallback(() => {
    dispatch(signOutUser({ history }));
    closeUserMenu();
  }, [dispatch, history, closeUserMenu]);
  const loginClick = useCallback(() => history.push('/login'), [history]);

  return (
    <div>
      <DesktopNavWrapper>
        {isLoggedIn && (
          <UserPanel>
            <IconButton
              color="inherit"
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={openUserMenu}
              className="icon-wrap"
            >
              <PersonIcon className="menu-icon" />
            </IconButton>
            <Menu
              id="user-menu"
              keepMounted
              disablePortal
              marginThreshold={0}
              anchorEl={anchorUserMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorUserMenu)}
              classes={{ paper: 'menu-paper' }}
              onClose={closeUserMenu}
            >
              {desktopNavigationSchema.map(({ title, key, path }) => (
                <a href={path} key={key} className="link -item" underline="none">
                  <MenuItem onClick={closeNavigation} className="item">
                    {title}
                  </MenuItem>
                </a>
              ))}
              <MenuItem className="link -logout -item" onClick={signOutClick}>
                Выйти
              </MenuItem>
            </Menu>
          </UserPanel>
        )}
        {!isLoggedIn && !isAuthPath && (
          <AuthorizationWrap>
            <LinkButton
              color="primary"
              onClick={loginClick}
              className="link -login"
              underline="none"
            >
              Вход
            </LinkButton>
          </AuthorizationWrap>
        )}
      </DesktopNavWrapper>
    </div>
  );
};
