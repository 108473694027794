import React from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { AdivceWrapper } from './styles';

export const Advice = ({ advice, styleModificator }) => (
  <AdivceWrapper className={styleModificator}>
    <InfoOutlined className="icon" />
    <p className="text">{advice}</p>
  </AdivceWrapper>
);
