import { handleActions } from 'redux-actions';

import { userActions } from '../../actions';

const {
  postScoringData,
  postScoringDataSuccess,
  postScoringDataFailure,
  getScoringResults,
  getScoringResultsSuccess,
  getScoringResultsFailure,
  scoringRefresh,
  scoringRefreshSuccess,
  scoringRefreshFailure,
} = userActions;

const initialState = { data: {}, loading: true, error: null };
export default handleActions(
  {
    [postScoringData](state) {
      return { ...state, loading: true };
    },

    [postScoringDataSuccess](state) {
      return { ...state, loading: false };
    },

    [postScoringDataFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, error, loading: false };
    },
    [getScoringResults](state) {
      return { ...state, loading: true };
    },

    [getScoringResultsSuccess](
      state,
      {
        payload: { data },
      },
    ) {
      return { ...state, data, loading: false };
    },

    [getScoringResultsFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, error, loading: false };
    },
    [scoringRefresh](state) {
      return { ...state, loading: true };
    },

    [scoringRefreshSuccess](state) {
      return { ...state, loading: false };
    },

    [scoringRefreshFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, error, loading: false };
    },
  },
  initialState,
);
