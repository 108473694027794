import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const Sidebar = styled.div`
  @media ${breakpoints.mobile} {
    & {
      height: 100%;
    }

    .sub-title {
      color: var(--primary-text);
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .title {
      margin-top: 28px;
      padding-right: 80px;
    }

    .text {
      margin: 8px 0 0 0;
    }

    .default-price {
      margin-top: 30px;
      font-size: 20px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .text-link {
      text-decoration: none;
      color: #066bd6;
      border-bottom: dotted;
      border-width: thin;
    }

    .icon {
      position: relative;
      left: 200px;
      top: -35px;
      font-size: 24px;
      color: #c4c4c4;

      &:hover {
        color: #066bd6;
      }

      &:last-child {
        left: 20px;
      }
    }

    .time {
      font-family: 'Open Sans';
      color: var(--primary-text);
      font-size: 16px;
      font-weight: 600;
    }

    .formgroup {
      margin-top: 40px;
    }

    .booking-checkbox {
      margin-top: 18px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      width: 360px;
      box-sizing: border-box;
      padding-right: 24px;
    }

    .title {
      margin-top: 27px;
      padding-right: 0;
    }

    .text {
      margin: 26px 0 0 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 440px;
    }

    .title {
      margin-top: 40px;
    }

    .text {
      margin: 8px 0 0 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: 530px;
    }
  }
`;

export const CustomButton = styled.button`
  & {
    margin: 21px 0 32px;
    outline: none;
    border: none;
    background-color: #fff;
    color: #066bd6;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #066bd6;
    padding: 15px;
    border-radius: 4px;
  }
`;

export const Period = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Date = styled.div`
  & {
    color: var(--primary-text);
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Icons = styled.div``;
