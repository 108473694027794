import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext, Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { MaskedInput } from '../../MaskedInput';
import { resetUserUpdateErrors } from '../../../redux/actions/user';
import { dateMask, passportMask } from '../../../constants/masks';

import { Error } from '../../../styledComponents';
import { Subtitle, CustomFormGroup } from '../styles';

export const PassportForm = () => {
  const dispatch = useDispatch();
  const { error } = useSelector(({ userUpdate }) => userUpdate);
  // TODO: create util for checking errors
  const {
    setFieldValue,
    setFieldError,
    handleBlur,
    values,
    errors: { passport: fieldsError },
    touched: { passport: fieldsTouched },
  } = useFormikContext();
  return (
    <CustomFormGroup>
      <Subtitle>Паспорт</Subtitle>
      <InputLabel className="label" htmlFor="serialNumber">
        Серия и номер
      </InputLabel>
      <Field
        id="serialNumber"
        className={
          fieldsError && fieldsError.serialNumber && fieldsTouched.serialNumber
            ? 'input error'
            : 'input'
        }
        placeholder="Введите серию и номер"
        mask={passportMask}
        component={MaskedInput}
        type="text"
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetUserUpdateErrors());
          }
          if (fieldsError && fieldsError.serialNumber) {
            setFieldError('passport.serialNumber');
          }
          setFieldValue('passport', { ...values.passport, serialNumber: value });
        }}
        onBlur={handleBlur}
      />
      {fieldsError
        ? fieldsError.serialNumber &&
          fieldsTouched.serialNumber && <Error>{fieldsError.serialNumber}</Error>
        : null}
      <InputLabel className="label" htmlFor="dateOfIssue">
        Дата выдачи
      </InputLabel>
      <Field
        id="dateOfIssue"
        className={
          fieldsError && fieldsError.dateOfIssue && fieldsTouched.dateOfIssue
            ? 'input error'
            : 'input'
        }
        placeholder="дд.мм.гггг"
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetUserUpdateErrors());
          }
          if (fieldsError && fieldsError.dateOfIssue) {
            setFieldError('passport.dateOfIssue');
          }
          setFieldValue('passport', { ...values.passport, dateOfIssue: value });
        }}
        onBlur={handleBlur}
        component={MaskedInput}
        mask={dateMask}
        type="text"
      />
      {fieldsError
        ? fieldsError.dateOfIssue &&
          fieldsTouched.dateOfIssue && <Error>{fieldsError.dateOfIssue}</Error>
        : null}
      <InputLabel className="label" htmlFor="registrationAddress">
        Адрес регистрации
      </InputLabel>
      <Field
        id="registrationAddress"
        className={
          fieldsError && fieldsError.registrationAddress && fieldsTouched.registrationAddress
            ? 'input error'
            : 'input'
        }
        placeholder="Введите адрес регистрации"
        component={Input}
        type="text"
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetUserUpdateErrors());
          }
          if (fieldsError && fieldsError.registrationAddress) {
            setFieldError('passport.registrationAddress');
          }
          setFieldValue('passport', { ...values.passport, registrationAddress: value });
        }}
        onBlur={handleBlur}
      />
      {fieldsError
        ? fieldsError.registrationAddress &&
          fieldsTouched.registrationAddress && <Error>{fieldsError.registrationAddress}</Error>
        : null}
    </CustomFormGroup>
  );
};
