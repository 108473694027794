export default {
  scoring: {
    POST_SCORING_DATA: 'POST_SCORING_DATA',
    POST_SCORING_DATA_SUCCESS: 'POST_SCORING_DATA_SUCCESS',
    POST_SCORING_DATA_FAILURE: 'POST_SCORING_DATA_FAILURE,',

    GET_SCORING_RESULTS: 'GET_SCORING_RESULTS',
    GET_SCORING_RESULTS_SUCCESS: 'GET_SCORING_RESULTS_SUCCESS',
    GET_SCORING_RESULTS_FAILURE: 'GET_SCORING_RESULTS_FAILURE',

    SCORING_REFRESH: 'SCORING_REFRESH',
    SCORING_REFRESH_SUCCESS: 'SCORING_REFRESH_SUCCESS',
    SCORING_REFRESH_FAILURE: 'SCORING_REFRESH_FAILURE',
  },
  update: {
    UPDATE_USER_DATA: 'UPDATE_USER_DATA',
    UPDATE_USER_DATA_SUCCESS: 'UPDATE_USER_DATA_SUCCESS',
    UPDATE_USER_DATA_ERROR: 'UPDATE_USER_DATA_ERROR',
    RESET_USER_UPDATE_ERRORS: 'RESET_USER_UPDATE_ERRORS',
  },
  confirmation: {
    CHECK_USER_DATA_COMPLETENESS: 'CHECK_USER_DATA_COMPLETENESS',
    CHECK_USER_DATA_COMPLETENESS_SUCCESS: 'CHECK_USER_DATA_COMPLETENESS_SUCCESS',
    CHECK_USER_DATA_COMPLETENESS_ERROR: 'CHECK_USER_DATA_COMPLETENESS_ERROR',
    SUBMIT_USER_EMAIL: 'SUBMIT_USER_EMAIL',
    SUBMIT_USER_EMAIL_SUCCESS: 'SUBMIT_USER_EMAIL_SUCCESS',
    SUBMIT_USER_EMAIL_ERROR: 'SUBMIT_USER_EMAIL_ERROR',
    SUBMIT_USER_PHONE: 'SUBMIT_USER_PHONE',
    SUBMIT_USER_PHONE_SUCCESS: 'SUBMIT_USER_PHONE_SUCCESS',
    SUBMIT_USER_PHONE_ERROR: 'SUBMIT_USER_PHONE_ERROR',
    SUBMIT_RECOVERY_EMAIL: 'SUBMIT_RECOVERY_EMAIL',
    SUBMIT_RECOVERY_EMAIL_SUCCESS: 'SUBMIT_RECOVERY_EMAIL_SUCCESS',
    SUBMIT_RECOVERY_EMAIL_ERROR: 'SUBMIT_RECOVERY_EMAIL_ERROR',
    CONFIRM_RECOVERY_EMAIL: 'CONFIRM_RECOVERY_EMAIL',
    CONFIRM_RECOVERY_EMAIL_SUCCESS: 'CONFIRM_RECOVERY_EMAIL_SUCCESS',
    CONFIRM_RECOVERY_EMAIL_ERROR: 'CONFIRM_RECOVERY_EMAIL_ERROR',
    CONFIRM_USER_EMAIL: 'CONFIRM_USER_EMAIL',
    CONFIRM_USER_EMAIL_SUCCESS: 'CONFIRM_USER_EMAIL_SUCCESS',
    CONFIRM_USER_EMAIL_ERROR: 'CONFIRM_USER_EMAIL_ERROR',
    CONFIRM_USER_PHONE: 'CONFIRM_USER_PHONE',
    CONFIRM_USER_PHONE_SUCCESS: 'CONFIRM_USER_PHONE_SUCCESS',
    CONFIRM_USER_PHONE_ERROR: 'CONFIRM_USER_PHONE_ERROR',
    RESET_CONFIRMATION: 'RESET_CONFIRMATION',
    RESET_CONFIRMATION_ERROR: 'RESET_CONFIRMATION_ERROR',
  },
};
