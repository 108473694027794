import { styled } from 'linaria/react';
import mapImg from '../../assets/pictures/mapExample.JPG';
import { breakpoints } from '../../constants/breakpoints';

export const StyledSearchContainer = styled.div`
  @media ${breakpoints.mobile} {
    & {
      padding: 25px 15px 0;
      width: 100%;
      box-sizing: border-box;
    }

    .search-offers {
      position: relative;
      width: 100%;
      padding: 0;
      border-bottom: 1px solid #dadce0;
      padding: 60px 0 0;
    }

    .search-offers .caption {
      color: var(--primary-text);
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      margin: 0;
    }

    .search-offers .subcaption {
      color: var(--grey-text);

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 0 0 5px;
    }

    .search-offers .wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      bottom: 0;
      padding: 10px 0;
    }

    .wrap .btn.-disabled {
      display: none;
    }

    .wrap .btn .icon {
      height: 16px;
      width: 16px;
      color: #8f9bb3;
      margin-right: 5px;
    }

    .wrap .btn > .text {
      color: var(--primary-text);

      font-size: 16px;
      line-height: 24px;
      margin: 0 20px 0 0;
    }

    .search-nav {
      display: none;
      justify-content: center;
      height: 32px;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #dadce0;
    }

    .search-nav .MuiTypography-body1 {
    }

    .search-nav > .btn {
      height: 32px;
      margin-right: 15px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 0;
    }

    .search-nav .tab-btn {
      height: 100%;
      font-size: 16px;
      border-radius: 16px;
      padding: 0 10px;

      &.-opened {
        border-color: #066bd6;
      }

      &.-active {
        color: #fff;
        background-color: #066bd6;
        border: 1px solid #066bd6;
      }
    }

    .box > .label {
      color: var(--primary-text);
    }

    .search-results-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 100px;
    }

    .column {
      width: 100%;
    }

    .row {
      width: 100%;
    }

    .infinite-scroll-component {
      display: grid;
      grid-template-rows: 1fr;
      grid-gap: 25px;
      padding: 25px 0 0;
    }

    .car-card {
      display: block;
      width: 100%;
    }

    .skeleton-img {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      border-radius: 8px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .skeleton-text {
      margin-top: 6px;
    }

    .car-card > .wrap {
      display: flex;
      justify-content: space-between;

      &.-img {
        position: relative;
        height: 0;
        padding-top: 50%;
      }
    }

    .tags {
      margin-top: 20px;
    }

    .tag {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #303133;
      padding: 4px 8px;
      border-radius: 12px;
      border: 1px solid rgb(6, 187, 155, 0.6);
      margin-right: 5px;
    }

    .wrap.-img > .box {
      position: absolute;
      bottom: 15px;
      right: 15px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 0 8px 0 rgba(83, 92, 115, 0.2);
    }

    .wrap.-img .box > .price {
      color: var(--dark-text);

      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
    }

    .box .price > .label {
      color: #333333;

      font-size: 12px;
      line-height: 14px;
    }

    .wrap.-model {
      margin: 10px 0 5px;
    }

    .wrap.-model > .subtitle {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #222222;
    }

    .wrap.-model > .box {
      display: flex;
      align-items: center;
    }

    .wrap.-stat {
      margin: 0;
    }

    .wrap.-stat > .caption {
      color: var(--grey-text);

      font-size: 14px;
      line-height: 16px;
    }

    .wrap.-model > .box > .label {
      color: var(--primary-text);

      font-size: 14px;
      line-height: 12px;
      margin-right: 5px;
      margin-top: 0;
    }

    .wrap.-model > .box > .icon {
      height: 13px;
      width: 14px;
      color: #ffaa01;
    }

    .car-card:nth-child(2n) {
      margin-left: 0;
    }

    .no-results {
      display: block;
      width: 65%;
      margin: 70px auto;
      text-align: center;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 25px 25px 0;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
    }

    .search-offers {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 145px 0 0;
      border-bottom: 1px solid #dadce0;
    }

    .search-offers > .caption {
      color: var(--primary-text);

      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      margin: 0;
    }

    .search-offers > .subcaption {
      color: var(--grey-text);

      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .search-offers > .wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      bottom: 10px;
      padding: 10px 0 0;
    }

    .search-nav {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 32px;
      padding: 25px 0 20px 25px;
      border-bottom: 1px solid #dadce0;
      position: fixed;
      top: 68px;
      left: 0;
      background: #fff;
      z-index: 1;
    }

    .switch-button {
      display: none;
    }

    .search-nav .MuiTypography-body1 {
    }

    .search-nav > .btn {
      height: 32px;
      margin-right: 15px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 0;
    }

    .search-nav .tab-btn {
      height: 100%;
      font-size: 16px;
      border-radius: 16px;
      padding: 0 10px;

      &.-opened {
        border-color: #066bd6;
      }

      &.-active {
        color: #fff;
        background-color: #066bd6;
        border: 1px solid #066bd6;
      }
    }

    .search-nav .filter-arrow {
      position: relative;
      left: 5px;
      width: 16px;
      height: 16px;
    }

    .search-nav .filter-arrow.-opened {
      transform: rotate(180deg);
    }

    .search-results-wrapper {
      display: inline-flex;
    }

    .column .infinite-scroll-component {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 25px 25px;
      width: 100%;
      padding: 30px 0 0;
    }

    .wrap.-model > .subtitle {
      margin: 0;
      font-size: 20px;
    }

    .wrap.-model > .box {
      display: block;
      align-items: center;
    }

    .wrap.-stat > .caption {
      color: var(--grey-text);
      font-size: 14px;
      line-height: 16px;
    }

    .wrap.-model > .box > .label {
      color: var(--primary-text);
      font-size: 14px;
      line-height: 12px;
      margin-right: 5px;
    }

    .wrap.-model > .box > .icon {
      height: 13px;
      width: 14px;
      color: #ffaa01;
    }

    .car-card .wrap {
      display: flex;
      justify-content: space-between;

      &.-img {
        position: relative;
      }
    }

    .wrap.-img > .box {
      position: absolute;
      bottom: 15px;
      right: 15px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 0 8px 0 rgba(83, 92, 115, 0.2);
    }

    .box > .price {
      color: var(--dark-text);

      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
    }

    .box > .label {
      color: #333333;
      font-size: 12px;
      line-height: 14px;
    }

    .-map {
      display: none;
      position: relative;
      bottom: 105px;
    }

    .map {
      background-image: url(${mapImg});
      width: 330px;
      height: 815px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: ${props => (props.mapexists ? '20px 0 0 25px' : '20px 25px 0')};
      justify-content: center;
      align-items: center;
    }

    .search-offers {
      width: ${props => (props.mapexists ? '67.5%' : '100%')};
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      padding: 155px 0 0;
      border-bottom: 1px solid #dadce0;
    }

    .search-offers > .caption {
      color: var(--primary-text);
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin: 0 0 10px;
    }

    .search-offers > .subcaption {
      color: var(--grey-text);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin: 0 0 5px;
    }

    .search-offers > .wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .search-offers > .wrap > .btn {
      align-items: center;
      margin-right: 30px;

      &:hover {
        text-decoration: none;
      }
    }

    .wrap > .btn > .icon {
      height: 16px;
      width: 16px;
      color: #8f9bb3;
      margin-right: 5px;
    }

    .wrap > .btn > .text {
      color: var(--primary-text);
      font-size: 16px;
      line-height: 24px;
    }

    .search-nav {
      display: flex;
      width: 100%;
      height: 32px;
      padding: 25px 0 20px 30px;
      justify-content: flex-start;
      border-bottom: 1px solid #dadce0;
    }

    .switch-button {
      display: block;
      margin: 0 60px 0 auto;
    }

    .search-nav > .btn {
      height: 32px;
      margin-right: 15px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 0;
    }

    .search-nav .tab-btn {
      height: 100%;
      font-size: 16px;
      border-radius: 16px;

      &.-opened {
        border-color: #066bd6;
      }

      &.-active {
        color: #fff;
        background-color: #066bd6;
        border: 1px solid #066bd6;
      }
    }

    .search-results-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .search-results-wrapper > .row:not(.-map) {
      display: flex;
      width: ${props => (props.mapexists ? '70%' : '100%')};
    }

    .row > .column {
      margin: ${props => (props.mapexists ? '0 25px 0 0' : '0')};
    }

    .column .infinite-scroll-component {
      display: grid;
      grid-template-columns: ${props => (props.mapexists ? '1fr 1fr' : '1fr 1fr 1fr')};
      grid-template-rows: 1fr;
      grid-gap: 25px 25px;
      padding: 30px 0 0;
      width: 100%;
    }

    .car-card > .wrap {
      display: flex;
      justify-content: space-between;
    }

    .search-results-wrapper .-map {
      display: block;
      position: fixed;
      width: 30%;
      height: calc(100vh - 145px);
      right: 0;
      top: 145px;
    }

    .wrap.-model > .subtitle {
      margin: 0;
      font-size: 20px;
    }

    .wrap.-model > .box {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    .wrap.-stat > .caption {
      color: var(--grey-text);

      font-size: 14px;
      line-height: 16px;
    }

    .wrap.-model > .box > .label {
      color: var(--primary-text);

      font-size: 14px;
      line-height: 12px;
      margin-right: 5px;
      margin-top: 0;
    }

    .wrap.-model > .box > .icon {
      height: 13px;
      width: 14px;
      color: #ffaa01;
    }

    .car-card .wrap.-img {
      position: relative;
      height: 0;
      border: none;
      padding-top: 50%;
    }

    .wrap.-img > img > .box {
      position: absolute;
      bottom: 15px;
      right: 15px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 0 8px 0 rgba(83, 92, 115, 0.2);
    }

    .box > .price {
      color: var(--dark-text);
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
    }

    .box > .price > .label {
      color: #333333;

      font-size: 12px;
      line-height: 14px;
    }

    .map {
      background-image: url(${mapImg});
      width: 100%;
      height: 86.5vh;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      position: relative;
      padding: ${props => (props.mapexists ? '0 50px 20px 50px' : '0 50px 20px')};
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
    }

    .search-offers {
      width: ${props => (props.mapexists ? '66%' : '100%')};
      padding: 175px 0 0;
      border-bottom: 1px solid #dadce0;
    }

    .search-offers > .caption {
      color: var(--primary-text);

      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin: 0 0 10px;
    }

    .search-offers > .subcaption {
      color: var(--grey-text);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin: 0 0 5px;
    }

    .search-offers > .wrap {
      display: flex;
      position: relative;
      padding: 0;
    }

    .search-offers > .wrap > .btn {
      display: flex;
      align-items: center;
      margin-right: 30px;

      &:hover {
        text-decoration: none;
      }
    }

    .wrap > .btn > .icon {
      height: 16px;
      width: 16px;
      color: #8f9bb3;
      margin-right: 5px;
    }

    .wrap > .btn > .text {
      color: var(--primary-text);
      font-size: 16px;
      line-height: 24px;
    }

    .search-nav-container {
      position: fixed;
      width: 100vw;
      left: 0;
      height: 70px;
      top: 70px;
      background: #fff;
      z-index: 1;
    }

    .search-nav {
      display: flex;
      width: 100%;
      max-width: 1440px;
      height: 32px;
      padding: 20px 0 20px 0;
      margin-left: 0;
      justify-content: flex-start;
      border-bottom: none;
      top: 69px;
      left: calc(50% + 50px);
      transform: translatex(-50%);

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100vw;
        bottom: 0;
        left: calc(50% - 50px);
        transform: translate(-50vw);
        background: #dadce0;
      }
    }

    .search-nav > .switch-button {
      margin: 0 90px 0 auto;
    }

    .search-nav > .btn {
      height: 32px;
      margin-right: 15px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 0;
    }

    .search-nav .tab-btn {
      height: 100%;
      font-size: 16px;
      border-radius: 16px;

      &.-opened {
        border-color: #066bd6;
      }

      &.-active {
        color: #fff;
        background-color: #066bd6;
        border: 1px solid #066bd6;
      }
    }

    .search-results-wrapper .row.-map {
      display: block;
      position: fixed;
      width: 450px;
      height: 100%;
      top: 142px;
      right: 50vw;
      transform: translateX(710px);
    }

    .row > .column {
      margin: ${props => (props.mapexists ? '0 55px 0 0' : '0')};
    }

    .column .infinite-scroll-component {
      grid-gap: 25px 35px;
    }

    .car-card > .wrap.-img {
      position: relative;
    }

    .wrap.-img > .box {
      position: absolute;
      bottom: 15px;
      right: 15px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 0 8px 0 rgba(83, 92, 115, 0.2);
    }

    .box > .price {
      color: var(--dark-text);
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
    }

    .box > .label {
      color: #333333;
      font-size: 12px;
      line-height: 14px;
    }

    .wrap.-model > .subtitle {
      margin: 0;
      font-size: 20px;
    }

    .wrap.-model > .box {
      display: block;
    }

    .wrap.-stat > .caption {
      color: var(--grey-text);

      font-size: 14px;
      line-height: 16px;
    }

    .wrap.-model > .box > .label {
      color: var(--primary-text);
      font-size: 14px;
      line-height: 12px;
      margin-right: 5px;
    }

    .wrap.-model > .box > .icon {
      height: 13px;
      width: 14px;
      color: #ffaa01;
    }

    .wrap > .btn.-disabled {
      display: none;
    }

    .car-card > .wrap {
      display: flex;
      justify-content: space-between;
    }

    .search-results-wrapper > .row:not(.-map) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .map {
      background-image: url(${mapImg});
      height: 87.5vh;
    }
  }

  @media ${breakpoints.desktop} {
    width: 1440px;
    margin: 0 auto;
  }
`;

export const Photo = styled.div`
  background-image: ${props => `url(${props.photo})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
