import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Link from '@material-ui/core/Link';
import FormGroup from '@material-ui/core/FormGroup';
import { CustomCheckbox } from '../../Checkbox';
import { Advice } from '../../Advice';
import { carIncludedFeatures, advice } from '../../../assets/data';
import { largeField } from '../../../constants/fieldLength';

import { TabFormControlLabel, TabTextArea, TabTitle, TabRecomendation } from '../featureStyles';
import { DescriptionWrapper } from './styles';

export const TabDescription = () => {
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <DescriptionWrapper>
      <FormGroup className="group">
        <TabTitle>Подробно опишите вашу машину</TabTitle>
        <TabRecomendation>
          Ознакомьтесь с рекомендациями{' '}
          <Link
            target="_blank"
            href="https://help.getarent.ru/knowledge-bases/9/articles/94-kak-sostavit-opisanie-mashinyi"
            className="subtitle-link"
          >
            как правильно составить описание
          </Link>
        </TabRecomendation>
        <TabTextArea
          multiline={true}
          rows={7}
          inputProps={{
            maxLength: largeField,
          }}
          placeholder="Например, напишите о вместительности машины и багажника, состоянии салона и расходе топлива"
          defaultValue={values.description}
          onBlur={({ target: { value } }) => setFieldValue('description', value)}
        />
      </FormGroup>
      <FormGroup className="group">
        <TabTitle>Что входит в комплектацию?</TabTitle>
        <div className="checkbox-holder">
          {Object.keys(carIncludedFeatures).map(feature => (
            <TabFormControlLabel
              control={
                <CustomCheckbox
                  checked={values[feature]}
                  name={feature}
                  onChange={({ target: { checked } }) => setFieldValue(feature, checked)}
                />
              }
              label={carIncludedFeatures[feature]}
              key={feature}
              className="checkbox"
            />
          ))}
        </div>
        <Advice advice={advice.forming} styleModificator="-line -borderless" />
      </FormGroup>
    </DescriptionWrapper>
  );
};
