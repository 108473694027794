import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

// TODO: create one shared component with PopupWrap from registration
export const ConfirmationDialog = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    height: 100%;
    max-width: 320px;
    padding: 36px 52px;
  }

  .empty-fields-wrap {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0;
    padding: 0;
  }

  .empty-field {
    display: flex;
    color: red;
    margin: 0 0 5px 0;
  }

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
  }

  .btn.-save {
    margin-right: 10px;
  }

  .inline-bold {
    font-weight: 600;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 14px;
    cursor: pointer;
  }
`;

export const EditContainer = styled.form`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      display: flex;
      box-sizing: border-box;
      color: #303133;
      margin: 75px auto 100px;
      padding: 0 16px;
      flex-wrap: wrap;
      width: 100%;
    }

    .name {
      width: 100%;
      position: relative;
    }

    > .wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 62px;
    }

    .MuiTabs-flexContainer {
      justify-content: flex-start;
    }

    .MuiCollapse-container {
      border-bottom: 1px solid #d8d8d8;
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0 0 0;
    }

    .car-box {
      display: flex;
      align-items: center;
    }

    .link {
      display: flex;
      align-items: center;
    }

    .car {
      color: #5d5d5d;
      font-size: 16px;
      line-height: 16px;
      margin-left: 4px;
    }

    .manage {
      display: flex;
    }

    .btn.-delete {
      height: 32px;
      width: 32px;
      min-width: 0;
      margin: 0 15px 0 auto;
      color: #5d5d5d;
      background: transparent;
      border: 1px solid #8d9bb5;
    }

    .btn.-save {
      height: 32px;
      font-size: 16px;
      font-weight: 400;
    }

    .icon.-back use {
      fill: #8f9bb3;
    }

    .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
      margin: 0 5px 0 0;
    }

    .wrap .tab-label {
      font-size: 16px;
      text-transform: none;
      margin-right: 32px;
      font-weight: 600;
      min-width: 85px;
    }

    .tabs-divider {
      width: 100%;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      margin: 0 auto 100px;
      padding: 0 24px 100px 24px;
      box-sizing: border-box;
    }

    .car-box {
      align-items: start;
      flex-direction: column;
    }

    .car {
      color: #303133;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      margin: 20px 0 0 0;
    }

    .btn.-save {
      height: 48px;
      font-weight: 600;
    }

    .btn.-delete {
      height: 48px;
      width: 48px;
    }

    .link-label {
      color: #5d5d5d;
      font-size: 14px;
      line-height: 16px;
      margin-left: 4px;
    }
  }

  @media ${breakpoints.laptop} {
    .manage {
      position: absolute;
      right: 25px;
      top: 75px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      max-width: 1440px;
    }
  }
`;
