import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const InsuranceWrapper = styled.div`
  @media ${breakpoints.mobile} {
    .title {
      margin: 20px 0 4px;
    }

    .recomendation {
      margin: 0 0 40px;
    }
  }
  @media ${breakpoints.tablet} {
    .formgroup {
      flex-direction: row;
      justify-content: space-between;
    }

    .title {
      margin: 40px 0 4px;
    }

    .recomendation {
      margin: 0 0 36px;
    }
  }

  @media ${breakpoints.laptop} {
    .title {
      margin: 36px 0 4px;
    }

    .recomendation {
      margin: 0 0 32px;
    }
  }
`;
