import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';

export const Form = styled.form`
  @media ${breakpoints.mobile} {
    & {
      max-width: 320px;
      padding: 106px 25px 54px;
    }

    .input {
      width: 100%;
    }

    .error {
      margin-bottom: 0;
    }

    .error-confirm {
      margin-bottom: 14px;
    }

    .error input {
      border: 1px solid red;
    }

    .checkbox-wrap {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin: 15px 0 0;
    }

    .checkbox {
      padding: 0;
      margin-right: 10px;
    }

    .inline-link {
      width: 100%;
    }

    .btn {
      width: 100%;
      margin: 31px 0 24px 0;
    }

    .link {
      color: #066bd6;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      margin-left: 8px;
      &:hover {
        color: #0258b3;
      }
    }

    .small-text {
      color: var(--primary-text);
      font-size: 12px;
    }

    .login {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }

    .eye {
      position: absolute;
      right: 11px;
    }

    .licence-label {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 36px 52px;
    }

    /* .licence-label {
      display: block;
      margin-top: 9px;
    } */

    /* .checkbox {
      display: flex;
      align-items: flex-start;
    } */
  }
`;

// TODO put in styled components
export const Error = styled.div`
  margin-top: 5px;
  color: red;
`;
