import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getUserInfo } from './services/auth-service';

export default ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const userInfo = getUserInfo();
      if (!userInfo) {
        return (
          <Redirect
            to={{
              pathname: '/registration',
              search: `?nextStep=${encodeURI(props.location.pathname)}`,
            }}
          />
        );
      }

      if (roles && roles.indexOf(userInfo.role) === -1) {
        window.location = '/';
        return null;
      }

      return <Component {...props} />;
    }}
  />
);
