import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { getUrls } from '../../utils/photos';
import { CarouselWrapper } from './styles';

export const Carousel = ({ photos }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = photos.length;

  const handleNext = () => {
    activeStep === maxSteps - 1
      ? setActiveStep(0)
      : setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    activeStep === 0
      ? setActiveStep(maxSteps - 1)
      : setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <CarouselWrapper>
      <img src={photos[activeStep]} alt="selected car" className="car" />
      <div className="photo-badge">
        {activeStep + 1} из {maxSteps}
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext}>
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>
        }
      />
    </CarouselWrapper>
  );
};
