export const authTypes = {
  SIGNIN: 'SIGNIN',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_ERROR: 'SIGNIN_ERROR',
  SIGNOUT: 'SIGNOUT',
  SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
  SIGNOUT_ERROR: 'SIGNOUT_ERROR',
  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  REFRESH_TOKENS: 'REFRESH_TOKENS',
  REFRESH_TOKENS_SUCCESS: 'REFRESH_TOKENS_SUCCESS',
  REFRESH_TOKENS_ERROR: 'REFRESH_TOKENS_ERROR',
  SET_INITIAL_USER: 'SET_INITIAL_USER',
  SIGNIN_GOOGLE: 'SIGNIN_GOOGLE',
  SIGNIN_FACEBOOK: 'SIGNIN_FACEBOOK',
  SIGNIN_YANDEX: 'SIGNIN_YANDEX',
  SIGNIN_VK: 'SIGNIN_VK',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  RESET_AUTH: 'RESET_AUTH',
  RESET_AUTH_ERROR: 'RESET_AUTH_ERROR',
};

export const authPopupOptions = {
  LOGIN_POPUP: 'LOGIN_POPUP',
  SIGNUP_POPUP: 'SIGNUP_POPUP',
  CONFIRM_EMAIL_POPUP: 'CONFIRM_EMAIL_POPUP',
  CONFIRM_PHONE_POPUP: 'CONFIRM_PHONE_POPUP',
  CLOSE_AUTH_POPUP: 'CLOSE_AUTH_POPUP',
};
