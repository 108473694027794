import { styled } from 'linaria/react';

export const Container = styled.div`
  .btn {
    margin-top: 15px;
  }

  .MuiButton-label {
    font-size: 12px;
  }
`;

export const Wrapper = styled.div`
  max-height: ${props => (props.isHide ? props.height : 'initial')};
  overflow: hidden;
`;
