import { handleActions } from 'redux-actions';
import { statuses } from '../../../constants/statuses';

import { carActions } from '../../actions';

const { PUBLISHED, STASHED } = statuses;

const {
  getCarList,
  getCarListSuccess,
  getCarListFailure,
  unpublishCarSuccess,
  publishCarSuccess,
} = carActions;

const initialState = { data: [], loading: true, error: null };
export default handleActions(
  {
    [getCarList](state) {
      return { ...state, loading: true };
    },

    [getCarListSuccess](
      state,
      {
        payload: { data },
      },
    ) {
      return { ...state, data, loading: false };
    },

    [getCarListFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, error, loading: false };
    },
    [publishCarSuccess](
      state,
      {
        payload: { uuid },
      },
    ) {
      const updatedData = state.data.map(car =>
        car.uuid === uuid ? { ...car, status: PUBLISHED } : car,
      );
      return { ...state, data: updatedData };
    },
    [unpublishCarSuccess](
      state,
      {
        payload: { uuid },
      },
    ) {
      const updatedData = state.data.map(car =>
        car.uuid === uuid ? { ...car, status: STASHED } : car,
      );
      return { ...state, data: updatedData };
    },
  },
  initialState,
);
