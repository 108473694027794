import { omit } from 'ramda';
import { carIncludedFeatures, carServices, carDevices } from '../../assets/data';
import parseNumberValue from '../../utils/parseNumberValue';

export const updateValues = values => {
  const includedFeatures = Object.keys(carIncludedFeatures).reduce((acc, feature) => {
    if (values[feature]) {
      return [...acc, feature];
    }
    return acc;
  }, []);

  const predefinedAdditionalFeatures = Object.keys(carDevices).reduce((acc, feature) => {
    if (values[feature].checked) {
      const currentFeature = {
        name: feature,
        dailyPrice: parseNumberValue(values[feature].dailyPrice),
        wholeRentPrice: parseNumberValue(values[feature].wholeRentPrice),
      };
      return [...acc, { ...currentFeature }];
    }
    return acc;
  }, []);

  const services = Object.keys(carServices).reduce((acc, service) => {
    if (values[service].checked) {
      return { ...acc, [service]: parseNumberValue(values[service].price) };
    }
    return { ...acc, [service]: null };
  }, {});

  const numericFields = {
    rentPricePerDay: parseNumberValue(values.rentPricePerDay),
    weekDiscount: parseNumberValue(values.weekDiscount),
    monthDiscount: parseNumberValue(values.monthDiscount),
    dailyMilageLimit: parseNumberValue(values.dailyMilageLimit),
    weeklyMilageLimit: parseNumberValue(values.weeklyMilageLimit),
    monthlyMilageLimit: parseNumberValue(values.monthlyMilageLimit),
    guestRequirements: {
      minAge: parseNumberValue(values.guestRequirements.minAge),
      yearsOfExperience: parseNumberValue(values.guestRequirements.yearsOfExperience),
    },
  };

  const updatedData = omit(
    [...Object.keys(carIncludedFeatures), ...Object.keys(carDevices), 'action'],
    values,
  );
  return {
    ...updatedData,
    includedFeatures,
    ...services,
    predefinedAdditionalFeatures,
    ...numericFields,
  };
};

const seconds = ':00.000Z';

export const updatedDates = dates => {
  if (!dates.length) {
    return [];
  }
  return dates.map(period => ({
    startDate: period.startDate.replace(seconds, ''),
    endDate: period.endDate.replace(seconds, ''),
  }));
};

export const checkRequiredFields = (values, photos, carPhotos) => {
  const isPrice = values.rentPricePerDay ? false : 'Укажите стоимость аренды в сутки';
  const isMileage = values.dailyMilageLimit ? false : 'Укажите ограничение по пробегу в день';
  const isPhoto = carPhotos.length + photos.length ? false : 'Загрузите хотя бы одну фотографию';
  const isSeatsQuantity = values.seatsQuantity ? false : 'Укажите количество мест в машине';
  const isEngineType = values.engineType ? false : 'Укажите тип двигателя';
  const isTransmissionLayout = values.transmissionLayout
    ? false
    : 'Укажите тип привода(передний, задний, полный)';
  const isBodyType = values.bodyType ? false : 'Укажите тип кузова(седан, универсал и тд)';
  const isTransmissionType = values.transmissionType ? false : 'Укажите тип коробки передач';

  return [
    isPrice,
    isMileage,
    isPhoto,
    isSeatsQuantity,
    isEngineType,
    isTransmissionLayout,
    isBodyType,
    isTransmissionType,
  ].filter(el => el);
};
