import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const Wrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-bottom: 40px;
      max-width: 558px;
    }

    .rate {
      margin: 0 8px 0 8px;
      fill: #f1a153;
    }

    .params {
      display: flex;
      align-items: center;
      margin-top: 14px;
    }

    .quantity {
      color: #323232;
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media ${breakpoints.tablet} {
    .params {
      margin-top: 22px;
    }
  }
`;

export const Title = styled.h3`
  & {
    color: var(--primary-text);
    font-size: 20px;
    font-weight: 600;
    margin: 15px 0 0 0;
  }
`;
