import { handleActions } from 'redux-actions';
import { carActions } from '../../actions';

const {
  getCarDataSuccess,
  getCarDataFailure,
  editCarData,
  postEditData,
  postEditDataSuccess,
  postEditDataFailure,
} = carActions;
// TODO fields naming
const initialState = {
  carData: {
    car: {
      // TODO how we can avoid initial value?
      unavailabilityDates: { booked: [], blocked: [], rent: [] },
    },
    insurancePlans: [],
  },
  uploadError: null,
  editError: null,
  getError: null,
  loading: true,
};

export default handleActions(
  {
    [editCarData](
      state,
      {
        payload: { data },
      },
    ) {
      return { ...state, carData: { ...state.carData, ...data } };
    },
    [getCarDataSuccess](
      state,
      {
        payload: { data },
      },
    ) {
      return { ...state, carData: data, loading: false };
    },
    [getCarDataFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, getError: error, loading: false };
    },
    [postEditData](state) {
      return { ...state, loading: true };
    },
    [postEditDataSuccess](state) {
      return { ...state, loading: false };
    },
    [postEditDataFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, editError: error, loading: false };
    },
  },
  initialState,
);
