import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const DescriptionWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .group {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .advice {
      display: flex;
      border: none;
      padding: 20px 0;
    }

    .checkbox-holder {
      display: flex;
      flex-direction: column;
      margin-top: 13px;
      width: 100%;
      flex-wrap: wrap;
    }

    .subtitle-link {
      cursor: pointer;
      color: #066bd6;
      border-bottom: dotted;
      text-decoration: none;
      border-width: thin;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      margin-bottom: 40px;
    }
  }

  @media ${breakpoints.desktop} {
    .checkbox-holder {
      flex-direction: row;
    }

    .checkbox {
      width: 30%;
    }
  }
`;
