import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';
import noPhotoImg from '../../assets/pictures/noPhoto.jpg';

export const CarMiniature = styled.div`
  @media ${breakpoints.mobile} {
    & {
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 4px 16px 0 rgba(72, 107, 125, 0.15);
      margin-bottom: 8px;
    }

    .description {
      padding: 16px 12px 30px 12px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      margin: 0 0 10px 0;
    }

    .name,
    .price {
      color: #303133;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-transform: capitalize;
    }

    .number {
      color: #5d5d5d;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 17px;
      margin: 8px 0 35px 0;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
    }

    .status-box {
      display: flex;
      position: absolute;
      bottom: 12px;
      left: 16px;
      padding: 6px 8px 5px 8px;
      opacity: 0.99;
      border-radius: 24px;
      background-color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(25, 115, 232, 0.05);
    }

    .status {
      color: #303133;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      text-align: center;
    }

    .photo-box {
      position: relative;
      height: 0;
      padding-top: 55%;
    }

    .icon {
      margin-right: 12px;
      cursor: pointer;
    }

    .switch {
      cursor: pointer;
      color: #333333;
      margin-left: 0;
      margin-right: 0;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .icon-box {
      display: flex;
    }

    @media ${breakpoints.tablet} {
      .photo-box {
        padding-top: 65%;
      }
    }
  }
`;

export const Photo = styled.div`
  & {
    position: relative;
    background-image: ${props => (props.photo ? `url(${props.photo})` : `url(${noPhotoImg})`)};
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const Marker = styled.div`
  @media ${breakpoints.mobile} {
    & {
      height: 12px;
      width: 12px;
      border-radius: 12px;
      margin-right: 5px;
      background-color: ${props => props.color};
    }
  }
`;
