import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';
import { CustomInputLabel } from '../../featureStyles';
import { ConfirmationCodeWrapper } from '../ConfirmationCode/styles';

export const EmailFormWrapper = styled.form`
  @media ${breakpoints.mobile} {
    & {
      max-width: 320px;
      /* padding: 106px 25px 54px; */
    }

    ${CustomInputLabel} {
      margin: 0 0 10px;
    }

    .text {
      margin: 15px 0 30px;
      color: var(--grey-text);
      font-size: 16px;
      line-height: 24px;
    }

    .input {
      width: 100%;
    }

    .btn {
      width: 191px;
      display: block;
      margin-top: 25px;
    }

    .link {
      color: #066bd6;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      margin-left: 8px;

      &:hover {
        color: #0258b3;
      }
    }

    .text-btn {
      color: #066bd6;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      text-transform: none;
      cursor: pointer;
      background: none;
      box-shadow: none;
      width: auto;
      height: auto;
      padding: 0;
      margin: 15px 0 0;
      padding-bottom: 1px;
      border-bottom: 1px dashed #066bd6;
      border-radius: 0;

      &:hover {
        background: none;
        box-shadow: none;
        text-decoration: none;
      }
    }
  }
`;

export const EmailWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      padding: 68px 16px 54px 16px;
    }

    ${ConfirmationCodeWrapper} {
      margin-top: 30px;
    }

    .close {
      position: absolute;
      top: 12px;
      right: 14px;
      cursor: pointer;
    }

    .wrap {
      max-width: 320px;
    }

    .text {
      margin: 15px 0 15px;
      color: var(--grey-text);
      font-size: 16px;
      line-height: 24px;
    }

    .text-btn {
      color: #066bd6;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      text-transform: none;
      cursor: pointer;
      background: none;
      box-shadow: none;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      padding-bottom: 1px;
      border-bottom: 1px dashed #066bd6;
      border-radius: 0;

      &:hover {
        background: none;
        box-shadow: none;
        text-decoration: none;
      }
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 36px 52px;
    }
  }
`;
