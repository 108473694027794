import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { addDays, isAfter } from 'date-fns';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Autocomplete } from '../../../Autocomplete';
import { DayPickerInput } from '../../../DayPickerInput';
import {
  formatDateISO,
  joinDayAndHours,
  defaultRentPeriod,
  getRentInitialValues,
} from '../../../../utils/date';
import { useQuery, setQuery } from '../../../../services/query-service';
import timeSlots from '../../../../constants/timeSlots';

import { StyledDatePicker } from './styles';

export const ControlDate = () => {
  const {
    values: { dateStart, dateEnd },
    setFieldValue,
  } = useFormikContext();
  const query = useQuery();
  const history = useHistory();
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'));
  const handleChange = (name, value) => {
    isLaptop ? setQuery(name, value, query, history) : setFieldValue(name, value);
  };
  const { dayStart, dayEnd, dayISOStart, timeStart, dayISOEnd, timeEnd } = getRentInitialValues({
    dateStart,
    dateEnd,
  });
  const modifiers = { start: dayStart, end: dayEnd };
  return (
    <StyledDatePicker>
      <div className="picker-wrapper">
        <DayPickerInput
          value={dayStart}
          format="dd MMMM yyyy"
          placeholder=""
          inputProps={{
            className: 'picker-input -from',
          }}
          dayPickerProps={{
            selectedDays: [dayStart, { from: dayStart, to: dayEnd }],
            disabledDays: { before: new Date(), after: addDays(new Date(), 364) },
            modifiers,
            numberOfMonths: 1,
          }}
          onDayChange={day => {
            const newDay = formatDateISO(day);
            // TODO add correct days( > 365 days)
            const nextDay = formatDateISO(addDays(day, defaultRentPeriod));
            if (isAfter(day, dayEnd)) {
              handleChange('dateEnd', joinDayAndHours(nextDay, timeEnd));
            }
            handleChange('dateStart', joinDayAndHours(newDay, timeStart));
          }}
          classNames={{ container: 'picker-wrap -from' }}
        />
        <DateRangeIcon className="calendar-icon -from" />
        <Autocomplete
          id="startHour"
          className="hourStart date-hours"
          value={timeStart}
          options={timeSlots}
          getOptionDisabled={option => option}
          renderInput={params => <TextField className="autocomplete" {...params} />}
          onChange={(_, newValue) =>
            handleChange('dateStart', joinDayAndHours(dayISOStart, newValue))
          }
        />
      </div>
      <div className="picker-wrapper">
        <DayPickerInput
          value={dayEnd}
          format="dd MMMM yyyy"
          inputProps={{
            className: 'picker-input -to',
          }}
          dayPickerProps={{
            selectedDays: [dayStart, { from: dayStart, to: dayEnd }],
            // TODO add correct after (with hours)
            disabledDays: { before: dayStart, after: addDays(new Date(), 365) },
            modifiers,
            month: dayStart,
            fromMonth: dayStart,
            numberOfMonths: 1,
          }}
          onDayChange={day => {
            const newDay = formatDateISO(day);
            handleChange('dateEnd', joinDayAndHours(newDay, timeEnd));
          }}
          classNames={{ container: 'picker-wrap -to' }}
        />
        <DateRangeIcon className="calendar-icon -to" />
        <Autocomplete
          id="endHour"
          className="hourEnd date-hours"
          value={timeEnd}
          options={timeSlots}
          getOptionDisabled={option => option}
          renderInput={params => <TextField className="autocomplete" {...params} />}
          onChange={(_, newValue) => handleChange('dateEnd', joinDayAndHours(dayISOEnd, newValue))}
        />
      </div>
    </StyledDatePicker>
  );
};
