import { styled } from 'linaria/react';

export const Tooltip = styled.div`
  & {
    transform: translate(calc(-100% + 4rem), calc(-100% - 1.5rem));
    min-width: 16rem;
    max-width: 16rem;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
  }

  &.left.top {
    transform: translate(calc(-100% + 4rem), calc(-100% - 1.5rem));

    &::before {
      border-width: 1rem 1rem 0 1rem;
      border-color: #fff transparent transparent transparent;
      transform: translate(50%, 99%);
      bottom: 0;
      right: 4rem;
    }
  }

  &.right.top {
    transform: translate(calc(-100% + 12rem), calc(-100% - 1.5rem));

    &::before {
      border-width: 1rem 1rem 0 1rem;
      border-color: #fff transparent transparent transparent;
      transform: translate(50%, 99%);
      bottom: 0;
      right: 12rem;
    }
  }

  &.left.bot {
    transform: translate(calc(-100% + 4rem), calc(1.5rem));

    &::before {
      border-width: 0 1rem 1rem 1rem;
      border-color: transparent transparent #fff;
      transform: translate(50%, -99%);
      top: 0;
      right: 4rem;
    }
  }

  &.right.bot {
    transform: translate(calc(-100% + 12rem), calc(1.5rem));

    &::before {
      border-width: 0 1rem 1rem 1rem;
      border-color: transparent transparent #fff;
      transform: translate(50%, -99%);
      top: 0;
      right: 12rem;
    }
  }

  .map-tooltip {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(72, 107, 125, 0.15);
    border-radius: 8px;
    padding: 12px;

    &__car-card {
      display: block;
      width: 100%;
    }

    &__img {
      position: relative;
      height: 0;
      border: none;
      padding-top: 50%;
    }

    &__box {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 0 8px 0 rgba(83, 92, 115, 0.2);
    }

    &__model-box {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    &__price {
      color: var(--dark-text);
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
    }

    &__label {
      color: #333333;
      font-size: 12px;
      line-height: 14px;
    }

    &__model {
      display: flex;
      justify-content: space-between;
    }

    &__model {
      margin: 0;
      font-size: 1rem;
    }

    &__icon {
      height: 13px;
      width: 14px;
      color: #ffaa01;
    }

    &__caption {
      color: var(--grey-text);
      font-size: 12px;
      line-height: 16px;
    }

    &__subtitle {
      color: #000;
    }
  }
`;

export const Photo = styled.div`
  background-image: ${props => `url(${props.photo})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
