import { styled } from 'linaria/react';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// TODO: remove !important
export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    height: 30px !important;
    font-size: 16px;
    margin: 0 15px 0 0;
    padding: 0 20px;
    border: 1px solid #8f9bb3;
    border-radius: 0;
    box-shadow: none;
    background-color: #ffffff;
    color: var(--grey-text);
  }

  .checkbox {
    display: none;
  }
`;
