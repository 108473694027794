import { styled } from 'linaria/react';
import FormGroup from '@material-ui/core/FormGroup';

export const Subtitle = styled.h4`
  & {
    color: var(--primary-text);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 15px;
  }
`;

export const CustomFormGroup = styled(FormGroup)`
  .text {
    color: var(--grey-text);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 29px;
  }

  .label {
    color: var(--primary-text);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .input {
    width: 100%;
    margin-bottom: 13px;
  }

  .error input {
    border: 1px solid red;
  }

  .passport {
    margin-bottom: 21px;
  }

  //TODO restyle it after adding response car edit
  .MuiPaper-root.MuiPaper-elevation1.MuiExpansionPanel-root {
    border: none;
    box-shadow: none;

    &:before {
      display: none;
    }
  }

  .MuiExpansionPanelSummary-root {
    padding: 0;
  }

  .MuiExpansionPanelDetails-root {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }
`;
