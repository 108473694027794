import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TabYourCar } from '../../components/CarEdit/TabYourCar';
import { TabDescription } from '../../components/CarEdit/TabDescription';
import { TabPhoto } from '../../components/CarEdit/TabPhoto';

import { PanelTitle, AccordionWrapper } from '../../styledComponents';

export default () => {
  return (
    <AccordionWrapper>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="main-content"
          id="main-header"
        >
          <PanelTitle>Основное</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabYourCar />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="description-content"
          id="description-header"
        >
          <PanelTitle>Описание</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabDescription />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="photo-content"
          id="photo-header"
        >
          <PanelTitle>Фотографии</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabPhoto />
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  );
};
