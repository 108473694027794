import React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckboxButton } from '../../../Button/CheckboxButton';
import { useQuery, checkQuery, setQuery } from '../../../../services/query-service';

// TODO we need this component ? we have also immediateBooking
export const ControlInstantBook = () => {
  const query = useQuery();
  const history = useHistory();
  const instant = query.get('instant') ? checkQuery(query.get('instant')) : false;
  return (
    <CheckboxButton
      name="instant"
      label="Мгновенное бронирование"
      checked={instant}
      onChange={({ target: { checked } }) => setQuery('instant', checked, query, history)}
      className={`tab-btn ${instant ? '-active' : ''}`}
    />
  );
};
