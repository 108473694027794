import React from 'react';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { Tooltip, Photo } from './style';

export const MapTooltip = ({ visible = false, position, car = {} }) => {
  const {
    rentPricePerDay,
    brand,
    model,
    productionYear,
    rating,
    rentsQuantity,
    reviewsQuantity,
    photoUrl,
  } = car;

  return (
    <Tooltip
      style={{
        position: 'absolute',
        display: visible ? 'block' : 'none',
        top: position.top,
        left: position.left,
      }}
      className={`${position.pos[0]} ${position.pos[1]}`}
    >
      <RouterLink to={encodeURI(`/car/card/${car.uuid}`)} target="_blank" className="car-card-link">
        <div className="map-tooltip">
          <div className="map-tooltip__car-card">
            <div className="map-tooltip__img">
              <Photo photo={photoUrl} />
              <div className="map-tooltip__box">
                <span className="map-tooltip__price">{rentPricePerDay} &#8381;</span>
                <span className="map-tooltip__label">/сутки</span>
              </div>
            </div>
            <div className="map-tooltip__model">
              <h4 className="map-tooltip__subtitle">{`${brand} ${model}, ${productionYear}`}</h4>
              <div className="map-tooltip__model-box">
                <span className="map-tooltip__label">{rating}</span>
                <StarOutlinedIcon className="map-tooltip__icon" />
              </div>
            </div>
            <div className="map-tooltip__stat">
              <span className="map-tooltip__caption">{`поездки: ${rentsQuantity}`} </span>
              <span className="map-tooltip__caption">{`отзывы: ${reviewsQuantity}`}</span>
            </div>
          </div>
        </div>
      </RouterLink>
    </Tooltip>
  );
};
