import { styled } from 'linaria/react';
import { CarRegisterWrapper } from '../styles';
import { Error as CustomError } from '../../../styledComponents';

export const UserDataFormWrapper = styled(CarRegisterWrapper)`
  & {
    margin: 0;
    padding: 0;
  }

  .title {
    margin-bottom: 15px;
  }

  .text {
    margin-bottom: 20px;

    &.-label {
      margin-bottom: 8px;
    }
  }

  .licence-wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 2px;
  }

  .licence-wrapper .licence {
    display: flex;
    align-items: flex-start;
    margin: 0;
  }

  .checkbox {
    padding: 0;
    margin-right: 10px;
  }

  .btn {
    margin-top: 20px;
  }

  ${CustomError} {
    margin: 0 0 13px;
  }
`;
