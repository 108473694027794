import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { searchActions, errorActions } from '../actions';
import { checkQuery } from '../../services/query-service';
import { makeCall } from '../../services/api-service';
import { SEARCH_CAR_URL } from '../../constants/endpoints';
import { httpVerbs } from '../../constants/http';
import { searchCarsErrors } from '../../constants/errors';
import {
  carTransmissionLayout,
  carTransmissionType,
  carDevices,
  carEngineType,
} from '../../assets/data';

const { GET } = httpVerbs;

const { getCars, getMoreCars, getCarsSuccess, getMoreCarsSuccess, getCarsError } = searchActions;

const { setError } = errorActions;

const transmissionTypeKeys = Object.values(carTransmissionType).map(
  transmission => transmission.value,
);
const transmissionLayoutKeys = Object.values(carTransmissionLayout).map(
  transmission => transmission.value,
);
const engineTypeKeys = Object.values(carEngineType).map(engine => engine.value);
const devicesKeys = Object.keys(carDevices).map(device => carDevices[device].data.name);

// TODO remove it
const updateForRequest = queries => {
  const updatedQueries = queries.map(query => {
    const value = query.split('=');
    if (transmissionTypeKeys.indexOf(value[0]) !== -1) {
      return `trType=${value[0]}`;
    }
    if (transmissionLayoutKeys.indexOf(value[0]) !== -1) {
      return `trLayout=${value[0]}`;
    }
    if (engineTypeKeys.indexOf(value[0]) !== -1) {
      return `engType=${value[0]}`;
    }
    if (devicesKeys.indexOf(value[0]) !== -1) {
      return `features=${value[0]}`;
    }
    return query;
  });
  const querystring = `${updatedQueries.join('&')}`;
  return querystring;
};

function* getCarsHandler(action) {
  const {
    payload: { queries },
  } = action;

  try {
    const queriesArray = queries.split('&');
    const positiveQueries = queriesArray.filter(query => {
      const value = query.split('=');
      return checkQuery(value[1]);
    });
    const querystring = updateForRequest(positiveQueries);
    const { data } = yield makeCall(GET, `${SEARCH_CAR_URL}?${querystring}&page=1`);
    yield put(getCarsSuccess(data));
  } catch (error) {
    const message = searchCarsErrors[error.response.status];
    yield put(setError(message));
    yield put(getCarsError(error));
  }
}

function* getMoreCarsHandler(action) {
  const {
    payload: { queries, page },
  } = action;
  try {
    const queriesArray = queries.split('&');
    const positiveQueries = queriesArray.filter(query => {
      const value = query.split('=');
      return checkQuery(value[1]);
    });
    const querystring = updateForRequest(positiveQueries);
    const { data } = yield makeCall(GET, `${SEARCH_CAR_URL}?${querystring}&page=${page}`);
    yield put(getMoreCarsSuccess(data));
  } catch (error) {
    const message = searchCarsErrors[error.response.status];
    yield put(setError(message));
    yield put(getCarsError(error));
  }
}

function* watchEditEvent() {
  yield takeEvery(getCars, getCarsHandler);
  yield takeEvery(getMoreCars, getMoreCarsHandler);
}

export default watchEditEvent;
