import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const AdivceWrapper = styled.div`
  & {
    box-sizing: border-box;
    border: 1px dotted rgba(241, 161, 83, 0.6);
    border-radius: 4px;
    background-color: rgba(252, 238, 223, 0.3);
    padding: 20px;
  }

  &.-main {
    margin-top: 20px;
  }

  &.-line {
    display: flex;
    padding: 20px 0;
    width: 100%;
  }

  &.-borderless {
    border: none;
    background-color: #fff;
  }

  &.-blue {
    display: none;
    width: 420px;
    margin-top: 0;
    border: 1px dotted #1973e8;
    background-color: transparent;
  }

  > .text {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 16px;
  }

  > .icon {
    font-size: 24px;
    color: #f1a153;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  &.-blue > .icon {
    color: #1973e8;
  }

  @media ${breakpoints.tablet} {
    &.-line {
      display: flex;
      padding: 20px 0;
      width: 650px;
    }
  }
`;
