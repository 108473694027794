import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { AuthPopup } from '../UserRegistration/AuthPopup';
import CommonHeader from '../Header/Common';
import { SearchHeader } from '../Header/Search';
import { errorActions, authActions } from '../../redux/actions';
import { getUserInfo } from '../../services/auth-service';

import { PageContainer } from './styles';

const autoHideDuration = 6000;

const { clearError } = errorActions;
const { setInitialUser } = authActions;

// TODO remove indexOf in renderHeader
const renderHeader = pathname => {
  // TODO finish headers anchors
  // if (pathname.indexOf('car/card') !== -1) {
  //   return <CardHeader />;
  // }
  if (pathname.indexOf('search') !== -1) {
    return <SearchHeader />;
  }

  if (pathname.indexOf('checkout') !== -1) {
    return null;
  }

  return <CommonHeader />;
};

const Layout = ({ children, location: { pathname } }) => {
  const message = useSelector(({ error: { message } }) => message);
  const currentUser = useSelector(({ auth: { user } }) => user);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(clearError());

  useEffect(() => {
    if (!currentUser) {
      const userFromStorage = getUserInfo();
      if (userFromStorage) {
        dispatch(setInitialUser(userFromStorage));
      }
    }
  }, [dispatch, currentUser]);
  return (
    <PageContainer className="page-container">
      <Snackbar open={!!message} autoHideDuration={autoHideDuration} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <AuthPopup />
      {renderHeader(pathname)}
      {children}
    </PageContainer>
  );
};

export default withRouter(Layout);
