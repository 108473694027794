import { styled } from 'linaria/react';

export const UploaderWrapper = styled.div`
  * {
    transform: none !important;
  }

  .filepond--wrapper {
    & {
      display: flex;
    }

    .uploader {
      display: flex;
      width: 100%;
      min-height: 200px;
      contain: none;
    }

    .upload-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .upload-action > .icon {
      height: 36px;
      width: 36px;
      fill: #066bd6;
      opacity: 0.4;
      margin-bottom: 25px;
    }

    .upload-action > .box > .title {
      font-size: 16px;
      color: #066bd6;
      line-height: 20px;

      &.-plus {
        margin-right: 10px;
        font-size: 20px;
      }
    }

    .filepond--drop-label {
      position: absolute;
      width: 300px;
      height: 200px;
      background-color: rgba(6, 107, 214, 0.03);
      border: 1px dotted rgba(6, 107, 214, 0.3);
      border-radius: 10px;
      margin: 0;
      z-index: 7;
    }

    .filepond--drop-label > label {
      width: 100%;
      height: 100%;
    }

    .filepond--root {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .filepond--list-scroller {
      margin: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      mask: none;
      border-radius: 10px;
    }

    .filepond--list.filepond--list {
      position: static;
      display: flex;
      flex-wrap: wrap;
      width: auto;
      height: auto;
      min-height: 235px;
    }

    .filepond--item {
      position: static;
      width: 300px;
      height: 200px !important;
    }

    .filepond--item-panel {
      height: 100%;
      border-radius: 10px;
    }

    .filepond--image-preview {
      background: none;
    }

    .filepond--file-info {
      display: none;
    }

    .filepond--load-indicator {
      display: none;
    }

    .filepond--image-preview-overlay {
      color: transparent;
    }

    .filepond--image-clip {
      height: 200px !important;
      width: 300px !important;
    }

    .filepond--image-canvas-wrapper {
      height: auto !important;
      width: auto !important;
    }
    .filepond--image-canvas-wrapper canvas {
      height: 100% !important;
      width: 100% !important;
    }

    .filepond--panel-root {
      background-color: #ffffff;
    }

    .filepond--item > .filepond--panel .filepond--panel-bottom {
      box-shadow: none;
    }
  }
`;
