import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';
import { CustomInputLabel } from '../../featureStyles';
import { ConfirmationCodeWrapper } from '../ConfirmationCode/styles';

export const PhoneFormWrapper = styled.form`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 320px;
    }

    ${CustomInputLabel} {
      margin: 0 0 10px;
    }

    .text {
      margin: 15px 0 30px;
      color: var(--grey-text);
      font-size: 16px;
      line-height: 24px;
    }

    .text-resend {
      color: var(--grey-text);
      font-size: 16px;
      line-height: 24px;
      margin-top: 10px;
    }

    .input {
      width: 191px;
      margin-bottom: 0;
    }

    .phone-input {
      font-family: 'Open Sans', sans-serif;
      margin-bottom: 0;
    }

    .btn {
      width: 191px;
      margin-top: 25px;
    }

    .text-btn {
      color: #066bd6;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      text-transform: none;
      cursor: pointer;
      background: none;
      box-shadow: none;
      width: auto;
      height: auto;
      padding: 0;
      margin: 15px 0 0;
      padding-bottom: 1px;
      border-bottom: 1px dashed #066bd6;
      border-radius: 0;

      &:hover {
        background: none;
        box-shadow: none;
        text-decoration: none;
      }
    }

    .link {
      color: #066bd6;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      margin-left: 8px;

      &:hover {
        color: #0258b3;
      }
    }

    .text-btn {
      color: #066bd6;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }
`;

export const PhoneWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      padding: 68px 16px 54px 16px;
    }

    ${ConfirmationCodeWrapper} {
      margin-top: 30px;
    }

    .flag-dropdown,
    .phone-input .flag-dropdown.open {
      background-color: transparent;
      border: none;
    }

    .form .phone {
      width: 100%;
      height: 48px;
      color: var(--grey-text);
      font-size: 16px;

      &:focus {
        border: 1px solid #066bd6;
      }
    }

    .close {
      position: absolute;
      top: 12px;
      right: 14px;
      cursor: pointer;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 36px 52px;
    }
  }
`;
