import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const PlanWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      height: 405px;
      width: 100%;
      border: ${props => (props.selected ? '1px solid #06BB9B' : '1px solid #066BD6')};
      border-radius: 10px;
      background-color: #ffffff;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .main-box {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      border-radius: 10px 10px 0 0;
      color: #ffffff;
      background-color: ${props => (props.selected ? '#06BB9B' : '#066BD6')};
    }

    .name {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 12px;
    }

    .price {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      margin-top: 10px;
    }

    .percent {
      font-size: 24px;
      font-weight: 600;
    }

    .condition {
      position: relative;
      margin: 8px 28px 0 28px;
      padding-left: 42px;
      color: #5d5d5d;
      font-size: 14px;
      line-height: 20px;
    }

    .icon {
      position: absolute;
      fill: #06bb9b;
      height: 18px;
      width: 18px;
      top: 2px;
      left: 0;
    }

    .common {
      height: 48px;
      width: calc(100% - 48px);
      margin: auto 24px 24px 24px;
      border: 1px solid #066bd6;
      border-radius: 4px;
      background-color: #ffffff;
      color: #066bd6;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;

      &:active,
      &:hover {
        color: #ffffff;
        background-color: 066bd6;
      }
    }

    .active:disabled {
      height: 48px;
      width: calc(100% - 48px);
      margin: auto 24px 24px 24px;
      border: none;
      border-radius: 4px;
      background-color: #06bb9b;
      color: #ffffff;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      opacity: 1;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      height: 405px;
      width: calc(50% - 12px);
      margin-bottom: 24px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: calc((100% - 50px) / 3);
      margin-bottom: 0;
    }
  }
`;
