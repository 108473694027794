import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const YourCarWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-bottom: 24px;
      width: 100%;
    }

    .block {
      margin: 22px 0 32px 0;
    }

    .box {
      margin-bottom: 16px;
    }

    .name {
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      color: var(--grey-text);
      margin-right: 8px;
    }

    .value {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #303133;
    }

    .select-wrap {
      position: relative;
      padding-top: 54px;
    }

    .select {
      margin-bottom: 16px;
      width: 100%;
    }

    .select:last-child {
      margin: 0;
    }

    .input-address {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .autocomplete-dropdown-container {
      width: 310px;
      margin-top: -12px;
      border-radius: 4px;
      overflow: hidden;
    }

    .suggestion-item,
    .loading {
      height: 48px;
      line-height: 48px;
      padding-left: 16px;
      background-color: #ffffff;
      cursor: pointer;
    }

    .suggestion-item,
    .suggestion-item--active {
      display: flex;
      width: 310px;
      max-width: 310px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .suggestion-item--active {
      height: 48px;
      line-height: 48px;
      padding-left: 16px;
      background-color: #fafafa;
      cursor: pointer;
    }

    .loader {
      position: absolute;
      z-index: 2;
      background: #fff;
      width: 70%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .map {
      height: 200px;
      width: 100%;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      margin-bottom: 40px;
    }

    .select {
      width: 310px;
    }

    .map {
      height: 250px;
    }
  }

  @media ${breakpoints.tablet} {
    .map {
      height: 350px;
    }
  }
`;
