import React from 'react';
import { range } from 'ramda';
import Skeleton from '@material-ui/lab/Skeleton';

export const SkeletonLoader = ({ itemQnt }) => {
  const skeletonArray = range(0, itemQnt);
  return (
    <div className="infinite-scroll-component">
      {skeletonArray.map(el => (
        <div key={el} className="car-card">
          <div className="wrap -img">
            <Skeleton
              className="skeleton-img"
              animation="wave"
              variant="rect"
              width="100%"
              height="100%"
            />
          </div>
          <Skeleton className="skeleton-text" width="60%" variant="text" />
          <Skeleton variant="text" />
        </div>
      ))}
    </div>
  );
};
