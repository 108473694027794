import React from 'react';
import { CustomCheckbox } from '../../Checkbox';
import { StyledFormControlLabel } from './styles';

export const CheckboxButton = ({
  name = '',
  label = '',
  onChange = null,
  checked = false,
  className = '',
}) => {
  return (
    <StyledFormControlLabel
      control={
        <CustomCheckbox checked={checked} onChange={onChange} value={name} className="checkbox" />
      }
      label={label}
      className={className}
    />
  );
};
