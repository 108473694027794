import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as OptionsIcon } from '../../../../assets/icons/options.svg';
import { Autocomplete } from '../../../Autocomplete';
import { CustomCheckbox } from '../../../Checkbox';
import {
  carTransmissionLayout,
  carTransmissionType,
  carDevices,
  carEngineType,
  searchFiltersSeats,
  searchFiltersYears,
  searchFiltersBody,
  searchFiltersCars,
} from '../../../../assets/data';
import { mapSearchFitersToKeys, mapSearchFiltersDefaultValues } from '../../../../utils/mapper';
import {
  useQuery,
  submitQueries,
  resetQueries,
  filtersMapper,
} from '../../../../services/query-service';
import {
  Container,
  Collection,
  Buttons,
  FilterForm,
  StyledDivider,
  StyledSlider,
  StyledPopover,
  SearchControlButtons,
} from './styles';
import { isExist } from './helper';

const { years, carBody, cars } = mapSearchFitersToKeys();

const Control = ({ handler, label, isOpen }) => (
  <Button
    variant="outlined"
    className={`tab-btn ${isOpen ? '-opened' : ''}  ${isExist(label) > 0 ? '-active' : ''}`}
    startIcon={<OptionsIcon />}
    {...handler}
  >
    {isExist(label) > 0 ? `Фильтры(${isExist(label)})` : 'Фильтры'}
  </Button>
);

export const Filters = () => {
  const query = useQuery();
  const history = useHistory();
  const defaultValues = mapSearchFiltersDefaultValues();
  const initialValues = filtersMapper(query, defaultValues);
  return (
    // TODO label?
    <StyledPopover control={Control} label={initialValues}>
      {closeHandler => (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            submitQueries(values, query, history);
          }}
        >
          {({ setFieldValue, handleSubmit, values }) => (
            <>
              <FilterForm>
                <Container>
                  <div className="box">
                    <h5 className="title -first">Показать</h5>
                    <FormGroup className="form-group">
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={values.noMilLim}
                            name="noMilLim"
                            onChange={({ target: { checked } }) => {
                              if (checked) {
                                setFieldValue('dailyMil', null);
                              }
                              setFieldValue('noMilLim', checked);
                            }}
                            className="filter-checkbox"
                          />
                        }
                        label="Без ограничений пробега"
                        className="filters-label"
                      />
                    </FormGroup>
                  </div>
                  <div className="box -using">
                    <h5 className="title -first">Использование</h5>
                    <RadioGroup
                      className="form-group"
                      aria-label="limitations"
                      name="limitations"
                      defaultValue="world"
                    >
                      <FormControlLabel
                        value="city"
                        className="filters-label"
                        control={<Radio onChange={() => setFieldValue('area', null)} />}
                        label="По городу"
                      />
                      <FormControlLabel
                        value="country"
                        className="filters-label"
                        control={<Radio onChange={() => setFieldValue('area', 'country')} />}
                        label="По всей стране"
                      />
                      <FormControlLabel
                        value="world"
                        className="filters-label"
                        control={<Radio onChange={() => setFieldValue('area', 'world')} />}
                        label="По миру"
                      />
                    </RadioGroup>
                  </div>
                  <div className="box">
                    <h5 className="title -first -distance">Дистанция, км/день</h5>
                    <StyledSlider
                      onChangeCommitted={(event, newValue) => {
                        setFieldValue('dailyMil', newValue);
                      }}
                      disabled={values.noMilLim}
                      value={values.dailyMil ? values.dailyMil : 0}
                      aria-labelledby="discrete-slider-restrict"
                      color="secondary"
                      valueLabelDisplay="on"
                      min={0}
                      max={1000}
                      step={1}
                    />
                  </div>
                  <div className="box -seats">
                    <h5 className="title -first" htmlFor="seats">
                      Количество мест
                    </h5>
                    <Autocomplete
                      id="seats"
                      className="select"
                      options={searchFiltersSeats.options}
                      getOptionSelected={option => option}
                      value={values.seats || null}
                      renderInput={params => (
                        <TextField {...params} placeholder="- Выберите число -" />
                      )}
                      onChange={(_, newValue) => {
                        setFieldValue('seats', newValue);
                      }}
                    />
                  </div>
                </Container>
                <StyledDivider />
                <Container nowrap>
                  <div className="box -selects">
                    <h5 className="title" htmlFor="manufacturer">
                      Марка
                    </h5>
                    <Autocomplete
                      id="brand"
                      className="select"
                      options={searchFiltersCars.options}
                      getOptionLabel={option => option.label}
                      value={cars[values.brand] || null}
                      renderInput={params => (
                        <TextField {...params} placeholder="- Выберите марку -" />
                      )}
                      onChange={(_, { value }) => {
                        setFieldValue('brand', value);
                      }}
                    />
                  </div>
                  <div className="box -selects">
                    <h5 className="title" htmlFor="prodYear">
                      Год
                    </h5>
                    <Autocomplete
                      id="prodYear"
                      className="select"
                      options={searchFiltersYears.options}
                      getOptionLabel={option => option.label}
                      value={years[values.prodYear] || null}
                      renderInput={params => (
                        <TextField {...params} placeholder="- Выберите год -" />
                      )}
                      onChange={(_, { value }) => {
                        setFieldValue('prodYear', value);
                      }}
                    />
                  </div>
                  <div className="box -selects">
                    <h5 className="title">Тип кузова</h5>
                    <Autocomplete
                      id="bodyType"
                      className="select"
                      options={searchFiltersBody.options}
                      getOptionLabel={option => option.label}
                      value={carBody[values.bodyType] || null}
                      renderInput={params => (
                        <TextField {...params} placeholder="- Выберите тип кузова -" />
                      )}
                      onChange={(_, { value }) => {
                        setFieldValue('bodyType', value);
                      }}
                    />
                  </div>
                </Container>
                <StyledDivider />
                <Container inline>
                  <div className="box -engine">
                    <h5 className="title">Коробка передач</h5>
                    <FormGroup className="form-group">
                      {Object.values(carTransmissionType).map(({ value, label }) => (
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={values[value]}
                              name={value}
                              onChange={({ target: { checked } }) => setFieldValue(value, checked)}
                              className="filter-checkbox"
                            />
                          }
                          label={label}
                          key={value}
                          className="filters-label"
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <div className="box -engine">
                    <h5 className="title">Привод</h5>
                    <FormGroup className="form-group">
                      {Object.values(carTransmissionLayout).map(({ value, label }) => (
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={values[value]}
                              name={value}
                              onChange={({ target: { checked } }) => setFieldValue(value, checked)}
                              className="filter-checkbox"
                            />
                          }
                          label={label}
                          key={value}
                          className="filters-label"
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <div className="box -engine">
                    <h5 className="title">Тип двигателя</h5>
                    <FormGroup className="form-group">
                      {Object.values(carEngineType).map(({ value, label }) => (
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={values[value]}
                              name={value}
                              onChange={({ target: { checked } }) => setFieldValue(value, checked)}
                              className="filter-checkbox"
                            />
                          }
                          label={label}
                          key={value}
                          className="filters-label"
                        />
                      ))}
                    </FormGroup>
                  </div>
                </Container>
                <StyledDivider />
                <Collection>
                  <h5 className="title -device">Дополнительно</h5>
                  <FormGroup className="form-group -devices">
                    {Object.values(carDevices).map(({ label, data: { name } }) => (
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={values[name]}
                            name={name}
                            onChange={({ target: { checked } }) => setFieldValue(name, checked)}
                            className="filter-checkbox"
                          />
                        }
                        className="filters-label"
                        label={label}
                        key={name}
                      />
                    ))}
                  </FormGroup>
                </Collection>
                <StyledDivider />
                <Collection>
                  <h5 className="title -device">Дополнительные услуги</h5>
                  <FormGroup className="form-group -devices">
                    <FormControlLabel
                      className="filters-label"
                      control={
                        <CustomCheckbox
                          checked={values.afterRentWashingPrice}
                          name="afterRentWashingPrice"
                          onChange={({ target: { checked } }) =>
                            setFieldValue('afterRentWashingPrice', checked)
                          }
                          className="filter-checkbox"
                        />
                      }
                      label="Мойка после аренды"
                    />
                    <FormControlLabel
                      className="filters-label"
                      control={
                        <CustomCheckbox
                          checked={values.fuelLiterCompensationPrice}
                          name="fuelLiterCompensationPrice"
                          onChange={({ target: { checked } }) =>
                            setFieldValue('fuelLiterCompensationPrice', checked)
                          }
                          className="filter-checkbox"
                        />
                      }
                      label="Возврат с пустым баком"
                    />
                  </FormGroup>
                </Collection>
              </FilterForm>
              <Buttons>
                <SearchControlButtons
                  submit={handleSubmit}
                  close={closeHandler}
                  resetForm={() => {
                    resetQueries(Object.keys(values), query, history);
                  }}
                  closingLabel="Сбросить фильтры"
                />
              </Buttons>
            </>
          )}
        </Formik>
      )}
    </StyledPopover>
  );
};
