import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { userActions, errorActions } from '../actions';
import { getUserInfo } from '../../services/auth-service';
import {
  POST_SCORING_DATA_URL,
  GET_SCORING_RESULTS_URLS,
  USER_SCORING_REFRESH,
} from '../../constants/endpoints';
import { postScoringErrors, getScoringErrors } from '../../constants/errors';

const {
  postScoringData,
  postScoringDataSuccess,
  postScoringDataFailure,
  getScoringResults,
  getScoringResultsSuccess,
  getScoringResultsFailure,
  scoringRefresh,
  scoringRefreshSuccess,
  scoringRefreshFailure,
} = userActions;

const { setError } = errorActions;

function* getScoringResultsHandler(action) {
  const user = getUserInfo();
  try {
    const { data } = yield call(
      axios.get,
      // TODO replace with uuid
      `${GET_SCORING_RESULTS_URLS}/${user.id}`,
    );
    yield put(getScoringResultsSuccess(data));
  } catch (error) {
    const message = getScoringErrors[error.response.status];
    yield put(setError(message));
    yield put(getScoringResultsFailure(error));
  }
}

// TODO we need scoring results page?
function* scoringRefreshsHandler() {
  const user = getUserInfo();
  try {
    yield call(
      axios.get,
      // TODO replace with uuid
      `${USER_SCORING_REFRESH}/${user.id}`,
    );
    yield put(scoringRefreshSuccess());
  } catch (error) {
    yield put(scoringRefreshFailure(error));
  }
}

function* postScoringHandler(action) {
  const { payload } = action;
  const user = getUserInfo();

  try {
    // TODO we need response here?
    yield call(
      axios.patch,
      // TODO replace with uuid
      `${POST_SCORING_DATA_URL}/${user.id}`,
      payload,
    );
    yield put(postScoringDataSuccess());
  } catch (error) {
    const message = postScoringErrors[error.response.status];
    yield put(setError(message));
    yield put(postScoringDataFailure(error));
  }
}

function* watchScoringEvent() {
  yield takeEvery(postScoringData, postScoringHandler);
  yield takeEvery(getScoringResults, getScoringResultsHandler);
  yield takeEvery(scoringRefresh, scoringRefreshsHandler);
}

export default watchScoringEvent;
