import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import FormGroup from '@material-ui/core/FormGroup';
import { largeField, shortField } from '../../../constants/fieldLength';

import { TabTextArea, TabTitle, TabLabel } from '../featureStyles';
import { InformationWrapper } from './styles';

export const TabInformation = () => {
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { greetingMessage, guestInstructions, rentRecomendations } = values;
  return (
    <InformationWrapper>
      <TabTitle>Расскажите о себе и своей машине </TabTitle>
      <FormGroup className="formgroup">
        <TabLabel>Напишите приветственное сообщение</TabLabel>
        <TabTextArea
          className="field"
          multiline={true}
          rows={7}
          inputProps={{
            maxLength: shortField,
          }}
          placeholder="Клиент получит это сообщение после бронирования. Например, 'Привет, меня зовут Иван, и я гарантирую, что поездка на моей машине принесет вам удовольствие :)'"
          defaultValue={greetingMessage}
          onBlur={({ target: { value } }) => setFieldValue('greetingMessage', value)}
        />
        <TabLabel>Напишите инструкцию для тех, кто забронировал</TabLabel>
        <TabTextArea
          className="field"
          multiline={true}
          rows={7}
          inputProps={{
            maxLength: largeField,
          }}
          placeholder="Например, как пользоваться мультимедийной системой, адаптивным круиз-контролем и системой автозапуска двигателя"
          defaultValue={guestInstructions}
          onBlur={({ target: { value } }) => setFieldValue('guestInstructions', value)}
        />
        <TabLabel>Напишите общие правила для вашей машины</TabLabel>
        <TabTextArea
          className="field"
          multiline={true}
          rows={7}
          inputProps={{
            maxLength: shortField,
          }}
          placeholder="Например, на каких АЗС и каким топливом заправляться, правила перевозки животных и т.д."
          defaultValue={rentRecomendations}
          onBlur={({ target: { value } }) => setFieldValue('rentRecomendations', value)}
        />
      </FormGroup>
    </InformationWrapper>
  );
};
