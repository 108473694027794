import React from 'react';
import { useFormikContext } from 'formik';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EditCalendarPeriod from '../../../containers/EditCalendarPeriod';
import { getDate } from '../../../utils/date';

import 'react-day-picker/lib/style.css';
import { TabTitle, TabRecomendation } from '../featureStyles';
import { Sidebar, CustomButton, Period, Date, Icons } from './styles';

export const CalendarSidebar = ({
  unavailabilityDates = { booked: [], blocked: [] },
  periodForUpdate,
  setPeriodForUpdate,
  updatePeriod,
  removePeriod,
  showDatePicker,
}) => {
  const { blocked } = unavailabilityDates;
  const {
    values: { immediateBooking },
    setFieldValue,
  } = useFormikContext();

  return (
    <Sidebar>
      <div className="wrapper">
        <TabTitle className="title">Установите период недоступности машины</TabTitle>
        <TabRecomendation className="text">
          Прочитайте инструкцию{' '}
          <Link
            target="_blank"
            href="https://help.getarent.ru/knowledge-bases/9/articles/98-instruktsiya-po-upravleniyu-kalendaryom"
            className="text-link"
          >
            по управлению календарем
          </Link>
        </TabRecomendation>
      </div>
      {blocked.length > 0 && <h3 className="sub-title">Машина недоступна:</h3>}
      {blocked.map((item, index) => {
        return index === periodForUpdate ? (
          <EditCalendarPeriod
            key={`blocked-period-${item.startDate}`}
            unavailabilityDates={unavailabilityDates}
            close={() => setPeriodForUpdate(undefined)}
            addPeriod={updatePeriod}
            index={index}
            showDatePicker={showDatePicker}
          />
        ) : (
          // TODO change key
          <Period key={`blocked-period-${item.startDate}`}>
            <Date>
              <span>{getDate(item.startDate, 'dd.MM.yyyy')}</span>&mdash;
              <span>{getDate(item.endDate, 'dd.MM.yyyy')}</span>
            </Date>
            <Icons>
              <IconButton
                aria-label="edit"
                key={`edit-period-${item.startDate}`}
                onClick={() => {
                  setPeriodForUpdate(index);
                  showDatePicker();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="close"
                key={`remove-period-${item.startDate}`}
                onClick={() => {
                  removePeriod(index);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Icons>
          </Period>
        );
      })}
      <CustomButton onClick={showDatePicker} variant="text" size="small">
        + Добавить период
      </CustomButton>
      <Divider />
      <FormGroup className="formgroup">
        <TabTitle>Разрешить бронировать мгновенно</TabTitle>
        <TabRecomendation className="text">
          Запросы на бронирование вашей машины будут подтверждаться автоматически. Вы получите
          приоритетный статус.{' '}
          <Link
            target="_blank"
            href="https://help.getarent.ru/knowledge-bases/2/articles/11-dostupnost-vashego-avtomobilya-dlya-arendyi"
            className="text-link"
          >
            Узнать подробнее
          </Link>
        </TabRecomendation>
        <FormControlLabel
          className="booking-checkbox"
          control={
            <Checkbox
              checked={immediateBooking}
              onChange={({ target: { checked } }) => setFieldValue('immediateBooking', checked)}
            />
          }
          label="Мгновенное бронирование"
        />
      </FormGroup>
    </Sidebar>
  );
};
