import { styled } from 'linaria/react';
import NextIcon from '../../../assets/icons/arrowNext.svg';
import PrevIcon from '../../../assets/icons/arrowPrev.svg';
import { breakpoints } from '../../../constants/breakpoints';

export const Container = styled.div`
  & {
    position: relative;
    display: flex;
  }

  .icon {
    fill: #8f9bb3;
  }

  .open-btn {
    position: absolute;
    top: 28px;
    right: 16px;
  }
`;

export const Calendar = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      padding-top: 77px;
    }

    .back {
      position: absolute;
      top: 20px;
      left: 13px;
      cursor: pointer;
    }

    .DayPicker-wrapper {
      outline: none;
    }

    .DayPicker-NavButton {
      top: 0;
      right: 16px;
      margin: 0;
    }

    .DayPicker-NavButton--next {
      background: url(${NextIcon});
    }

    .DayPicker-NavButton--prev {
      background: url(${PrevIcon});
      margin-right: 1.5em;
    }

    .DayPicker-Caption {
      margin-bottom: 60px;
      padding: 0;
    }

    .DayPicker-Caption > div {
      color: var(--primary-text);
      font-family: 'Open Sans', 'sans-serif';
      font-size: 24px;
      font-weight: 600;
      line-height: 20px;
    }

    .DayPicker-Month {
      border-collapse: separate;
      border-spacing: 2px 2px;
      margin: 0 14px;
    }

    .DayPicker-Weekday {
      color: var(--grey-text);
      font-family: 'Open Sans', 'sans-serif';
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      text-align: left;
      padding: 0 0 15px 0;
    }

    .DayPicker-Day {
      padding: 0;
      width: calc((100vw - 45px) / 7);
      height: calc((100vw - 45px) / 7);
      box-sizing: border-box;
      color: var(--primary-text);
    }

    /* dateStyle*/
    .DayPicker-Day > div > div {
      position: absolute;
      color: #303133;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      top: 8px;
      left: 8px;
    }

    .DayPicker-Day--outside {
      border: none !important;
      background-color: rgba(196, 196, 196, 0.43) !important;
      border-radius: 0;
      opacity: 0 !important;
    }

    .DayPicker-Day--disabled {
      background-color: rgba(196, 196, 196, 0.43);
      border-radius: 0;
      opacity: 0.5;
    }

    .DayPicker-Day:focus {
      outline: none;
    }

    .DayPicker-Day:not(.DayPicker-Day--disabled) {
      border: 2px solid #06bb9b;
      background-color: #fff;
      border-radius: 0;
    }

    .DayPicker-Day--blocked {
      border: 2px solid #e24c4c;
      background-color: rgba(226, 76, 76, 0.1);
      opacity: 0.5;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 34px 0 0 40px;
      box-shadow: -15px -12px 6px -15px rgba(34, 34, 34, 0.2);
      width: calc(100% - 360px);
      box-sizing: border-box;
    }

    .DayPicker-Caption {
      margin-bottom: 46px;
    }

    .DayPicker-Caption > div {
      font-size: 20px;
      line-height: 20px;
    }

    .DayPicker-Month {
      margin: 0;
    }

    .DayPicker-NavButton {
      right: 0;
    }

    .DayPicker-Day {
      width: calc((100vw - 464px) / 7);
      height: calc((100vw - 464px) / 7);
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: calc(100% - 440px);
      padding: 36px 0 0 8px;
    }

    .DayPicker-Caption {
      margin-left: 16px;
    }

    .DayPicker-Caption > div {
      font-size: 24px;
    }

    .DayPicker-Weekday {
      font-size: 20px;
      line-height: 24px;
      padding: 0 0 24px 0;
    }

    /* dateStyle*/
    .DayPicker-Day > div > div {
      font-size: 16px;
      line-height: 24px;
      top: 4px;
      left: 9px;
    }

    .DayPicker-Day {
      width: calc((100vw - 640px) / 7);
      height: calc((100vw - 640px) / 7);
    }

    .DayPicker-Month {
      border-spacing: 16px 16px;
      margin: 0;
    }

    .DayPicker-NavButton {
      right: 16px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: calc(100% - 530px);
      padding: 40px 0 0 36px;
    }

    .DayPicker-Caption {
      margin-left: 4px;
    }

    .DayPicker-Weekday {
      padding: 0 0 20px 0;
    }

    .DayPicker-Day {
      width: 100px;
      height: 100px;
    }

    /* dateStyle*/
    .DayPicker-Day > div > div {
      top: 16px;
      left: 16px;
    }

    .DayPicker-Month {
      border-spacing: 4px 4px;
    }

    .DayPicker-NavButton {
      right: 4px;
    }
  }
`;

export const Legend = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      justify-content: space-between;
      margin: 0 16px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      margin: 0 2px;
    }
  }

  @media ${breakpoints.laptop} {
    margin: 0 17px;
  }

  @media ${breakpoints.desktop} {
    margin: 0 4px;
  }
`;

export const Box = styled.div`
  & {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-text);
    margin-left: 20px;
  }
`;

export const View = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border: ${props => (props.color ? `1px solid ${props.color}` : '1px solid #06bb9b')};
  background-color: ${props => props.backgroundColor || '#fff'};
`;
