import { createAction } from 'redux-actions';
import { authTypes, authPopupOptions } from '../../types';
import {
  SIGNIN_GOOGLE_URL,
  SIGNIN_FACEBOOK_URL,
  SIGNIN_YANDEX_URL,
  SIGNIN_VK_URL,
} from '../../../constants/endpoints';

const {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SIGNOUT_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SET_INITIAL_USER,
  SIGNIN_GOOGLE,
  SIGNIN_FACEBOOK,
  SIGNIN_YANDEX,
  SIGNIN_VK,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_AUTH,
  RESET_AUTH_ERROR,
} = authTypes;

const {
  LOGIN_POPUP,
  SIGNUP_POPUP,
  CONFIRM_EMAIL_POPUP,
  CONFIRM_PHONE_POPUP,
  CLOSE_AUTH_POPUP,
} = authPopupOptions;

// Sign-In
export const signInUser = createAction(SIGNIN, userData => userData);
export const signInUserSuccess = createAction(SIGNIN_SUCCESS, user => ({ user }));
export const signInUserError = createAction(SIGNIN_ERROR);

// Sign-Out
export const signOutUser = createAction(SIGNOUT);
export const signOutUserSuccess = createAction(SIGNOUT_SUCCESS);
export const signOutUserError = createAction(SIGNOUT_ERROR);

// Sign-Up
export const signUpUser = createAction(SIGNUP, userData => userData);
export const signUpUserSuccess = createAction(SIGNUP_SUCCESS, user => ({ user }));
export const signUpUserError = createAction(SIGNUP_ERROR);

// Reset password
export const resetPassword = createAction(RESET_PASSWORD);
export const resetPasswordError = createAction(RESET_PASSWORD_ERROR, error => ({ error }));

// Auth popup
export const showLoginPopup = createAction(LOGIN_POPUP);
export const showSignupPopup = createAction(SIGNUP_POPUP);
export const showConfirmEmailPopup = createAction(CONFIRM_EMAIL_POPUP);
export const showConfirmPhonePopup = createAction(CONFIRM_PHONE_POPUP);
export const closeAuthPopup = createAction(CLOSE_AUTH_POPUP);

// Social auth
export const signInGoogle = createAction(SIGNIN_GOOGLE, () => {
  window.location.href = SIGNIN_GOOGLE_URL;
});
export const signInFacebook = createAction(SIGNIN_FACEBOOK, () => {
  window.location.href = SIGNIN_FACEBOOK_URL;
});
export const signInYandex = createAction(SIGNIN_YANDEX, () => {
  window.location.href = SIGNIN_YANDEX_URL;
});
export const signInVk = createAction(SIGNIN_VK, () => {
  window.location.href = SIGNIN_VK_URL;
});

// Other
export const setInitialUser = createAction(SET_INITIAL_USER, user => ({ user }));
export const resetAuth = createAction(RESET_AUTH);
export const resetAuthError = createAction(RESET_AUTH_ERROR);

// export const signInGoogle = createAction(SIGNIN_GOOGLE);
