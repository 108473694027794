import { styled } from 'linaria/react';
import { breakpoints } from '../../../../constants/breakpoints';
import { Title } from '../../featureStyles';
import { ConfirmationCodeWrapper } from '../ConfirmationCode/styles';

export const TitleMain = styled.h3`
  @media ${breakpoints.mobile} {
    & {
      margin: 0;
      color: var(--primary-text);
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      line-height: 36px;
    }
  }
`;

export const ForgotPasswordWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      padding: 106px 16px 54px;
    }

    ${ConfirmationCodeWrapper} {
      margin-top: 30px;
    }

    ${Title} {
      font-size: 16px;
      line-height: 24px;
    }

    ${TitleMain} {
      margin-bottom: 15px;
    }

    .close {
      position: absolute;
      top: 12px;
      right: 14px;
      cursor: pointer;
    }

    .label {
      color: var(--primary-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .input {
      width: 100%;
    }

    .actions {
      margin-top: 31px;
    }

    .submit {
      margin-right: 15px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 36px 52px;
    }
  }
`;
