import { handleActions } from 'redux-actions';
import { errorActions } from '../../actions';

const { setError, clearError } = errorActions;
const initialState = { message: null };

export default handleActions(
  {
    [setError](
      state,
      {
        payload: { message },
      },
    ) {
      return { ...state, message };
    },
    [clearError](state) {
      return { ...state, message: null };
    },
  },
  initialState,
);
