import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormGroup from '@material-ui/core/FormGroup';
import { CustomCheckbox } from '../../Checkbox';
import { AdditionalDeviceConditions } from '../AdditionalDeviceConditions';
import { carDevices } from '../../../assets/data';

import { TabTitle, TabFormControlLabel, TabRecomendation } from '../featureStyles';
import { DeviceWrapper } from './styles';

export const TabDevices = () => {
  const { values, errors, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <DeviceWrapper>
      <FormGroup>
        <TabTitle>Предложите устройства и установите цены</TabTitle>
        {!isMobile && (
          <TabRecomendation className="text">
            Выберите дополнительные устройства. которые вы хотите предоставить клиентам, и
            установите их стоимость (в день и за весь период аренды)
          </TabRecomendation>
        )}
        <div className="box">
          {Object.values(carDevices).map(({ label, data: { name } }) => {
            const { checked } = values[name];
            return (
              <div key={`${name}-wrapper`}>
                <TabFormControlLabel
                  control={
                    <CustomCheckbox
                      id={name}
                      name={name}
                      checked={checked}
                      onChange={({ target: { checked } }) => setFieldValue(name, { checked })}
                    />
                  }
                  label={label}
                  key={name}
                />
                {checked && (
                  <AdditionalDeviceConditions
                    setFieldValue={setFieldValue}
                    name={name}
                    values={values}
                    errors={errors}
                  />
                )}
              </div>
            );
          })}
        </div>
      </FormGroup>
    </DeviceWrapper>
  );
};
