import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const ReviewWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-top: 20px;
    }

    .params {
      display: flex;
      margin-bottom: 9px;
    }

    .container {
      margin-left: 10px;
    }

    .line {
      display: flex;
      align-items: center;
    }

    .author {
      color: var(--dark-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin-right: 10px;
    }

    .date {
      color: var(--grey-text);
      font-size: 12px;
      line-height: 16px;
    }

    .text {
      color: var(--primary-text);
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      margin-top: 18px;
    }

    .author {
      margin-right: 12px;
    }
  }
`;
