import { handleActions, combineActions } from 'redux-actions';

import { userActions } from '../../actions';

const {
  updateUserData,
  updateUserDataSuccess,
  updateUserDataError,
  resetUserUpdateErrors,
} = userActions;

const initialState = { loading: false, error: null };
export const userUpdate = handleActions(
  {
    [updateUserData](state) {
      return { ...state, loading: true };
    },

    [updateUserDataSuccess](state) {
      return { ...state, loading: false, error: null };
    },

    [updateUserDataError](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, error, loading: false };
    },
    [resetUserUpdateErrors](state) {
      return { ...state, error: initialState.error };
    },
  },
  initialState,
);
