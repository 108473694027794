import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

const StyledSearchHeader = styled.header`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      position: fixed;
      background: #fff;
      z-index: 1;
      top: 0;
    }

    .container {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 70px;
      padding: 0 3%;
      box-sizing: border-box;
      background: #fff;
    }

    .toolbar {
      display: flex;
      justify-content: flex-end;
      min-height: 100%;
      padding: 0;
    }

    .logo {
      width: 40px;
      height: 24px;
      margin: 0;
    }

    .control-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      left: 10px;
      width: 100%;
      line-height: 18px;
    }

    .periods-wrapper.-mobile {
      display: flex;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      z-index: 2;
    }

    .container {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 70px;
      padding: 0 25px;
      background: #fff;
    }

    .control-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      left: 35px;
      line-height: 24px;
    }

    .menu-arrow {
      display: none;
    }

    .periods-wrapper {
      display: flex;

      &.-mobile {
        display: none;
      }
    }
  }

  @media ${breakpoints.laptop} {
    .container {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 70px;
      padding: 0 25px;
    }

    .control-wrap {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      position: relative;
      left: 0;
    }
  }

  @media ${breakpoints.desktop} {
    .container {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 70px;
      padding: 0 50px;
      max-width: 1440px;
      margin: 0 auto;
    }

    .control-wrap {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      position: relative;
      left: 0;
    }
  }
`;

export default StyledSearchHeader;
