import { put, takeEvery } from 'redux-saga/effects';
import { makeCall } from '../../services/api-service';
import { carActions, errorActions } from '../actions';
import { deleteCarErrors } from '../../constants/errors';
import { CAR_URL, CAR_VERIFICATION_URL } from '../../constants/endpoints';
import { httpVerbs } from '../../constants';

const { PATCH, DELETE } = httpVerbs;

const {
  deleteCar,
  deleteCarSuccess,
  deleteCarFailure,
  startVerification,
  startVerificationSuccess,
  startVerificationFailure,
} = carActions;

const { setError } = errorActions;

function* deleteCarHandler(action) {
  const {
    payload: { id, history },
  } = action;
  try {
    yield makeCall(DELETE, `${CAR_URL}/${id}`);
    yield put(deleteCarSuccess());
    history.push('/my-cars');
  } catch (error) {
    const message = deleteCarErrors[error.response.status];
    yield put(setError(message));
    yield put(deleteCarFailure(error));
  }
}

function* verificationHandler(action) {
  const {
    payload: { id, data, history },
  } = action;
  try {
    yield makeCall(PATCH, `${CAR_URL}/${id}`, data);
    yield makeCall(PATCH, `${CAR_VERIFICATION_URL}/${id}`);
    yield put(startVerificationSuccess());
    history.push('/my-cars');
  } catch (error) {
    yield put(startVerificationFailure(error));
  }
}

function* watchDeleteEvent() {
  yield takeEvery(deleteCar, deleteCarHandler);
  yield takeEvery(startVerification, verificationHandler);
}

export default watchDeleteEvent;
