import { styled } from 'linaria/react';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { breakpoints } from '../../constants/breakpoints';

export const Socials = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0;
    }

    .icon {
      margin: 0 16px;
    }
  }
`;

export const Title = styled.h3`
  @media ${breakpoints.mobile} {
    & {
      margin: 0;
      color: var(--primary-text);
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      line-height: 28px;
    }
  }
`;

export const Separator = styled.span`
  @media ${breakpoints.mobile} {
    & {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 38px;
      overflow: hidden;
      color: var(--primary-text);
      font-size: 12px;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 100%;
        height: 1px;
        background-color: #d8d8d8;
      }

      &::before {
        margin-left: -100%;
        left: -8px;
      }

      &::after {
        margin-right: -100%;
        right: -8px;
      }
    }
  }
`;

export const CustomInputLabel = styled(InputLabel)`
  @media ${breakpoints.mobile} {
    && {
      color: var(--primary-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin: 16px 0 8px 0;
    }
  }
`;

export const PopupWrap = styled.div`
  & {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 14px;
    cursor: pointer;
  }
`;

// TODO add shared Input
export const CustomLargeInput = styled(Input)``;

// TODO add shared code Input
export const CustomShortInput = styled(Input)``;
