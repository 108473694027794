export const dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

export const passportMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const driverLicenceMask = [
  /\d/,
  /\d/,
  ' ',
  /[A-Za-zА-Яа-я0-9]/i,
  /[A-Za-zА-Яа-я0-9]/i,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

// TODO add uppercase function
export const registrationNumberMask = [
  /[АВЕКМНОРСТУХ]/i,
  /\d/,
  /\d/,
  /\d/,
  /[АВЕКМНОРСТУХ]/i,
  /[АВЕКМНОРСТУХ]/i,
  /\d/,
  /\d/,
  /\d/,
];
