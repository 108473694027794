import { put, takeEvery, select } from 'redux-saga/effects';
import { authActions, errorActions } from '../../actions';
import { httpVerbs } from '../../../constants';
import {
  SIGNIN_URL,
  SIGNOUT_URL,
  SIGNUP_URL,
  RESET_PASSWORD_URL,
} from '../../../constants/endpoints';
import {
  defaultError,
  resetPasswordErrors,
  loginErrors,
  signupErrors,
} from '../../../constants/errors';
import { makeAuthCall, makeCall } from '../../../services/api-service';
import { deleteUserFromStorage } from '../../../services/auth-service';

const { POST } = httpVerbs;
const {
  signInUser,
  signInUserSuccess,
  signInUserError,
  signOutUser,
  signOutUserSuccess,
  signOutUserError,
  signUpUser,
  signUpUserSuccess,
  signUpUserError,
  resetPassword,
  resetPasswordError,
  setInitialUser,
} = authActions;

const { setError } = errorActions;

function* signInUserSaga(action) {
  const {
    payload: { history, nextStep, authData },
  } = action;
  try {
    const response = yield makeAuthCall(POST, SIGNIN_URL, authData);
    yield put(signInUserSuccess(response));
    if (nextStep) {
      history.push(nextStep);
    } else {
      window.location = '/';
    }
  } catch (error) {
    const message = error.response ? loginErrors[error.response.status] : defaultError;
    yield put(signInUserError({ error: message }));
  }
}
function* signUpUserSaga(action) {
  const {
    payload: { history, nextStep, authData },
  } = action;
  try {
    const response = yield makeAuthCall(POST, SIGNUP_URL, authData);
    yield put(signUpUserSuccess(response));
    if (nextStep) {
      history.push(nextStep);
    } else {
      window.location = '/';
    }
  } catch (error) {
    const message = error.response ? signupErrors[error.response.status] : defaultError;
    yield put(signUpUserError({ error: message }));
  }
}
function* signOutUserSaga() {
  try {
    const { data } = yield makeCall(POST, SIGNOUT_URL);
    deleteUserFromStorage();
    yield put(signOutUserSuccess({ data }));

    window.location = '/';
  } catch (error) {
    yield put(signOutUserError(error));
  }
}

function* resetPasswordSaga(action) {
  const {
    payload: { password },
  } = action;
  try {
    const { id } = yield select(({ auth: { user } }) => user);
    const user = yield makeAuthCall(POST, RESET_PASSWORD_URL, { password, id });
    yield put(setInitialUser(user));

    window.location = '/';
  } catch (error) {
    const message = error.response ? resetPasswordErrors[error.response.status] : defaultError;
    yield put(setError(message));
    yield put(resetPasswordError(error));
  }
}

function* authSaga() {
  yield takeEvery(signInUser, signInUserSaga);
  yield takeEvery(signOutUser, signOutUserSaga);
  yield takeEvery(signUpUser, signUpUserSaga);
  yield takeEvery(resetPassword, resetPasswordSaga);
}

export { authSaga };
