import { put, takeEvery, select } from 'redux-saga/effects';
import { userActions, authActions, errorActions } from '../../actions';
import {
  USER_DATA_COMPLETENESS_URL,
  SUBMIT_USER_EMAIL_URL,
  CONFIRM_USER_EMAIL_URL,
  SUBMIT_USER_PHONE_URL,
  CONFIRM_USER_PHONE_URL,
  CONFIRM_RECOVERY_EMAIL_URL,
  SUBMIT_RECOVERY_EMAIL_URL,
} from '../../../constants/endpoints';
import { httpVerbs } from '../../../constants';
import {
  submitEmailErrors,
  confirmEmailErrors,
  submitPhoneErrors,
  confirmPhoneErrors,
  defaultError,
} from '../../../constants/errors';
import { getUserInfo } from '../../../services/auth-service';
import { makeCall } from '../../../services/api-service';

const { GET, POST, PATCH } = httpVerbs;
const {
  checkUserDataCompleteness,
  checkUserDataCompletenessSuccess,
  checkUserDataCompletenessError,
  submitUserEmail,
  submitUserEmailSuccess,
  submitUserEmailError,
  submitUserPhone,
  submitUserPhoneSuccess,
  submitUserPhoneError,
  submitRecoveryEmail,
  submitRecoveryEmailSuccess,
  submitRecoveryEmailError,
  confirmUserEmail,
  confirmUserEmailSuccess,
  confirmUserEmailError,
  confirmUserPhone,
  confirmUserPhoneSuccess,
  confirmUserPhoneError,
  confirmRecoveryEmail,
  confirmRecoveryEmailSuccess,
  confirmRecoveryEmailError,
} = userActions;
const { closeAuthPopup } = authActions;
const { setError } = errorActions;

function* checkUserDataCompletenessSaga() {
  const { id } = getUserInfo();
  try {
    const { data } = yield makeCall(GET, `${USER_DATA_COMPLETENESS_URL}/${id}`);
    yield put(checkUserDataCompletenessSuccess(data));
  } catch (error) {
    yield put(checkUserDataCompletenessError(error));
  }
}

function* submitEmailSaga() {
  // const {
  //   payload: { email },
  // } = action;

  const { id } = getUserInfo();
  try {
    yield makeCall(PATCH, `${SUBMIT_USER_EMAIL_URL}/${id}`);
    yield put(submitUserEmailSuccess());
  } catch (error) {
    const message = error.response ? submitEmailErrors[error.response.status] : defaultError;
    yield put(submitUserEmailError({ error: message }));
  }
}
function* submitPhoneSaga(action) {
  const {
    payload: { phone },
  } = action;

  const { id } = getUserInfo();
  try {
    yield makeCall(PATCH, `${SUBMIT_USER_PHONE_URL}/${id}`, { phone });
    yield put(submitUserPhoneSuccess());
  } catch (error) {
    const message = error.response ? submitPhoneErrors[error.response.status] : defaultError;
    yield put(submitUserPhoneError({ error: message }));
  }
}

function* submitRecoveryEmailSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield makeCall(POST, SUBMIT_RECOVERY_EMAIL_URL, payload);
    yield put(submitRecoveryEmailSuccess(data.id));
  } catch (error) {
    const message = error.response ? submitEmailErrors[error.response.status] : defaultError;
    yield put(submitRecoveryEmailError({ error: message }));
  }
}

function* confirmEmailSaga(action) {
  const {
    payload: { confirmationCode },
  } = action;
  const { id } = getUserInfo();
  try {
    const { data } = yield makeCall(POST, `${CONFIRM_USER_EMAIL_URL}/${id}`, { confirmationCode });
    yield put(confirmUserEmailSuccess(data));
    yield put(closeAuthPopup());
  } catch (error) {
    const message = error.response ? confirmEmailErrors[error.response.status] : defaultError;
    yield put(confirmUserEmailError({ error: message }));
  }
}
function* confirmPhoneSaga(action) {
  const {
    payload: { confirmationCode },
  } = action;
  const { id } = getUserInfo();
  try {
    const { data } = yield makeCall(POST, `${CONFIRM_USER_PHONE_URL}/${id}`, { confirmationCode });
    yield put(confirmUserPhoneSuccess(data));
    yield put(closeAuthPopup());
  } catch (error) {
    const message = error.response ? confirmPhoneErrors[error.response.status] : defaultError;
    yield put(confirmUserPhoneError({ error: message }));
  }
}

function* confirmRecoveryEmailSaga(action) {
  const {
    payload: { data, history },
  } = action;
  try {
    const id = yield select(({ confirmation: { userId } }) => userId);
    const result = yield makeCall(POST, `${CONFIRM_RECOVERY_EMAIL_URL}/${id}`, data);
    yield put(confirmRecoveryEmailSuccess(result.data.id));
    history.push('/reset-password');
  } catch (error) {
    const message = error.response ? confirmEmailErrors[error.response.status] : defaultError;
    yield put(confirmRecoveryEmailError({ error: message }));
  }
}

function* confirmationSaga() {
  yield takeEvery(checkUserDataCompleteness, checkUserDataCompletenessSaga);
  yield takeEvery(submitUserEmail, submitEmailSaga);
  yield takeEvery(submitUserPhone, submitPhoneSaga);
  yield takeEvery(submitRecoveryEmail, submitRecoveryEmailSaga);
  yield takeEvery(confirmUserEmail, confirmEmailSaga);
  yield takeEvery(confirmUserPhone, confirmPhoneSaga);
  yield takeEvery(confirmRecoveryEmail, confirmRecoveryEmailSaga);
}

export { confirmationSaga };
