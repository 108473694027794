import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const CarouselWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: calc(100% + 32px);
      margin-left: -16px;
      position: relative;
      overflow: hidden;
    }

    .car {
      height: 40vw;
      max-height: 770px;
      display: block;
      overflow: hidden;
      width: 100%;
      object-fit: cover;
    }

    .car-wrapper {
      width: 100%;
    }

    .MuiMobileStepper-root {
      position: absolute;
      bottom: 50%;
      width: 99%;
      background: none;
      font-size: 0;
      color: #fff;
    }

    .MuiButton-textSizeSmall {
      position: relative;
      bottom: 0;
      min-width: 30px;
      height: 30px;
      padding: 0;
      margin: 0 5px 0 0;
      opacity: 0.3;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 0 8px 0 rgba(83, 92, 115, 0.2);
    }

    .photo-badge {
      position: absolute;
      bottom: 10px;
      left: 50%;
      width: 85px;
      transform: translateX(-50%);
      z-index: 1;
      text-align: center;

      font-size: 16px;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 10px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      width: calc(100% + 50px);
      margin-left: -25px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 100vw;
      margin-left: -25px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: 100vw;
      transform: translate(770px);
      margin: 0 -50vw 0 0;
      margin-left: calc(2px - 50vw - 52px);
    }
  }
`;
