import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import {
  submitRecoveryEmail,
  confirmRecoveryEmail,
  resetConfirmation,
} from '../../../../redux/actions/user';
import {
  submitEmailValidationSchema,
  confirmationCodeValidationSchema,
} from '../../../../utils/validate';
import { EmailForm } from '../EmailForm';
import { ConfirmationCodeForm } from '../ConfirmationCode';

import { Title } from '../../featureStyles';
import { ForgotPasswordWrapper, TitleMain } from './styles';

export const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSubmit } = useSelector(({ confirmation }) => confirmation);
  const onFormSubmit = useCallback(
    values => {
      dispatch(submitRecoveryEmail(values));
    },
    [dispatch],
  );
  const onConfirmationSubmit = useCallback(
    values => {
      dispatch(confirmRecoveryEmail({ data: values, history }));
    },
    [dispatch, history],
  );
  useEffect(() => () => dispatch(resetConfirmation()), [dispatch]);

  return (
    <ForgotPasswordWrapper>
      <TitleMain>Восстановление пароля</TitleMain>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={onFormSubmit}
        validationSchema={submitEmailValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <EmailForm />
      </Formik>
      {isSubmit && (
        <Formik
          initialValues={{ confirmationCode: '' }}
          onSubmit={onConfirmationSubmit}
          validationSchema={confirmationCodeValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <ConfirmationCodeForm />
        </Formik>
      )}
    </ForgotPasswordWrapper>
  );
};
