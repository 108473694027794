import React, { useState } from 'react';
import { NavButton } from '../../components/Button/NavButton';
import getNavigation from '../../utils/navigation';
import tabs from './tabsMapping';

import { CalendarContainer } from './styles';

export default ({ parentNavigation }) => {
  const [tabValue, updateTabValue] = useState(0);
  const tabsHandleChange = (event, value) => updateTabValue(value);

  const { content } = tabs[tabValue];
  const childNavigation = {
    prev: tabs[tabValue - 1] ? tabValue - 1 : null,
    next: tabs[tabValue + 1] ? tabValue + 1 : null,
    handler: tabsHandleChange,
  };
  const navigation = getNavigation(childNavigation, parentNavigation);
  return (
    <CalendarContainer>
      <div className="content">
        {content}
        <div className="navigation">
          <NavButton {...navigation} />
        </div>
      </div>
    </CalendarContainer>
  );
};
