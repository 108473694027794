import React from 'react';
import DatePickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import ruLocale from 'date-fns/locale/ru';
import localizationUtils from '../../assets/locale';
import 'react-day-picker/lib/style.css';

function parseDate(str, format) {
  // TODO: add other locales if needed (3rd parameter is locale)
  const parsed = dateFnsParse(str, format, new Date(), { locale: ruLocale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format) {
  // TODO: add other locales if needed (3rd parameter is locale)
  return dateFnsFormat(date, format, { locale: ruLocale });
}

export const DayPickerInput = ({ classNames, key, format, dayPickerProps, ...props }) => (
  <DatePickerInput
    {...props}
    placeholder=""
    format={format}
    formatDate={formatDate}
    parseDate={parseDate}
    dayPickerProps={{
      locale: 'ru',
      localeUtils: localizationUtils,
      ...dayPickerProps,
    }}
    classNames={{ overlay: 'overlay-wrap', ...classNames }}
  />
);
