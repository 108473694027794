import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as ClosingCross } from '../../assets/icons/closingCross.svg';

import { ConfirmWrapper, Text, Title } from './styles';

export const ConfirmModal = ({ open, handleClose, sumbmit, title = '', text = '' }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <ConfirmWrapper>
        {title && <Title>{title}</Title>}
        {text && <Text>{text}</Text>}
        <div className="actions">
          <Button onClick={handleClose} color="secondary" size="small">
            Отменить
          </Button>
          <Button onClick={sumbmit} className="confirm" color="secondary" size="small" autoFocus>
            Подтвердить
          </Button>
          <ClosingCross className="close" onClick={handleClose} />
        </div>
      </ConfirmWrapper>
    </Dialog>
  );
};
