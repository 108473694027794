import { styled } from 'linaria/react';

export const StyledPriceSort = styled.div`
  & {
    display: flex;
    align-items: center;
  }

  .title {
    color: var(--primary-text);
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 10px 0 0;
  }

  .btn {
    display: flex;
    align-items: center;
    margin: 0;

    &.-up {
      margin: 0 7px 0 0;
    }
  }

  .icon {
    height: 16px;
    width: 16px;
    fill: #8f9bb3;
  }

  .icon.-active {
    fill: #222b45;
  }
`;
