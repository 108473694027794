import {
  carTransmissionLayout,
  carTransmissionType,
  carDevices,
  carEngineType,
  searchFiltersAdditions,
  searchFiltersSeats,
  searchFiltersYears,
  searchFiltersBody,
  searchFiltersCars,
} from '../assets/data';

const additions = Object.keys(searchFiltersAdditions);
const additionsState = additions.reduce((acc, addition) => {
  acc[addition] = false;
  return acc;
}, {});
const transmissionType = Object.values(carTransmissionType).map(transmission => transmission.value);
const transmissionTypeState = transmissionType.reduce((acc, transmission) => {
  acc[transmission] = false;
  return acc;
}, {});
const transmissionLayout = Object.values(carTransmissionLayout).map(
  transmission => transmission.value,
);
const transmissionLayoutState = transmissionLayout.reduce((acc, transmission) => {
  acc[transmission] = false;
  return acc;
}, {});
const engineType = Object.values(carEngineType).map(engine => engine.value);
const engineTypeState = engineType.reduce((acc, engine) => {
  acc[engine] = false;
  return acc;
}, {});
const devices = Object.keys(carDevices).map(device => carDevices[device].data.name);
const devicesState = devices.reduce((acc, device) => {
  acc[device] = false;
  return acc;
}, {});

export const mapSearchFiltersDefaultValues = () => ({
  noMilLim: false,
  fuelLiterCompensationPrice: false,
  afterRentWashingPrice: false,
  area: null,
  seats: null,
  brand: null,
  prodYear: null,
  bodyType: null,
  dailyMil: null,
  ...transmissionTypeState,
  ...transmissionLayoutState,
  ...engineTypeState,
  ...devicesState,
});

export const mapSearchFiltersBarDefaultValues = () => ({
  delivery: false,
  immediateBooking: false,
  noMilLim: false,
  fuelLiterCompensationPrice: false,
  afterRentWashingPrice: false,
  area: null,
  seats: null,
  brand: null,
  prodYear: null,
  bodyType: null,
  dailyMil: null,
  ...transmissionTypeState,
  ...transmissionLayoutState,
  ...engineTypeState,
  ...devicesState,
});

const { keys: seats } = searchFiltersSeats;
const { keys: years } = searchFiltersYears;
const { keys: carBody } = searchFiltersBody;
const { keys: cars } = searchFiltersCars;

export const mapSearchFitersToKeys = () => ({ additions, seats, years, carBody, cars });
