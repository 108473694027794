import { handleActions } from 'redux-actions';

import { carActions } from '../../actions';

const { getCardData, getCardDataSuccess, getCardDataFailure } = carActions;

const initialState = { data: {}, loading: true, error: null };
export default handleActions(
  {
    [getCardData](state) {
      return { ...state, loading: true };
    },

    [getCardDataSuccess](
      state,
      {
        payload: { data },
      },
    ) {
      return { ...state, data, loading: false };
    },

    [getCardDataFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, error, loading: false };
    },
  },
  initialState,
);
