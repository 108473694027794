import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const RegisterForm = styled.form`
  @media ${breakpoints.mobile} {
    & {
      font-weight: 400;
      text-align: start;
      width: 100%;
    }

    .label {
      display: block;
      font-family: 'Open Sans';
      margin: 16px 0 8px 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .btn {
      display: flex;
      width: 200px;
      font-size: 16px;
      font-weight: 600;
      margin-top: 32px;
      color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(51, 36, 3, 0.08);
      border-radius: 4px;
      background-color: #006cde;
      &:disabled {
        color: #ffffff;
        background-color: #006cde;
        opacity: 0.5;
      }
      &:hover {
        background-color: #1973e8;
      }
    }

    .hint-wrapper {
      padding-bottom: 10px;
      margin: 10px 0 4px 0;
    }

    .label:first-child {
      margin-top: 25px;
    }

    .input-hint {
      font-weight: 400;
      color: var(--grey-text);
    }

    .hint-wrapper {
      padding-bottom: 10px;
    }

    .select {
      width: 100%;
    }

    .filepond--wrapper .filepond--list-scroller {
      height: 100%;
    }

    .licence-wrapper {
      display: inline-block;
      margin-top: 15px;
    }

    .licence-wrapper .licence {
      display: flex;
      align-items: flex-start;
      margin: 0;
    }

    .checkbox {
      padding: 0;
      margin-right: 10px;
    }

    .MuiFormControlLabel-root {
      margin-right: 5px;
      margin-top: -4px;
    }

    .PrivateSwitchBase-root-119 {
      padding: 0 9px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      font-weight: 400;
      text-align: start;
      margin: 0;
    }

    .label {
      display: block;
      margin: 15px 0 10px 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .btn {
      display: flex;
      width: 190px;
      font-size: 16px;
      font-weight: 600;
      margin-top: 24px;
      color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(51, 36, 3, 0.08);
      border-radius: 4px;
      background-color: #006cde;
      &:disabled {
        color: #ffffff;
        background-color: #006cde;
        opacity: 0.5;
      }
      &:hover {
        background-color: #1973e8;
      }
    }

    .input-hint {
      font-weight: 400;
      color: var(--grey-text);
    }

    .hint-wrapper {
      padding-bottom: 10px;
      margin: 10px 0 4px 0;
    }

    .label:first-child {
      margin-top: 20px;
    }

    .hint-wrapper {
      padding-bottom: 10px;
    }

    .select {
      width: 420px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      font-weight: 400;
      text-align: start;
      margin: 0;
    }

    .label {
      display: block;
      font-family: 'Open Sans';
      margin: 16px 0 13px 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .btn {
      display: flex;
      width: 190px;
      font-size: 16px;
      font-weight: 600;
      margin-top: 24px;
      color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(51, 36, 3, 0.08);
      border-radius: 4px;
      background-color: #006cde;
      &:disabled {
        color: #ffffff;
        background-color: #006cde;
        opacity: 0.5;
      }
      &:hover {
        background-color: #1973e8;
      }
    }

    .input-hint {
      font-weight: 400;
      color: var(--grey-text);
    }

    .hint-wrapper {
      padding-bottom: 10px;
      margin: 10px 0 4px 0;
    }

    .label:first-child {
      margin-top: 20px;
    }

    .hint-wrapper {
      padding-bottom: 10px;
    }

    .select {
      width: 420px;
    }
  }
`;
