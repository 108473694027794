export const getMinutes = timer => Math.floor(timer / 60);

export const getSeconds = (minutes, timer) => timer - minutes * 60;

export const getMinutesMessage = minutes => {
  switch (minutes) {
    case 0:
      return '';
    case 1:
      return '1 минуту';
    case 2:
    case 3:
    case 4:
      return `${minutes} минуты`;
    default:
      return `${minutes} минут`;
  }
};
