import { styled } from 'linaria/react';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const CustomAutocomplete = styled(Autocomplete)`
  .popup-indicator {
    width: 24px;
    height: 24px;
  }

  .MuiAutocomplete-popupIndicator:hover {
    background: none;
  }
`;
