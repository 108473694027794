import React, { useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Advice } from '../../Advice';
import adviceData from '../../../assets/data/advice.json';

import { Error } from '../../../styledComponents';
import { TabTitle, TabSubtitle, TabLabel } from '../featureStyles';
import { RulesWrapper } from './styles';

export const TabRules = () => {
  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    rentLocations,
    dailyMilageLimit,
    weeklyMilageLimit,
    monthlyMilageLimit,
    guestRequirements = {},
  } = values;
  const { minAge, yearsOfExperience } = guestRequirements;
  return (
    <RulesWrapper>
      <TabTitle>Установите ваши правила аренды</TabTitle>
      <FormGroup className="formgroup">
        <TabSubtitle className="rent-rules">Где можно использовать авто?</TabSubtitle>
        <RadioGroup aria-label="limitations" name="limitations" defaultValue={rentLocations}>
          <FormControlLabel
            className="radio"
            value="city"
            control={<Radio onChange={() => setFieldValue('rentLocations', 'city')} />}
            label="Только в городе и ближайших окрестностях"
          />
          <FormControlLabel
            className="radio"
            value="country"
            control={<Radio onChange={() => setFieldValue('rentLocations', 'country')} />}
            label="По всей стране"
          />
          <FormControlLabel
            className="radio"
            value="world"
            control={<Radio onChange={() => setFieldValue('rentLocations', 'world')} />}
            label="По всей стране и заграницей"
          />
        </RadioGroup>
      </FormGroup>
      <FormGroup className="formgroup">
        <TabSubtitle className="subtitle">Есть ли ограничения по пробегу?</TabSubtitle>
        <TabLabel htmlFor="dailyMilageLimit">В день, км:</TabLabel>
        <Field
          id="dailyMilageLimit"
          className="input"
          placeholder="Например, 0 или 300"
          defaultValue={dailyMilageLimit}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('dailyMilageLimit', e.target.value);
          }}
        />
        {errors.dailyMilageLimit && touched.dailyMilageLimit ? (
          <Error>{errors.dailyMilageLimit}</Error>
        ) : null}
        <TabLabel htmlFor="weeklyMilageLimit">В неделю, км:</TabLabel>
        <Field
          id="weeklyMilageLimit"
          className="input"
          placeholder="Например, 0 или 600"
          defaultValue={weeklyMilageLimit}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('weeklyMilageLimit', e.target.value);
          }}
        />
        {errors.weeklyMilageLimit && touched.weeklyMilageLimit ? (
          <Error>{errors.weeklyMilageLimit}</Error>
        ) : null}
        <TabLabel htmlFor="monthlyMilageLimit">В месяц, км:</TabLabel>
        <Field
          id="monthlyMilageLimit"
          className="input"
          placeholder="Например, 0 или 3500"
          defaultValue={monthlyMilageLimit}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('monthlyMilageLimit', e.target.value);
          }}
        />
        {errors.monthlyMilageLimit && touched.monthlyMilageLimit ? (
          <Error>{errors.monthlyMilageLimit}</Error>
        ) : null}
        <Advice advice={adviceData.rulesMillage} styleModificator="advice -main -blue" />
      </FormGroup>
      <FormGroup className="formgroup">
        <TabSubtitle className="subtitle">Какие требования к водителю?</TabSubtitle>
        <TabLabel htmlFor="minAge">Минимальный возраст:</TabLabel>
        <Field
          id="minAge"
          className="input"
          placeholder="Например, 0 или 3500"
          defaultValue={minAge}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('guestRequirements', {
              ...guestRequirements,
              minAge: e.target.value,
            });
          }}
        />
        {errors.guestRequirements && errors.guestRequirements.minAge && touched.minAge ? (
          <Error>{errors.guestRequirements.minAge}</Error>
        ) : null}
        <TabLabel htmlFor="yearsOfExperience">Минимальный стаж:</TabLabel>
        <Field
          id="yearsOfExperience"
          className="input"
          placeholder="Например, 18"
          defaultValue={yearsOfExperience}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('guestRequirements', {
              ...guestRequirements,
              yearsOfExperience: e.target.value,
            });
          }}
        />
        {errors.guestRequirements &&
        errors.guestRequirements.yearsOfExperience &&
        touched.yearsOfExperience ? (
          <Error>{errors.guestRequirements.yearsOfExperience}</Error>
        ) : null}
        <Advice advice={adviceData.rulesAge} styleModificator="advice -main -blue" />
      </FormGroup>
    </RulesWrapper>
  );
};
