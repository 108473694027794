const calculateMileageLimit = (
  rentDuration,
  dailyMilageLimit,
  weeklyMilageLimit,
  monthlyMilageLimit,
) => {
  switch (true) {
    case rentDuration < 7:
      return Math.ceil(rentDuration * dailyMilageLimit);
    case rentDuration >= 7 && rentDuration < 30:
      return Math.ceil(rentDuration * (weeklyMilageLimit / 7));
    case rentDuration >= 30:
      return Math.ceil(rentDuration * (monthlyMilageLimit / 30));
    default:
      return 'ошибка в расчетах';
  }
};

export default calculateMileageLimit;
