import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import { checkHost } from '../../../../services/auth-service';
import { signOutUser, showLoginPopup } from '../../../../redux/actions/auth';
import { mobileNavigationSchema } from '../navigationSchema';
import { MobileNavWrapper, SideBarMenu } from './styles';

export const MobileNav = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const isLoggedIn = useSelector(({ auth: { isLoggedIn } }) => isLoggedIn);
  const isHost = checkHost();

  const SideBarIcon = isLoggedIn ? PersonIcon : MenuIcon;
  const toggleOpen = useCallback(() => {
    setIsOpened(prevIsOpened => !prevIsOpened);
  }, []);
  const handleClickAway = useCallback(() => {
    setIsOpened(false);
  }, []);
  const signOutClick = useCallback(() => {
    dispatch(signOutUser({ history }));
    toggleOpen();
  }, [dispatch, history, toggleOpen]);
  const signInClick = useCallback(() => {
    dispatch(showLoginPopup({ history }));
    toggleOpen();
  }, [dispatch, history, toggleOpen]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MobileNavWrapper>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleOpen}
          className="icon-wrap"
        >
          {isOpened ? <CloseIcon className="menu-icon" /> : <SideBarIcon className="menu-icon" />}
        </IconButton>
        <SideBarMenu
          variant="persistent"
          anchor="right"
          open={isOpened}
          PaperProps={{ className: 'menu' }}
        >
          <List className="list">
            {isHost ? (
              <ListItem button onClick={toggleOpen} className="item">
                <Link to="/my-cars" className="link">
                  Мои машины
                </Link>
              </ListItem>
            ) : null}
            {mobileNavigationSchema.map(({ title, key, path }) => (
              <a href={path} className="link">
                <ListItem button key={key} onClick={toggleOpen} className="item">
                  {title}
                </ListItem>
              </a>
            ))}
            <Divider className="divider" />
            {isLoggedIn ? (
              <ListItem button className="link -logout" onClick={signOutClick}>
                Выйти
              </ListItem>
            ) : (
              <ListItem button className="link -login" onClick={signInClick}>
                Войти
              </ListItem>
            )}
          </List>
        </SideBarMenu>
      </MobileNavWrapper>
    </ClickAwayListener>
  );
};
