import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { signupValidationSchema } from '../../../../utils/validate';
import { signUpUser, showLoginPopup, closeAuthPopup } from '../../../../redux/actions/auth';
import { RegistrationForm } from '../Form';

import { PopupWrap } from '../../featureStyles';

export const RegistrationPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onFormSubmit = useCallback(
    ({ email, password }) => {
      dispatch(signUpUser({ authData: { email, password }, history }));
    },
    [dispatch, history],
  );
  const onLoginClick = useCallback(() => dispatch(showLoginPopup()), [dispatch]);

  return (
    <PopupWrap>
      <CloseIcon className="close" onClick={() => dispatch(closeAuthPopup())} />
      <Formik
        initialValues={{
          email: '',
          password: '',
          isAgree: true,
        }}
        validateOnChange={false}
        validationSchema={signupValidationSchema}
        onSubmit={onFormSubmit}
      >
        <RegistrationForm onLoginClick={onLoginClick} />
      </Formik>
    </PopupWrap>
  );
};
