export const defaultError = 'Неизвестная ошибка. Попробуйте позже';

export const postCarEditErrors = {
  401: 'Недостаточно прав на данное действие',
  404: 'Сервер отдыхает',
  409: 'Машина находится на проверке. Изменение данных недоступно',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const listCarErrors = {
  401: 'Недостаточно прав на данное действие',
  403: 'Действие невозможно',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const deleteCarErrors = {
  204: 'Машина уже удалена. Обновите страницу',
  401: 'Недостаточно прав на данное действие',
  400: 'Неизвестная ошибка. Обратитесь в саппорт за дальнейшими инструкциями',
  404: 'Данная машина не найдена в системе',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const registerCarErrors = {
  400: 'Введите корректные данные',
  401: 'Недостаточно прав на данное действие',
  409: 'Такой гос. номер уже существует в системе',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const postScoringErrors = {
  401: 'Недостаточно прав на данное действие',
  500: 'На сервере не пускают такой запрос',
};

export const getScoringErrors = {
  401: 'Недостаточно прав на данное действие',
  500: 'На сервере не пускают такой запрос',
};

export const searchCarsErrors = {
  401: 'Недостаточно прав на данное действие',
  500: 'На сервере не пускают такой запрос',
};

export const publishCarErrors = {
  401: 'Недостаточно прав на данное действие',
  500: 'На сервере не пускают такой запрос',
};

export const unpublishCarErrors = {
  401: 'Недостаточно прав на данное действие',
  500: 'На сервере не пускают такой запрос',
};

export const userUpdateErrors = {
  400: 'Введите корректные данные',
  401: 'Недостаточно прав на данное действие',
  403: 'Пользовательские данные уже существуют в системе',
  404: 'Данный пользователь не найден в системе',
  409: 'Такой паспорт уже существует в системе',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const submitEmailErrors = {
  400: 'Введите правильный email',
  401: 'Недостаточно прав на данное действие',
  404: 'Пользователь с такой почтой не найден',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const confirmEmailErrors = {
  400: 'Неверный код подтверждения',
  401: 'Недостаточно прав на данное действие',
  404: 'Пользователь с такой почтой не найден',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};
export const submitPhoneErrors = {
  400: 'Введите правильный телефон',
  401: 'Недостаточно прав на данное действие',
  404: 'Пользователь с таким телефоном не найден',
  409: 'Такой телефон уже существует в системе',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const confirmPhoneErrors = {
  400: 'Неверный код подтверждения',
  401: 'Недостаточно прав на данное действие',
  404: 'Пользователь с таким телефоном не найден',
  409: 'Такой телефон уже существует в системе',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const resetPasswordErrors = {
  400: 'Введите корректный пароль или воспользуйтесь функцией "Забыли пароль"',
  404: 'Данный пользователь не найден в системе',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const loginErrors = {
  400: 'Введите корректный логин и пароль',
  401: 'Неверный логин или пароль',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};

export const signupErrors = {
  400: 'Такой email уже существует в системе',
  500: 'В данный момент действие невозможно. Попробуйте позже',
};
