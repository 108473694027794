import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const EditCalendarPeriodWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      padding: 20px 16px 0 16px;
    }

    .title {
      line-height: 20px;
    }

    .edit-calendar-wrapper {
      margin-top: 15px;
    }

    .text {
      margin: 16px 0 24px;
    }

    .icon {
      left: 175px;
      font-size: 24px;
      color: #9c9c9c;
    }

    .icon:hover {
      color: #066bd6;
    }

    .time {
      font-family: 'Open Sans';
      color: var(--primary-text);
      font-size: 16px;
      font-weight: 600;
    }

    .menu-icon {
      width: 24px;
      height: 24px;
      color: #222b45;
      cursor: pointer;
      padding-bottom: 32px;
    }

    .edit-calendar-divider {
      margin-top: 20px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      width: 360px;
      box-sizing: border-box;
      height: 100%;
      padding: 35px 24px 40px 0;
    }

    .edit-calendar-divider {
      margin: 25px 0 0 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 440px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: 530px;
    }
  }
`;

export const InputsContainer = styled.div`
  @media ${breakpoints.tablet} {
    & {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  & {
    display: flex;
    justify-content: flex-end;
  }
`;

export const CustomButton = styled.button`
  & {
    margin: 16px 0 0 24px;
    outline: none;
    border: none;
    background-color: #fff;
    color: ${props => props.color || '#066BD6'};
    font-size: 16px;
    font-weight: ${props => props.fontWeight || 400};
    line-height: 16px;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      width: 100%;

      &:last-child {
        margin-top: 10px;
      }
    }

    .DayPickerInput {
      width: 100%;
    }

    .DayPickerInput input {
      font-size: 16px;
      width: 100%;
      height: 50px;
      padding: 15px;
      box-sizing: border-box;
      border: 1px solid #8f9bb3;
      border-radius: 4px;
      background-color: #ffffff;
      color: var(--primary-text);
    }

    .icon {
      position: absolute;
      top: 40px;
      left: calc(100% - 50px);
    }

    .datepicker-label {
      display: inline-block;
      margin: 0 0 5px 0;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      width: calc((100% - 35px) / 2);

      &:last-child {
        margin-top: 0;
      }

      &:first-child {
        margin-right: 35px;
      }
    }

    .icon {
      display: none;
    }
  }

  @media ${breakpoints.laptop} {
    .icon {
      display: initial;
    }
  }
`;
