import { styled } from 'linaria/react';
import { breakpoints } from '../../constants/breakpoints';

export const CardContainer = styled.div`
  & {
    margin: 70px 16px 72px 16px;
    width: calc(100% - 32px);
  }
  @media ${breakpoints.tablet} {
    margin: 0 25px 72px 25px;
    width: calc(100% - 50px);
  }
  @media ${breakpoints.laptop} {
    margin: 0 24px 0 24px;
    max-width: 1440px;
  }
`;
