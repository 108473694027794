import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
// import UserRegistrationForm from './UserRegistrationForm';
import { LoginPopup } from './Login/Popup';
import { RegistrationPopup } from './Registration/Popup';
// import { ConfirmEmailPopup } from './ConfirmEmail/Popup';
// import ConfirmPhone from './ConfirmPhone';
import { closeAuthPopup } from '../../redux/actions/auth';
import { authPopupOptions } from '../../redux/types';

const { LOGIN_POPUP, SIGNUP_POPUP, CONFIRM_EMAIL_POPUP, CONFIRM_PHONE_POPUP } = authPopupOptions;

// TODO refactor it, this component only for view registration and login form
export const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AuthPopup = () => {
  const dispatch = useDispatch();
  const popupOption = useSelector(({ authPopup: { popupOption } }) => popupOption);

  const handleClose = () => {
    dispatch(closeAuthPopup());
  };

  const fullScreen = useMediaQuery('(max-width:768px)');

  return (
    <div>
      <Dialog
        open={popupOption === LOGIN_POPUP}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        // onExit={handleClose}
        onBackdropClick={handleClose}
        fullScreen={fullScreen}
      >
        <LoginPopup />
      </Dialog>
      <Dialog
        open={popupOption === SIGNUP_POPUP}
        scroll="body"
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        keepMounted
        // onExit={handleClose}
        onBackdropClick={handleClose}
      >
        <RegistrationPopup />
      </Dialog>
      {/* <Dialog
        open={popupOption === CONFIRM_EMAIL_POPUP}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        onExit={handleClose}
        fullScreen={fullScreen}
      >
        <ConfirmEmailPopup />
      </Dialog>
      <Dialog
        open={popupOption === CONFIRM_PHONE_POPUP}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        onExit={handleClose}
        fullScreen={fullScreen}
      >
        <ConfirmPhone />
      </Dialog> */}
    </div>
  );
};
