import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Toolbar from '@material-ui/core/Toolbar';
import { SearchResultLocation } from '../../SearchResults/Location';
import { DesktopNav } from './DesktopNav';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import { useQuery } from '../../../services/query-service';
import StyledSearchHeader from './styles';

export const SearchHeader = () => {
  const query = useQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dateStart = query.get('dateStart');
  const dateEnd = query.get('dateEnd');
  return (
    <StyledSearchHeader>
      <div className="container">
        <a href="/" className="link">
          <LogoIcon className="logo" />
        </a>
        <div className="control-wrap">
          {dateStart && dateEnd && <SearchResultLocation dateStart={dateStart} dateEnd={dateEnd} />}
        </div>
        {!isMobile && (
          <Toolbar className="toolbar">
            <DesktopNav />
          </Toolbar>
        )}
      </div>
    </StyledSearchHeader>
  );
};
