import React from 'react';
import { TabYourCar } from '../../components/CarEdit/TabYourCar';
import { TabDescription } from '../../components/CarEdit/TabDescription';
import { TabPhoto } from '../../components/CarEdit/TabPhoto';
import { Advice } from '../../components/Advice';
import { advice } from '../../assets/data';

export default {
  '0': {
    content: <TabYourCar />,
    advice: <Advice advice={advice.info} styleModificator="-main" />,
  },
  '1': {
    content: <TabDescription />,
    advice: <Advice advice={advice.description} styleModificator="-main" />,
  },
  '2': {
    content: <TabPhoto />,
    advice: <Advice advice={advice.photo} styleModificator="-main" />,
  },
};
