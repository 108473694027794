export const colors = {
  NOT_VERIFIED: '#E24C4C',
  VERIFICATION: '#FE9D3F',
  INVALID: '#E24C4C',
  PUBLISHED: '#06BB9B',
  STASHED: '#5D5D5D',
  BANNED: 'E24C4C',
  RENT: '#1973E8',
};

export const names = {
  NOT_VERIFIED: 'Не опубликована',
  VERIFICATION: 'На проверке',
  INVALID: 'Отказ',
  PUBLISHED: 'На поиске',
  STASHED: 'Скрыта',
  BANNED: 'Заблокирована',
  RENT: 'В аренде',
};

export const statuses = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFICATION: 'VERIFICATION',
  INVALID: 'INVALID',
  PUBLISHED: 'PUBLISHED',
  STASHED: 'STASHED',
  BANNED: 'BANNED',
  RENT: 'RENT',
};
