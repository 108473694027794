import React from 'react';
import { addDays, parseISO, isAfter } from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@material-ui/core/Divider';
import { DayPickerInput } from '../../DayPickerInput';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menuArrowLeft.svg';
import localizationUtils from '../../../assets/locale';

import { TabTitle, TabLabel, TabRecomendation } from '../featureStyles';
import {
  EditCalendarPeriodWrapper,
  CustomButton,
  Wrapper,
  ButtonsContainer,
  InputsContainer,
} from './styles';

export const EditCalendarPeriod = ({
  unavailabilityDates = { booked: [], blocked: [], rent: [] },
  setFieldValue,
  values,
  errors,
  handleSubmit,
  showDatePicker,
}) => {
  const { start, end } = values;
  // TODO refactor
  const { blocked, booked } = unavailabilityDates;
  const blockedPeriods = blocked.map(period => ({
    after: addDays(parseISO(period.startDate), -1),
    before: addDays(parseISO(period.endDate), 1),
  }));
  const bookedPeriods = booked.map(period => ({
    after: addDays(parseISO(period.startDate), -1),
    before: addDays(parseISO(period.endDate), 1),
  }));
  const disabledDates = [...blockedPeriods, ...bookedPeriods];
  return (
    <EditCalendarPeriodWrapper>
      <MenuIcon className="menu-icon" onClick={showDatePicker} />
      <TabTitle className="title">Машина недоступна:</TabTitle>
      <TabRecomendation className="text">
        Настройте период, когда ваша машина будет недоступна для аренды.
      </TabRecomendation>
      <form onSubmit={handleSubmit}>
        <InputsContainer>
          <Wrapper>
            <TabLabel className="datepicker-label" htmlFor="datepicker-start">
              С:
            </TabLabel>
            <DayPickerInput
              id="datepicker-start"
              placeholder="Выберите дату"
              value={start}
              format="dd.MM.yyyy"
              dayPickerProps={{
                selectedDays: [start, { start, end }],
                disabledDays: [
                  ...disabledDates,
                  {
                    before: new Date(),
                  },
                ],
                numberOfMonths: 1,
                locale: 'ru',
                localeUtils: localizationUtils,
              }}
              onDayChange={day => {
                setFieldValue('start', day);
                if (isAfter(day, end)) {
                  setFieldValue('end', addDays(day, 1));
                }
              }}
            />
            <DateRangeIcon className="icon" />
          </Wrapper>
          {errors.start ? <div>{errors.start}</div> : null}
          <Wrapper>
            <TabLabel className="datepicker-label" htmlFor="datepicker-end">
              По:
            </TabLabel>
            <DayPickerInput
              id="datepicker-end"
              value={end}
              format="dd.MM.yyyy"
              placeholder="Выберите дату"
              dayPickerProps={{
                selectedDays: [start, { start, end }],
                disabledDays: [...disabledDates, { before: new Date() }, { before: start }],
                month: start,
                fromMonth: start,
                numberOfMonths: 1,
                locale: 'ru',
                localeUtils: localizationUtils,
              }}
              onDayChange={day => setFieldValue('end', day)}
            />
            <DateRangeIcon className="icon" />
          </Wrapper>
          {errors.end ? <div>{errors.end}</div> : null}
        </InputsContainer>
        {errors.period ? <div>{errors.period}</div> : null}
        <Divider className="edit-calendar-divider" />
        <ButtonsContainer>
          <CustomButton color="#5D5D5D" onClick={showDatePicker} variant="text" size="small">
            Отмена
          </CustomButton>
          <CustomButton type="submit" className="submit-button" fontWeight="600">
            Сохранить период
          </CustomButton>
        </ButtonsContainer>
      </form>
    </EditCalendarPeriodWrapper>
  );
};
