import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const PriceWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
    }

    .text {
      margin: 4px 0 16px 0;
    }

    .formgroup {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .container {
      margin-top: 15px;
    }

    .select-wrap {
      margin-right: 40px;
    }

    .field {
      width: 100%;
      margin-bottom: 16px;
    }

    .price-link {
      cursor: pointer;
      text-decoration: none;
      border-bottom: dotted;
      border-width: thin;
      color: #066bd6;
    }
  }

  @media ${breakpoints.tablet} {
    .text {
      margin: 4px 0 20px 0;
    }

    .input {
      width: 310px;
    }

    .field {
      width: 310px;
    }

    .formgroup:last-child {
      margin-bottom: 24px;
    }
  }

  @media ${breakpoints.laptop} {
    .text {
      margin: 4px 0 16px 0;
    }
  }
`;
