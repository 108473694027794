import * as Yup from 'yup';
import { parse, isValid, isToday, differenceInYears } from 'date-fns';

// TODO: move somewhere
export const phoneRegExp = '/^(?=.{12,13}$).*\\+[0-9]{1,3}[0-9]{1,3}[0-9]{7}$/';

Yup.addMethod(Yup.string, 'confirmationCode', function() {
  return this.matches(/^\d{4}$/, 'Код подтверждения должен состоять из 4х чисел');
});

Yup.addMethod(Yup.date, 'format', function(formats) {
  return this.transform(function(value, originalValue) {
    if (this.isType(value) && isToday(value)) {
      return value;
    }
    const formatedValue = parse(originalValue, formats, new Date());
    return isValid(parse(originalValue, formats, new Date())) ? formatedValue : new Date('');
  });
});

Yup.setLocale({
  mixed: {
    notType: function notType(_ref) {
      switch (_ref.type) {
        case 'date':
          return 'Пожалуйста, введите корректную дату';
        default:
          return 'Неверный формат данных';
      }
    },
  },
});

export const priceSearchValidationSchema = Yup.object().shape({
  // priceMin: Yup.number()
  //   .min(5, 'Пароль не может быть меньше 5 символов')
  //   .max(64, 'Данное поле не может быть больше 64 символов')
  //   .required('Пожалуйста, введите пароль'),
  priceMax: Yup.number().test('isLess', 'Введите корректное значения', function(value) {
    const { priceMin } = this.parent;
    return value >= priceMin;
  }),
});

export const signupValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Пароль не может быть меньше 5 символов')
    .max(64, 'Данное поле не может быть больше 64 символов')
    .required('Пожалуйста, введите пароль'),
  email: Yup.string()
    .email('Пожалуйста, введите корректный email')
    .max(64, 'Данное поле не может быть больше 64 символов')
    .required('Пожалуйста, введите свой email'),
  isAgree: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями пользования сервисом'),
});

export const signinValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Пароль не может быть меньше 5 символов')
    .max(64, 'Пароль не может быть больше 64 символов')
    .required('Пожалуйста, введите пароль'),
  email: Yup.string()
    .email('Пожалуйста, введите корректный email')
    .max(64, 'Данное поле не может быть больше 64 символов')
    .required('Пожалуйста, введите свой email'),
});

export const submitEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Пожалуйста, введите корректный email')
    .max(64, 'Данное поле не может быть больше 64 символов')
    .required('Пожалуйста, введите свой email'),
});

export const submitPhoneValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, 'Пожалуйста, введите корректный номер')
    .required('Пожалуйста, введите свой номер телефона'),
});

export const confirmationCodeValidationSchema = Yup.object().shape({
  confirmationCode: Yup.string()
    .confirmationCode()
    .required('Пожалуйста, введите код подтверждения'),
});

export const restorePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Пароль не может быть меньше 5 символов')
    .max(64, 'Пароль не может быть больше 64 символов')
    .required('Пожалуйста, введите пароль'),
  confirmedPassword: Yup.string()
    .min(5, 'Пароль не может быть меньше 5 символов')
    .max(64, 'Пароль не может быть больше 64 символов')
    .required('Пожалуйста, подтвердите пароль'),
});

export const guestScoringValidationSchema = Yup.object().shape({
  passport: Yup.object().shape({
    serialNumber: Yup.string()
      .required('Пожалуйста, введите серию и номер паспорта')
      .matches(/^\d{10}$/, 'Введите корректную серию и номер паспорта'),
    registrationAddress: Yup.string().required('Пожалуйста, введите адрес регистрации'),
    dateOfIssue: Yup.date()
      .format('dd.MM.yyyy')
      .max(new Date(), 'Пожалуйста, введите корректную дату'),
  }),
  driversLicense: Yup.object().shape({
    serialNumber: Yup.string().required('Пожалуйста, введите серию и номер водительских прав'),
    dateOfIssue: Yup.date()
      .format('dd.MM.yyyy')
      .max(new Date(), 'Пожалуйста, введите корректную дату'),
  }),
  userData: Yup.object().shape({
    firstName: Yup.string()
      .required('Пожалуйста, введите ваше имя')
      .matches(/^[а-яА-ЯёЁ\s]+$/, 'В имени могут использоваться только буквы русского алфавита'),
    lastName: Yup.string()
      .required('Пожалуйста, введите ваше фамилию')
      .matches(
        /^[а-яА-ЯёЁ\s]+$/,
        'В фамилилии могут использоваться только буквы русского алфавита',
      ),
    midName: Yup.string()
      .required('Пожалуйста, введите ваше отчество')
      .matches(/^[а-яА-ЯёЁ\s]+$/, 'В отчестве могут использоваться только буквы русского алфавита'),
    birthDate: Yup.date()
      .format('dd.MM.yyyy')
      .test(
        'isAdulthood',
        'Пользоваться сервисом могут лица старше 18 лет',
        value => differenceInYears(new Date(), value) > 17,
      )
      .max(new Date(), 'Пожалуйста, введите корректную дату'),
  }),
  licence: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями пользования сервисом'),
});

export const carRegistrationValidationSchema = Yup.object().shape({
  brand: Yup.string().required('Пожалуйста, укажите марку автомобиля'),
  model: Yup.string().required('Пожалуйста, укажите модель автомобиля'),
  productionYear: Yup.number().required('Пожалуйста, укажите год выпуска автомобиля'),
  registrationNumber: Yup.string()
    .required('Пожалуйста, укажите гос. номер автомобиля')
    .matches(/^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/, 'Укажите корректный гос номер'),
  licence: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями пользования сервисом'),
});

export const userDataValidationSchema = Yup.object().shape({
  passport: Yup.object().shape({
    serialNumber: Yup.string()
      .required('Пожалуйста, введите серию и номер паспорта')
      .matches(/^\d{10}$/, 'Введите корректную серию и номер паспорта'),
    registrationAddress: Yup.string().required('Пожалуйста, введите адрес регистрации'),
    dateOfIssue: Yup.date()
      .format('dd.MM.yyyy')
      .max(new Date(), 'Пожалуйста, введите корректную дату'),
  }),
  userData: Yup.object().shape({
    firstName: Yup.string()
      .required('Пожалуйста, введите ваше имя')
      .matches(/^[а-яА-ЯёЁ\s]+$/, 'В имени могут использоваться только буквы русского алфавита'),
    lastName: Yup.string()
      .required('Пожалуйста, введите вашу фамилию')
      .matches(
        /^[а-яА-ЯёЁ\s]+$/,
        'В фамилилии могут использоваться только буквы русского алфавита',
      ),
    midName: Yup.string()
      .required('Пожалуйста, введите ваше отчество')
      .matches(/^[а-яА-ЯёЁ\s]+$/, 'В отчестве могут использоваться только буквы русского алфавита'),
    birthDate: Yup.date()
      .format('dd.MM.yyyy')
      .test(
        'isAdulthood',
        'Пользоваться сервисом могут лица старше 18 лет',
        value => differenceInYears(new Date(), value) > 17,
      )
      .max(new Date(), 'Пожалуйста, введите корректную дату'),
  }),
  licence: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями пользования сервисом'),
});

export const carEditValidationSchema = Yup.object().shape({
  rentPricePerDay: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .integer('Пожалуйста, введите целое значение')
    .positive('Цена должна быть положительной'),
  weekDiscount: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .min(0, 'Скидка не может быть отрицательной')
    .max(100, 'Скидка не может быть больше 100 процентов')
    .integer('Пожалуйста, введите целое значение'),
  monthDiscount: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .min(0, 'Скидка не может быть отрицательной')
    .max(100, 'Скидка не может быть больше 100 процентов')
    .integer('Пожалуйста, введите целое значение'),
  dailyMilageLimit: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .integer('Пожалуйста, введите целое значение')
    .positive('Ограничение по пробегу должно быть больше 0'),
  weeklyMilageLimit: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .integer('Пожалуйста, введите целое значение')
    .positive('Ограничение по пробегу должно быть больше 0'),
  monthlyMilageLimit: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .integer('Пожалуйста, введите целое значение')
    .positive('Ограничение по пробегу должно быть больше 0'),
  guestRequirements: Yup.object().shape({
    minAge: Yup.number()
      .integer('Пожалуйста, введите целое значение')
      .min(18, 'Возраст водителя не может быть меньше 18 лет'),
    yearsOfExperience: Yup.number()
      .test('isPossibleValue', 'Введите корректное значение стажа водителя', function(value) {
        const { minAge } = this.parent;
        return minAge - value > 17;
      })
      .nullable()
      .integer('Пожалуйста, введите целое значение')
      .min(0, 'Минимальный стаж не может быть отрицательным'),
  }),
  child_seat: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  navigator: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  registrator: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  roof_rack: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  fridge: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  bike_rack: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  snowboard_rack: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  skiing_rack: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  trailer: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  signaling: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  winch: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  radar_detector: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  hitch: Yup.object().shape({
    checked: Yup.boolean(),
    wholeRentPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
    dailyPrice: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .min(0, 'Цена не может быть отрицательной')
        .integer('Введите целое число')
        .required('Поле обязательно для заполнения'),
      otherwise: Yup.mixed(),
    }),
  }),
  additionalKmPrice: Yup.object().shape({
    checked: Yup.boolean(),
    price: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .required('Поле обязательно для заполнения')
        .integer('Введите целое число')
        .min(0, 'Цена не может быть отрицательной'),
      otherwise: Yup.mixed(),
    }),
  }),
  afterRentWashingPrice: Yup.object().shape({
    checked: Yup.boolean(),
    price: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .required('Поле обязательно для заполнения')
        .integer('Введите целое число')
        .min(0, 'Цена не может быть отрицательной'),
      otherwise: Yup.mixed(),
    }),
  }),
  fuelLiterCompensationPrice: Yup.object().shape({
    checked: Yup.boolean(),
    price: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .required('Поле обязательно для заполнения')
        .integer('Введите целое число')
        .min(0, 'Цена не может быть отрицательной'),
      otherwise: Yup.mixed(),
    }),
  }),
  unlimitedMilagePrice: Yup.object().shape({
    checked: Yup.boolean(),
    price: Yup.mixed().when('checked', {
      is: true,
      then: Yup.number()
        .required('Поле обязательно для заполнения')
        .integer('Введите целое число')
        .min(0, 'Цена не может быть отрицательной'),
      otherwise: Yup.mixed(),
    }),
  }),
  action: Yup.string().strip(),
});
