import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import Button from '@material-ui/core/Button';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import { resetConfirmationError } from '../../../redux/actions/user';
import { getMinutesMessage, getMinutes, getSeconds } from './ConfirmPhone/helper';
import { Loader } from '../../Loader/Backdrop';

import { Error } from '../../../styledComponents';
import { CustomInputLabel, Title } from '../featureStyles';
import { PhoneFormWrapper } from './ConfirmPhone/styles';
import 'react-phone-input-2/lib/style.css';

const TIMER_DELAY = 60;

export const PhoneForm = () => {
  const dispatch = useDispatch();
  // TODO: move it to parent component
  const { error, loading, isSubmit } = useSelector(({ confirmation }) => confirmation);
  const {
    values: { phone },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldError,
    submitForm,
  } = useFormikContext();
  const [timer, setTimer] = useState(0);
  // TODO can we import one function for time message?
  const minutes = getMinutes(timer);
  const minutesMessage = getMinutesMessage(minutes);
  const seconds = getSeconds(minutes, timer);

  useEffect(() => {
    const time = setInterval(() => setTimer(count => (count ? count - 1 : count)), 1000);
    return () => clearInterval(time);
  }, []);

  return (
    <PhoneFormWrapper onSubmit={handleSubmit} className="form">
      <Loader open={loading} />
      <Title>Укажите ваш телефон</Title>
      <p className="text">
        На указанный вами телефон мы вышлем 4-значный код подтверждения. Введите код в поле ниже.
      </p>
      <CustomInputLabel htmlFor="code">Введите номер телефона</CustomInputLabel>
      <PhoneInput
        localization={ru}
        placeholder="+7 (911) 456-78-90"
        onlyCountries={['ru']}
        disableDropdown
        countryCodeEditable={false}
        value={phone}
        country="ru"
        inputClass="phone"
        containerClass="phone-input"
        onChange={phone => {
          if (error) {
            dispatch(resetConfirmationError());
          }
          if (errors.phone) {
            setFieldError('phone');
          }
          setFieldValue('phone', phone);
        }}
        prefix="+"
      />
      {errors.phone && touched.phone ? <Error>{errors.phone}</Error> : null}
      {error && !isSubmit ? <Error>{error}</Error> : null}
      {phone && !isSubmit && (
        <Button
          className="btn"
          variant="contained"
          size="large"
          onClick={() => {
            submitForm();
            setTimer(TIMER_DELAY);
          }}
        >
          Выслать код
        </Button>
      )}
      {isSubmit && Boolean(timer) && (
        <span className="text-resend">{`Повторная отправка через ${minutesMessage} ${seconds} секунд`}</span>
      )}
      {isSubmit && !timer && (
        <Button className="text-btn" variant="contained" size="large" type="submit">
          Отправить код повторно
        </Button>
      )}
    </PhoneFormWrapper>
  );
};
