export const mobileNavigationSchema = [
  {
    title: 'Зарегистрировать машину',
    key: 'car-register',
    path: '/car/register',
  },
  {
    title: 'О сервисе',
    key: 'about',
    path: '/about',
  },
  {
    title: 'Помощь владельцам',
    key: 'help',
    path: 'https://help.getarent.ru/knowledge-bases/2-tsentr-pomoschi/categories/1-vladelets',
  },
  {
    title: 'Тарифы страхования',
    key: 'insurance',
    path: 'https://help.getarent.ru/knowledge-bases/10/articles/80-strahovyie-tarifyi-vladeltsa',
  },
  {
    title: 'Наша команда и контакты',
    key: 'contacts',
    path: '/team',
  },
];

export const desktopNavigationSchema = [
  {
    title: 'О сервисе',
    key: 'about',
    path: '/about',
  },
  {
    title: 'Помощь владельцам',
    key: 'help',
    path: 'https://help.getarent.ru/knowledge-bases/2-tsentr-pomoschi/categories/1-vladelets',
  },
  {
    title: 'Тарифы страхования',
    key: 'insurance',
    path: 'https://help.getarent.ru/knowledge-bases/10/articles/80-strahovyie-tarifyi-vladeltsa',
  },
  {
    title: 'Наша команда и контакты',
    key: 'contacts',
    path: '/team',
  },
];
