import { handleActions } from 'redux-actions';

import { carActions } from '../../actions';

const { registerCar, registerCarSuccess, registerCarFailure } = carActions;

const initialState = { data: {}, loading: false, error: null };
// TODO: create loading component
export default handleActions(
  {
    [registerCar](state) {
      return { ...state, loading: true, error: null };
    },

    [registerCarSuccess](
      state,
      {
        payload: { data },
      },
    ) {
      return { ...state, data, loading: false, error: null };
    },

    [registerCarFailure](
      state,
      {
        payload: { error },
      },
    ) {
      return { ...state, error, loading: false };
    },
  },
  initialState,
);
