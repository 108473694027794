import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Loader } from '../../components/Loader/Backdrop';
import { ReactComponent as BackIcon } from '../../assets/icons/menuArrowLeft.svg';
import { carIncludedFeatures, carDevices, carServices } from '../../assets/data';
import { carActions } from '../../redux/actions';
import { carEditValidationSchema } from '../../utils/validate';
import { statuses } from '../../constants/statuses';

import { updateValues, updatedDates, checkRequiredFields } from './helper';
import { EditContainer, ConfirmationDialog } from './styles';
import tabs from './tabsMapping';

const { NOT_VERIFIED } = statuses;

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

/* eslint-disable */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/* eslint-enable */

const { getCarData, postEditData, deleteCar, startVerification } = carActions;

const CarEdit = () => {
  const history = useHistory();
  const [tabValue, updateTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);

  const loadingEdit = useSelector(({ carEditData: { loading } }) => loading);
  const loadingManage = useSelector(({ carManage: { loading } }) => loading);
  const data = useSelector(
    ({
      carEditData: {
        carData: { car },
      },
    }) => car,
  );
  const { carPhotos } = useSelector(({ uploader }) => uploader);

  const theme = useTheme();

  const { id } = useParams();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tabsHandleChange = (event, value) => {
    updateTabValue(value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEmptyFields([]);
    setOpen(false);
  };
  const handleDelete = () => {
    dispatch(deleteCar(id, history));
  };

  useEffect(() => {
    dispatch(getCarData(id));
  }, [dispatch, id]);

  const CurrentComponent = tabs[tabValue];
  const navigation = {
    prev: tabs[tabValue - 1] ? tabValue - 1 : null,
    next: tabs[tabValue + 1] ? tabValue + 1 : null,
    handler: tabsHandleChange,
  };
  const {
    brand,
    model,
    productionYear,
    bodyType,
    description,
    engineType,
    includedFeatures,
    predefinedAdditionalFeatures = [],
    rentRecomendations,
    greetingMessage,
    guestInstructions,
    minRentDays,
    maxRentDays,
    rentPricePerDay,
    weekDiscount,
    monthDiscount,
    rentLocations,
    dailyMilageLimit,
    weeklyMilageLimit,
    monthlyMilageLimit,
    guestRequirements,
    unavailabilityDates,
    immediateBooking,
    insurancePlanId,
    photos,
    transmissionType,
    transmissionLayout,
    seatsQuantity,
    status,
    location,
  } = data;
  const devices = Object.values(carDevices)
    .map(({ checked, data }) => ({
      ...data,
      checked,
    }))
    .reduce((acc, device) => {
      const carDevice = predefinedAdditionalFeatures.find(el => el.name === device.name);
      return carDevice
        ? { ...acc, [carDevice.name]: { ...carDevice, checked: true } }
        : { ...acc, [device.name]: device };
    }, {});

  const features = Object.keys(carIncludedFeatures).reduce(
    (acc, feature) =>
      includedFeatures && includedFeatures.indexOf(feature) !== -1
        ? { ...acc, [feature]: true }
        : { ...acc, [feature]: false },
    {},
  );

  const services = Object.keys(carServices).reduce((acc, service) => {
    return data[service]
      ? {
          ...acc,
          [service]: {
            checked: true,
            price: data[service],
          },
        }
      : {
          ...acc,
          [service]: {
            checked: false,
          },
        };
  }, {});

  const formikInitialValues = {
    bodyType,
    description,
    engineType,
    ...features,
    ...devices,
    ...services,
    rentRecomendations,
    greetingMessage,
    guestInstructions,
    minRentDays,
    maxRentDays,
    rentPricePerDay,
    weekDiscount,
    monthDiscount,
    rentLocations,
    dailyMilageLimit,
    weeklyMilageLimit,
    monthlyMilageLimit,
    guestRequirements,
    immediateBooking,
    insurancePlanId,
    transmissionType,
    transmissionLayout,
    seatsQuantity,
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      enableReinitialize={true}
      validationSchema={carEditValidationSchema}
      onSubmit={(values, actions) => {
        const { action } = values;
        switch (action) {
          case 'save':
            dispatch(
              postEditData(
                id,
                {
                  ...updateValues(values),
                  blockedDates: updatedDates(unavailabilityDates.blocked),
                  location,
                  photosUpdate: [...carPhotos],
                },
                history,
              ),
            );
            setOpen(false);
            break;

          case 'verificate':
            dispatch(
              startVerification(
                id,
                {
                  ...updateValues(values),
                  blockedDates: updatedDates(unavailabilityDates.blocked),
                  location,
                  photosUpdate: [...carPhotos],
                },
                history,
              ),
            );
            setOpen(false);
            break;

          default:
            console.log(`${action} not in submit scope`);
        }
      }}
      render={({ values, errors, handleSubmit, setFieldValue, handleReset }) => (
        <EditContainer onSubmit={handleSubmit}>
          <Loader open={loadingManage || loadingEdit} />
          <div className="actions">
            <div className="car-box">
              <Link to="/my-cars" className="link">
                <BackIcon className="icon -back" />
                {!isMobile && <span className="link-label">Мои машины</span>}
              </Link>
              <span className="car">{`${brand} ${model}, ${productionYear}`}</span>
            </div>
            {status === NOT_VERIFIED ? (
              <div className="manage">
                <Button className="btn -delete" onClick={handleDelete}>
                  <DeleteIcon />
                </Button>
                <Button
                  className="btn -save"
                  variant="contained"
                  size="large"
                  onClick={handleClickOpen}
                >
                  Сохранить
                </Button>
                <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                  <ConfirmationDialog>
                    <CloseIcon className="close" onClick={handleClose} />
                    <span className="dialog-text">
                      Если вы заполнили все данные о машине, то нажмите{' '}
                      <span className="inline-bold">"Опубликовать"</span>, если вы хотите продолжить
                      заполнение данных о машине позже, то нажмите{' '}
                      <span className="inline-bold">"Cохранить"</span>
                    </span>
                    <div className="btn-wrap">
                      <Button
                        className="btn -save"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setFieldValue('action', 'save', false);
                          handleSubmit();
                        }}
                      >
                        Сохранить
                      </Button>
                      <Button
                        className="btn -publish"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          const emptyFields = checkRequiredFields(values, photos, carPhotos);
                          if (emptyFields.length) {
                            setEmptyFields(emptyFields);
                          } else {
                            setFieldValue('action', 'verificate', false);
                            handleSubmit();
                          }
                        }}
                      >
                        Опубликовать
                      </Button>
                    </div>
                    <ul className="empty-fields-wrap" type="circle">
                      {emptyFields.length > 0 &&
                        emptyFields.map(field => (
                          <li className="empty-field" key={field}>
                            {field}
                          </li>
                        ))}
                    </ul>
                  </ConfirmationDialog>
                </Dialog>
              </div>
            ) : (
              <div className="manage">
                <Button
                  className="btn -save"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setFieldValue('action', 'save', false);
                    handleSubmit();
                  }}
                >
                  Сохранить
                </Button>
              </div>
            )}
          </div>
          <div className="wrap">
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="off"
              onChange={Object.keys(errors).length ? console.log(errors) : tabsHandleChange}
              className="navigation"
            >
              <Tab label="О машине" className="tab-label" {...a11yProps(0)} />
              <Tab label="Страхование" className="tab-label" {...a11yProps(1)} />
              <Tab label="Условия и цены" className="tab-label" {...a11yProps(2)} />
              <Tab
                label="Доступность"
                icon={<DateRangeIcon />}
                className="tab-label"
                {...a11yProps(3)}
              />
            </Tabs>
          </div>
          <Divider className="tabs-divider" />
          <CurrentComponent parentNavigation={navigation} />
        </EditContainer>
      )}
    />
  );
};

export default CarEdit;
