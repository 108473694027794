import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext, Field } from 'formik';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { resetConfirmationError } from '../../../../redux/actions/user';
import { Error } from '../../../../styledComponents';
import { CustomInputLabel } from '../../featureStyles';
import { ConfirmationCodeWrapper } from './styles';

export const ConfirmationCodeForm = () => {
  const dispatch = useDispatch();
  const { error } = useSelector(({ confirmation }) => confirmation);
  const {
    values: { confirmationCode },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldError,
    handleBlur,
  } = useFormikContext();
  return (
    <ConfirmationCodeWrapper onSubmit={handleSubmit}>
      <CustomInputLabel htmlFor="confirmationCode">Введите код</CustomInputLabel>
      <Field
        id="confirmationCode"
        className={
          (errors.confirmationCode && touched.confirmationCode) || error
            ? 'code-input error'
            : 'code-input'
        }
        inputProps={{
          maxLength: 4,
        }}
        value={confirmationCode}
        onChange={({ target: { value } }) => {
          if (error) {
            dispatch(resetConfirmationError());
          }
          if (errors.confirmationCode) {
            setFieldError('confirmationCode');
          }
          setFieldValue('confirmationCode', value);
        }}
        onBlur={handleBlur}
        component={Input}
        type="text"
      />
      {errors.confirmationCode && touched.confirmationCode ? (
        <Error>{errors.confirmationCode}</Error>
      ) : null}
      {error ? <Error>{error}</Error> : null}
      <Button
        disabled={confirmationCode.length !== 4}
        className="btn"
        variant="contained"
        size="large"
        type="submit"
      >
        Подтвердить
      </Button>
    </ConfirmationCodeWrapper>
  );
};
