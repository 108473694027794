import { createAction } from 'redux-actions';
import userTypes from '../../types/user';

// scoring
const {
  scoring: {
    POST_SCORING_DATA,
    POST_SCORING_DATA_SUCCESS,
    POST_SCORING_DATA_FAILURE,
    GET_SCORING_RESULTS,
    GET_SCORING_RESULTS_SUCCESS,
    GET_SCORING_RESULTS_FAILURE,
    SCORING_REFRESH,
    SCORING_REFRESH_SUCCESS,
    SCORING_REFRESH_FAILURE,
  },
  update: {
    UPDATE_USER_DATA,
    UPDATE_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_ERROR,
    RESET_USER_UPDATE_ERRORS,
  },
  confirmation: {
    CHECK_USER_DATA_COMPLETENESS,
    CHECK_USER_DATA_COMPLETENESS_SUCCESS,
    CHECK_USER_DATA_COMPLETENESS_ERROR,
    SUBMIT_RECOVERY_EMAIL,
    SUBMIT_RECOVERY_EMAIL_SUCCESS,
    SUBMIT_RECOVERY_EMAIL_ERROR,
    CONFIRM_RECOVERY_EMAIL,
    CONFIRM_RECOVERY_EMAIL_SUCCESS,
    CONFIRM_RECOVERY_EMAIL_ERROR,
    SUBMIT_USER_EMAIL,
    SUBMIT_USER_EMAIL_SUCCESS,
    SUBMIT_USER_EMAIL_ERROR,
    SUBMIT_USER_PHONE,
    SUBMIT_USER_PHONE_SUCCESS,
    SUBMIT_USER_PHONE_ERROR,
    CONFIRM_USER_EMAIL,
    CONFIRM_USER_EMAIL_SUCCESS,
    CONFIRM_USER_EMAIL_ERROR,
    CONFIRM_USER_PHONE,
    CONFIRM_USER_PHONE_SUCCESS,
    CONFIRM_USER_PHONE_ERROR,
    RESET_CONFIRMATION,
    RESET_CONFIRMATION_ERROR,
  },
} = userTypes;

// Scoring
export const postScoringData = createAction(POST_SCORING_DATA);
export const postScoringDataSuccess = createAction(POST_SCORING_DATA_SUCCESS);
export const postScoringDataFailure = createAction(POST_SCORING_DATA_FAILURE, error => ({
  error,
}));

export const getScoringResults = createAction(GET_SCORING_RESULTS);
export const getScoringResultsSuccess = createAction(GET_SCORING_RESULTS_SUCCESS, data => ({
  data,
}));
export const getScoringResultsFailure = createAction(GET_SCORING_RESULTS_FAILURE, error => ({
  error,
}));

export const scoringRefresh = createAction(SCORING_REFRESH);
export const scoringRefreshSuccess = createAction(SCORING_REFRESH_SUCCESS);
export const scoringRefreshFailure = createAction(SCORING_REFRESH_FAILURE, error => ({
  error,
}));

// User data Update
export const updateUserData = createAction(UPDATE_USER_DATA);
export const updateUserDataSuccess = createAction(UPDATE_USER_DATA_SUCCESS, user => ({ user }));
export const updateUserDataError = createAction(UPDATE_USER_DATA_ERROR);
export const resetUserUpdateErrors = createAction(RESET_USER_UPDATE_ERRORS);

// Confirmation
export const checkUserDataCompleteness = createAction(CHECK_USER_DATA_COMPLETENESS);
export const checkUserDataCompletenessSuccess = createAction(
  CHECK_USER_DATA_COMPLETENESS_SUCCESS,
  confirmationData => ({ confirmationData }),
);
export const checkUserDataCompletenessError = createAction(CHECK_USER_DATA_COMPLETENESS_ERROR);
export const submitUserEmail = createAction(SUBMIT_USER_EMAIL);
export const submitUserEmailSuccess = createAction(SUBMIT_USER_EMAIL_SUCCESS);
export const submitUserEmailError = createAction(SUBMIT_USER_EMAIL_ERROR);
export const submitUserPhone = createAction(SUBMIT_USER_PHONE);
export const submitUserPhoneSuccess = createAction(SUBMIT_USER_PHONE_SUCCESS);
export const submitUserPhoneError = createAction(SUBMIT_USER_PHONE_ERROR);
export const submitRecoveryEmail = createAction(SUBMIT_RECOVERY_EMAIL);
export const submitRecoveryEmailSuccess = createAction(SUBMIT_RECOVERY_EMAIL_SUCCESS, id => ({
  id,
}));
export const submitRecoveryEmailError = createAction(SUBMIT_RECOVERY_EMAIL_ERROR);
export const confirmRecoveryEmail = createAction(CONFIRM_RECOVERY_EMAIL);
export const confirmRecoveryEmailSuccess = createAction(CONFIRM_RECOVERY_EMAIL_SUCCESS, id => ({
  id,
}));
export const confirmRecoveryEmailError = createAction(CONFIRM_RECOVERY_EMAIL_ERROR);
export const confirmUserEmail = createAction(CONFIRM_USER_EMAIL);
export const confirmUserEmailSuccess = createAction(
  CONFIRM_USER_EMAIL_SUCCESS,
  confirmationData => ({ confirmationData }),
);
export const confirmUserEmailError = createAction(CONFIRM_USER_EMAIL_ERROR);
export const confirmUserPhone = createAction(CONFIRM_USER_PHONE);
export const confirmUserPhoneSuccess = createAction(
  CONFIRM_USER_PHONE_SUCCESS,
  confirmationData => ({ confirmationData }),
);
export const confirmUserPhoneError = createAction(CONFIRM_USER_PHONE_ERROR);
export const resetConfirmation = createAction(RESET_CONFIRMATION);
export const resetConfirmationError = createAction(RESET_CONFIRMATION_ERROR);
