import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { parseISO, format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ControlLocation from '../Controls/City';
import { ControlDate } from '../Controls/Date';
import { getTime } from '../../../utils/date';
import { useQuery, submitQueries } from '../../../services/query-service';
import { ReactComponent as DateArrow } from '../../../assets/icons/arrowRight.svg';
import { searchCitiesData } from '../../../assets/data';
import { SearchResultContainer, ChangeParametersLink } from './styles';

// TODO remove deprected
export const SearchResultLocation = ({ dateStart, dateEnd }) => {
  const [open, setOpen] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const theme = useTheme();
  const location = query.get('location');
  const toggleMenu = () => setOpen(!open);
  const city = location ? searchCitiesData.find(({ value }) => value === location).label : '';
  const startPeriodTime = getTime(dateStart);
  const endPeriodTime = getTime(dateEnd);
  const startPeriod = format(parseISO(dateStart), 'd MMM y', { locale: ruLocale });
  const endPeriod = format(parseISO(dateEnd), 'd MMM y', { locale: ruLocale });
  const mobileStartPeriod = format(parseISO(dateStart), 'd MMM', { locale: ruLocale });
  const mobileEndPeriod = format(parseISO(dateEnd), 'd MMM', { locale: ruLocale });
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'));

  const initialValues = {
    dateStart,
    dateEnd,
  };

  return (
    <SearchResultContainer>
      <p className="location">{city}</p>
      <p className="periods-wrapper">
        <span className="date-time">
          {startPeriod},{startPeriodTime}
          <DateArrow className="arrow-right" />
          {endPeriod},{endPeriodTime}
        </span>
        <ChangeParametersLink onClick={toggleMenu} className="change-parameters">
          Изменить параметры поиска
        </ChangeParametersLink>
      </p>
      <p className="periods-wrapper -mobile">
        <span className="date-time">
          {mobileStartPeriod},{startPeriodTime}
          <DateArrow className="arrow-right" />
          {mobileEndPeriod},{endPeriodTime}
        </span>
      </p>
      {open ? (
        <ExpandMoreIcon onClick={toggleMenu} className="menu-opened" />
      ) : (
        <ExpandMoreIcon onClick={toggleMenu} className="menu-closed" />
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          submitQueries(values, query, history);
          toggleMenu();
        }}
      >
        {({ handleSubmit }) => (
          <div className={open ? 'date-pick opened' : 'date-pick'}>
            {/* <ControlLocation /> */}
            <ControlDate />
            {!isLaptop && (
              <div className="actions">
                <Button className="btn -cancel" onClick={toggleMenu}>
                  Отмена
                </Button>
                <Button type="submit" className="btn -search" onClick={handleSubmit}>
                  Найти
                </Button>
              </div>
            )}
          </div>
        )}
      </Formik>
    </SearchResultContainer>
  );
};
