import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TabPrices } from '../../components/CarEdit/TabPrices';
import { TabDevices } from '../../components/CarEdit/TabDevices';
import { TabServices } from '../../components/CarEdit/TabServices';
import { TabRules } from '../../components/CarEdit/TabRules';
import { TabInformation } from '../../components/CarEdit/TabInformation';

import { PanelTitle, AccordionWrapper } from '../../styledComponents';

export default () => {
  return (
    <AccordionWrapper>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="price-content"
          id="price-header"
        >
          <PanelTitle>Стоимость аренды</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabPrices />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="rules-content"
          id="rules-header"
        >
          <PanelTitle>Правила аренды</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabRules />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="devices-content"
          id="devices-header"
        >
          <PanelTitle>Устройства для поездок</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabDevices />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="services-content"
          id="services-header"
        >
          <PanelTitle>Ваши Услуги</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabServices />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="instractions-content"
          id="instractions-header"
        >
          <PanelTitle>Инструкции водителям</PanelTitle>
        </AccordionSummary>
        <AccordionDetails>
          <TabInformation />
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  );
};
