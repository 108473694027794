import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const InformationWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
    }

    .formgroup {
      margin-top: 24px;
    }

    .field {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media ${breakpoints.tablet} {
    .formgroup {
      margin-top: 8px;
    }

    .field:last-child {
      margin-bottom: 40px;
    }
  }
`;
