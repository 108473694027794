import React, { useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import Link from '@material-ui/core/Link';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import { Autocomplete } from '../../Autocomplete';
import { carDurations } from '../../../assets/data';

import { Error } from '../../../styledComponents';
import { TabTitle, TabLabel, TabRecomendation } from '../featureStyles';
import { PriceWrapper } from './styles';

const { minRent, maxRent } = carDurations;

export const TabPrices = () => {
  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { minRentDays, maxRentDays, rentPricePerDay, weekDiscount, monthDiscount } = values;
  return (
    <PriceWrapper>
      <FormGroup className="formgroup">
        <TabTitle>Установите сроки аренды</TabTitle>
        <TabRecomendation className="text">
          Определите максимальную и минимальную длительность аренды машины
        </TabRecomendation>
        <TabLabel htmlFor="minRentDays">Минимальная длительность</TabLabel>
        <Autocomplete
          id="minRentDays"
          className="field"
          options={Object.values(minRent)}
          getOptionLabel={({ label }) => label}
          value={minRent[minRentDays] || { label: '' }}
          onChange={(_, newValue) => setFieldValue('minRentDays', newValue ? newValue.value : null)}
          renderInput={params => (
            <TextField {...params} placeholder="- Минимальная длительность -" />
          )}
        />
        <TabLabel htmlFor="maxRentDays">Максимальная длительность</TabLabel>
        <Autocomplete
          id="maxRentDays"
          className="field"
          options={Object.values(maxRent)}
          getOptionLabel={({ label }) => label}
          value={maxRent[maxRentDays] || { label: '' }}
          onChange={(_, newValue) => setFieldValue('maxRentDays', newValue ? newValue.value : null)}
          renderInput={params => (
            <TextField {...params} placeholder="- Максимальная длительность -" />
          )}
        />
      </FormGroup>
      <FormGroup className="formgroup">
        <TabTitle>Установите ваши цены и скидки</TabTitle>
        <TabRecomendation className="text">
          Прочитайте инструкцию{' '}
          <Link
            target="_blank"
            href="https://help.getarent.ru/knowledge-bases/9/articles/96-kakuyu-tsenu-ustanovit-za-arendu"
            className="price-link"
          >
            как установить цену за аренду
          </Link>
        </TabRecomendation>
        <TabLabel htmlFor="rentPricePerDay">Стоимость аренды в сутки, &#8381;</TabLabel>
        <Field
          id="rentPricePerDay"
          className="field"
          placeholder="Например, 2500"
          defaultValue={rentPricePerDay}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('rentPricePerDay', e.target.value);
          }}
        />
        {errors.rentPricePerDay && touched.rentPricePerDay ? (
          <Error>{errors.rentPricePerDay}</Error>
        ) : null}
        <TabLabel htmlFor="weekDiscount">Скидка при аренде на неделю, %</TabLabel>
        <Field
          id="weekDiscount"
          className="field"
          placeholder="Например, 0 или 15"
          defaultValue={weekDiscount}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('weekDiscount', e.target.value);
          }}
        />
        {errors.weekDiscount && touched.weekDiscount ? <Error>{errors.weekDiscount}</Error> : null}
        <TabLabel htmlFor="monthDiscount">Скидка при аренде на месяц, %</TabLabel>
        <Field
          id="monthDiscount"
          className="field"
          placeholder="Например, 0 или 25"
          defaultValue={monthDiscount}
          component={Input}
          type="number"
          onBlur={e => {
            handleBlur(e);
            setFieldValue('monthDiscount', e.target.value);
          }}
        />
        {errors.monthDiscount && touched.monthDiscount ? (
          <Error>{errors.monthDiscount}</Error>
        ) : null}
      </FormGroup>
    </PriceWrapper>
  );
};
