import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const ServiceWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: calc(100% - 30px);
      margin: 3px 0 0 30px;
    }

    .input {
      width: 149px;
    }

    .additional-price {
      margin-left: 12px;
      font-size: 16px;
      color: var(--grey-text);
    }
  }

  @media ${breakpoints.tablet} {
    .input {
      width: 280px;
    }
  }
`;
