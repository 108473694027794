import { styled } from 'linaria/react';
import { breakpoints } from '../../../constants/breakpoints';

export const RulesWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
    }

    .text {
      margin: 8px 0 24px;
    }

    .formgroup {
      position: relative;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .subtitle {
      margin-bottom: 16px;
    }

    .rent-rules {
      margin: 8px 0 9px;
    }

    .radio:last-child {
      margin-bottom: -9px;
    }

    .input {
      width: 100%;
      margin-bottom: 16px;
    }

    .text-link {
      cursor: pointer;
      text-decoration: none;
      border-bottom: dotted;
      border-width: thin;
      color: #066bd6;
    }

    .advice {
      position: absolute;
      top: 70px;
      left: 345px;
      width: 420px;
      margin-top: 0;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      margin-bottom: 24px;
    }

    .formgroup {
      margin-bottom: 32px;
    }

    .radio:focus-within ~ .advice {
      display: block;
    }

    .input {
      width: 310px;

      &:focus-within ~ .advice {
        display: block;
      }
    }

    .driver-requirements {
      margin: 0;
    }
  }
`;
