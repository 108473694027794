import React from 'react';
import Button from '@material-ui/core/Button';
import { ReactComponent as ClosingCross } from '../../../assets/icons/closingCross.svg';
import { Wrapper } from './styles';

export const SearchControlButton = ({
  resetForm = () => {},
  resetState = () => {},
  submit = () => {},
  close = () => {},
  label = '',
  className,
  closingLabel = 'Сбросить',
  applyLabel = 'Показать',
}) => (
  <Wrapper className={className}>
    <Button
      variant="text"
      size="small"
      className="btn -cancel"
      onClick={() => {
        resetForm();
        close();
      }}
    >
      <ClosingCross className="closing-icon" />
      {closingLabel}
    </Button>
    <Button
      variant="contained"
      color="secondary"
      className="btn -submit"
      onClick={() => {
        submit();
        close();
      }}
    >
      {applyLabel}
    </Button>
  </Wrapper>
);
