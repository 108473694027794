import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { carActions, errorActions, uploaderActions } from '../actions';
import { CAR_URL } from '../../constants/endpoints';
import { httpVerbs } from '../../constants/http';
import { postCarEditErrors } from '../../constants/errors';
import { makeCall } from '../../services/api-service';

const { GET, PATCH } = httpVerbs;

const {
  getCarData,
  getCarDataSuccess,
  getCarDataFailure,
  postEditData,
  // uploadCarFiles,
  // uploadCarFilesSuccess,
  postEditDataSuccess,
  postEditDataFailure,
} = carActions;

const { clearStorage } = uploaderActions;

const { setError } = errorActions;

function* getCarEditHandler(action) {
  const { payload } = action;
  try {
    const { data } = yield makeCall(GET, `${CAR_URL}/${payload}`);
    yield put(getCarDataSuccess(data));
  } catch (error) {
    yield put(getCarDataFailure(error));
  }
}

function* postEditDataHandler(action) {
  const {
    payload: { id, data, history },
  } = action;
  try {
    yield makeCall(PATCH, `${CAR_URL}/${id}`, data);
    yield put(postEditDataSuccess());
    yield put(clearStorage());
    history.push('/my-cars');
  } catch (error) {
    const message = postCarEditErrors[error.response.status];
    yield put(setError(message));
    yield put(postEditDataFailure(error));
  }
}

function* watchEditEvent() {
  yield takeEvery(postEditData, postEditDataHandler);
  // TODO maybe better takeLatest
  yield takeEvery(getCarData, getCarEditHandler);
}

export default watchEditEvent;
