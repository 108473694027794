import { styled } from 'linaria/react';
import Drawer from '@material-ui/core/Drawer';
import { breakpoints } from '../../../../constants/breakpoints';

export const MobileNavWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
    }

    .icon-wrap {
      position: fixed;
      top: 20px;
      right: 3%;

      &:hover {
        background: none;
      }
    }
  }
`;

export const SideBarMenu = styled(Drawer)`
  & {
    min-height: 0;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    padding: 70px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .link {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #303133;

    &:hover {
      color: #066bd6;
      font-weight: 600;
    }

    &.-logout {
      color: #ff0303;
      padding: 5% 5% 10%;
    }

    &.-login {
      padding: 5% 5% 10%;
    }

    &.-login:hover {
      background: none;
    }
  }

  .item {
    padding: 5% 5%;

    &:hover {
      background: none;
    }
  }

  .list {
    padding: 0;
  }

  .divider {
    margin: 5% 0;
  }
`;
